import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function addUpdateCoaching(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COACHING.addUpdateCoaching, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function updateAdminCoaching(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COACHING.updateAdminCoaching, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getCoachingList(auth) {
  return axios.get(APP_SETTINGS.API_PATH.COACHING.getCoachingList, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getPurchasedCoaching(auth) {
  return axios.get(APP_SETTINGS.API_PATH.COACHING.getPurchasedCoaching, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function deleteCoaching(CoachingId, auth) {
  return axios.delete(
    `${APP_SETTINGS.API_PATH.COACHING.deleteCoaching}?coachingId=${CoachingId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function publishCoaching(data, auth) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.COACHING.publishCoaching}?coachingId=${data.coachingId}`,
    { coachingId: data.coachingId },
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function purchaseCoaching(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COACHING.purchaseCoaching, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function updateCoaching(coachingId, auth) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COACHING.updateCoaching}?coachingId=${coachingId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}

export function markCoachingAsCompleted(data, auth) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.COACHING.markCoachingAsCompleted}?CoachingId=${data.CoachingId}`,
    { CoachingId: data.CoachingId },
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}

export function getUpcomingCoaching(auth) {
  return axios.get(`${APP_SETTINGS.API_PATH.COACHING.getUpcomingCoaching}`, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getCompletedCoaching(auth) {
  return axios.get(`${APP_SETTINGS.API_PATH.COACHING.getCompletedCoaching}`, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getTodaysCoaching(auth) {
  return axios.get(`${APP_SETTINGS.API_PATH.COACHING.getTodaysCoaching}`, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getTopCoaching(auth) {
  return axios.get(`${APP_SETTINGS.API_PATH.COACHING.getTopCoaching}`, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  });
}
export function getTodaysCoachingLP(auth) {
  return axios.get(`${APP_SETTINGS.API_PATH.COACHING.getTodaysCoachingLP}`, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  });
}
export function getUpcomingCoachingLP(auth) {
  return axios.get(`${APP_SETTINGS.API_PATH.COACHING.getUpcomingCoachingLP}`, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  });
}
