import { request, success, failure, LOACTION_REQUEST } from "./utilities";
import { getAllCountry, countryByName, getIndustry } from "../api";
export function getCountries() {
  return (dispatch) => {
    dispatch(request(LOACTION_REQUEST.GET_COUNTRIES_REQUEST));
    getAllCountry().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(LOACTION_REQUEST.GET_COUNTRIES_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              LOACTION_REQUEST.GET_COUNTRIES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            LOACTION_REQUEST.GET_COUNTRIES_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CountryByName(data) {
  return (dispatch) => {
    dispatch(request(LOACTION_REQUEST.COUNTRIES_BY_NAME_REQUEST));
    countryByName(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              LOACTION_REQUEST.COUNTRIES_BY_NAME_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              LOACTION_REQUEST.COUNTRIES_BY_NAME_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            LOACTION_REQUEST.COUNTRIES_BY_NAME_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetIndustry (data) {
  return (dispatch) => {
    dispatch(request(LOACTION_REQUEST.INDUSTRY_BY_TYPE_REQUEST));
    getIndustry(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              LOACTION_REQUEST.INDUSTRY_BY_TYPE_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              LOACTION_REQUEST.INDUSTRY_BY_TYPE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            LOACTION_REQUEST.INDUSTRY_BY_TYPE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
