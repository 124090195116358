import {
  request,
  success,
  failure,
  MENU_PERMISSION_REQUEST,
} from "./utilities";
import { createPermission, getMenuPermissionById } from "../api";

export function CreatePermission(data, auth, Notification, moveToNext) {
  return (dispatch) => {
    dispatch(request(MENU_PERMISSION_REQUEST.MENU_PERMISSION_REQUEST));
    createPermission(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              MENU_PERMISSION_REQUEST.MENU_PERMISSION_SUCCESS,
              response.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              MENU_PERMISSION_REQUEST.MENU_PERMISSION_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            MENU_PERMISSION_REQUEST.MENU_PERMISSION_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetMenuPermissionById(userId, auth) {
  return (dispatch) => {
    dispatch(request(MENU_PERMISSION_REQUEST.GET_PERMISSIONBY_ID_REQUEST));
    getMenuPermissionById(userId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              MENU_PERMISSION_REQUEST.GET_PERMISSIONBY_ID_SUCCESS,
              response.data
            )
          );
        } else {
          dispatch(
            failure(
              MENU_PERMISSION_REQUEST.GET_PERMISSIONBY_ID_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MENU_PERMISSION_REQUEST.GET_PERMISSIONBY_ID_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
