import React from 'react';
import { Form } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
export default class PaymentPlan extends React.Component {
    state = {
      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
    };
   
    handleInputFocus = (e) => {
      this.setState({ focus: e.target.name });
    }
    
    handleInputChange = (e) => {
      const { name, value } = e.target;
      
      this.setState({ [name]: value });
    }
    
    
    render() {
      return (
        <div id="PaymentForm">
          <Cards
            cvc={this.state.cvc}
            expiry={this.state.expiry}
            focused={this.state.focus}
            name={this.state.name}
            number={this.state.number}
          />
          <Form>
          <Form.Group>
           
            <Form.Control
              className="my-2 rccs"
              name="number"
              type="number"
              placeholder="Enter Valid number"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
             <Form.Control
              className="my-2 rccs"
              name="name"
              type="text"
              placeholder="Enter Your Name"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
             <Form.Control
              className="my-2 rccs"
              name="expiry"
              type="number"
              placeholder="Enter Expiry number"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
             <Form.Control
              className="my-2 rccs"
              name="cvc"
              type="number"
              placeholder="Enter CVC  number"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
          </Form.Group>
              {/* <input
              type="tel"
              name="number"
              placeholder="Card Number"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
            ... */}
          </Form>
        </div>
      );
    }
  }


  

 

  
