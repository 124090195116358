import React, { useEffect, useState } from "react";
import { IMAGES } from "../../assets/images";
import Dropdown from "react-bootstrap/Dropdown";
import { Loader } from "../../assets";
import { PATH } from "../../config";
import { FaBars } from "react-icons/fa";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { Link, useHistory } from "react-router-dom";
import { SignOut } from "./SignOut";
import { Notification } from "./Notification";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { GetMenuPermissionById } from "../../redux/actions";

export function Header() {
  let auth = useAuth();
  let imgFile = auth.edu_tech_user.image;
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetMenuPermissionById(auth?.edu_tech_user?.userId, auth));
  }, [dispatch, auth]);
  let rolePermission = useSelector((state) => state.permission);
  return (
    <>
      {loading === true && <Loader />}
      <div className="header">
        {/* Logo */}
        <div className="header-left">
          {auth.edu_tech_user.roleId === 3 && (
            <>
              <Link to={PATH.DASHBOARD} className="logo mr-0">
                <img
                  src={IMAGES.EDUTECH_LOGO}
                  alt="Logo"
                  className="img-fluid"
                />
              </Link>
            </>
          )}
          {auth.edu_tech_user.roleId === 2 && (
            <>
              <Link to={PATH.TEACHERDASHBOARD} className="logo mr-0">
                <img
                  src={IMAGES.EDUTECH_LOGO}
                  alt="Logo"
                  className="img-fluid"
                />
              </Link>
            </>
          )}
          {auth.edu_tech_user.roleId === 1 && (
            <>
              <Link to={PATH.ADMIN_DASHBOARD} className="logo mr-0">
                <img
                  src={IMAGES.EDUTECH_LOGO}
                  alt="Logo"
                  className="img-fluid"
                />
              </Link>
            </>
          )}
          {auth.edu_tech_user.roleId === 4 && (
            <>
              <Link to={PATH.ADMIN_FAQ} className="logo mr-0">
                <img
                  src={IMAGES.EDUTECH_LOGO}
                  alt="Logo"
                  className="img-fluid"
                />
              </Link>
            </>
          )}
        </div>
        <div className="PageName">
          <a href="javascript:void(0)" id="toggle_btn">
            <FaBars className="toggleset" />
          </a>
          {auth.edu_tech_user.roleId === 4 && (
            <>
              {history.location.pathname === PATH.ADMIN_FAQ && (
                <h3 className="ml-2">{`FAQ's`}</h3>
              )}

              {rolePermission?.getMenuPermissionById?.data?.map(
                (course, index) => {
                  return (
                    <>
                      {course.sideBarMenuId === 21 ? (
                        <>
                          {history.location.pathname ===
                            PATH.CONTACT_US_ADMIN && (
                            <h3 className="ml-2">{`Contact Us`}</h3>
                          )}
                          {history.location.pathname ===
                            PATH.TEACHERCURRICULUMPUBLISH ||
                          history.location.pathname ===
                            PATH.TEACHERCOURSESGROUP ? (
                            <h3 className="ml-2">{`Courses`}</h3>
                          ) : (
                            <></>
                          )}
                          {history.location.pathname ===
                            PATH.TEACHERNEWCOURSE && (
                            <h3 className="ml-2">{`New Course`}</h3>
                          )}
                          {history.location.pathname ===
                            PATH.TEACHER_COURSE_PREVIEW && (
                            <h3 className="ml-2">{`Course Preview`}</h3>
                          )}
                          {history.location.pathname ===
                            PATH.TEACHERCURRICULUMLIST ||
                          history.location.pathname ===
                            PATH.CREATENEWSECTION ? (
                            <h3 className="ml-2">{`Curriculum`}</h3>
                          ) : (
                            <></>
                          )}
                          {history.location.pathname === PATH.PRICINGPLAN ||
                          history.location.pathname ===
                            PATH.TEACHERPRICINGLIST ? (
                            <h3 className="ml-2">{`Pricing`}</h3>
                          ) : (
                            <></>
                          )}
                          {history.location.pathname ===
                            PATH.TEACHER_CREATE_COURSE && (
                            <h3 className="ml-2">{`Create Courses`}</h3>
                          )}
                          {history.location.pathname === PATH.ADDLECTURES && (
                            <h3 className="ml-2">{`First Lecture`}</h3>
                          )}
                          {history.location.pathname ===
                            PATH.TEACHERCOURSEINFORMATION ||
                          history.location.pathname ===
                            PATH.ADMIN_COURSE_PREVIEW ||
                          history.location.pathname ===
                            PATH.TEACHERCURRICULUMPREVIEW ? (
                            <h3 className="ml-2">{`Preview`}</h3>
                          ) : (
                            <></>
                          )}
                          {history.location.pathname === PATH.COURSE ||
                          history.location.pathname === PATH.COURSE_PREVIEW ? (
                            <h3 className="ml-2">{`MY COURSES`}</h3>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {course.sideBarMenuId === 22 ? (
                        <>
                          {history.location.pathname ===
                            PATH.CONTACT_US_ADMIN && (
                            <h3 className="ml-2">{`Contact Us`}</h3>
                          )}
                          {history.location.pathname === PATH.COACHINGLIST ||
                          history.location.pathname === PATH.FINISHUPCOACHING ||
                          history.location.pathname ===
                            PATH.TEACHERUPDATECOACHING ||
                          history.location.pathname === PATH.TEACHERGROUP ? (
                            <h3 className="ml-2">{`Coaching`}</h3>
                          ) : (
                            <></>
                          )}
                          {history.location.pathname ===
                            PATH.TEACHERCREATECOACHING && (
                            <h3 className="ml-2">{`Create Coaching`}</h3>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {course.sideBarMenuId === 23 ? (
                        <>
                          {history.location.pathname ===
                            PATH.ADMINPRICINGPLAN ||
                          history.location.pathname ===
                            PATH.TEACHERPRICINGLIST ? (
                            <h3 className="ml-2">{`Pricing`}</h3>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {course.sideBarMenuId === 24 ? (
                        <>
                          {history.location.pathname ===
                            PATH.PAYMENT_DETAIL && (
                            <h3 className="ml-2">{`Payment Details`}</h3>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {course.sideBarMenuId === 25 ? (
                        <>
                          {history.location.pathname === PATH.MANAGE_USER ? (
                            <h3 className="ml-2">{`Manage Users`}</h3>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {course.sideBarMenuId === 26 ? (
                        <>
                          {history.location.pathname === PATH.REQUEST_TOPIC && (
                            <h3 className="ml-2">{`Category Request`}</h3>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                }
              )}
            </>
          )}
          {auth.edu_tech_user.roleId === 3 && (
            <>
              {history.location.pathname === PATH.DASHBOARD ||
              history.location.pathname === PATH.Dashboard_COURSE_PREVIEW ||
              history.location.pathname === PATH.DASHBOARD_ADDTOCARTCOURSE ||
              history.location.pathname === PATH.DASHBOARD_BREAKTHROUGH ||
              history.location.pathname === PATH.EXPLOREDASHBOARDCATEGORY ||
              history.location.pathname === PATH.COMPLETE_COURSE ? (
                <h3 className="ml-2">{`Dashboard`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.EXPLORE ||
              history.location.pathname === PATH.EXPLORE_COURSE_PREVIEW ||
              history.location.pathname === PATH.COACHING_PREVIEW ||
              history.location.pathname === PATH.EXPLORE_BREAKTHROUGH ||
              history.location.pathname === PATH.EXPLORECATEGORY ||
              history.location.pathname === PATH.EXPLORE_ADDTOCARTCOURSE ? (
                <h3 className="ml-2">{`EXPLORE`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.COURSE ||
              history.location.pathname === PATH.COURSES_BREAKTHROUGH ||
              history.location.pathname === PATH.COURSE_ADDTOCARTCOURSE ||
              history.location.pathname === PATH.WISHLIST_COURSE_PREVIEW ||
              history.location.pathname === PATH.MY_COURSE_PREVIEW ||
              history.location.pathname === PATH.COURSE_PREVIEW ? (
                <h3 className="ml-2">{`MY COURSES`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.MY_COACHING_PREVIEW ||
              history.location.pathname === PATH.MY_COACHING ? (
                <h3 className="ml-2">{`MY COACHINGS`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.STUDENT_PROFILEEDIT ||
              history.location.pathname === PATH.STUDENT_PROFILESHOW ||
              history.location.pathname === PATH.TEACHER_PROFILEEDIT ||
              history.location.pathname === PATH.TEACHER_PROFILESHOW ? (
                <h3 className="ml-2">{`PROFILE`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.SETTINGS && (
                <h3 className="ml-2">{`SETTINGS`}</h3>
              )}
              {/* {history.location.pathname === PATH.EXPLORECATEGORY && (
                <h3 className="ml-2">{`Current Progress`}</h3>
              )} */}
              {history.location.pathname === PATH.STUDENT_CREDITS && (
                <h3 className="ml-2">{`CREDITS`}</h3>
              )}
              {history.location.pathname === PATH.PURCHASECREDIT && (
                <h3 className="ml-2">{`PURCHASE CREDIT`}</h3>
              )}
              {history.location.pathname === PATH.EXPLOREPRICINGPLANS && (
                <h3 className="ml-2">{`PRICING PLAN`}</h3>
              )}
              {history.location.pathname === PATH.WISHLIST && (
                <h3 className="ml-2">{`WISHLIST`}</h3>
              )}
              {history.location.pathname === PATH.STUDENT_FAQ && (
                <h3 className="ml-2">{`FAQ'S`}</h3>
              )}
              {history.location.pathname === PATH.CONTACT_US && (
                <h3 className="ml-2">{`Contact Us`}</h3>
              )}
            </>
          )}
          {auth.edu_tech_user.roleId === 2 && (
            <>
              {history.location.pathname === PATH.TEACHERDASHBOARD ||
              history.location.pathname === PATH.DASHBOARD ||
              history.location.pathname === PATH.DASHBOARDBREAKTHROUGH ||
              history.location.pathname === PATH.COMPLETE_COURSE ? (
                <h3 className="ml-2">{`Dashboard`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHERCURRICULUMLIST ||
              history.location.pathname === PATH.CREATENEWSECTION ? (
                <h3 className="ml-2">{`Curriculum`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHERNEWCOURSE && (
                <h3 className="ml-2">{`New Course`}</h3>
              )}
              {history.location.pathname === PATH.TEACHERCURRICULUMPUBLISH ||
              history.location.pathname === PATH.TEACHERCOURSESGROUP ? (
                <h3 className="ml-2">{`Courses`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHER_TOTAL_COURSES && (
                <h3 className="ml-2">{`Total Courses`}</h3>
              )}
              {history.location.pathname === PATH.TEACHER_TOTAL_STUDENT && (
                <h3 className="ml-2">{`Total Students`}</h3>
              )}
              {history.location.pathname === PATH.TEACHER_TOTAL_SALE && (
                <h3 className="ml-2">{`Total Sales`}</h3>
              )}
              {history.location.pathname === PATH.TEACHER_TOTAL_CERTIFICATE && (
                <h3 className="ml-2">{`Total Certificates`}</h3>
              )}

              {history.location.pathname === PATH.TEACHER_CREATE_COURSE && (
                <h3 className="ml-2">{`Create Courses`}</h3>
              )}
              {history.location.pathname === PATH.TEACHERCREATECOACHING && (
                <h3 className="ml-2">{`Create Coaching`}</h3>
              )}
              {history.location.pathname === PATH.ADDLECTURES && (
                <h3 className="ml-2">{`First Lecture`}</h3>
              )}
              {history.location.pathname === PATH.PRICINGPLAN ||
              history.location.pathname === PATH.TEACHERPRICINGLIST ? (
                <h3 className="ml-2">{`Pricing`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHERONETOONE && (
                <h3 className="ml-2">{`One To One`}</h3>
              )}
              {history.location.pathname === PATH.BULLETINCALLBOARD && (
                <h3 className="ml-2">{`Bulletin Board`}</h3>
              )}
              {history.location.pathname === PATH.COACHINGLIST ||
              history.location.pathname === PATH.FINISHUPCOACHING ||
              history.location.pathname === PATH.REVIEW_AND_SAVE ||
              history.location.pathname === PATH.TEACHERUPDATECOACHING ||
              history.location.pathname === PATH.TEACHERGROUP ? (
                <h3 className="ml-2">{`Coaching`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHINGCLASS && (
                <h3 className="ml-2">{`Class`}</h3>
              )}
              {history.location.pathname === PATH.TEACHERCOURSEINFORMATION ||
              history.location.pathname === PATH.TEACHERCURRICULUMPREVIEW ? (
                <h3 className="ml-2">{`Preview`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.COURSE ||
              history.location.pathname === PATH.COURSE_PREVIEW ? (
                <h3 className="ml-2">{`MY COURSES`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHER_FAQ && (
                <h3 className="ml-2">{`FAQ'S`}</h3>
              )}
              {history.location.pathname === PATH.STUDENT_PROFILEEDIT ||
              history.location.pathname === PATH.STUDENT_PROFILESHOW ||
              history.location.pathname === PATH.TEACHER_PROFILEEDIT ||
              history.location.pathname === PATH.TEACHER_PROFILESHOW ? (
                <h3 className="ml-2">{`PROFILE`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.SETTINGS && (
                <h3 className="ml-2">{`SETTINGS`}</h3>
              )}
              {history.location.pathname === PATH.TEACHER_COURSE_PREVIEW && (
                <h3 className="ml-2">{`Course Preview`}</h3>
              )}
              {history.location.pathname === PATH.CONTACT_US && (
                <h3 className="ml-2">{`Contact Us`}</h3>
              )}
            </>
          )}
          {auth.edu_tech_user.roleId === 1 && (
            <>
              {history.location.pathname === PATH.ADMIN_DASHBOARD ||
              history.location.pathname === PATH.DASHBOARD ? (
                <h3 className="ml-2">{`Dashboard`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.MANAGE_USER ? (
                <h3 className="ml-2">{`Manage Users`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.CREATE_NEW_STAFF ? (
                <h3 className="ml-2">{`Staff`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.ROLE_AND_PERMISSION ? (
                <h3 className="ml-2">{`Permissions`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHERCURRICULUMLIST ||
              history.location.pathname === PATH.CREATENEWSECTION ? (
                <h3 className="ml-2">{`Curriculum`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHERNEWCOURSE && (
                <h3 className="ml-2">{`New Course`}</h3>
              )}
              {history.location.pathname === PATH.ADMIN_TOTAL_USERS && (
                <h3 className="ml-2">{`Total Users`}</h3>
              )}
              {history.location.pathname === PATH.ADMIN_TOTAL_COACHING && (
                <h3 className="ml-2">{`Total Coaching`}</h3>
              )}
              {history.location.pathname === PATH.ADMIN_TOTAL_COURSES && (
                <h3 className="ml-2">{`Total Courses`}</h3>
              )}
              {history.location.pathname === PATH.ADMIN_TOTAL_SALES && (
                <h3 className="ml-2">{`Total Sales`}</h3>
              )}
              {history.location.pathname === PATH.TEACHERCURRICULUMPUBLISH ||
              history.location.pathname === PATH.TEACHERCOURSESGROUP ? (
                <h3 className="ml-2">{`Courses`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHER_CREATE_COURSE && (
                <h3 className="ml-2">{`Create Courses`}</h3>
              )}
              {history.location.pathname === PATH.TEACHERCREATECOACHING && (
                <h3 className="ml-2">{`Create Coaching`}</h3>
              )}
              {history.location.pathname === PATH.ADDLECTURES && (
                <h3 className="ml-2">{`First Lecture`}</h3>
              )}
              {history.location.pathname === PATH.REQUEST_TOPIC && (
                <h3 className="ml-2">{`Category Request`}</h3>
              )}
              {history.location.pathname === PATH.CONTACT_US_ADMIN && (
                <h3 className="ml-2">{`Contact Us`}</h3>
              )}
              {history.location.pathname === PATH.TEACHERONETOONE && (
                <h3 className="ml-2">{`One To One`}</h3>
              )}
              {history.location.pathname === PATH.BULLETINCALLBOARD && (
                <h3 className="ml-2">{`Bulletin Board`}</h3>
              )}
              {history.location.pathname === PATH.COACHINGLIST ||
              history.location.pathname === PATH.FINISHUPCOACHING ||
              history.location.pathname === PATH.TEACHERUPDATECOACHING ||
              history.location.pathname === PATH.TEACHERGROUP ? (
                <h3 className="ml-2">{`Coaching`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.PRICINGPLAN ||
              history.location.pathname === PATH.TEACHERPRICINGLIST ? (
                <h3 className="ml-2">{`Pricing`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.TEACHINGCLASS && (
                <h3 className="ml-2">{`Class`}</h3>
              )}
              {history.location.pathname === PATH.TEACHERCOURSEINFORMATION ||
              history.location.pathname === PATH.ADMIN_COURSE_PREVIEW ||
              history.location.pathname === PATH.TEACHERCURRICULUMPREVIEW ? (
                <h3 className="ml-2">{`Preview`}</h3>
              ) : (
                <></>
              )}
              {history.location.pathname === PATH.ADMIN_COURSE_REQUESTS && (
                <h3 className="ml-2">{`Course Requests`}</h3>
              )}
              {history.location.pathname === PATH.ADMIN_COACHING_REQUESTS && (
                <h3 className="ml-2">{`Coaching Requests`}</h3>
              )}
              {history.location.pathname === PATH.ADMINPRICINGPLAN && (
                <h3 className="ml-2">{`Pricing Plan`}</h3>
              )}
              {history.location.pathname === PATH.PAYMENT_DETAIL && (
                <h3 className="ml-2">{`Payment Details`}</h3>
              )}
              {history.location.pathname === PATH.ADMIN_QUERY && (
                <h3 className="ml-2">{`QUERIES`}</h3>
              )}
              {history.location.pathname === PATH.ADMIN_FAQ && (
                <h3 className="ml-2">{`FAQ's`}</h3>
              )}
              {history.location.pathname === PATH.COURSE ||
              history.location.pathname === PATH.COURSE_PREVIEW ? (
                <h3 className="ml-2">{`MY COURSES`}</h3>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {/* /Logo */}
        {/* Mobile Menu Toggle */}
        <a href="javascript:void(0)" className="mobile_btn" id="mobile_btn">
          <FaBars />
        </a>
        {/* /Mobile Menu Toggle */}
        <ul className="nav user-menu">
          <li className="nav-item dropdown noti-dropdown">
            <Notification />
          </li>
          <li className="nav-item dropdown has-arrow">
            <Dropdown className="user-dropdown d-flex align-items-center h-100">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="user_dropdown"
              >
                <span className="user-img mt-0">
                  <img
                    className="rounded-circle"
                    src={imgFile ? imgFile : IMAGES.AVATAR}
                    width="31"
                    alt=""
                  />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="profile-dropmenu">
                {/* <Dropdown.Item href="javascript:void(0)" className="no-padding">
                  <p className="muted-text f-12">Your Profile</p>
                  <p className="progress-text mb-0 f-13">39% Completed</p>
                  <ProgressBar className="mt-1" now={61} />
                </Dropdown.Item> */}
                {auth.edu_tech_user.roleId === 3 && (
                  <>
                    <Dropdown.Item
                      href={PATH.STUDENT_PROFILESHOW}
                      className="no-padding"
                    >
                      <p className="progress-text mb-0 f-13">My Profile</p>
                    </Dropdown.Item>
                  </>
                )}
                {auth.edu_tech_user.roleId === 2 && (
                  <>
                    <Dropdown.Item
                      href={PATH.TEACHER_PROFILESHOW}
                      className="no-padding"
                    >
                      <p className="progress-text mb-0 f-13">My Profile</p>
                    </Dropdown.Item>
                  </>
                )}
                {auth.edu_tech_user.roleId === 3 && (
                  <>
                    <Dropdown.Item href={PATH.WISHLIST} className="no-padding">
                      <p className="progress-text mb-0 f-13">Wishlist</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={PATH.PURCHASECREDIT}
                      className="no-padding"
                    >
                      <p className="progress-text mb-0 f-13">Purchase Credit</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={PATH.STUDENT_CREDITS}
                      className="no-padding"
                    >
                      <p className="progress-text mb-0 f-13">Credits</p>
                    </Dropdown.Item>
                  </>
                )}
                {/* <Dropdown.Item href="javascript:void(0)" className="no-padding">
                  <p className="muted-text">Registered courses</p>
                  <p className="progress-text mb-0 f-13">10 Courses</p>
                </Dropdown.Item> */}
                <Dropdown.Item className="d-block px-0">
                  <SignOut />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </>
  );
}
