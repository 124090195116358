import React, { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Col, Row } from "react-bootstrap";
import { AREA_INTEREST } from "../../config";
import { HiPlus } from "react-icons/hi";
import { toast } from "react-toastify";
import { FaMinus } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { ErrorMessage, FieldError, Loader } from "../../assets";
import { CreateInterest, DeleteInterest, GetAllApprovedInterest, GetAllUnapprovedInterest, GetSelectedInterestsByStudents, Interest, UpdateSelectedInterests } from "../../redux/actions/interestAction";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import { interest } from "../../redux/api";
export default function Settings() {
  useEffect(() => {
    document.title = "Settings || EduTech";
  }, []);
  const { reset } = useForm();
  const dispatch1 = useDispatch();
  const [description, setDescription] = useState(null);
  const { handleSubmit, register, errors } = useForm();
  const [showField, setAddField] = useState(false);
  // function onSubmit(data) {
  //   let obj = {
  //     id: state.buttonList.length + 1,
  //     type: "areaInterest",
  //     text: `${data.requestTopic}`,
  //     value: `${data.requestTopic}`,
  //   };
  //   dispatch({ type: "ADD_BUTTON", payload: obj });
  //   toasterFnction()
  // }
  let auth = useAuth();
  function onSubmit(data) {
    let finalData = {
      "description": data.description,
    }
    dispatch1(CreateInterest(finalData, auth, moveToNext, Notificiation))
  }
  const [state, dispatch] = useReducer(btnSetReducer, INITIAL_STATE);
  useEffect(() => {
    dispatch1(GetAllUnapprovedInterest(auth));
    dispatch1(GetSelectedInterestsByStudents(auth));
    dispatch1(GetAllApprovedInterest());
  }, [dispatch]);
  const [pricingButtonSelect, setPricingButtonSelect] = useState([]);
  let interestValue = useSelector((state) => state.interest);
  function moveToNext(data) {
    dispatch1(GetAllUnapprovedInterest(auth));
    setDescription(null)
    window.location.reload();
  }
  function SelectByFilter(data) {
    let finalData2 = {
      "interestId": pricingButtonSelect,
    }
    dispatch1(UpdateSelectedInterests(finalData2, auth, Notificiation))
  }
  function PricingButtonSelected(id) {
    if (pricingButtonSelect.includes(id)) {
      for (let i = 0; i < pricingButtonSelect.length; i++) {
        if (pricingButtonSelect[i] === id) {
          pricingButtonSelect.splice(i, 1);
        }
      }
    }
    //  else if (pricingButtonSelect.length >= 3) {
    //   // return alert("only 3 selected");
    //   // return alert("only 3 selected");
    // } 
    else if (pricingButtonSelect.length < 10) {
      setPricingButtonSelect((prev) => [...prev, id]);
    }
  }
  function Notificiation(data, condition) {
    reset();
    condition === "error" ?
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      :
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }
  return (
    <>
      <div className="settings-view">
        <div className="row">
          <div className="col-12 col-xl-12 col-lg-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <>
                <Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label className="bold-800 font-20">{`Customize Your Category`}</Form.Label>
                  </div>
                  <div className="interest-btn">
                    <>
                      {interestValue &&
                        interestValue.getAllApprovedInterestLoading === true &&
                        interestValue.getAllApprovedInterestSuccess === false &&
                        interestValue.getAllApprovedInterestFailure === false &&
                        <Loader />}
                      {interestValue &&
                        interestValue.getAllApprovedInterestLoading === false &&
                        interestValue.getAllApprovedInterestSuccess === false &&
                        interestValue.getAllApprovedInterestFailure === true && (
                          <ErrorMessage message={interestValue.getAllApprovedInterestError} />
                        )}
                      {interestValue &&
                        interestValue.getAllApprovedInterestLoading === false &&
                        interestValue.getAllApprovedInterestSuccess === true &&
                        interestValue.getAllApprovedInterestFailure === false && (
                          <div>
                            {interestValue.getAllApprovedInterest &&
                              interestValue.getAllApprovedInterest.map((interest, index) => {
                                return (
                                  <>
                                    <ButtonSection interest={interest} interestValue={interestValue.getSelectedInterestsByStudents} index={index} pricingButtonSelect={pricingButtonSelect}
                                      PricingButtonSelected={PricingButtonSelected} />
                                  </>
                                );
                              })}
                            {/* {AREA_INTEREST &&
                    AREA_INTEREST.map((interest, index) => {
                      return (
                        <>
                          <ButtonSection interest={interest} index={index} />
                        </>
                      );
                    })} */}
                            <Button type="button" className="cursor save-btn"
                              onClick={() => {
                                SelectByFilter();
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                    </>
                  </div>
                </Form.Group>
              </>
              <Form.Group>
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Label className="bold-800 font-20">{`Request New Category`}</Form.Label>
                </div>
                <div className="request-new-btn">
                  <>
                    {interestValue &&
                      interestValue.getAllUnapprovedInterestLoading === false &&
                      interestValue.getAllUnapprovedInterestSuccess === false &&
                      interestValue.getAllUnapprovedInterestFailure === true && (
                        <ErrorMessage message={interestValue.getAllUnapprovedInterestError} />
                      )}
                    {interestValue &&
                      interestValue.getAllUnapprovedInterestLoading === false &&
                      interestValue.getAllUnapprovedInterestSuccess === true &&
                      interestValue.getAllUnapprovedInterestFailure === false && (
                        <div>
                          {interestValue.getAllUnapprovedInterest &&
                            interestValue.getAllUnapprovedInterest.map((value, index) => {
                              return (
                                <>
                                  <ButtonSection1 value={value} index={index} />
                                </>
                              );
                            })}
                          <Button
                            type="button"
                            className={`cursor add-button`}
                            onClick={() => setAddField(true)}
                          >
                            <HiPlus size={35} />
                          </Button>
                        </div>
                      )}
                    {showField === true ? (
                      <div className="col-md-8">
                        <Row className="">
                          <Col xs={1} className="d-flex align-self-start p-0">
                            <Button
                              type="button"
                              className={`cursor add-button`}
                              onClick={() => setAddField(false)}
                            >
                              <FaMinus size={30} />
                            </Button>
                          </Col>
                          <Col xs={9}>
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control floating mt-3 edutech-plus-field"
                                name="description"
                                placeholder="Enter new topic"
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                                style={{
                                  borderColor: errors && errors.description ? "#a80000" : "",
                                }}
                                ref={register({ required: true })}
                              />
                              {errors.description && (
                                <FieldError message={"This Field is Required"} />
                              )}
                            </div>
                          </Col>
                          <Col xs={2} className="d-flex align-self-start p-0">
                            <Button type="submit">Submit</Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
function ButtonSection(props) {
  const [buttonState, setButtonState] = useState(false);
  useEffect(() => {
    setButtonState(props.pricingButtonSelect.includes(props.interest.interestId));
  }, [props.pricingButtonSelect]);
  function buttonCheck() {
    if (buttonState) {
      setButtonState(false)
    }
  }
  return (
    <>
      <Button
        type="button"
        key={props.index}
        onClick={() => {
          props.PricingButtonSelected(props.interest.interestId); buttonCheck()
        }}
        className={`cursor ${buttonState === true || props.interestValue.some(item => item.interestId === props.interest.interestId) ? "active" : ""}`}
      >
        {props.interest.description}
      </Button>
    </>
  );
}

function ButtonSection1(props) {
  const [buttonState, setButtonState] = useState(false);
  let { index } = props;
  const dispatch = useDispatch();
  const { reset } = useForm();
  let auth = useAuth();
  function listRemove(interestId) {
    //   props.dispatch({ type: "REMOVE_BUTTON", payload: id });
    // }
    let finalData = {
      interestId: interestId
    }
    dispatch(DeleteInterest(finalData, auth, moveToNext, Notificiation));
  }
  function moveToNext(data) {
    dispatch(GetAllUnapprovedInterest(auth));
  }
  function Notificiation(data, condition) {
    reset();
    condition === "error" ?
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      :
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }
  return (
    <>
      {
        <Button
          type="button"
          key={index}
          onClick={() => setButtonState(!buttonState)}
          className={`cursor delete_icon ${buttonState === true ? "active" : ""}`}
        >
          {props.value.description}
          {buttonState === true ?
            // <RiCloseCircleFill onClick={(e) => listRemove(props.value.id)} />
            <RiCloseCircleFill onClick={(e) => listRemove(props.value.interestId)} />
            :
            ""}
        </Button>
      }
    </>
  );
}
const INITIAL_STATE = {
  buttonList: [],
};
const btnSetReducer = (intial_state, action) => {
  switch (action.type) {
    case "ADD_BUTTON":
      let button_list_for_add = intial_state.buttonList;
      if (button_list_for_add.includes(action.payload) === false) {
        button_list_for_add.push(action.payload);
      }
      return {
        ...intial_state,
        buttonList: button_list_for_add,
      };
    case "REMOVE_BUTTON":
      let button_list_for_remove = intial_state.buttonList;
      for (let i = 0; i < button_list_for_remove.length; i++) {
        if (button_list_for_remove[i].id === action.payload) {
          button_list_for_remove.splice(i, 1);
        }
      }
      return {
        ...intial_state,
        buttonList: button_list_for_remove,
      };
    default:
      return intial_state;
  }
};