import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { PATH } from "../../../config";
import { GetWishlistCourses, Remove } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";

export default function Wishlist() {
  let auth = useAuth()
  let dispatch = useDispatch()
  useEffect(() => {
    dispatch(GetWishlistCourses(auth));
  }, [dispatch]);
  let wishListData = useSelector((state) => state.wishList)
  console.log("ashgdhsadas", wishListData.getWishlistCourses)
  return (
    <>
      <ToastContainer />
      <div className="CurentCourses">
        <div className="container-fluid">
          <div className="row">
            {wishListData.getWishlistCourses.map((list, index) => {
              return <CategoriesBody list={list} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}
function CategoriesBody(props) {
  const [addWishList, setAddWishList] = useState(null);
  let history = useHistory();
  function moveToNext() {
    // history.push(PATH.EXPLORE_COURSE_PREVIEW);
    history.push({
      pathname: PATH.EXPLORE_COURSE_PREVIEW,
      state: {
        wishListDataList: props.list,
        Courses: "WISHLISTDATA",
      },
    });
  }
  return (
    <>
      <div
        className="col-md-3 col-sm-12 col-12 slide-item position-relative mb-3 cursor"
        onClick={() => moveToNext()}
      >
        <div className="mx-2 Courseiframeheight">
          <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img border">
            <img
              className="img-fluid embed-responsive-item"
              src={props.list.coursePreviewPictureURL}
              alt=""
            />
            <div className="wishlist_border">
              {props.list.wishlist === true ? (
                <WishListIcon
                  addWishList={addWishList}
                  setAddWishList={setAddWishList}
                  courseid={props.list.courseId}
                />
              ) : (
                <WishListIcon
                  addWishList={addWishList}
                  setAddWishList={setAddWishList}
                  courseid={props.list.courseId}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-2 mx-3">
          <p className="firstpara mb-0">{props.list.courseTitle}</p>
          <p className="secpara mb-0">{props.list.courseSubtitle}</p>
        </div>
      </div>
    </>
  );
}
function WishListIcon(props) {
  let auth = useAuth()
  console.log("ashgdsada", props)
  let dispatch = useDispatch()
  function wishlist(e, val) {
    e.stopPropagation();
    props.setAddWishList(val);
    if (props.addWishList === true) {
      toast.success("Added to wishlist", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      dispatch(Remove(props.courseid, auth, NotificationRemove, moveToNext));
    }
  }
  function moveToNext() {
    // window.location.reload();
    dispatch(GetWishlistCourses(auth));
  }
  function NotificationRemove(data) {
    toast.error("Removed from wishlist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      {props.addWishList === true ? (
        <RiHeartLine onClick={(e) => wishlist(e, false)} />
        // ""
      ) : (
        <RiHeartFill onClick={(e) => wishlist(e, true)} />
      )}
    </>
  );
}
var list2 = [
  {
    id: 0,
    image: "https://img-c.udemycdn.com/course/240x135/3408522_50dc.jpg",
    heading: "Mastering Capital Wisdom",
    detail: "Preparation",
  },
  {
    id: 1,
    image: "https://img-c.udemycdn.com/course/240x135/937678_abd2_3.jpg",
    heading: "Road To IPO",
    detail: "Preparation",
  },
  {
    id: 2,
    image: "https://img-c.udemycdn.com/course/240x135/1208634_cd50_2.jpg",
    heading: "Coin bases IPO ",
    detail: "Preparation",
  },
  {
    id: 3,
    image: "https://img-c.udemycdn.com/course/240x135/2509634_a995_8.jpg",
    heading: "Alibaba IPO: Stock surges 38%..",
    detail: "Preparation",
  },
  {
    id: 4,
    image: "https://img-c.udemycdn.com/course/240x135/1595266_8fe6.jpg",
    heading: "Alibaba IPO: Stock surges 38%..",
    detail: "Preparation",
  },
  {
    id: 5,
    image:
      "https://img-b.udemycdn.com/course/240x135/995016_ebf4_3.jpg?secure=PPBgZxGQXZXXy_mYd-YMzQ%3D%3D%2C1636762270",
    heading: "Alibaba IPO: Stock surges 38%..",
    detail: "Preparation",
  },
];
