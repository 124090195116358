import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BsArrowLeft } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { FieldError, Loader } from "../../../../../assets";
import { uploadImage } from "../../../../../redux/api";
import ReactPlayer from "react-player";
import ReactHtmlParser from "react-html-parser";

export default function AddDescription(props) {
  const { Coaching, setCoaching } = props;
  const { register, handleSubmit, errors } = useForm();
  const [Description, SetDescripton] = useState(
    Coaching && Coaching.description ? Coaching.description : null
  );
  const [zoomLink, setZoomLink] = useState(
    Coaching && Coaching.zoomLink ? Coaching.zoomLink : null
  );
  const [zoomId, setZoomId] = useState(
    Coaching && Coaching.zoomId ? Coaching.zoomId : null
  );
  const [zoomPassCode, setZoomPassCode] = useState(
    Coaching && Coaching.zoomPassCode ? Coaching.zoomPassCode : null
  );
  const [Descreptionerror, SetDescreptionerror] = useState(false);
  console.log("test data for desc error", Descreptionerror);
  const [videoFile, setvideoFile] = useState(
    Coaching && Coaching.videoURL ? Coaching.videoURL : null
  );
  console.log(videoFile);
  const onChangeProfilePicture = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    if (file) {
      setvideoFile(!videoFile);
      uploadImage(formData).then(
        (response) => {
          console.log("respi", response);
          if (response.data.succeeded === true) {
            setvideoFile(response.data.data);
          }
        },
        (error) => {}
      );
    }
  };
  function onSubmit() {
    if (Description !== undefined) {
      Coaching.description = Description;
      Coaching.zoomId = zoomId;
      Coaching.zoomLink = zoomLink;
      Coaching.zoomPassCode = zoomPassCode;
      Coaching.videoURL = videoFile;
      setCoaching(Coaching);
      if (videoFile) {
        props.changeView(5);
        props.handleNext(5);
      }
    } else {
      SetDescreptionerror(true);
    }
  }
  function goBack() {
    Coaching.description = "";
    Coaching.zoomLink = "";
    Coaching.zoomPassCode = "";
    Coaching.videoURL = "";
    props.changeView(3);
    props.handleBack(3);
  }
  const editorConfiguration = {
    toolbar: ["bold", "italic", "underline", "numberedlist", "bulletedList"],
  };
  console.log("asdasd", videoFile);
  return (
    <div>
      <h2
        className="d-flex align-items-center cursor font-weight-bold"
        onClick={() => goBack()}
      >
        <BsArrowLeft size={35} className="mr-2" /> Add a Description
      </h2>
      <hr className="edutech-hr-colors" />
      {/* <p className="mt-4 font-weight-bold">
        What will your clients need to know once they’ve purchased?
      </p> */}
      <Card className="p-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 col-md-10">
              <div className="form-group ckeditor-modification">
                <label className="font-weight-bold mb-2">Description</label>
                <CKEditor
                  className="edutech-ckeditor"
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={Description}
                  name="Adddescription"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    SetDescripton(editor.getData());
                    // console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
                <input
                  hidden
                  type="text"
                  name="Adddescription"
                  value={Description}
                  ref={register({ required: true })}
                />
                {errors.Adddescription && (
                  <div className="mt-2">
                    <FieldError message={"This Field is Required"} />
                  </div>
                )}
              </div>
              <div className="row align-items-center">
                <div className="col-sm-4 col-md-4">
                  <p className="calendar-fs mb-0 ml-2">Zoom ID :</p>
                </div>
                <div className="col-sm-8 col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="zoomID"
                    placeholder="Add zoom id i.e 232-252-4512"
                    pattern="^\d{3}[-\s]\d{3}[-\s]\d{4}$"
                    value={zoomId}
                    onChange={(e) => {
                      setZoomId(e.target.value);
                    }}
                    style={{
                      borderColor: errors && errors.name ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.zoomID && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-sm-4 col-md-4">
                  <p className="calendar-fs mb-0 ml-2">Zoom Link :</p>
                </div>
                <div className="col-sm-8 col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    id="zoom"
                    name="zoomlink"
                    // pattern="^[a-zA-Z][\sa-zA-Z]*"
                    placeholder="https://zoom.us"
                    value={zoomLink}
                    onChange={(e) => {
                      setZoomLink(e.target.value);
                    }}
                    style={{
                      borderColor: errors && errors.name ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.zoomlink && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-sm-4 col-md-4">
                  <p className="calendar-fs mb-0 ml-2">Zoom Passcode :</p>
                </div>
                <div className="col-sm-8 col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    id="passcode"
                    placeholder="Passcode"
                    name="zoompasscode"
                    pattern="^\d{8,11}$"
                    value={zoomPassCode}
                    onChange={(e) => {
                      setZoomPassCode(e.target.value);
                    }}
                    style={{
                      borderColor: errors && errors.name ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.zoompasscode && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-sm-4 col-md-4">
                  <p className="calendar-fs mb-0 ml-2">Upload Video :</p>
                </div>
                <div className="col-sm-8 col-md-8 mt-2">
                  {videoFile === true || videoFile === false ? (
                    <Loader />
                  ) : (
                    <>
                      <ReactPlayer
                        url={videoFile ? videoFile : ""}
                        width="100%"
                        height="100%"
                        className={` ${
                          videoFile !== null ? "video_show_set" : ""
                        }`}
                      />
                      {/* {
                        setvideoFile(null)} */}
                      <button type="button" className="upload_video_btn">
                        <input
                          type="file"
                          name="video"
                          placeholder="Uploading......"
                          data-browse="UPLOAD"
                          className="upload_video_input"
                          accept="video/*"
                          onChange={(e) => {
                            onChangeProfilePicture(e);
                          }}
                        />
                        Upload file
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 float-right">
            <Button
              type="submit"
              className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold"
            >
              Next
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}
