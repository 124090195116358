import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs";
import { IoChatbubblesOutline } from "react-icons/io5";
import { CgClose } from "react-icons/cg";
import { ToastContainer } from "react-toastify";

export function ChatButton() {
  const [key, setKey] = useState("chat");
  const [chatSection, setChatSection] = useState(false);
  return (
    <>
      <ToastContainer />
      <div id="chat-box-body">
        <div
          id="chat-circle"
          className="waves-effect waves-circle btn btn-circle btn-sm l-h-50"
        >
          {chatSection === false ? (
            <button type="button" onClick={() => setChatSection(true)}>
              <BsQuestionCircle /> Help
            </button>
          ) : (
            <button type="button" onClick={() => setChatSection(false)}>
              <CgClose /> Close
            </button>
          )}
        </div>
        <div
          className={`chat-box ${
            chatSection === true ? "chat-box-show" : "chat-box-hide"
          }`}
        >
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="explore_tabs"
          >
            {/* <Tab eventKey="Query" title="Send Query">
              <SendQuery />
            </Tab> */}

            <Tab eventKey="chat" title="Chat">
              <Chat />
            </Tab>
          </Tabs>
          {/* <div className="px-4 py-3 d-flex align-items-center justify-content-between border-bottom font-weight-bold">
            Send Query <CgClose onClick={() => setChatSection(false)} />
          </div> */}
        </div>
      </div>
    </>
  );
}
function Chat() {
  return (
    <>
      <div className="height_adjust_chat">
        <div className="msg left-msg">
          {/* <div
          className="msg-img"
        ></div> */}
          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">BOT</div>
              <div className="msg-info-time">12:45</div>
            </div>

            <div className="msg-text">
              Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
            </div>
          </div>
        </div>
        <div className="msg right-msg">
          {/* <div
          className="msg-img"
        ></div> */}

          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">Sajad</div>
              <div className="msg-info-time">12:46</div>
            </div>

            <div className="msg-text">
              You can change your name in JS section!
            </div>
          </div>
        </div>
        <div className="msg left-msg">
          {/* <div
          className="msg-img"
        ></div> */}
          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">BOT</div>
              <div className="msg-info-time">12:45</div>
            </div>

            <div className="msg-text">
              Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
            </div>
          </div>
        </div>
        <div className="msg right-msg">
          {/* <div
          className="msg-img"
        ></div> */}

          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">Sajad</div>
              <div className="msg-info-time">12:46</div>
            </div>

            <div className="msg-text">
              You can change your name in JS section!
            </div>
          </div>
        </div>
      </div>
      <form className="msger-inputarea">
        <input
          type="text"
          className="msger-input"
          placeholder="Enter your message..."
        />
        <button type="button" className="msger-send-btn">
          Send
        </button>
      </form>
    </>
  );
}
export function ChatButtonAdmin() {
  const [chatSection, setChatSection] = useState(false);

  return (
    <>
      <ToastContainer />
      <div id="chat-box-body">
        <div
          id="chat-circle"
          className="waves-effect waves-circle btn btn-circle btn-sm l-h-50"
        >
          {chatSection === false ? (
            <button type="button" onClick={() => setChatSection(true)}>
              <IoChatbubblesOutline /> Chat
            </button>
          ) : (
            <button type="button" onClick={() => setChatSection(false)}>
              <CgClose /> Close
            </button>
          )}
        </div>
        <div
          className={`chat-box ${
            chatSection === true ? "chat-box-show" : "chat-box-hide"
          }`}
        >
          <div className="px-4 py-3 d-flex align-items-center justify-content-between border-bottom font-weight-bold">
            CHAT <CgClose onClick={() => setChatSection(false)} />
          </div>
          <ChatSectionAdmin />
        </div>
      </div>
    </>
  );
}
function ChatSectionAdmin() {
  return (
    <>
      <div className="height_adjust_chat">
        <div className="msg left-msg">
          {/* <div
          className="msg-img"
        ></div> */}
          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">BOT</div>
              <div className="msg-info-time">12:45</div>
            </div>

            <div className="msg-text">
              Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
            </div>
          </div>
        </div>
        <div className="msg right-msg">
          {/* <div
          className="msg-img"
        ></div> */}

          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">Sajad</div>
              <div className="msg-info-time">12:46</div>
            </div>

            <div className="msg-text">
              You can change your name in JS section!
            </div>
          </div>
        </div>
        <div className="msg left-msg">
          {/* <div
          className="msg-img"
        ></div> */}
          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">BOT</div>
              <div className="msg-info-time">12:45</div>
            </div>

            <div className="msg-text">
              Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
            </div>
          </div>
        </div>
        <div className="msg right-msg">
          {/* <div
          className="msg-img"
        ></div> */}

          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">Sajad</div>
              <div className="msg-info-time">12:46</div>
            </div>

            <div className="msg-text">
              You can change your name in JS section!
            </div>
          </div>
        </div>
      </div>
      <form className="msger-inputarea">
        <input
          type="text"
          className="msger-input"
          placeholder="Enter your message..."
        />
        <button type="button" className="msger-send-btn">
          Send
        </button>
      </form>
    </>
  );
}
