import { RSS_FEED_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  getRSSFeedInvestingLoading: false,
  getRSSFeedInvestingSuccess: false,
  getRSSFeedInvestingFailure: false,
  getRSSFeedInvestingError: null,
  getRSSFeedInvesting: [],
  
  getRSSFeedFTLoading: false,
  getRSSFeedFTSuccess: false,
  getRSSFeedFTFailure: false,
  getRSSFeedFTError: null,
  getRSSFeedFT: [],

  getRSSFeedFortuneLoading: false,
  getRSSFeedFortuneSuccess: false,
  getRSSFeedFortuneFailure: false,
  getRSSFeedFortuneError: null,
  getRSSFeedFortune: [],

};

export const rssFeedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RSS_FEED_REQUEST.GET_RSS_FEED_INVESTING_REQUEST:
      return {
        ...state,
        getRSSFeedInvestingLoading: true,
        getRSSFeedInvestingSuccess: false,
        getRSSFeedInvestingFailure: false,
        getRSSFeedInvestingError: null,
      };
    case RSS_FEED_REQUEST.GET_RSS_FEED_INVESTING_SUCCESS:
      return {
        ...state,
        getRSSFeedInvestingLoading: false,
        getRSSFeedInvestingSuccess: true,
        getRSSFeedInvestingFailure: false,
        getRSSFeedInvestingError: null,
        getRSSFeedInvesting: action.payload,
      };
    case RSS_FEED_REQUEST.GET_RSS_FEED_INVESTING_FAILURE:
      return {
        ...state,
        getRSSFeedInvestingLoading: false,
        getRSSFeedInvestingSuccess: false,
        getRSSFeedInvestingFailure: true,
        getRSSFeedInvestingError: action.payload,
      };
      
    case RSS_FEED_REQUEST.GET_RSS_FEED_FT_REQUEST:
      return {
        ...state,
        getRSSFeedFTLoading: true,
        getRSSFeedFTSuccess: false,
        getRSSFeedFTFailure: false,
        getRSSFeedFTError: null,
      };
    case RSS_FEED_REQUEST.GET_RSS_FEED_FT_SUCCESS:
      return {
        ...state,
        getRSSFeedFTLoading: false,
        getRSSFeedFTSuccess: true,
        getRSSFeedFTFailure: false,
        getRSSFeedFTError: null,
        getRSSFeedFT: action.payload,
      };
    case RSS_FEED_REQUEST.GET_RSS_FEED_FT_FAILURE:
      return {
        ...state,
        getRSSFeedFTLoading: false,
        getRSSFeedFTSuccess: false,
        getRSSFeedFTFailure: true,
        getRSSFeedFTError: action.payload,
      };

    case RSS_FEED_REQUEST.GET_RSS_FEED_FORTUNE_REQUEST:
      return {
        ...state,
        getRSSFeedFortuneLoading: true,
        getRSSFeedFortuneSuccess: false,
        getRSSFeedFortuneFailure: false,
        getRSSFeedFortuneError: null,
      };
    case RSS_FEED_REQUEST.GET_RSS_FEED_FORTUNE_SUCCESS:
      return {
        ...state,
        getRSSFeedFortuneLoading: false,
        getRSSFeedFortuneSuccess: true,
        getRSSFeedFortuneFailure: false,
        getRSSFeedFortuneError: null,
        getRSSFeedFortune: action.payload,
      };
    case RSS_FEED_REQUEST.GET_RSS_FEED_FORTUNE_FAILURE:
      return {
        ...state,
        getRSSFeedFortuneLoading: false,
        getRSSFeedFortuneSuccess: false,
        getRSSFeedFortuneFailure: true,
        getRSSFeedFortuneError: action.payload,
      };

      default:
      return state;
  }
};
