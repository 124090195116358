import { combineReducers } from "redux";
import { accountReducer } from "./accountReducer";
import { countryReducer } from "./countryReducer";
import { LookUpReducer } from "./lookUpReducer";
import { adminReducer } from "./adminReducer";
import { userProfileReducer } from "./userProfileReducer";
import { CoachingReducer } from "./coachingReducer";
import { CoursesReducer } from "./coursesReducer";
import { InterestReducer } from "./interestReducer";
import { pricingPlanReducer } from "./pricingPlanReducer";
import { FaqReducer } from "./faqReducer";
import { lectureNotesReducer } from "./lectureNotesReducer";
import { DashbaordTilesReducer } from "./dashboardTilesReducer";
import { rssFeedReducer } from "./rssFeedReducer";
import { notificationReducer } from "./notificationReducer";
import { courseWishListReducer } from "./courseWishListReducer";
import { forgetPasswordReducer } from "./forgetPasswordReducer";
import { studentPaymentReducer } from "./studentPaymentReducer";
import { contactUsReducer } from "./contactUsReducer";
import { feedBackReducer } from "./feedBackReducer";
import { staffMemberReducer } from "./staffMember";
import { createPermissionReducer } from "./menuPermissionReducer";
export const rootReducer = combineReducers({
  permission: createPermissionReducer,
  notification: notificationReducer,
  contactUs: contactUsReducer,
  feedback: feedBackReducer,
  studentPayment: studentPaymentReducer,
  staffMember: staffMemberReducer,
  wishList: courseWishListReducer,
  forgetPassword: forgetPasswordReducer,
  rssFeed: rssFeedReducer,
  lectureNotes: lectureNotesReducer,
  account: accountReducer,
  faq: FaqReducer,
  country: countryReducer,
  lookup: LookUpReducer,
  admin: adminReducer,
  userProfile: userProfileReducer,
  dashboardTiles: DashbaordTilesReducer,
  coaching: CoachingReducer,
  courses: CoursesReducer,
  interest: InterestReducer,
  pricingPlan: pricingPlanReducer,
});
