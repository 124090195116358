import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { IMAGES } from "../../assets/images";
import { BsBookmarkStar, BsBookmarkStarFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { dateTimeConvert, GenericSearchField } from "../../assets";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PATH } from "../../config";
import { BsCoin } from "react-icons/bs";
import { ImInfinite } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCredits,
  GetTopCoaching,
  GetUpcomingCoaching,
  PurchaseCoaching,
} from "../../redux/actions";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import ReactHtmlParser from "react-html-parser";

const moment = require("moment-timezone");

export default function CoachingPreview() {
  const location = useLocation();
  const CoachingsData = location?.state?.CoachingData;
  const Coachings = location?.state?.Coaching;
  console.log("sasasaaaaaa", CoachingsData);
  let dispatch = useDispatch();
  let auth = useAuth();
  let history = useHistory();
  useEffect(() => {
    dispatch(GetUpcomingCoaching(auth));
    dispatch(GetTopCoaching());
  }, [dispatch, auth]);
  return (
    <>
      <ToastContainer />
      {history.location.pathname === PATH.UPCOMING_COACHING_PREVIEW ? (
        <UpcommingCoaching CoachingsData={CoachingsData} />
      ) : Coachings === "TODAY" ? (
        <TodayCoaching CoachingsData={CoachingsData} />
      ) : Coachings === "TOP" ? (
        <TopCoaching CoachingsData={CoachingsData} />
      ) : Coachings === "RECOMMENDED" ? (
        <TopCoaching CoachingsData={CoachingsData} />
      ) : (
        <UpcommingCoaching CoachingsData={CoachingsData} />
      )}
    </>
  );
}
function BeginCourseData(props) {
  let location = useLocation();
  const [addWishList, setAddWishList] = useState(true);
  const [freePlan, setFreePlan] = useState(
    location && location.state && location.state.price
      ? location.state.price
      : null
  );
  function wishlist(e, val) {
    e.stopPropagation();
    setAddWishList(val);
    if (addWishList === false) {
      toast.success("Added to wishlist", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Removed from wishlist", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  return (
    <div className="col-12 col-md-4 mb-md-2">
      <Card className="px-4 py-2 pt-3">
        <h3 className="f-20 font-weight-bold">About Course Provider</h3>
        <hr className="mt-0" />
        <div className="d-flex align-items-center">
          <span className="user-img mt-0">
            <img
              className="rounded-circle edutech-speaker-img"
              // src={IMAGES.PROFILELOGO}
              src={
                props.CoachingsData.providerImage
                  ? props.CoachingsData.providerImage
                  : IMAGES.PROFILELOGO
              }
              width="31"
              alt="No Image"
            />
          </span>
          <div>
            <h5 className="pl-4 font-weight-bold mb-0">
              {props.CoachingsData.companyTitle}
            </h5>
            {/* <h6 className="pl-4 mb-0">{props.CoachingsData.companyTitle}</h6> */}
          </div>
        </div>
        <div className="mt-4">
          <h5 className="font-weight-bold">Details :-</h5>
          <p className="edutech-speaker-text-detail mb-0">
            {props.CoachingsData.providerDescription
              ? props.CoachingsData.providerDescription
              : "No Detail Found"}
          </p>
        </div>
      </Card>
      <Card className="px-4 py-2 pt-3">
        <h3 className="f-20">
          <b>More about the coaching</b>
        </h3>
        <hr className="mt-0" />
        <table className="table table-borderless ">
          {/* <tr className="f-13">
            <td>
              <b>Duration:</b>
            </td>
            <td>19 minutes</td>
          </tr> */}
          {props.CoachingsData.fixedDate ? (
            <>
              <tr className="f-13">
                <td>
                  <b>Date:</b>
                </td>
                <td>
                  {/* {dateTimeConvert(props.CoachingsData.fixedDate)
                    ? dateTimeConvert(props.CoachingsData.fixedDate)
                    : "N/A"} */}

                  {props.CoachingsData.fixedDate.split("T")[0] +
                    " , " +
                    moment(
                      props.CoachingsData.fixedDate.split("T")[1],
                      "HH:mm:ss"
                    ).format("hh:mm A")}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr className="f-13">
                <td>
                  <b>Reccurrent date:</b>
                </td>
                <td>
                  {/* {dateTimeConvert(props.CoachingsData.startRecurrentDate)
                    ? dateTimeConvert(props.CoachingsData.startRecurrentDate)
                    : "N/A"}{" "} */}
                  {props.CoachingsData.startRecurrentDate.split("T")[0] +
                    " , " +
                    moment(
                      props.CoachingsData.startRecurrentDate.split("T")[1],
                      "HH:mm:ss"
                    ).format("hh:mm A")}
                  ,{" "}
                  {/* {dateTimeConvert(props.CoachingsData.endRecurrentDate)
                    ? dateTimeConvert(props.CoachingsData.endRecurrentDate)
                    : "N/A"} */}
                  {props.CoachingsData.endRecurrentDate.split("T")[0] +
                    " , " +
                    moment(
                      props.CoachingsData.endRecurrentDate.split("T")[1],
                      "HH:mm:ss"
                    ).format("hh:mm A")}
                </td>
              </tr>
            </>
          )}
        </table>
      </Card>
      <Card className="px-4 py-2 pt-3">
        <h3 className="f-20">
          <b>More actions</b>
        </h3>
        <hr className="mt-0" />
        <table className="table table-borderless table-responsive">
          {/* <tr>
            <td>
              {addWishList === true ? (
                <div onClick={(e) => wishlist(e, false)} className="d-flex">
                  <BsBookmarkStarFill className="edutech-actions-bookmark-icon" />
                  <div className="edutech-bookmark cursor">
                    <u>Add to Wishlist</u>
                  </div>
                </div>
              ) : (
                <div onClick={(e) => wishlist(e, true)} className="d-flex">
                  <BsBookmarkStar className="edutech-actions-bookmark-icon" />
                  <div className="edutech-bookmark cursor ">
                    <u>Add to Wishlist</u>
                  </div>
                </div>
              )}
            </td>
          </tr> */}
        </table>
        {freePlan === "Free" ? (
          <Link to={PATH.MY_COACHING}>
            <Button className="edutech-action-button my-2 border-0 w-100">
              Begin coaching
            </Button>
          </Link>
        ) : (
          <Button
            className="edutech-action-button my-2 border-0 w-100"
            onClick={() => props.setAddToCart(false)}
          >
            Begin Coaching
          </Button>
        )}
      </Card>
    </div>
  );
}
function AddtoCart(props) {
  let dispatch = useDispatch();
  let auth = useAuth();
  let getCoachingCredits = useSelector((state) => state.account);
  useEffect(() => {
    dispatch(GetCredits(auth));
    document.title = `Credits || EduTech`;
  }, [dispatch]);
  let data = getCoachingCredits && getCoachingCredits.getCredits;
  function BuyNowCoaching() {
    let finalData = {
      coachingId: props.CoachingsData.coachingId,
      price: props.CoachingsData.price,
    };
    dispatch(PurchaseCoaching(finalData, auth, AfterBuyCoaching, notification));
  }
  function AfterBuyCoaching() {
    return dispatch(GetCredits(auth));
  }
  function notification(data, condition) {
    if (condition === "success") {
      toast.success(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }
  return (
    <div className="col-12 col-sm-12 col-md-4 mb-md-2 add_to_cart">
      <Card>
        <div>
          <div className="add_cart_price_section">
            {/* <h3>$99</h3>   */}
            <h2 className="text-white d-flex justify-content-center align-items-center">
              Credit Left: {data.availableCredit} <BsCoin className="pl-2" />
            </h2>
          </div>
          <div className="course_include_section">
            <h3>This coaching includes:</h3>
            {/* <p>
              <RiYoutubeLine />
              2.5 hours on demand
            </p>
            <p>
              <BsFileEarmark />6 articles
            </p> */}
            {/* <p>
              <HiOutlineFolderDownload />
              11 downloadable resources
            </p> */}
            <p>
              <ImInfinite />
              Full lifetime access
            </p>
            {/* <p>
              <VscDeviceMobile />
              Access on mobile and TV
            </p> */}
            {/* <p>
              <MdOutlineAssignment />
              Assignments
            </p>
            <p>
              <AiOutlineTrophy />
              Certificate of completion
            </p> */}
          </div>
        </div>
        {/* <Link to={PATH.EXPLOREPRICINGPLANS}> */}
        <button
          type="button"
          onClick={() => {
            BuyNowCoaching();
          }}
          className="pricing_plan_btn mt-3"
        >
          Buy now
        </button>
        {/* </Link> */}
      </Card>
    </div>
  );
}
function TopCoaching() {
  const [addToCart, setAddToCart] = useState(true);
  let dispatch = useDispatch();
  let auth = useAuth();
  const location = useLocation();
  const CoachingsData = location?.state?.CoachingData;
  let getTodayListing = useSelector((state) => state.coaching);
  useEffect(() => {
    dispatch(GetTopCoaching());
  }, [dispatch]);
  let topCoaching = getTodayListing && getTodayListing.getUpcomingCoaching;
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="font-weight-bold">
          <u>Coaching Preview</u>
        </h2>
        {/* <GenericSearchField placeholder="Search" /> */}
      </div>
      <div className="row mt-5 edutech-sm-mt-5">
        <div className="col-12 col-md-8 mb-md-2">
          <Card className=" p-4">
            <div className="embed-responsive embed-responsive-16by9 video-height">
              <iframe
                title="dashboard-video"
                className="embed-responsive-item"
                src={CoachingsData.videoURL}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                // src="//www.youtube.com/embed/zB4I68XVPzQ"
              ></iframe>
              {/* <div className="edutech-video-span">
                <p className="edutech-video-span-determine-text">
                  What determines
                </p>
                <p className="edutech-video-span-profit-text">Good Profits?</p>
              </div> */}
            </div>
            <div className="text-capitilize b-500 px-3 py-2 pt-3 mt-4">
              <div className="d-flex justify-content-between">
                <h3 className="mb-3 font-weight-bold">
                  {/* Master Capital Wisdom - M1 */}
                  {CoachingsData.coachingName}
                </h3>
                <div>
                  <p className="firstpara text-left mt-0 pt-0">
                    {" "}
                    {CoachingsData.price
                      ? `$ ${CoachingsData.price}`
                      : "Free"}{" "}
                  </p>
                  {/* <span className="  mb-0 pricing-discount-line text-muted">
                    $24.99
                  </span> */}
                </div>
              </div>

              <h5 className="mb-3">COACHING DESCRIPTION</h5>
              <p className="text-justify mb-0">
                {ReactHtmlParser(CoachingsData.description)}
                {/* Amet minim mollit non deserunt ullamco est sit aliqua dolor do
      amet sint. Velit officia consequat duis enim velit mollit.
      Exercitation veniam consequat sunt nostrud amet. Amet minim
      mollit non deserunt ullamco est sit aliqua dolor do amet sint.
      Velit officia consequat duis enim velit mollit. Exercitation
      veniam consequat sunt nostrud amet. Velit officia consequat duis
      enim velit mollit. Exercitation veniam consequat sunt nostrud
      amet. Exercitation veniam consequat sunt nostrud amet. */}
              </p>
            </div>
          </Card>
          <Card className="mt-3 px-4 pt-4 edutech-sm-mt-5">
            <h3 className="mb-4">Other Upcoming Coaching</h3>
            <div className="row">
              {topCoaching &&
                topCoaching.map((list2, index) => {
                  return (
                    <div className="col-12 col-md-4 " key={index}>
                      <Link
                        to={{
                          pathname: PATH.COACHING_PREVIEW,
                          state: {
                            CoachingData: list2,
                            Coaching: "UPCOMMING",
                          },
                        }}
                      >
                        <div className="card cursor">
                          {" "}
                          <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                            <iframe
                              title="dashboard-video"
                              className="embed-responsive-item"
                              allowFullScreen
                              src={list2.videoURL}
                            ></iframe>
                          </div>
                          <div className="pl-3 my-2">
                            <p className="firstpara text-left m-0">
                              {" "}
                              {list2.coachingName}
                            </p>
                            <p className="text-muted text-left mb-0">
                              {" "}
                              {list2.companyTitle}
                            </p>
                            <p className="firstpara text-left m-0 p-0">
                              {" "}
                              {list2.price ? list2.price : "Free"}{" "}
                              <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                                {list2.discount}
                              </span>
                            </p>
                            <p className=" text-left mt-2 mb-0">
                              {" "}
                              {list2.fixedDate !== null ? (
                                <>
                                  {/* {dateTimeConvert(list2.fixedDate)
                                    ? dateTimeConvert(list2.fixedDate)
                                    : "N/A"} */}

                                  {list2.fixedDate.split("T")[0] +
                                    " , " +
                                    moment(
                                      list2.fixedDate.split("T")[1],
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}
                                </>
                              ) : (
                                <>
                                  <span className=" ml-3 mb-0  ">
                                    {/* {dateTimeConvert(list2.startRecurrentDate)} */}
                                    {list2.startRecurrentDate.split("T")[0] +
                                      " , " +
                                      moment(
                                        list2.startRecurrentDate.split("T")[1],
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </Card>
        </div>
        {addToCart === true ? (
          <BeginCourseData
            setAddToCart={setAddToCart}
            CoachingsData={CoachingsData}
          />
        ) : (
          <AddtoCart CoachingsData={CoachingsData} />
        )}
      </div>
    </>
  );
}
function TodayCoaching() {
  const location = useLocation();
  const CoachingsData = location?.state?.CoachingData;
  let dispatch = useDispatch();
  let auth = useAuth();
  let getTodayListing = useSelector((state) => state.coaching);
  const [addToCart, setAddToCart] = useState(true);
  useEffect(() => {
    dispatch(GetUpcomingCoaching(auth));
  }, [dispatch]);
  let upcomingCoaching = getTodayListing && getTodayListing.getUpcomingCoaching;
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="font-weight-bold">
          <u>Coaching Preview</u>
        </h2>
        {/* <GenericSearchField placeholder="Search" /> */}
      </div>
      <div className="row mt-5 edutech-sm-mt-5">
        <div className="col-12 col-md-8 mb-md-2">
          <Card className=" p-4">
            <div className="embed-responsive embed-responsive-16by9 video-height">
              <iframe
                title="dashboard-video"
                className="embed-responsive-item"
                src={CoachingsData.videoURL}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                // src="//www.youtube.com/embed/zB4I68XVPzQ"
              ></iframe>
              {/* <div className="edutech-video-span">
                <p className="edutech-video-span-determine-text">
                  What determines
                </p>
                <p className="edutech-video-span-profit-text">Good Profits?</p>
              </div> */}
            </div>
            <div className="text-capitilize b-500 px-3 py-2 pt-3 mt-4">
              <div className="d-flex justify-content-between">
                <h3 className="mb-3 font-weight-bold">
                  {/* Master Capital Wisdom - M1 */}
                  {CoachingsData.coachingName}
                </h3>
                <div>
                  <p className="firstpara text-left mt-0 pt-0">
                    {" "}
                    {CoachingsData.price
                      ? `$ ${CoachingsData.price}`
                      : "Free"}{" "}
                  </p>
                  {/* <span className="  mb-0 pricing-discount-line text-muted">
                    $24.99
                  </span> */}
                </div>
              </div>
              <h5 className="mb-3">COACHING DESCRIPTION</h5>
              <p className="text-justify mb-0">
                {ReactHtmlParser(CoachingsData.description)}
              </p>
            </div>
          </Card>
          <Card className="mt-3 px-4 pt-4 edutech-sm-mt-5">
            <h3 className="mb-4">Other Upcoming Coaching</h3>
            <div className="row">
              {upcomingCoaching &&
                upcomingCoaching.map((list2, index) => {
                  return (
                    <div className="col-12 col-md-4 " key={index}>
                      <Link
                        to={{
                          pathname: PATH.COACHING_PREVIEW,
                          state: {
                            CoachingData: list2,
                            Coaching: "UPCOMMING",
                          },
                        }}
                      >
                        <div className="card cursor">
                          {" "}
                          <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                            <iframe
                              title="dashboard-video"
                              className="embed-responsive-item"
                              allowFullScreen
                              src={list2.videoURL}
                            ></iframe>
                          </div>
                          <div className="pl-3 my-2">
                            <p className="firstpara text-left m-0">
                              {" "}
                              {list2.coachingName}
                            </p>
                            <p className="text-muted text-left mb-0">
                              {" "}
                              {list2.companyTitle}
                            </p>
                            <p className="firstpara text-left m-0 p-0">
                              {" "}
                              {list2.price ? list2.price : "Free"}{" "}
                              <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                                {list2.discount}
                              </span>
                            </p>
                            <p className=" text-left mt-2 mb-0">
                              {" "}
                              {list2.fixedDate !== null ? (
                                <>
                                  {/* {dateTimeConvert(list2.fixedDate)
                                    ? dateTimeConvert(list2.fixedDate)
                                    : "N/A"} */}

                                  {list2.fixedDate.split("T")[0] +
                                    " , " +
                                    moment(
                                      list2.fixedDate.split("T")[1],
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}
                                </>
                              ) : (
                                <>
                                  <span className=" ml-3 mb-0  ">
                                    {/* {dateTimeConvert(list2.startRecurrentDate)}{" "} */}
                                    {list2.startRecurrentDate.split("T")[0] +
                                      " , " +
                                      moment(
                                        list2.startRecurrentDate.split("T")[1],
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </Card>
        </div>
        {addToCart === true ? (
          <BeginCourseData
            setAddToCart={setAddToCart}
            CoachingsData={CoachingsData}
          />
        ) : (
          <AddtoCart CoachingsData={CoachingsData} />
        )}
      </div>
    </>
  );
}
function UpcommingCoaching() {
  const location = useLocation();
  const CoachingsData = location?.state?.CoachingData;
  let dispatch = useDispatch();
  let auth = useAuth();
  let getTodayListing = useSelector((state) => state.coaching);
  const [addToCart, setAddToCart] = useState(true);
  useEffect(() => {
    dispatch(GetUpcomingCoaching(auth));
  }, [dispatch]);
  let upcomingCoaching = getTodayListing && getTodayListing.getUpcomingCoaching;
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="font-weight-bold">
          <u>Coaching Preview</u>
        </h2>
        {/* <GenericSearchField placeholder="Search" /> */}
      </div>
      <div className="row mt-5 edutech-sm-mt-5">
        <div className="col-12 col-md-8 mb-md-2">
          <Card className=" p-4">
            <div className="embed-responsive embed-responsive-16by9 video-height">
              <iframe
                title="dashboard-video"
                className="embed-responsive-item"
                src={CoachingsData.videoURL}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                // src="//www.youtube.com/embed/zB4I68XVPzQ"
              ></iframe>
              {/* <div className="edutech-video-span">
                <p className="edutech-video-span-determine-text">
                  What determines
                </p>
                <p className="edutech-video-span-profit-text">Good Profits?</p>
              </div> */}
            </div>
            <div className="text-capitilize b-500 px-3 py-2 pt-3 mt-4">
              <div className="d-flex justify-content-between">
                <h3 className="mb-3 font-weight-bold">
                  {/* Master Capital Wisdom - M1 */}
                  {CoachingsData.coachingName}
                </h3>
                <div>
                  <p className="firstpara text-left mt-0 pt-0">
                    {" "}
                    {CoachingsData.price
                      ? `$ ${CoachingsData.price}`
                      : "Free"}{" "}
                  </p>
                  {/* <span className="  mb-0 pricing-discount-line text-muted">
                    $24.99
                  </span> */}
                </div>
              </div>
              <h5 className="mb-3">COACHING DESCRIPTION</h5>
              <p className="text-justify mb-0">
                {ReactHtmlParser(CoachingsData.description)}
                {/* Amet minim mollit non deserunt ullamco est sit aliqua dolor do
        amet sint. Velit officia consequat duis enim velit mollit.
        Exercitation veniam consequat sunt nostrud amet. Amet minim
        mollit non deserunt ullamco est sit aliqua dolor do amet sint.
        Velit officia consequat duis enim velit mollit. Exercitation
        veniam consequat sunt nostrud amet. Velit officia consequat duis
        enim velit mollit. Exercitation veniam consequat sunt nostrud
        amet. Exercitation veniam consequat sunt nostrud amet. */}
              </p>
            </div>
          </Card>
          <Card className="mt-3 px-4 pt-4 edutech-sm-mt-5">
            <h3 className="mb-4">Other Upcoming Coaching</h3>
            <div className="row">
              {upcomingCoaching &&
                upcomingCoaching.map((list2, index) => {
                  return (
                    <div className="col-12 col-md-4 " key={index}>
                      <Link
                        to={{
                          pathname: PATH.COACHING_PREVIEW,
                          state: {
                            CoachingData: list2,
                            Coaching: "UPCOMMING",
                          },
                        }}
                      >
                        <div className="card cursor">
                          {" "}
                          <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                            <iframe
                              title="dashboard-video"
                              className="embed-responsive-item"
                              allowFullScreen
                              src={list2.videoURL}
                            ></iframe>
                          </div>
                          <div className="pl-3 my-2">
                            <p className="firstpara text-left m-0">
                              {" "}
                              {list2.coachingName}
                            </p>
                            <p className="text-muted text-left mb-0">
                              {" "}
                              {list2.companyTitle}
                            </p>
                            <p className="firstpara text-left m-0 p-0">
                              {" "}
                              {list2.price ? list2.price : "Free"}{" "}
                              <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                                {list2.discount}
                              </span>
                            </p>
                            <p className=" text-left mt-2 mb-0">
                              {list2.fixedDate !== null ? (
                                <>
                                  {/* {dateTimeConvert(list2.fixedDate)
                                    ? dateTimeConvert(list2.fixedDate)
                                    : "N/A"} */}
                                  {list2.fixedDate.split("T")[0] +
                                    " , " +
                                    moment(
                                      list2.fixedDate.split("T")[1],
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}
                                </>
                              ) : (
                                <>
                                  <span className=" ml-3 mb-0  ">
                                    {/* {dateTimeConvert(list2.startRecurrentDate)}{" "} */}
                                    {list2.startRecurrentDate.split("T")[0] +
                                      " , " +
                                      moment(
                                        list2.startRecurrentDate.split("T")[1],
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </Card>
        </div>
        {addToCart === true ? (
          <BeginCourseData
            setAddToCart={setAddToCart}
            CoachingsData={CoachingsData}
          />
        ) : (
          <AddtoCart CoachingsData={CoachingsData} />
        )}
      </div>
    </>
  );
}
