import { request, success, failure, LECTURE_NOTES_REQUEST } from "./utilities";
import { createNotes, getAllNotesByLecture } from "../api";

export function CreateNotes(data, auth, moveToNext) {
    return (dispatch) => {
        dispatch(request(LECTURE_NOTES_REQUEST.LECTURE_NOTES_REQUEST));
        createNotes(data, auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(LECTURE_NOTES_REQUEST.LECTURE_NOTES_SUCCESS, response.data.data)
                    );
                    if (moveToNext) {
                        moveToNext();
                    }
                } else {
                    dispatch(
                        failure(
                            LECTURE_NOTES_REQUEST.LECTURE_NOTES_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        LECTURE_NOTES_REQUEST.LECTURE_NOTES_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetAllNotesByLecture(LectureId, auth) {
    return (dispatch) => {
        dispatch(request(LECTURE_NOTES_REQUEST.GET_ALL_NOTES_BY_LECTURE_REQUEST));
        getAllNotesByLecture(LectureId, auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(LECTURE_NOTES_REQUEST.GET_ALL_NOTES_BY_LECTURE_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            LECTURE_NOTES_REQUEST.GET_ALL_NOTES_BY_LECTURE_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        LECTURE_NOTES_REQUEST.GET_ALL_NOTES_BY_LECTURE_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}