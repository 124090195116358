import React from "react";
import { Button, Card } from "react-bootstrap";
import { IMAGES } from "../../../assets/images";
import { ToastContainer, toast } from "react-toastify";
import { dateTimeConvert, GenericSearchField } from "../../../assets";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import ReactHtmlParser from "react-html-parser";

const moment = require("moment-timezone");

export default function MyCoachingPreview() {
  const location = useLocation();
  const CoachingsData = location?.state?.id;
  return (
    <>
      <MyCoachingListPreview CoachingsData={CoachingsData} />
    </>
  );
}
function BeginCourseData(props) {
  function CopiedToaster() {
    toast.success("Zoom link Copied Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      <ToastContainer />
      <div className="col-12 col-md-4 mb-md-2">
        <Card className="px-4 py-2 pt-3">
          <h3 className="f-20 font-weight-bold">About Course Provider</h3>
          <hr className="mt-0" />
          <div className="d-flex align-items-center">
            <span className="user-img mt-0">
              <img
                className="rounded-circle edutech-speaker-img"
                // src={IMAGES.PROFILELOGO}
                src={
                  props.CoachingsData.providerImage
                    ? props.CoachingsData.providerImage
                    : IMAGES.PROFILELOGO
                }
                width="31"
                alt="No Image"
              />
            </span>
            <div>
              <h5 className="pl-4 font-weight-bold mb-0">
                {props.CoachingsData.companyTitle}
              </h5>
              {/* <h6 className="pl-4 mb-0">{props.CoachingsData.companyTitle}</h6> */}
            </div>
          </div>
          <div className="mt-4">
            <h5 className="font-weight-bold">Details :-</h5>
            <p className="edutech-speaker-text-detail mb-0">
              {props.CoachingsData.providerDescription
                ? props.CoachingsData.providerDescription
                : "No Detail Found"}
            </p>
          </div>
        </Card>
        <Card className="px-4 py-2 pt-3">
          <h3 className="f-20">
            <b>More about the coaching</b>
          </h3>
          <hr className="mt-0" />
          <table className="table table-borderless ">
            {props.CoachingsData.fixedDate ? (
              <>
                <tr className="f-13">
                  <td>
                    <b>Date:</b>
                  </td>
                  <td>
                    {/* {dateTimeConvert(props.CoachingsData.fixedDate) ? dateTimeConvert(props.CoachingsData.fixedDate) : "N/A"} */}
                    {props.CoachingsData.fixedDate.split("T")[0] +
                      " , " +
                      moment(
                        props.CoachingsData.fixedDate.split("T")[1],
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr className="f-13">
                  <td>
                    <b>Reccurrent date:</b>
                  </td>
                  <td>
                    {props.CoachingsData.startRecurrentDate.split("T")[0] +
                      " , " +
                      moment(
                        props.CoachingsData.startRecurrentDate.split("T")[1],
                        "HH:mm:ss"
                      ).format("hh:mm A")}{" "}
                    ,{" "}
                    {props.CoachingsData.endRecurrentDate.split("T")[0] +
                      " , " +
                      moment(
                        props.CoachingsData.endRecurrentDate.split("T")[1],
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    {/* {dateTimeConvert(props.CoachingsData.endRecurrentDate)
                      ? dateTimeConvert(props.CoachingsData.endRecurrentDate)
                      : "N/A"} */}
                  </td>
                </tr>
              </>
            )}
          </table>
        </Card>
        <Card className="px-4 py-2 pt-3">
          <h3 className="f-20">
            <b>More actions</b>
          </h3>
          <hr className="mt-0" />
          <table className="table table-borderless table-responsive">
            <tr>
              <td>Zoom Link:</td>
              <td
                onClick={() => {
                  navigator.clipboard.writeText(props.CoachingsData.zoomLink);
                  CopiedToaster();
                }}
                className="longtext-dotted-format"
              >
                {props.CoachingsData.zoomLink}
              </td>
            </tr>
            <tr>
              <td>Zoom ID:</td>
              <td>{props.CoachingsData.zoomId}</td>
            </tr>
            <tr>
              <td>Zoom Passcode:</td>
              <td>{props.CoachingsData.zoomPassCode}</td>
            </tr>
          </table>
          <a href="https://zoom.us/join" target="_blank " className="mt-3">
            <Button className="edutech-action-button mb-4 border-0 w-100">
              LAUNCH MEETING
            </Button>
          </a>
        </Card>
      </div>
    </>
  );
}
function MyCoachingListPreview(props) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="font-weight-bold">
          <u>Coaching Preview</u>
        </h2>
        {/* <GenericSearchField placeholder="Search" /> */}
      </div>
      <div className="row mt-5 edutech-sm-mt-5">
        <div className="col-12 col-md-8 mb-md-2">
          <Card className=" p-4">
            <div className="embed-responsive embed-responsive-16by9 video-height">
              <iframe
                title="dashboard-video"
                className="embed-responsive-item"
                src={props.CoachingsData.videoURL}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </div>
            <div className="text-capitilize b-500 px-3 py-2 pt-3 mt-4">
              <div className="d-flex justify-content-between">
                <h3 className="mb-3 font-weight-bold">
                  {props.CoachingsData.coachingName}
                </h3>
                <div>
                  <p className="firstpara text-left mt-0 pt-0">
                    {" "}
                    {props.CoachingsData.price
                      ? `$ ${props.CoachingsData.price}`
                      : "Free"}{" "}
                  </p>
                </div>
              </div>

              <h5 className="mb-3">COACHING DESCRIPTION</h5>
              <p className="text-justify mb-0">
                {ReactHtmlParser(props.CoachingsData.description)}
              </p>
            </div>
          </Card>
        </div>
        <BeginCourseData CoachingsData={props.CoachingsData} />
      </div>
    </>
  );
}
