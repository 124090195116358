import { request, success, failure, USER_PROFILE_REQUEST } from "./utilities";
import {
  getUserProfile,
  getUserStudentProfile,
  updateProviderProfile,
  updateStudentProfile,
} from "../api";
export function GetUserStudentProfile(auth) {
  return (dispatch) => {
    dispatch(request(USER_PROFILE_REQUEST.GET_USER_STUDENT_PROFILE_REQUEST));
    getUserStudentProfile(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              USER_PROFILE_REQUEST.GET_USER_STUDENT_PROFILE_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              USER_PROFILE_REQUEST.GET_USER_STUDENT_PROFILE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER_PROFILE_REQUEST.GET_USER_STUDENT_PROFILE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetUserProfile(auth) {
  return (dispatch) => {
    dispatch(request(USER_PROFILE_REQUEST.GET_USER_PROFILE_REQUEST));
    getUserProfile(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              USER_PROFILE_REQUEST.GET_USER_PROFILE_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              USER_PROFILE_REQUEST.GET_USER_PROFILE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER_PROFILE_REQUEST.GET_USER_PROFILE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function UpdateProviderProfile(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(USER_PROFILE_REQUEST.UPDATE_PROVIDER_PROFILE_REQUEST));
    updateProviderProfile(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              USER_PROFILE_REQUEST.UPDATE_PROVIDER_PROFILE_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              USER_PROFILE_REQUEST.UPDATE_PROVIDER_PROFILE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER_PROFILE_REQUEST.UPDATE_PROVIDER_PROFILE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function UpdateStudentProfile(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(USER_PROFILE_REQUEST.UPDATE_STUDENT_PROFILE_REQUEST));
    updateStudentProfile(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              USER_PROFILE_REQUEST.UPDATE_STUDENT_PROFILE_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              USER_PROFILE_REQUEST.UPDATE_STUDENT_PROFILE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER_PROFILE_REQUEST.UPDATE_STUDENT_PROFILE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
