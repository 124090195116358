import React, { useEffect, useReducer, useState } from "react";
import { Table } from "react-bootstrap";
import { ErrorMessage, FieldError, Loader, TeacherCourseTabs } from "../../../../../assets";
import { PATH } from "../../../../../config";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { Form, Modal, Button } from "react-bootstrap";
import {
  MdDelete,
  MdDeleteOutline,
  MdOutlineDragIndicator,
} from "react-icons/md";
import ReactTooltip from "react-tooltip";
import {
  IoCloudDownloadOutline,
  IoCloudDownloadSharp,
  IoClose,
} from "react-icons/io5";
import { Container, Draggable } from "react-smooth-dnd";
import { RiPencilLine } from "react-icons/ri";
import { GoCheck } from "react-icons/go";
import {
  AiOutlineEye,
  AiOutlineCheckCircle,
  AiFillEye,
  AiFillCheckCircle,
} from "react-icons/ai";
import { useForm } from "react-hook-form";
import { CreateSection, GetLectureByFilter, GetSectionByFilter } from "../../../../../redux/actions/coursesAction";
import { useAuth } from "../../../../../Navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
export default function TeacherCurriculumList(props) {
  let history = useHistory();
  let dispatch1 = useDispatch();
  let CourseValue = useSelector((state) => state.courses);
  // let lectNameData = CourseValue?.getLectureByFilter?.[0]?.lectures?.[0]?.lectureName;
  console.log("asjgywq", CourseValue?.getLectureByFilter?.[0]?.lectures)
  let auth = useAuth();
  useEffect(() => {
    // dispatch1(GetSectionByFilter(CourseValue.createCourses, auth));
    dispatch1(GetLectureByFilter(CourseValue.createCourses, auth));
  }, [dispatch1]);
  function goBack() {
    history.push(PATH.TEACHERNEWCOURSE);
  }
  const [showInputfield, setInputfield] = useState(false);
  const [inputfieldvalue, setInputfieldvalue] = useState("");
  const [tempvalue, settempvalue] = useState();
  const [bulkShow, setBulkShow] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [indexes, setIndexes] = React.useState([
    {
      id: 0,
      status: "DRAFT",
      lectureName: "First Lecture",
      isChecked: false,
    },
    {
      id: 1,
      status: "",
      lectureName: "Finance management",
      isChecked: false,
    },
    {
      id: 2,
      status: "DRAFT",
      lectureName: "Chemistry",
      isChecked: false,
    },
  ]);
  const [counter, setCounter] = React.useState(1);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const addFriend = () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes,
      {
        id: counter,
        status: "DRAFT",
        lectureName: "New Lecture",
        isChecked: checkAll,
      },
    ]);
    setCounter((prevCounter) => prevCounter + 1);
  };
  function setCheckChanged(id) {
    dispatch({ type: "ADD_OR_REMOVE", payload: id });
  }
  function checkAllChanged(chcked) {
    setCheckAll(chcked);
    if (state.isChecked.length !== indexes.length) {
      let checkList = [];
      for (let i = 0; i < indexes.length; i++) {
        let k = indexes[i];

        checkList.push(k.id);
      }

      dispatch({ type: "CHECK_ALL", payload: checkList });
    } else {
      dispatch({ type: "REMOVE_ALL", payload: [] });
    }
  }
  function settableheader() {
    settempvalue(inputfieldvalue);
    setInputfield(true);
  }
  function hidefield() {
    setInputfieldvalue(tempvalue);
    setInputfield(false);
  }
  const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;
    const result = [...arr];
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
  };
  const [newSectionAdd, setNewSectionAdd] = React.useState(false);
  const [idx, setidx] = React.useState([]);
  const [cx, setcx] = React.useState(0);
  const addEnemy = () => {
    setidx(previdx => [...previdx, cx]);
    setcx(prevcx => prevcx + 1);
  }
  const { handleSubmit } = useForm();
  function onSubmit(data) {
    moveToNext(data);

  }
  function moveToNext(finaldata) {
    history.push({
      pathname: PATH.PRICINGPLAN,
      // state: { fromPreviewPage: false },
      finaldata: finaldata
    });

  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="teacher-curriculum  pl-3 pb-4">
          <div className="d-flex align-items-center justify-content-between float-right">
            {/* <BsArrowLeft size={35} className="cursor" onClick={() => goBack()} /> */}
            <div className="teacher-curriculum-dashboard d-flex align-items-center">
              {/* <Link
                to={{
                  pathname: PATH.TEACHERCURRICULUMPREVIEW,
                  // state: { fromPreviewPage: fromPreviewPage },
                }}
                className="mr-2"
              >
                <button className="previews_btn w-100" type="button">
                  Preview
                </button>
              </Link> */}
              {/* <Link
              to={{
                pathname: PATH.CREATENEWSECTION,
                state: { fromPreviewPage: fromPreviewPage },
              }}
            > */}
              <button
                className="create_course w-100"
                type="button"
                onClick={() => setNewSectionAdd(true)}
              >
                New Section
              </button>
              {/* </Link> */}
            </div>
          </div>

          {/* <div className="d-flex align-items-center pl-4 ml-1 mt-5">
            <div className="checkbox-border">
              <input
                type="checkbox"
                checked={
                  state &&
                    state.isChecked &&
                    state.isChecked.length !== indexes.length
                    ? false
                    : true
                }
                onChange={(e) => checkAllChanged(e.target.checked)}
                id="flexCheckDefault"
              />
              <label for="flexCheckDefault" className="mb-0">{``}</label>
            </div>
            {showInputfield === false ? (
              <label className="form-check-label font-weight-400 pl-2">
                {inputfieldvalue}
                <RiPencilLine
                  className="ml-3 mb-2 penicon cursor"
                  onClick={() => settableheader()}
                />
              </label>
            ) : (
              <div className="d-flex">
                <input
                  className="form-control "
                  value={inputfieldvalue}
                  onChange={(e) => setInputfieldvalue(e.target.value)}
                />
                <GoCheck
                  className="iconsstyling ml-2"
                  onClick={() => setInputfield(false)}
                />
                <IoClose
                  className="iconsstyling closecolor ml-2 "
                  onClick={() => hidefield()}
                />
              </div>
            )}
          </div> */}
        </div>
        <div className="card teacher-curriculum table-responsive">
          {/* <Table>
            <tbody className="">
              <Container
                lockAxis="y"
                dragHandleSelector=".column-drag-handle"
                onDrop={(e) => setIndexes(applyDrag(indexes, e))}
              >
                {indexes.map((data, index) => {
                  const fieldName = `friends[${index}]`;
                  return (
                    <LectureBody
                      key={index}
                      idx={index}
                      index={index + 1}
                      data={data}
                      isChecked={state.isChecked}
                      setCheckChanged={setCheckChanged}
                      bulkShow={bulkShow}
                      fieldName={fieldName}
                    // fromPreviewPage={fromPreviewPage}
                    />
                  );
                })}
              </Container>
              <tr>
                <td className="w-100">
                  <div className="pl-2 cursor" onClick={addFriend}>
                    <span className="set-radius-add-bnt">+</span>
                    <label className="font-weight-400 mt-1 pl-3 cursor">
                      Add new lecture
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table> */}
        </div>
        {/* {
          idx.map(index => {
            console.log("indexxxxxx", index)
            const fieldName = `friends[${index}]`;
            return (
              <fieldset name={fieldName} key={index}>
                { */}
        {
          CourseValue.getLectureByFilter.length === 0 ?
            <p className="text-secondary p-4 text-center">Add New Section</p>
            :
            <ComponentNewSection
              CourseValue={CourseValue}
              inputfieldvalue={inputfieldvalue}
            />
        }
        {/* }

              </fieldset>
            )
          })
        } */}
        {
          CourseValue.getLectureByFilter.length !== 0 && CourseValue?.getLectureByFilter?.[0]?.lectures !== null && CourseValue?.getLectureByFilter?.[0]?.lectures !== undefined ?
            <>
              <div className="teacher-curriculum-dashboard d-flex align-items-center justify-content-end">
                {/* <Link to={PATH.PRICINGPLAN}> */}
                <Button className="edutech-curriculum-action-button edutech-pull-right my-2 font-weight-bold" type="submit">
                  Next
                </Button>
                {/* </Link> */}
              </div>
            </>
            :
            ""
        }
        {/* <Link to={PATH.PRICINGPLAN}>
            <button
              className="create_course w-100 align-items-center d-flex"
              type="button"
            >
              Next <HiArrowRight className="ml-1" size={20} />
            </button>
          </Link> */}
        {/* {fromPreviewPage === false ? (
            <Link to={PATH.PRICINGPLAN}>
              <button
                className="create_course w-100 align-items-center d-flex"
                type="button"
              >
                Next <HiArrowRight className="ml-1" size={20} />
              </button>
            </Link>
          ) : (
            // <Link
            //   to={{
            //     pathname: PATH.PRICINGPLAN,
            //   }}
            // >
            //   <button
            //     className="create_course w-100 align-items-center d-flex"
            //     type="button"
            //   >
            //     Next
            //   </button>
            // </Link>
            <Link
              to={{
                pathname: PATH.TEACHERCOURSEINFORMATION,
              }}
            >
              <button
                className="create_course w-100 align-items-center d-flex"
                type="button"
              >
                Next
              </button>
            </Link>
          )} */}
        {/* {fromPreviewPage === true ? (
          ""
        ) : ( */}
        <div className="d-flex align-items-center justify-content-center coursetabs_height_set">
          <div className="teachergroupbtn">
            <TeacherCourseTabs />
          </div>
        </div>
        {/* )} */}
        {state &&
          state.isChecked &&
          state.isChecked.length &&
          state.isChecked.length >= 1 ? (
          <BulkCard />
        ) : (
          <></>
        )}
        <AddNewSectionModal
          show={newSectionAdd}
          addEnemy={addEnemy}
          onHide={() => setNewSectionAdd(false)}
        />
      </Form>
    </>
  );
}
function AddNewSectionModal(props) {
  const { register, handleSubmit, errors } = useForm();
  let auth = useAuth()
  let dispatch = useDispatch();
  let CourseValue = useSelector((state) => state.courses);
  const [appendSection, setAppendSection] = useState(
    props.inputfieldvalue ? props.inputfieldvalue : ""
  );
  const [errorsection, setErrorSection] = useState("");
  function onSubmit2() {
    setErrorSection(appendSection)
    if (appendSection) {
      let finaldata = {
        "courseId": parseInt(CourseValue.createCourses),
        "sectionName": appendSection
      }
      dispatch(CreateSection(finaldata, auth, moveToNext));
    }
  }
  function moveToNext() {
    setAppendSection(null)
    props.onHide();
    props.addEnemy();
    dispatch(GetLectureByFilter(CourseValue.createCourses, auth));
  }
  useEffect(()=> {
    if(appendSection){
    setErrorSection(appendSection)
    }
  },[appendSection])
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="admin-manage-user"
    >
      <Modal.Title id="contained-modal-title-vcenter">New Section</Modal.Title>
      <Form >
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-12">
              <Form.Group>
                <Form.Label>Section Name</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="appendSectionnew"
                  type="text"
                  placeholder="Section title"
                  value={appendSection}
                  pattern="^(?!\s*$).+"
                  ref={register({ required: true })}
                  onChange={(e) => {
                    setAppendSection(e.target.value);
                  }}
                  style={{
                    borderColor: errors && errors.appendSectionnew ? "#a80000" : "",
                  }}
                />
                {errors.appendSectionnew && (
                  <FieldError message={"This Field is Required"} />
                )}
                {errorsection ?
                  "" :
                  <FieldError message={"This Field is Required"} />
                }

              </Form.Group>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer ClassName="edutech-footter-modal ">
          <Button variant=" edutech-admin-primary " type="button" onClick={() => { onSubmit2() }}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
function ComponentNewSection(props) {
  let location = useLocation();
  let history = useHistory();
  const INITIAL_STATE_2 = {
    isChecked: [],
  };
  const { register, handleSubmit, errors } = useForm();
  const [showInputfield, setInputfield] = useState(false);
  const [bulkShow] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [indexes, setIndexes] = React.useState([0
  ]);
  function submitArrayData(data) {
    console.log("ahsjhdjkhsajdhsaj", data)
  }
  const [counter, setCounter] = React.useState(1);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE_2);
  const addFriend = () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes, counter
    ]);
    console.log("asdasdhashjsh", indexes)
    setCounter((prevCounter) => prevCounter + 1);
  };
  function setCheckChanged(id) {
    dispatch({ type: "ADD_OR_REMOVE", payload: id });
  }
  const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
  };
  return (
    <>
      {props?.CourseValue &&
        props?.CourseValue.getLectureByFilterLoading === true &&
        props?.CourseValue.getLectureByFilterSuccess === false &&
        props?.CourseValue.getLectureByFilterFailure === false && <Loader />}
      {props?.CourseValue &&
        props?.CourseValue.getLectureByFilterLoading === false &&
        props?.CourseValue.getLectureByFilterSuccess === false &&
        props?.CourseValue.getLectureByFilterFailure === true && (
          <ErrorMessage message={"record not found"} />
        )}
      {props?.CourseValue &&
        props?.CourseValue.getLectureByFilterLoading === false &&
        props?.CourseValue.getLectureByFilterSuccess === true &&
        props?.CourseValue.getLectureByFilterFailure === false && (
          <>
            {props?.CourseValue?.getLectureByFilter?.map((course, index) => {
              console.log("askdgkuqgwe", course)
              return (
                <>
                  <Form onSubmit={handleSubmit(submitArrayData)} name="myform" id="myform" className="text-left">
                    {/* <CheckBoxSection CourseValue={props.CourseValue} course={course} /> */}
                    <div className="teacher-curriculum  pl-3 pb-4" key={index}>
                      <div className="d-flex align-items-center pl-4 ml-1 mt-5">
                        <div className="checkbox-border">
                          <input
                            type="checkbox"
                            checked={
                              state &&
                                state.isChecked &&
                                state.isChecked.length !== indexes.length
                                ? false
                                : true
                            }
                            // onChange={(e) => checkAllChanged(e.target.checked)}
                            id={index}
                          />
                          <label for={index} className="mb-0">{``}</label>
                        </div>
                        <label className="form-check-label font-weight-400 pl-2 ">
                          {course.sectionName}
                        </label>
                      </div>
                    </div>
                    {/* {course.sectionName} */}
                    <div className="card teacher-curriculum table-responsive">
                      <Table >
                        <tbody className="">
                          <Container
                            lockAxis="y"
                            dragHandleSelector=".column-drag-handle"
                            onDrop={(e) => setIndexes(applyDrag(indexes, e))}
                          >

                            <LectureBody
                              // key={id}
                              // idx={id}
                              // id={id + 1}
                              // data={data}
                              isChecked={state.isChecked}
                              setCheckChanged={setCheckChanged}
                              bulkShow={bulkShow}
                              course={course}
                              CourseValueData={props.CourseValue}
                              // fieldName={fieldName}
                              sectionId={course.sectionId}
                            // fromPreviewPage={fromPreviewPage}
                            />
                          </Container>
                          {/* <tr>
                            <td className="w-100">
                              <div className="pl-2 cursor" onClick={addFriend} type="submit">
                                <span className="set-radius-add-bnt">+</span>
                                <label className="font-weight-400 mt-1 pl-3 cursor">
                                  Add new lecture
                                </label>
                              </div>
                            </td>
                          </tr> */}
                        </tbody>
                      </Table>
                    </div>
                  </Form>
                </>
              )
            })}
          </>
        )}
    </>
  )
}
function LectureBody(props) {
  const [showInputfield, setInputfield] = useState(false);
  let CourseValue = useSelector((state) => state.courses);
  console.log("ashdghgsadsa", props.course)
  let auth = useAuth()
  let dispatch = useDispatch();
  const [inputfieldvalue, setInputfieldvalue] = useState(
    CourseValue.lectureName ? CourseValue.lectureName : ""
  );
  const [tempvalue, settempvalue] = useState();
  function settableheader() {
    settempvalue(inputfieldvalue);
    setInputfield(true);
  }
  function hidefield() {
    setInputfieldvalue(tempvalue);
    setInputfield(false);
  }
  function minMaxField(data) {
    console.log("dasfasa", data)
  }
  const { register, errors, handleSubmit } = useForm();
  const [check, setCheck] = useState(false);

  const [courses, setCourses] = useState({
    sectionId: props.sectionId,
    lectureName: inputfieldvalue,
    lectureVideo: "",
    lectureBanner: "",
    lectureSummary: "",
    lectureTranscript: "",
    lectureResource: "",
    quiz: [
      {
        quizQuestionList: [
          {
            questionDescription: "",
            quizOptionList: [
              {
                optionDescription: "",
              }
            ]
          }
        ]
      }
    ]
  });
  function showInputfield123() {
    setCourses({ ...courses, lectureName: inputfieldvalue })
  }
  return (
    <>
      {
        props?.course?.lectures?.map((section, sectionId) => {
          console.log("sdjygsfygqyw", section)
          return (
            <>
              <Draggable key={sectionId}>
                <tr key={sectionId} className="d-flex align-items-center w-100">
                  <td className="pl-5">
                    <span className="column-drag-handle">
                      <MdOutlineDragIndicator size={22} className="text-muted" />
                    </span>
                  </td>
                  <td className=" align-items-center">

                    <>
                      <h4 className="" >
                        {
                          section.lectureName
                        }
                      </h4>
                    </>
                  </td>
                  <td className="text-right pr-5 ml-auto"></td>
                </tr>
              </Draggable>

            </>
          )
        })
      }
      <Draggable >
        <tr className="d-flex align-items-center w-100">
          <td className="pl-5">
            <span className="column-drag-handle">
              <MdOutlineDragIndicator size={22} className="text-muted" />
            </span>
          </td>
          <td className="d-flex align-items-center">
            <div className="checkbox-border">
              <input
                type="checkbox"
                checked={check}
                // onChange={(e) => {
                //   props.setCheckChanged(e.target.checked, props.data.id);
                // }}
                value={check}
                // id={props.id}
                ref={register}
              />
            </div>

            {showInputfield === false ? (
              <>
                <Link
                  to={{
                    pathname: PATH.ADDLECTURES,
                    state: { courses: courses },
                  }}
                >
                  <label className="mb-0 cursor" >
                    {inputfieldvalue}
                  </label>
                </Link>
                {
                  inputfieldvalue ?
                    <RiPencilLine
                      className="ml-3 mb-2 penicon cursor"
                      onClick={() => settableheader()}
                    />
                    :
                    <p onClick={() => settableheader()} className="pt-2 cursor">Add new lecture</p>
                }
              </>
            ) : (
              <Form onSubmit={handleSubmit(minMaxField)} >
                <div className="d-flex">
                  <input
                    className="form-control "
                    type="text"
                    name={`${props.fieldName}.lectureName`}
                    placeholder="Add new lecture"
                    // value={inputfieldvalue}
                    onChange={(e) => setInputfieldvalue(e.target.value)}
                    ref={register({ required: true })}
                  />
                  {errors.lectureName && (
                    <div className="mt-2">
                      <FieldError message={"This Field is Required"} />
                    </div>
                  )}
                  <GoCheck
                    className="iconsstyling ml-2"
                    type="submit"
                    onClick={() => { setInputfield(false); showInputfield123() }}
                  />
                  <IoClose
                    className="iconsstyling closecolor ml-2 "
                    onClick={() => hidefield(false)}
                  />
                </div>
              </Form>
            )}
          </td>
          <td className="text-right pr-5 ml-auto"></td>
        </tr>
      </Draggable>
    </>
  );
}
function DownloadAbleIcon() {
  const [downloadAble, setDownloadAble] = useState(true);
  return (
    <>
      {downloadAble === true ? (
        <span
          data-for="downloadable"
          data-tip="Make downloadable"
          data-iscapture="true"
        >
          <IoCloudDownloadOutline
            onClick={() => setDownloadAble(false)}
            className="ml-4 text-muted cursor"
          />
        </span>
      ) : (
        <span
          data-for="downloadable"
          data-tip="Make not downloadable"
          data-iscapture="true"
        >
          <IoCloudDownloadSharp
            onClick={() => setDownloadAble(true)}
            className="ml-4 text-muted cursor"
          />
        </span>
      )}
      <ReactTooltip
        id="downloadable"
        place="bottom"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </>
  );
}
function PreviewIcon() {
  const [preview, setPreview] = useState(true);
  return (
    <>
      {preview === true ? (
        <span
          data-for="preview"
          data-tip="Make free preview. (Any content included in free preview will be searchable on Google, including comments.)"
          data-iscapture="true"
        >
          <AiOutlineEye
            onClick={() => setPreview(false)}
            className="ml-4 text-muted cursor"
          />
        </span>
      ) : (
        <span
          data-for="preview"
          data-tip="Turn off free preview"
          data-iscapture="true"
        >
          <AiFillEye
            onClick={() => setPreview(true)}
            className="ml-4 text-muted cursor"
          />
        </span>
      )}
      <ReactTooltip
        id="preview"
        place="bottom"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </>
  );
}
function PublishLectureIcon(props) {
  return (
    <>
      {props.publish === true ? (
        <span
          data-for="publish"
          data-tip="Publish lecture"
          data-iscapture="true"
        >
          <AiOutlineCheckCircle
            onClick={() => props.setPublish(false)}
            className="ml-4 text-muted cursor"
          />
        </span>
      ) : (
        <span
          data-for="publish"
          data-tip="Unpublish lecture"
          data-iscapture="true"
        >
          <AiFillCheckCircle
            onClick={() => props.setPublish(true)}
            className="ml-4 text-muted cursor"
          />
        </span>
      )}

      <ReactTooltip
        id="publish"
        place="bottom"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </>
  );
}
function DeleteLectureIcon() {
  const [deleteLecture, setDeleteLecture] = useState(true);
  return (
    <>
      {deleteLecture === true ? (
        <span
          data-for="deleteLecture"
          data-tip="Delete lecture"
          data-iscapture="true"
        >
          <MdDeleteOutline
            onClick={() => setDeleteLecture(false)}
            className="ml-4 text-muted cursor"
          />
        </span>
      ) : (
        <span
          data-for="deleteLecture"
          data-tip="Do not delete lecture"
          data-iscapture="true"
        >
          <MdDelete
            onClick={() => setDeleteLecture(true)}
            className="ml-4 text-muted cursor"
          />
        </span>
      )}
      <ReactTooltip
        id="deleteLecture"
        place="bottom"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </>
  );
}
function BulkCard() {
  const [publish, setPublish] = useState(true);
  return (
    <div className="bulk-actions dsp-flex-xs flex-align-items-center-xs cssFade">
      <span className="title">Bulk actions:</span>
      <PreviewIcon />
      <DownloadAbleIcon />
      <PublishLectureIcon publish={publish} setPublish={setPublish} />
      <DeleteLectureIcon />
    </div>
  );
}
const INITIAL_STATE = {
  isChecked: [],
};
const reducer = (initail, action) => {
  switch (action.type) {
    case "ADD_OR_REMOVE":
      let checkList = initail.isChecked;
      if (checkList.includes(action.payload)) {
        for (let i = 0; i < checkList.length; i++) {
          if (checkList[i] === action.payload) {
            checkList.splice(i, 1);
          }
        }
      } else {
        checkList.push(action.payload);
      }
      return {
        ...initail,
        isChecked: checkList,
      };
    case "CHECK_ALL":
      return {
        ...initail,
        isChecked: action.payload,
      };
    case "REMOVE_ALL":
      return {
        ...initail,
        isChecked: action.payload,
      };
    default:
      return initail;
  }
};
function CheckBoxSection(props) {
  const [checkAll, setCheckAll] = useState(false);
  const [showInputfield, setInputfield] = useState(false);

  function settableheader() {
    settempvalue(inputfieldvalue);
    setInputfield(true);
  }
  const INITIAL_STATE_2 = {
    isChecked: [],
  };
  const [inputfieldvalue, setInputfieldvalue] = useState("");
  const [tempvalue, settempvalue] = useState();
  const [indexes, setIndexes] = React.useState([
    {
      id: 4,
      status: "DRAFT",
      lectureName: "First Lecture",
      isChecked: false,
    },
    {
      id: 5,
      status: "",
      lectureName: "Finance management",
      isChecked: false,
    },
    {
      id: 6,
      status: "DRAFT",
      lectureName: "Chemistry",
      isChecked: false,
    },
  ]);

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE_2);
  function hidefield() {
    setInputfieldvalue(tempvalue);
    setInputfield(false);
  }
  return (
    <div className="teacher-curriculum  pl-3 pb-4">
      <div className="d-flex align-items-center pl-4 ml-1 mt-5">
        <div className="checkbox-border">
          <input
            type="checkbox"
            checked={
              state &&
                state.isChecked &&
                state.isChecked.length !== indexes.length
                ? false
                : true
            }
            id="flexCheckDefault2"
          />
          <label for="flexCheckDefault2" className="mb-0">{``}</label>
        </div>
        {showInputfield === false ? (
          <label className="form-check-label font-weight-400 pl-2">
            {props.course.sectionName}
          </label>
        ) : (
          <div className="d-flex">
            <input
              className="form-control "
              value={inputfieldvalue}
              onChange={(e) => setInputfieldvalue(e.target.value)}
            />
            <GoCheck
              className="iconsstyling ml-2"
              onClick={() => setInputfield(false)}
            />
            <IoClose
              className="iconsstyling closecolor ml-2 "
              onClick={() => hidefield()}
            />
          </div>
        )}
      </div>
    </div>
  )
}