import React from "react";
import { Card } from "react-bootstrap";

export default function Calendar() {
  return (
    <>
      <div className="row">
        <div className="col-md-7">{/* <div className="" */}</div>
        <div className="col-md-5">
          <Card className="background-image-styling">
            <div className="padding-styling d-flex justify-content-between ">
              <span>
                <h1>CERTIFICATE</h1>
                <p className="mb-0">OF APPRECIATION</p>
              </span>
              <span>November 12th 2025</span>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
