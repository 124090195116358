import React, { useState } from "react";
import NameService from "./NameService";
import SelectDate from "./SelectDate";
import SetPrice from "./SetPrice";
import SelectSettings from "./SelectSettings";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import AddDescription from "./AddDescription";
import StepLabel from "@mui/material/StepLabel";
import ReviewandSave from "./ReviewAndSave";
export default function TeacherGroup() {
  const [currentView, setCurrentView] = useState(0);
  function changeView(value) {
    setCurrentView(value);
  }

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
  const steps = [
    {
      label: "Name your coaching",
      description: `(4 minutes)`,
    },
    // {
    //   label: "Get clients",
    //   description: "(3 minutes)",
    // },
    {
      label: "Set a price",
      description: `(3 minutes)`,
    },
    {
      label: "Select a start date",
      description: `(5 minutes)`,
    },
    {
      label: "Add a Description",
      description: `(4 minutes)`,
    },
    {
      label: "Review and save",
      description: ``,
    },
    {
      label: "Finish up",
      description: ``,
    },
  ];

  const [Coaching, setCoaching] = useState({
    // coachingId: 0,
    coachingName: null,
    companyTitle: null,
    // pricePlan: 0,
    price: 0,
    // dateType: 0,
    interestId: 0,
    isPaid: null,
    isRecurrent: null,
    fixedDate: null,
    startRecurrentDate: null,
    endRecurrentDate: null,
    description: null,
    zoomId: null,
    zoomLink: null,
    zoomPassCode: null,
    videoURL: null,
    coachingRecurrenceId: 103,
    // isPublished: null,
    // userId: 0,
    // roleId: 0,
    status: 0,
  });

  return (
    <React.Fragment>
      <div className="row Coaching-Grouping">
        <div className="col-lg-9 col-md-8">
          {currentView === 0 && (
            <NameService
              changeView={changeView}
              handleNext={handleNext}
              handleBack={handleBack}
              Coaching={Coaching}
              setCoaching={setCoaching}
            />
          )}
          {currentView === 1 && (
            <SelectSettings
              changeView={changeView}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          )}
          {currentView === 2 && (
            <SetPrice
              changeView={changeView}
              handleNext={handleNext}
              handleBack={handleBack}
              Coaching={Coaching}
              setCoaching={setCoaching}
            />
          )}
          {currentView === 3 && (
            <SelectDate
              changeView={changeView}
              handleNext={handleNext}
              handleBack={handleBack}
              Coaching={Coaching}
              setCoaching={setCoaching}
            />
          )}
          {currentView === 4 && (
            <AddDescription
              changeView={changeView}
              handleNext={handleNext}
              handleBack={handleBack}
              Coaching={Coaching}
              setCoaching={setCoaching}
            />
          )}
          {currentView === 5 && (
            <ReviewandSave
              changeView={changeView}
              handleNext={handleNext}
              handleBack={handleBack}
              coaching={Coaching}
              setCoaching={setCoaching}
            />
          )}
        </div>
        <div className="col-md-4 col-lg-3 d-none d-md-block">
          <h3 className="mt-2 ml-4">Get Started</h3>

          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className="ml-4"
          >
            {steps.map((step, index = 0) => (
              <Step type="button" key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </React.Fragment>
  );
}
