import { STAFF_MEMBER_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  getStaffProfileLoading: false,
  getStaffProfileSuccess: false,
  getStaffProfileFailure: false,
  getStaffProfileError: null,
  getStaffProfile: [],

  createStaffMemberLoading: false,
  createStaffMemberSuccess: false,
  createStaffMemberFailure: false,
  createStaffMemberError: null,
  createStaffMember: [],

  updateStaffMemberLoading: false,
  updateStaffMemberSuccess: false,
  updateStaffMemberFailure: false,
  updateStaffMemberError: null,
  updateStaffMember: [],
};

export const staffMemberReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STAFF_MEMBER_REQUEST.GET_STAFF_MEMBER_REQUEST:
      return {
        ...state,
        getStaffProfileLoading: true,
        getStaffProfileSuccess: false,
        getStaffProfileFailure: false,
        getStaffProfileError: null,
      };
    case STAFF_MEMBER_REQUEST.GET_STAFF_MEMBER_SUCCESS:
      return {
        ...state,
        getStaffProfileLoading: false,
        getStaffProfileSuccess: true,
        getStaffProfileFailure: false,
        getStaffProfileError: null,
        getStaffProfile: action.payload,
      };
    case STAFF_MEMBER_REQUEST.GET_STAFF_MEMBER_FAILURE:
      return {
        ...state,
        getStaffProfileLoading: false,
        getStaffProfileSuccess: false,
        getStaffProfileFailure: true,
        getStaffProfileError: action.payload,
      };

    case STAFF_MEMBER_REQUEST.CREATE_STAFF_MEMBER_REQUEST:
      return {
        ...state,
        createStaffMemberLoading: true,
        createStaffMemberSuccess: false,
        createStaffMemberFailure: false,
        createStaffMemberError: null,
      };
    case STAFF_MEMBER_REQUEST.CREATE_STAFF_MEMBER_SUCCESS:
      return {
        ...state,
        createStaffMemberLoading: false,
        createStaffMemberSuccess: true,
        createStaffMemberFailure: false,
        createStaffMemberError: null,
        createStaffMember: action.payload,
      };
    case STAFF_MEMBER_REQUEST.CREATE_STAFF_MEMBER_FAILURE:
      return {
        ...state,
        createStaffMemberLoading: false,
        createStaffMemberSuccess: false,
        createStaffMemberFailure: true,
        createStaffMemberError: action.payload,
      };

    case STAFF_MEMBER_REQUEST.UPDATE_STAFF_MEMBER_REQUEST:
      return {
        ...state,
        updateStaffMemberLoading: true,
        updateStaffMemberSuccess: false,
        updateStaffMemberFailure: false,
        updateStaffMemberError: null,
      };
    case STAFF_MEMBER_REQUEST.UPDATE_STAFF_MEMBER_SUCCESS:
      return {
        ...state,
        updateStaffMemberLoading: false,
        updateStaffMemberSuccess: true,
        updateStaffMemberFailure: false,
        updateStaffMemberError: null,
        updateStaffMember: action.payload,
      };
    case STAFF_MEMBER_REQUEST.UPDATE_STAFF_MEMBER_FAILURE:
      return {
        ...state,
        updateStaffMemberLoading: false,
        updateStaffMemberSuccess: false,
        updateStaffMemberFailure: true,
        updateStaffMemberError: action.payload,
      };

    default:
      return state;
  }
};
