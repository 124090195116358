import NoPage from "./NoPageFound";
import React from "react";
import { LoaderPage, LoaderPageWithoutBG } from "../assets";
import ForgotPassword from "./ForgotPassword";
import SignUpSteps from "./Signup/SignUpSteps";
import TeacherCreateCourse from "./Teacher/Teacher-CreateCourse";
import Settings from "./Settings";
import Explore from "./Explore";
import CompleteCourse from "./CoursePreview/CompleteCourse";
import DashboardBreakthrough from "./Student/Dashboard/Dashboard-Breakthrough";
import Course from "./Courses";
import TeacherCurriculumPublish from "./Teacher/Teacher-CreateCourse/Course/CurriculumPublish";
import TeacherCurriculumList from "./Teacher/Teacher-CreateCourse/Course/CurriculumList/CurriculumList";
import TeacherCourseInformation from "./Teacher/Teacher-CreateCourse/Course/CourseInformation";
import TeacherCourseInformationPreview from "./Teacher/Teacher-CreateCourse/Course/CourseInformationForCoursePreview";
import TeacherNewCourse from "./Teacher/Teacher-CreateCourse/Course/NewCourse";
import ProfileEdit from "./Profile/ProfileEdit";
import ProfileShow from "./Profile/ProfileShow";
import PricingPlan from "./Teacher/Teacher-CreateCourse/Course/PricingPlan";
import AddLectures from "./Teacher/Teacher-CreateCourse/Course/AddLectures";
import TeacherCreateCoaching from "./Teacher/Teacher-CreateCourse/Coaching";
import TeacherGroup from "./Teacher/Teacher-CreateCourse/Coaching/Coaching-Group";
import TeacherCoursesGroup from "./Teacher/Teacher-CreateCourse/Course/Test";
import TeacherDashboard from "./Teacher/Teacher-Dashboard";
import TeacherSteps from "./Signup/TeacherSteps";
import AdminDashboard from "./Admin/Admin-Dashboard";
import AdminPricingPlan from "./Admin/Admin-PricingPlan";
import ManageCourse from "./Admin/Admin-Manage-Course";
import ManageUser from "./Admin/Admin-Manage_User";
import teacherCoachingInformation from "./Teacher/Teacher-CreateCourse/Coaching/CoachingInformation";
import finishUpCoaching from "./Teacher/Teacher-CreateCourse/Coaching/Coaching-Group/FinishUp";
import CoachingList from "./Teacher/Teacher-CreateCourse/Coaching/Coaching-Group/CoachingList";
import TeacherCoachingPreview from "./Teacher/Teacher-CreateCourse/Coaching/Coaching-Group/Preview";
import CreateNewSection from "./Teacher/Teacher-CreateCourse/Course/CurriculumList/CreateNewSection";
import TeacherPricingList from "./Teacher/Teacher-CreateCourse/Course/PricingPlan/PricingList";
import AddToCartCourse from "./Explore/AddToCartCourse";
import ExplorePricingPlans from "./Explore/ExplorePricingPlans";
import Wishlist from "./Student/Wishlist";
import StudentFaq from "./Student/Student-Faq";
import TeacherFaq from "./Teacher/Teacher-Faq";
import TeacherCurriculumPreview from "./Teacher/Teacher-CreateCourse/Course/CurriculumList/CurriculumPreview";
import TotalCoachng from "./Admin/Admin-Tiles-Dynamic/TotalCoaching";
import TotalCourse from "./Admin/Admin-Tiles-Dynamic/TotalCourses";
import totalSales from "./Admin/Admin-Tiles-Dynamic/TotalSales";
import TotalUsers from "./Admin/Admin-Tiles-Dynamic/TotalUsers";
import TotalStudent from "./Teacher/Teacher-Dynamic-Tiles/TotalStudent";
import TotalCourses from "./Teacher/Teacher-Dynamic-Tiles/TotalCourses";
import TotalCertificate from "./Teacher/Teacher-Dynamic-Tiles/TotalCertificate";
import TotalSale from "./Teacher/Teacher-Dynamic-Tiles/TotalSales";
import RequestTopic from "./Admin/Request-Topic";
import Credits from "./Student/Credits";
import AdminQuery from "./Admin/Admin-Query";
import CourseRequests from "./Admin/Course Requests";
import PaymentDetails from "./Admin/PaymentDetails";
import AdminFaq from "./Admin/Admin-FAQ'S";
import CoachingRequests from "./Admin/Admin-Coaching-Requests";
import CoachingPreview from "./Explore/CoachingPreview";
import MyCoachingPreview from "./Student/MyCoaching/MyCoachingPreview";
import MyCoaching from "./Student/MyCoaching/myCoaching";
import PaymentPlan from "./Explore/PaymentPlan";
import TeacherUpdateCoaching from "./Teacher/Teacher-CreateCourse/Coaching/Coaching-Group/UpdateCoaching";
import ReviewandSave from "./Teacher/Teacher-CreateCourse/Coaching/Coaching-Group/ReviewAndSave";
import MyCoursePreview from "./Courses/CoursePreview";
import PurchaseCredits from "./Student/Credits/purchaseCredit";
import CurrentDashboardProgress from "./Student/Dashboard/currentProgress";
import ContactUs from "./ContactUs/index";
import AdminCoursePreview from "./Teacher/Teacher-CreateCourse/Course/adminCoursePreview";
import ContactUsAdmin from "./Admin/ContactUs";
import Certificate from "./Student/Certificate";
import CreateNewStaff from "./Admin/Admin-Create-New-Staff";
import RoleAndPermission from "./Admin/Role-and-permission";
import PrivacyPolicy from "./Privacy&Policy";

class DynamicImport extends React.Component {
  state = {
    component: null,
  };
  componentDidMount() {
    this.props.load().then((component) => {
      this.setState(() => ({
        component: component.default ? component.default : component,
      }));
    });
  }
  render() {
    return this.props.children(this.state.component);
  }
}

//  After login Screen Loader and routing

const Dashboard = (props) => (
  <DynamicImport load={() => import("./Student/Dashboard")}>
    {(Component) =>
      Component === null ? <LoaderPageWithoutBG /> : <Component {...props} />
    }
  </DynamicImport>
);
const ExploreDashboard = (props) => (
  <DynamicImport load={() => import("./Explore/ExploreCourse")}>
    {(Component) =>
      Component === null ? <LoaderPageWithoutBG /> : <Component {...props} />
    }
  </DynamicImport>
);
const CoursePreview = (props) => (
  <DynamicImport load={() => import("./CoursePreview")}>
    {(Component) =>
      Component === null ? <LoaderPageWithoutBG /> : <Component {...props} />
    }
  </DynamicImport>
);

//  Before login Screen Loader and routing

const SignUp = (props) => (
  <DynamicImport load={() => import("./Signup")}>
    {(Component) =>
      Component === null ? <LoaderPage /> : <Component {...props} />
    }
  </DynamicImport>
);
const login = (props) => (
  <DynamicImport load={() => import("./Login")}>
    {(Component) =>
      Component === null ? <LoaderPage /> : <Component {...props} />
    }
  </DynamicImport>
);
const LoginSupportDashboard = (props) => (
  <DynamicImport load={() => import("./LoginScreens/Login-Support")}>
    {(Component) =>
      Component === null ? <LoaderPage /> : <Component {...props} />
    }
  </DynamicImport>
);
const LoginExploreCourse = (props) => (
  <DynamicImport
    load={() => import("./LoginScreens/Login-Explore/LoginExploreCourse")}
  >
    {(Component) =>
      Component === null ? <LoaderPage /> : <Component {...props} />
    }
  </DynamicImport>
);
const LoginExploreDashboard = (props) => (
  <DynamicImport load={() => import("./LoginScreens/Login-Explore")}>
    {(Component) =>
      Component === null ? <LoaderPage /> : <Component {...props} />
    }
  </DynamicImport>
);
const LoginAddCart = (props) => (
  <DynamicImport
    load={() => import("./LoginScreens/Login-Explore/LoginAddCart")}
  >
    {(Component) =>
      Component === null ? <LoaderPage /> : <Component {...props} />
    }
  </DynamicImport>
);
const SubscriptionPlans = (props) => (
  <DynamicImport load={() => import("./SubscriptionPlan")}>
    {(Component) =>
      Component === null ? <LoaderPage /> : <Component {...props} />
    }
  </DynamicImport>
);
const WEB_PAGES = {
  PRIVACY_POLICY: PrivacyPolicy,
  PAYMENT_PLAN: PaymentPlan,
  PURCHASECREDIT: PurchaseCredits,
  CERTIFICATE: Certificate,
  CREATE_NEW_STAFF: CreateNewStaff,
  ROLE_AND_PERMISSION: RoleAndPermission,
  CONTACT_US: ContactUs,
  CONTACT_US_ADMIN: ContactUsAdmin,
  EXPLOREDASHBOARDCATEGORY: CurrentDashboardProgress,
  TEACHER_COURSE_PREVIEW: TeacherCourseInformationPreview,
  ADMIN_COURSE_PREVIEW: AdminCoursePreview,
  MY_COACHING: MyCoaching,
  COACHING_PREVIEW: CoachingPreview,
  MY_COACHING_PREVIEW: MyCoachingPreview,
  REQUEST_TOPIC: RequestTopic,
  LOGIN_ADD_CART: LoginAddCart,
  LOGIN_EXPLORE_COURSE: LoginExploreCourse,
  TEACHER_TOTAL_COURSES: TotalCourses,
  TEACHER_TOTAL_STUDENT: TotalStudent,
  TEACHER_TOTAL_SALE: TotalSale,
  TEACHER_TOTAL_CERTIFICATE: TotalCertificate,
  TEACHERCURRICULUMPREVIEW: TeacherCurriculumPreview,
  MANAGE_USER: ManageUser,
  WISHLIST: Wishlist,
  LOGINSUPPORTDASHBOARD: LoginSupportDashboard,
  LOGINEXPLOREDASHBOARD: LoginExploreDashboard,
  MANAGE_COURSE: ManageCourse,
  ADMIN_DASHBOARD: AdminDashboard,
  TEACHERSTEPS: TeacherSteps,
  TEACHERGROUP: TeacherGroup,
  TEACHERCOURSESGROUP: TeacherCoursesGroup,
  TEACHERUPDATECOACHING: TeacherUpdateCoaching,
  TEACHERCREATECOACHING: TeacherCreateCoaching,
  ADDLECTURES: AddLectures,
  NOPAGE: NoPage,
  LOGIN: login,
  FORGOTPASSWORD: ForgotPassword,
  SIGNUP: SignUp,
  SIGNUPSTEPS: SignUpSteps,
  EXPLORE: Explore,
  COURSE: Course,
  DASHBOARD: Dashboard,
  SETTINGS: Settings,
  TEACHER_CREATE_COURSE: TeacherCreateCourse,
  PROFILESHOW: ProfileShow,
  PROFILEEDIT: ProfileEdit,
  COMPLETE_COURSE: CompleteCourse,
  COURSE_PREVIEW: CoursePreview,
  MY_COURSE_PREVIEW: MyCoursePreview,
  EXPLORECATEGORY: ExploreDashboard,
  DASHBOARDBREAKTHROUGH: DashboardBreakthrough,
  PRICINGPLAN: PricingPlan,
  TEACHERDASHBOARD: TeacherDashboard,
  TEACHERCURRICULUMLIST: TeacherCurriculumList,
  TEACHERCOURSEINFORMATION: TeacherCourseInformation,
  TEACHERNEWCOURSE: TeacherNewCourse,
  TEACHERCURRICULUMPUBLISH: TeacherCurriculumPublish,
  ADMIN_QUERY: AdminQuery,
  ADMIN_FAQ: AdminFaq,
  REVIEW_AND_SAVE: ReviewandSave,
  ADMIN_COURSE_REQUESTS: CourseRequests,
  PAYMENT_DETAIL: PaymentDetails,
  ADMIN_COACHING_REQUESTS: CoachingRequests,
  ADMINPRICINGPLAN: AdminPricingPlan,
  TEACHINGCOACHINGINFORMATION: teacherCoachingInformation,
  FINISHUPCOACHING: finishUpCoaching,
  COACHINGLIST: CoachingList,
  STUDENT_FAQ: StudentFaq,
  TEACHER_FAQ: TeacherFaq,
  TEACHERCOACHINGPREVIEW: TeacherCoachingPreview,
  CREATENEWSECTION: CreateNewSection,
  SUBSCRIPTIONPLANS: SubscriptionPlans,
  TEACHERPRICINGLIST: TeacherPricingList,
  ADDTOCARTCOURSE: AddToCartCourse,
  EXPLOREPRICINGPLANS: ExplorePricingPlans,
  ADMIN_TOTAL_USERS: TotalUsers,
  ADMIN_TOTAL_COACHING: TotalCoachng,
  ADMIN_TOTAL_COURSES: TotalCourse,
  ADMIN_TOTAL_SALES: totalSales,
  STUDENT_CREDITS: Credits,
};

export { WEB_PAGES };
