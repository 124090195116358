import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Students from "./Students";
import CourseProviders from "./CourseProviders";
import { useDispatch } from "react-redux";
import { ManageUsersAdmin } from "../../../redux/actions";

export default function ManageUser() {
  let dispatch = useDispatch();
  function Call(key) {
    dispatch(ManageUsersAdmin(key));
  }
  useEffect(() => {
    dispatch(ManageUsersAdmin(3));
  }, []);
  return (
    <>
      <div
        className={`mb-4 card card-custom w-100 bg-dark admin-course-request`}
      >
        <div className="card-header ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className=" font-weight-700 mb-0">Manage User</h3>
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey={3}
        onSelect={(k) => Call(k)}
        className="mb-3 explore_tabs"
      >
        <Tab eventKey={3} title="Students">
          <Students />
        </Tab>
        <Tab eventKey={2} title="Course Providers">
          <CourseProviders />
        </Tab>
        {/* <Tab eventKey={1} title="Staff">
          <Staff />
        </Tab> */}
      </Tabs>
    </>
  );
}
