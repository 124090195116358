import React, { useEffect, useRef } from "react";
import {
  dateTimeConvert,
  ErrorMessage,
  GenericSearchField,
  Loader,
} from "../../assets";
import Slider from "react-slick";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { Card } from "react-bootstrap";
import { PATH } from "../../config";
import { Link } from "react-router-dom";
import {
  GetTodaysCoaching,
  GetTopCoaching,
  GetUpcomingCoaching,
} from "../../redux/actions/coachingAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
const moment = require("moment-timezone");

export default function CoachingDashboard(props) {
  function SetExplore(val) {
    props.setExplore(val);
  }
  var settings = {
    centerMode: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 5,
    dots: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const setCategories1 = useRef();
  const setCategories2 = useRef();
  const setCategories3 = useRef();
  function nextslide1() {
    setCategories1.current.slickNext();
  }
  function prevslide1() {
    setCategories1.current.slickPrev();
  }
  function nextslide2() {
    setCategories2.current.slickNext();
  }
  function prevslide2() {
    setCategories2.current.slickPrev();
  }
  function nextslide3() {
    setCategories3.current.slickNext();
  }
  function prevslide3() {
    setCategories3.current.slickPrev();
  }
  let dispatch = useDispatch();
  let auth = useAuth();
  let getTodayListing = useSelector((state) => state.coaching);
  useEffect(() => {
    dispatch(GetTodaysCoaching(auth));
    dispatch(GetUpcomingCoaching(auth));
    dispatch(GetTopCoaching());
  }, [dispatch]);
  let todaysCoaching = getTodayListing && getTodayListing.getTodaysCoaching;
  let upcomingCoaching = getTodayListing && getTodayListing.getUpcomingCoaching;
  let topCoaching = getTodayListing && getTodayListing.getTopCoaching;
  return (
    <>
      <div className="Explore">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-sm-between category position-relative">
            <h2 className="mb-0 mt-1 ml-2 fw-800">Today's Coaching</h2>
            <div className="mr-3">
              {/* <GenericSearchField placeholder="search" /> */}
            </div>
          </div>
          {todaysCoaching?.length === 0 ? (
            <h4 className="text-center  pt-4">No Record Found</h4>
          ) : (
            <>
              <div className="firstcard mt-3  position-relative">
                <div className=" icons right-icon prev-arrow-position ml-lg-n3 cursor">
                  <BsArrowLeftShort onClick={prevslide1} />
                </div>
                <div className=" icons edutech-icon right-icon cursor">
                  <BsArrowRightShort onClick={nextslide1} />
                </div>
                <>
                  {getTodayListing &&
                    getTodayListing.getTodaysCoachingLoading === true &&
                    getTodayListing.getTodaysCoachingSuccess === false &&
                    getTodayListing.getTodaysCoachingFailure === false && (
                      <Loader />
                    )}
                  {getTodayListing &&
                    getTodayListing.getTodaysCoachingLoading === false &&
                    getTodayListing.getTodaysCoachingSuccess === false &&
                    getTodayListing.getTodaysCoachingFailure === true && (
                      <ErrorMessage message={"No record Found"} />
                    )}
                  {getTodayListing &&
                    getTodayListing.getTodaysCoachingLoading === false &&
                    getTodayListing.getTodaysCoachingSuccess === true &&
                    getTodayListing.getTodaysCoachingFailure === false && (
                      <Slider {...settings} ref={setCategories1}>
                        {todaysCoaching &&
                          todaysCoaching.map((list, index) => {
                            return (
                              <TodayCoaching
                                list={list}
                                index={index}
                                SetExplore={SetExplore}
                              />
                            );
                          })}
                      </Slider>
                    )}
                </>
              </div>
            </>
          )}

          {/* <div className="firstcard mt-3  position-relative">
            <div className=" icons left-icon d-none">
              <BsArrowLeftShort />
            </div>
            <div className=" icons right-icon cursor">
              <BsArrowRightShort onClick={nextslide1} />
            </div>
            <Slider {...settings} ref={setCategories1}>
              {list1.map((list) => {
                return <TodayCoaching list={list} SetExplore={SetExplore} />;
              })}
            </Slider>
          </div> */}
        </div>
        <div className="Courses Coursebackground pt-4 pb-4 pl-2 position-relative mt-4">
          <div className="container-fluid">
            <h2 className="mb-0 mt-1 ml-2 fw-800">Top Coaching</h2>
            <p className="topcourses mb-0 ml-2 mt-3">
              Top coaching that people in your industry are taking
            </p>
            {topCoaching?.length === 0 ? (
              <h4 className="text-center  pt-4">No Record Found</h4>
            ) : (
              <>
                <div className="firstcard mt-3  position-relative">
                  <div className=" icons right-icon prev-arrow-position ml-lg-n3 cursor">
                    <BsArrowLeftShort onClick={prevslide3} />
                  </div>
                  <div className=" icons right-icon cursor">
                    <BsArrowRightShort onClick={nextslide3} />
                  </div>
                  <>
                    {getTodayListing &&
                      getTodayListing.getTopCoachingLoading === true &&
                      getTodayListing.getTopCoachingSuccess === false &&
                      getTodayListing.getTopCoachingFailure === false && (
                        <Loader />
                      )}
                    {getTodayListing &&
                      getTodayListing.getTopCoachingLoading === false &&
                      getTodayListing.getTopCoachingSuccess === false &&
                      getTodayListing.getTopCoachingFailure === true && (
                        <ErrorMessage message={"No record Found"} />
                      )}
                    {getTodayListing &&
                      getTodayListing.getTopCoachingLoading === false &&
                      getTodayListing.getTopCoachingSuccess === true &&
                      getTodayListing.getTopCoachingFailure === false && (
                        <Slider {...settings} ref={setCategories3}>
                          {topCoaching &&
                            topCoaching.map((list, index) => {
                              return (
                                <TopCoachingBody
                                  list={list}
                                  index={index}
                                  SetExplore={SetExplore}
                                />
                              );
                            })}
                        </Slider>
                      )}
                  </>
                </div>
              </>
            )}
          </div>
          <br></br>
        </div>
        <div className=" related-courses relatedbackground pt-3 pb-4 pl-2 position-relative">
          <div className="container-fluid">
            <h2 className="mb-0 mt-1 ml-2 fw-800 ">Upcoming Coaching</h2>
            <p className="topcourses mb-0 ml-2 mt-3">
              Upcoming coaching based on your topic selection
            </p>
            {upcomingCoaching.length === 0 ? (
              <h4 className="text-center  pt-4">No Record Found</h4>
            ) : (
              <>
                <div className="firstcard mt-3  position-relative">
                  <div className=" icons right-icon prev-arrow-position ml-lg-n3 cursor">
                    <BsArrowLeftShort onClick={prevslide2} />
                  </div>
                  <div className=" icons right-icon cursor">
                    <BsArrowRightShort onClick={nextslide2} />
                  </div>
                  <>
                    {getTodayListing &&
                      getTodayListing.getUpcomingCoachingLoading === true &&
                      getTodayListing.getUpcomingCoachingSuccess === false &&
                      getTodayListing.getUpcomingCoachingFailure === false && (
                        <Loader />
                      )}
                    {getTodayListing &&
                      getTodayListing.getUpcomingCoachingLoading === false &&
                      getTodayListing.getUpcomingCoachingSuccess === false &&
                      getTodayListing.getUpcomingCoachingFailure === true && (
                        <ErrorMessage message={"No record Found"} />
                      )}
                    {getTodayListing &&
                      getTodayListing.getUpcomingCoachingLoading === false &&
                      getTodayListing.getUpcomingCoachingSuccess === true &&
                      getTodayListing.getUpcomingCoachingFailure === false && (
                        <Slider {...settings} ref={setCategories2}>
                          {upcomingCoaching &&
                            upcomingCoaching.map((list, index) => {
                              return (
                                <UpcommingCoaching
                                  list={list}
                                  index={index}
                                  SetExplore={SetExplore}
                                />
                              );
                            })}
                        </Slider>
                      )}
                  </>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
function TodayCoaching(props) {
  return (
    <>
      <div className="slide-item mt-1 " key={props.index}>
        <Card className="mx-2 mb-0">
          <Link
            to={{
              pathname: PATH.COACHING_PREVIEW,
              state: {
                CoachingData: props.list,
                Coaching: "TODAY",
              },
            }}
          >
            <Card.Body className="p-0">
              <div className="Courseiframeheight">
                <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                  {/* <img
                  className="img-fluid embed-responsive-item"
                  src={props.list.URL}
                /> */}
                  <iframe
                    title="dashboard-video"
                    className="embed-responsive-item"
                    src={props.list.videoURL}
                  ></iframe>
                </div>
              </div>
              <div className="pl-2 py-2">
                <p className="firstpara text-left mb-0">
                  {" "}
                  {props.list.coachingName}
                </p>
                <p className="text-muted text-left mb-0">
                  {" "}
                  {props.list.companyTitle}
                </p>
                <p className="firstpara text-left mt-0 pt-0">
                  {" "}
                  {props.list.price ? `$ ${props.list.price}` : "Free"}
                  <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                    {props.list.discount}
                  </span>
                </p>
                <p>{props.list.recurrence}</p>
                <p className=" text-left mt-2 mb-0">
                  {props.list.fixedDate !== null ? (
                    <>
                      {/* {dateTimeConvert(props.list.fixedDate)
                        ? dateTimeConvert(props.list.fixedDate)
                        : "N/A"} */}
                      {props.list.fixedDate.split("T")[0] +
                        " , " +
                        moment(
                          props.list.fixedDate.split("T")[1],
                          "HH:mm:ss"
                        ).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      <span className=" ml-3 mb-0  ">
                        {/* {dateTimeConvert(props.list.startRecurrentDate)}{" "} */}
                        {props.list.startRecurrentDate.split("T")[0] +
                          " , " +
                          moment(
                            props.list.startRecurrentDate.split("T")[1],
                            "HH:mm:ss"
                          ).format("hh:mm A")}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </Card.Body>
          </Link>
        </Card>
      </div>
    </>
  );
}

function TopCoachingBody(props) {
  return (
    <>
      <div className="slide-item position-relative mt-4 cursor">
        <Card className="mx-2 mb-0">
          <Link
            to={{
              pathname: PATH.COACHING_PREVIEW,
              state: {
                CoachingData: props.list,
                Coaching: "TOP",
              },
            }}
          >
            <Card.Body className="p-0">
              <div className="Courseiframeheight">
                <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                  {/* <img
                  className="img-fluid embed-responsive-item"
                  src={props.list.URL}
                /> */}
                  <iframe
                    title="dashboard-video"
                    className="embed-responsive-item"
                    src={props.list.videoURL}
                  ></iframe>
                </div>
              </div>
              <div className="pl-2 py-2">
                <p className="firstpara text-left mb-0">
                  {" "}
                  {props.list.coachingName}
                </p>
                <p className="text-muted text-left mb-0">
                  {" "}
                  {props.list.companyTitle}
                </p>
                <p className="firstpara text-left mt-0 pt-0">
                  {" "}
                  {props.list.price ? `$ ${props.list.price}` : "Free"}
                  <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                    {props.list.discount}
                  </span>
                </p>
                <p>{props.list.recurrence}</p>
                <p className=" text-left mt-2 mb-0">
                  {props.list.fixedDate !== null ? (
                    <>
                      {/* {dateTimeConvert(props.list.fixedDate)
                        ? dateTimeConvert(props.list.fixedDate)
                        : "N/A"} */}
                      {props.list.fixedDate.split("T")[0] +
                        " , " +
                        moment(
                          props.list.fixedDate.split("T")[1],
                          "HH:mm:ss"
                        ).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      <span className=" ml-3 mb-0  ">
                        {/* {dateTimeConvert(props.list.startRecurrentDate)} */}
                        {props.list.startRecurrentDate.split("T")[0] +
                          " , " +
                          moment(
                            props.list.startRecurrentDate.split("T")[1],
                            "HH:mm:ss"
                          ).format("hh:mm A")}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </Card.Body>
          </Link>
        </Card>
      </div>
    </>
  );
}

function UpcommingCoaching(props) {
  return (
    <>
      <div className="slide-item mt-1 cursor">
        <Card className="mx-2 mb-0">
          <Link
            to={{
              pathname: PATH.COACHING_PREVIEW,
              state: {
                CoachingData: props.list,
                Coaching: "UPCOMMING",
              },
            }}
          >
            <Card.Body className="p-0">
              <div className="Courseiframeheight">
                <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                  <iframe
                    title="dashboard-video"
                    className="embed-responsive-item"
                    src={props.list.videoURL}
                  ></iframe>
                </div>
              </div>
              <div className="pl-2 py-2">
                <p className="firstpara text-left mb-0">
                  {" "}
                  {props.list.coachingName}
                </p>
                <p className="text-muted text-left mb-0">
                  {" "}
                  {props.list.companyTitle}
                </p>
                <p className="firstpara text-left mt-0 pt-0">
                  {" "}
                  {props.list.price ? `$ ${props.list.price}` : "Free"}
                  <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                    {props.list.discount}
                  </span>
                </p>
                <p>{props.list.recurrence}</p>
                <p className=" text-left mt-2 mb-0">
                  {props.list.fixedDate !== null ? (
                    <>
                      {/* {dateTimeConvert(props.list.fixedDate)
                        ? dateTimeConvert(props.list.fixedDate)
                        : "N/A"} */}
                      {props.list.fixedDate.split("T")[0] +
                        " , " +
                        moment(
                          props.list.fixedDate.split("T")[1],
                          "HH:mm:ss"
                        ).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      <span className=" ml-3 mb-0  ">
                        {/* {dateTimeConvert(props.list.startRecurrentDate)}{" "} */}
                        {props.list.startRecurrentDate.split("T")[0] +
                          " , " +
                          moment(
                            props.list.startRecurrentDate.split("T")[1],
                            "HH:mm:ss"
                          ).format("hh:mm A")}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </Card.Body>
          </Link>
        </Card>
      </div>
    </>
  );
}
