import { USER_PROFILE_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  getUserProfileLoading: false,
  getUserProfileSuccess: false,
  getUserProfileFailure: false,
  getUserProfileError: null,
  getUserProfile: [],

  getUserStudentProfileLoading: false,
  getUserStudentProfileSuccess: false,
  getUserStudentProfileFailure: false,
  getUserStudentProfileError: null,
  getUserStudentProfile: [],

  updateProviderProfileLoading: false,
  updateProviderProfileSuccess: false,
  updateProviderProfileFailure: false,
  updateProviderProfileError: null,
  updateProviderProfile: [],

  updateStudentProfileLoading: false,
  updateStudentProfileSuccess: false,
  updateStudentProfileFailure: false,
  updateStudentProfileError: null,
  updateStudentProfile: [],
};

export const userProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST.GET_USER_STUDENT_PROFILE_REQUEST:
      return {
        ...state,
        getUserStudentProfileLoading: true,
        getUserStudentProfileSuccess: false,
        getUserStudentProfileFailure: false,
        getUserStudentProfileError: null,
      };
    case USER_PROFILE_REQUEST.GET_USER_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        getUserStudentProfileLoading: false,
        getUserStudentProfileSuccess: true,
        getUserStudentProfileFailure: false,
        getUserStudentProfileError: null,
        getUserStudentProfile: action.payload,
      };
    case USER_PROFILE_REQUEST.GET_USER_STUDENT_PROFILE_FAILURE:
      return {
        ...state,
        getUserStudentProfileLoading: false,
        getUserStudentProfileSuccess: false,
        getUserStudentProfileFailure: true,
        getUserStudentProfileError: action.payload,
      };
      
    case USER_PROFILE_REQUEST.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        getUserProfileLoading: true,
        getUserProfileSuccess: false,
        getUserProfileFailure: false,
        getUserProfileError: null,
      };
    case USER_PROFILE_REQUEST.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        getUserProfileLoading: false,
        getUserProfileSuccess: true,
        getUserProfileFailure: false,
        getUserProfileError: null,
        getUserProfile: action.payload,
      };
    case USER_PROFILE_REQUEST.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        getUserProfileLoading: false,
        getUserProfileSuccess: false,
        getUserProfileFailure: true,
        getUserProfileError: action.payload,
      };
      
      
      case USER_PROFILE_REQUEST.UPDATE_PROVIDER_PROFILE_REQUEST:
        return {
          ...state,
         updateProviderProfileLoading: true,
         updateProviderProfileSuccess: false,
         updateProviderProfileFailure: false,
         updateProviderProfileError: null,
        };
      case USER_PROFILE_REQUEST.UPDATE_PROVIDER_PROFILE_SUCCESS:
        return {
          ...state,
          updateProviderProfileLoading: false,
          updateProviderProfileSuccess: true,
          updateProviderProfileFailure: false,
          updateProviderProfileError: null,
          updateProviderProfile: action.payload,
        };
      case USER_PROFILE_REQUEST.UPDATE_PROVIDER_PROFILE_FAILURE:
        return {
          ...state,
          updateProviderProfileLoading: false,
          updateProviderProfileSuccess: false,
          updateProviderProfileFailure: true,
          updateProviderProfileError: action.payload,
        };
        case USER_PROFILE_REQUEST.UPDATE_STUDENT_PROFILE_REQUEST:
        return {
          ...state,
        updateStudentProfileLoading: true,
        updateStudentProfileSuccess: false,
        updateStudentProfileFailure: false,
        updateStudentProfileError: null,
        };
      case USER_PROFILE_REQUEST.UPDATE_STUDENT_PROFILE_SUCCESS:
        return {
          ...state,
          updateStudentProfileLoading: false,
          updateStudentProfileSuccess: true,
          updateStudentProfileFailure: false,
          updateStudentProfileError: null,
          updateStudentProfile: action.payload,
        };
      case USER_PROFILE_REQUEST.UPDATE_STUDENT_PROFILE_FAILURE:
        return {
          ...state,
          updateStudentProfileLoading: false,
          updateStudentProfileSuccess: false,
          updateStudentProfileFailure: true,
          updateStudentProfileError: action.payload,
        };
     
      default:
      return state;
  }
};
