import React, { useState } from "react";
import { Col, Dropdown, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IoMdArrowDropdown } from "react-icons/io";
import { FieldError, IMAGES } from "../../assets";
import { useDispatch } from "react-redux";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { CreateContact } from "../../redux/actions/contactUsAction";

export default function ContactUs() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let studentName =
    auth.edu_tech_user.firstName + " " + auth.edu_tech_user.lastName;
  const [description, setDescription] = useState(null);
  const [HeipChat, setHeipChat] = useState("Select");
  const [name, setName] = useState(studentName);
  const { register, reset, errors } = useForm();
  function onSubmit() {
    if (HeipChat !== "Select" && description !== null) {
      let finalData = {
        contactTypeId: parseInt(
          HeipChat === "Comaplaint"
            ? 170
            : HeipChat === "Inquiry"
            ? 171
            : HeipChat === "Feedback"
            ? 172
            : null
        ),
        description: description,
      };
      dispatch(CreateContact(finalData, auth, Notificiation, moveToNext));
    } else {
      toast.error("Field is Required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  function moveToNext() {
    setHeipChat("Select");
    setDescription("");
  }
  function Notificiation(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  return (
    <>
      <div className="container main-section-margin card teacher-dashboard">
        <div className="logo-img-set">
          <img src={IMAGES.CONTACTUSLOGO} className="img-fluid shadow" alt="" />
        </div>
        {/* <form onSubmit={handleSubmit(onSubmit)} className="px-4 py-3"> */}
        <Form.Group>
          <Form.Label>Your Name:</Form.Label>
          <Form.Control
            className="mt-1 px-4 py-3 name-styling-field"
            name="name"
            type="text"
            disabled
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{
              borderColor: errors && errors.name ? "#a80000" : "",
            }}
            ref={register({ required: true })}
          />
          {errors.name && <FieldError message={"This Field is Required"} />}
        </Form.Group>
        <Form.Group
          controlId=""
          className="mt-3 dropdown_bg_white contact-us-margin"
        >
          <Form.Label>Subject</Form.Label>
          <Row>
            <Col md={12}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  ref={register({ required: true })}
                  className="form-label text-left bg-transparent"
                >
                  {HeipChat}
                  <IoMdArrowDropdown />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow">
                  <Dropdown.Item
                    onClick={() => {
                      setHeipChat("Comaplaint");
                    }}
                  >
                    {"Complain "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setHeipChat("Inquiry");
                    }}
                  >
                    {" Enquiry"}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setHeipChat("Feedback");
                    }}
                  >
                    {" Feedback"}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Form.Group>
        {errors.subject && <FieldError message={"This Field is Required"} />}
        <Form.Group>
          <Form.Label>Description:</Form.Label>
          <Form.Control
            className="mt-1"
            name="description"
            as="textarea"
            maxlength="300"
            pattern="(/[^\s]+/,)"
            rows={3}
            required
            requiredMessage="This field is required."
            errorTarget="under"
            placeholder="Enter Your Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            style={{
              borderColor: errors && errors.description ? "#a80000" : "",
            }}
            ref={register({ required: true })}
          />
        </Form.Group>
        <div className="form-group text-right mb-0">
          <Button
            type="button"
            className="download-btn mb-2 bg-default-light mt-3 ml-4"
            variant="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            Send
          </Button>
        </div>
        {/* </form> */}
      </div>
    </>
  );
}
