import { request, success, failure, FEED_BACK_REQUEST } from "./utilities";
import { createCoachingFeedback, createFeedback } from "../api/feedbackApi";

export function CreateFeedback(data, auth, Notification) {
  return (dispatch) => {
    dispatch(request(FEED_BACK_REQUEST.FEED_BACK_REQUEST));
    createFeedback(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(FEED_BACK_REQUEST.FEED_BACK_SUCCESS, response.data));
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(FEED_BACK_REQUEST.FEED_BACK_FAILURE, response.data.message)
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            FEED_BACK_REQUEST.FEED_BACK_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CreateCoachingFeedback(data, auth, Notification) {
  return (dispatch) => {
    dispatch(request(FEED_BACK_REQUEST.COACHING_FFED_BACK_REQUEST));
    createCoachingFeedback(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(FEED_BACK_REQUEST.COACHING_FFED_BACK_SUCCESS, response.data)
          );
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              FEED_BACK_REQUEST.COACHING_FFED_BACK_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            FEED_BACK_REQUEST.COACHING_FFED_BACK_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
