import React, { useState } from "react";
import { Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { BsChevronLeft, BsChevronDown } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { PATH } from "../../../../../config";
import { IoCloseSharp } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { FieldError } from "../../../../../assets";
import { CreateCoursePricing } from "../../../../../redux/actions/coursesAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../../Navigation/Auth/ProvideAuth";

export default function PricingModals(props) {
  const [lgShow, setLgShow] = useState(true);
  return (
    <>
      <Modal
        size="lg"
        show={lgShow}
        aria-labelledby="example-modal-sizes-title-lg"
        className="PrizeModal"
        centered
      >
        {props.value === 1 && <PricePlan {...props} setLgShows={setLgShow} />}
      </Modal>
    </>
  );
}

function PricePlan(props) {
  let history = useHistory();
  let dispatch = useDispatch();
  let auth = useAuth()
  const [priceAmount, setPriceAmount] = useState("");
  const [discount, setDiscount] = useState(0);
  const [customizeAmount, setCustomizeAmount] = useState("Enable");
  function goPrevvModal(val) {
    props.prevModal(val);
  }
  let CourseValue = useSelector((state) => state.courses);
  console.log("asdqweqw123", CourseValue.createCourses)
  function hideModals(val) {
    props.setLgShows(val);
    props.setmainmodal(val);
    props.prevModal(3);
  }
  function goNext(data) {
    let finaldata = {
      "courseId": parseInt(CourseValue.createCourses),
      "isDiscountAvailable": discount ? true : false,
      "pricePlanId": priceAmount ? 151 : 150,
      "price": parseInt(data.priceAmount),
      "discountedPrice":  discount ? parseInt(discount) : 0
    }
    console.log("asdhasdkjhwq", finaldata)
    dispatch(CreateCoursePricing(finaldata, auth, moveToNext));
  }
  function moveToNext(finaldata) {
    history.push({
      pathname: PATH.TEACHERCOURSEINFORMATION,
      finaldata: finaldata
    });
  }

  const { register, handleSubmit, errors } = useForm();
  return (
    <Modal.Body>
      <div className="d-flex justify-content-center align-items-center modalheader">
        <div className="d-flex left-arrow" onClick={() => goPrevvModal(0)}>
          <BsChevronLeft className="chevarrow" />
          <small className="small">Pricing Plan</small>
        </div>
        <p>Price Plan</p>
        <IoCloseSharp
          className="modalclose"
          onClick={() => hideModals(false)}
        />
      </div>
      <hr />
      <Row className="d-flex ">
        <Col md={12} className="">
          <Form onSubmit={handleSubmit(goNext)}>
            <Form.Group controlId="" className="mb-3">
              <Form.Label>Price</Form.Label>
              <Row>
                <Col md={4}>
                  <Form.Control
                    className="text-center"
                    type="text"
                    value="SGD"
                    disabled
                  />
                </Col>
                <Col md={8} className="pl-0 ">
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    name="priceAmount"
                    value={priceAmount}
                    onChange={(e) => {
                      setPriceAmount(e.target.value);
                    }}
                    style={{
                      borderColor: errors && errors.priceAmount ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.priceAmount && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group controlId="" className="mt-3">
              <Form.Label>Discount</Form.Label>
              <Row>
                <Col md={4}>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="btn-block price-modal-dropdown"
                    >
                      {customizeAmount}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setCustomizeAmount("Enable")}
                      >
                        {"Enable "}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setCustomizeAmount("Disbale")}
                      >
                        {" Disbale"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                    <BsChevronDown className="chenvenarrow" />
                  </Dropdown>
                </Col>
                <Col md={8} className="pl-0">
                  {
                    customizeAmount === "Disbale" ?
                      <>
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          // readOnly={customizeAmount === "Disbale" ? true : false}
                          name="discount"
                          disabled
                        />
                      </>
                      :
                      <>
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          // readOnly={customizeAmount === "Disbale" ? true : false}
                          name="discount"
                          value={discount}
                          onChange={(e) => {
                            setDiscount(e.target.value);
                          }}
                          style={{
                            borderColor: errors && errors.discount ? "#a80000" : "",
                          }}
                          ref={register({ required: true })}
                        />
                        {
                          customizeAmount === "Disbale" ? null :
                            <>
                              {errors.discount && (
                                <FieldError message={"This Field is Required"} />
                              )}</>
                        }
                      </>
                  }


                </Col>
              </Row>
            </Form.Group>
            {/* <Form.Group controlId="" className="mb-3">
              <Form.Label>Credits</Form.Label>
              <Form.Control type="number" placeholder="" />
            </Form.Group> */}
            {/* <Form.Group className="d-flex justify-content-between">
              <div className="checkbox-border">
                <input type="checkbox" id="task" onClick={() => {
                  setLimitedCourse(!limitedCourse);
                }} />
                <label htmlFor="task">Limited course access duration</label>
              </div>
            </Form.Group>
            {
              limitedCourse === true ?
                <>
                  <div className="col-sm-12 col-xl-10 d-flex mt-2">
                    <div className="ml-4">
                      <label className="mb-1 fw-600"> Start date</label>
                      <Form.Control
                        className="mt-1"
                        name="startRecurrentDate"
                        type="datetime-local"
                        defaultValue={startDate}
                        onChange={(e) => {
                          SetStartDate(e.target.value);
                        }}
                        style={{
                          borderColor:
                            errors && errors.startRecurrentDate
                              ? "#a80000"
                              : "",
                        }}
                        ref={register({ required: true })}
                      />
                      {errors.startRecurrentDate && (
                        <FieldError message={"This Field is Required"} />
                      )}
                    </div>
                    <div className="ml-4">
                      <label className="mb-1 fw-600"> End date</label>
                      <Form.Control
                        className="mt-1"
                        name="endRecurrentDate"
                        type="datetime-local"
                        defaultValue={endDate}
                        onChange={(e) => {
                          SetEndDate(e.target.value);
                        }}
                        style={{
                          borderColor:
                            errors && errors.endRecurrentDate ? "#a80000" : "",
                        }}
                        ref={register({ required: true })}
                      />
                      {errors.endRecurrentDate && (
                        <FieldError message={"This Field is Required"} />
                      )}
                    </div>
                  </div>
                </>
                :
                ""
            } */}
            <hr></hr>
            <div className="d-flex justify-content-center ">
              <button
                type="submit"
                // onClick={() => goNext()}
                className="btn btn-primary btn-lg mt-2 btn-size"
              >
                {" "}
                Add Price Plan
              </button>
            </div>
          </Form>
        </Col>
        {/* <Col md={5} className="">
          <div className="card">
            <p className="free">Price</p>
            <p className="nameplan">Name of Plan</p>
            <p className="subtitle">subtitle</p>
            <div className="d-flex rightcoldiv " onClick={() => setOpen(!open)}>
              <p className="mb-0">More</p>
              {open === false ? (
                <BsChevronDown className="right-chev" />
              ) : (
                <BsChevronUp className="right-chev" />
              )}
            </div>
            <Collapse in={open}>
              <div id="example-collapse-text">
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. Nihil anim keffiyeh
                helvetica, craft beer labore wes anderson cred nesciunt sapiente
                ea proident.
              </div>
            </Collapse>
          </div>
        </Col> */}
      </Row>
      {/* <hr></hr>
      <div className="d-flex justify-content-center ">
        <button
          type="button"
          onClick={() => goNext()}
          className="btn btn-primary btn-lg mt-2 btn-size"
        >
          {" "}
          Add Price Plan
        </button>
      </div> */}
    </Modal.Body>
  );
}
