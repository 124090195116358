import { request, success, failure, DASHBOARD_TILES_REQUEST } from "./utilities";
import { adminDashboardRevenue, adminDashboardTopCategoriesGraph, adminDashboardTopCoursesGraph, getAdminDashboardTiles, getCertificationsAwardedList, getCoachingSalesList, getCoachingStudentsList, getCourseSalesList, getCourseStudentsList, getProviderDashboardTiles, getQuotation, getTotalCoachingSales, getTotalCoursesSales, providerDashboardStatusOfCourses, providerDashboardTotalEarnings } from "../api";

export function GetAdminDashboardTiles(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_ADMIN_DASHBOARD_TILES_REQUEST));
        getAdminDashboardTiles(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_ADMIN_DASHBOARD_TILES_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_ADMIN_DASHBOARD_TILES_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_ADMIN_DASHBOARD_TILES_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetTotalCoursesSales(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_TOTAL_COURSES_SALE_REQUEST));
        getTotalCoursesSales(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_TOTAL_COURSES_SALE_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_TOTAL_COURSES_SALE_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_TOTAL_COURSES_SALE_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetTotalCoachingSales(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_TOTAL_COACHING_SALE_REQUEST));
        getTotalCoachingSales(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_TOTAL_COACHING_SALE_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_TOTAL_COACHING_SALE_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_TOTAL_COACHING_SALE_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetProviderDashboardTiles(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_PROVIDER_DASHBOARD_TILES_REQUEST));
        getProviderDashboardTiles(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_PROVIDER_DASHBOARD_TILES_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_PROVIDER_DASHBOARD_TILES_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_PROVIDER_DASHBOARD_TILES_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetCertificationsAwardedList(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_CERTIFICATION_AWARDED_LIST_REQUEST));
        getCertificationsAwardedList(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_CERTIFICATION_AWARDED_LIST_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_CERTIFICATION_AWARDED_LIST_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_CERTIFICATION_AWARDED_LIST_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetCourseSalesList(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_COURSES_SALES_LIST_REQUEST));
        getCourseSalesList(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_COURSES_SALES_LIST_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_COURSES_SALES_LIST_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_COURSES_SALES_LIST_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetCoachingSalesList(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_COACHING_SALES_LIST_REQUEST));
        getCoachingSalesList(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_COACHING_SALES_LIST_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_COACHING_SALES_LIST_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_COACHING_SALES_LIST_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function AdminDashboardTopCategoriesGraph(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_REQUEST));
        adminDashboardTopCategoriesGraph(auth).then(
            (response) => {

                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function ProviderDashboardTotalEarnings(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_TOTAL_EARNING_REQUEST));
        providerDashboardTotalEarnings(auth).then(
            (response) => {

                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_TOTAL_EARNING_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_TOTAL_EARNING_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_TOTAL_EARNING_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function AdminDashboardTopCoursesGraph(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_COURSES_GRAPH_REQUEST));
        adminDashboardTopCoursesGraph(auth).then(
            (response) => {

                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_COURSES_GRAPH_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_COURSES_GRAPH_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_COURSES_GRAPH_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function ProviderDashboardStatusOfCourses(auth) {
    return (dispatch) => {

        dispatch(request(DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_STATUS_OF_COURSES_REQUEST));
        providerDashboardStatusOfCourses(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_STATUS_OF_COURSES_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_STATUS_OF_COURSES_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_STATUS_OF_COURSES_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetCourseStudentsList(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_COURSES_STUDENTS_LIST_REQUEST));
        getCourseStudentsList(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_COURSES_STUDENTS_LIST_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_COURSES_STUDENTS_LIST_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_COURSES_STUDENTS_LIST_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function AdminDashboardRevenue(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_REVENUE_REQUEST));
        adminDashboardRevenue(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_REVENUE_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_REVENUE_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_REVENUE_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetCoachingStudentsList(auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_COACHING_STUDENTS_LIST_REQUEST));
        getCoachingStudentsList(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_COACHING_STUDENTS_LIST_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_COACHING_STUDENTS_LIST_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_COACHING_STUDENTS_LIST_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetQuotation(QuoteId, auth) {
    return (dispatch) => {
        dispatch(request(DASHBOARD_TILES_REQUEST.GET_QUOTATIONS_REQUEST));
        getQuotation(QuoteId, auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(DASHBOARD_TILES_REQUEST.GET_QUOTATIONS_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            DASHBOARD_TILES_REQUEST.GET_QUOTATIONS_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        DASHBOARD_TILES_REQUEST.GET_QUOTATIONS_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}