import { request, success, failure, COURSES_REQUEST } from "./utilities";
import {
  createCoursePricing,
  createCourses,
  createLecture,
  createSection,
  getSectionByFilter,
  getVideoTimeStamp,
  updateVideoTimeStamp,
  markCourseAsStarted,
  getCourseList,
  publishCourse,
  certificateReceived,
  getLectureByFilter,
  getCourseCertification,
  getPurchasedCourses,
  getRelatedCourses,
  getTopCourses,
  getTopCategoriesCourses,
  purchaseCourse,
  getById,
  checkQuizAnswer,
  purchasecredit,
  getCompletedCourseListWithCertificates,
  getInProgressCourseList,
  certificateConversion,
} from "../api/coursesApi";

export function CreateCourses(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.CREATE_COURSES_REQUEST));
    createCourses(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(COURSES_REQUEST.CREATE_COURSES_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.CREATE_COURSES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.CREATE_COURSES_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CreateSection(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.CREATE_SECTION_REQUEST));
    createSection(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(COURSES_REQUEST.CREATE_SECTION_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.CREATE_SECTION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.CREATE_SECTION_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CreateCoursePricing(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.CREATE_COURSE_PRICING_REQUEST));
    createCoursePricing(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.CREATE_COURSE_PRICING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.CREATE_COURSE_PRICING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.CREATE_COURSE_PRICING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CreateLecture(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.CREATE_LECTURE_REQUEST));
    createLecture(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(COURSES_REQUEST.CREATE_LECTURE_SUCCESS, data));
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.CREATE_LECTURE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.CREATE_LECTURE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CertificateConversion(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.CERTIFICATE_CONVERSION_REQUEST));
    certificateConversion(data, auth).then(
      (response) => {
        console.log("helloconsole", response);
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.CERTIFICATE_CONVERSION_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext(response.data.data.certificateURL);
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.CERTIFICATE_CONVERSION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.CERTIFICATE_CONVERSION_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function Purchasecredit(data, auth, moveToNext, NotificationAlert) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.PURCHASE_CREDITS_REQUEST));
    purchasecredit(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(COURSES_REQUEST.PURCHASE_CREDITS_SUCCESS, data));
          if (moveToNext) {
            moveToNext();
          }
          NotificationAlert(response.data.message, "success");
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.PURCHASE_CREDITS_FAILURE,
              response.data.message
            )
          );
          NotificationAlert(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.PURCHASE_CREDITS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetById(CourseId, auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_BY_ID_REQUEST));
    getById(CourseId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(COURSES_REQUEST.GET_BY_ID_SUCCESS, response.data.data)
          );
          // if (moveToNext) {
          //   moveToNext();
          // }
        } else {
          dispatch(
            failure(COURSES_REQUEST.GET_BY_ID_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_BY_ID_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetCourseCertification(CourseId, auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_COURSE_CERTIFICATION_REQUEST));
    getCourseCertification(CourseId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_COURSE_CERTIFICATION_SUCCESS,
              response.data.data
            )
          );
          // if (moveToNext) {
          //   moveToNext();
          // }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_COURSE_CERTIFICATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_COURSE_CERTIFICATION_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CheckQuizAnswer(QuizOptionId, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.CHECK_QUIZ_ANSWER_REQUEST));
    checkQuizAnswer(QuizOptionId, auth).then(
      (response) => {
        console.log("dslkjfl", response);
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.CHECK_QUIZ_ANSWER_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.CHECK_QUIZ_ANSWER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.CHECK_QUIZ_ANSWER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetLectureByFilter(CourseId, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_LECTURE_BY_FILTER_REQUEST));
    getLectureByFilter(CourseId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_LECTURE_BY_FILTER_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_LECTURE_BY_FILTER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_LECTURE_BY_FILTER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetTopCategoriesCourses(CategoryId, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_TOP_CATEGORIES_COURSES_REQUEST));
    getTopCategoriesCourses(CategoryId).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_TOP_CATEGORIES_COURSES_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_TOP_CATEGORIES_COURSES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_TOP_CATEGORIES_COURSES_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetVideoTimeStamp(courseId, auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_VIDEO_TIME_STAMP_REQUEST));
    getVideoTimeStamp(courseId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_VIDEO_TIME_STAMP_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_VIDEO_TIME_STAMP_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_VIDEO_TIME_STAMP_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetSectionByFilter(CourseId, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_SECTION_BY_FILTER_REQUEST));
    getSectionByFilter(CourseId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_SECTION_BY_FILTER_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_SECTION_BY_FILTER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_SECTION_BY_FILTER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetCourseList(auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_COURSE_LIST_REQUEST));
    getCourseList(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(COURSES_REQUEST.GET_COURSE_LIST_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_COURSE_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_COURSE_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetRelatedCourses(auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_RELATED_COURSES_REQUEST));
    getRelatedCourses(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_RELATED_COURSES_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_RELATED_COURSES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_RELATED_COURSES_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetInProgressCourseList(auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_INPROGRESS_COURSE_LIST_REQUEST));
    getInProgressCourseList(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_INPROGRESS_COURSE_LIST_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_INPROGRESS_COURSE_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_INPROGRESS_COURSE_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetCompletedCourseListWithCertificates(auth) {
  return (dispatch) => {
    dispatch(
      request(
        COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_REQUEST
      )
    );
    getCompletedCourseListWithCertificates(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetPurchasedCourses(auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_PURCHASED_COURSES_REQUEST));
    getPurchasedCourses(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.GET_PURCHASED_COURSES_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_PURCHASED_COURSES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_PURCHASED_COURSES_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetTopCourses() {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.GET_TOP_COURSES_REQUEST));
    getTopCourses().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(COURSES_REQUEST.GET_TOP_COURSES_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.GET_TOP_COURSES_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.GET_TOP_COURSES_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function PublishCourse(
  data,
  auth,
  loadingState,
  AfterStatusChange,
  Notification
) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.PUBLISH_COURSE_REQUEST));
    if (loadingState) {
      loadingState(true);
    }
    publishCourse(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(COURSES_REQUEST.PUBLISH_COURSE_SUCCESS, data));
          if (loadingState) {
            loadingState(false);
          }
          AfterStatusChange();
          Notification(response.data.message);
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.PUBLISH_COURSE_FAILURE,
              response.data.message
            )
          );
          if (loadingState) {
            loadingState(false);
          }
        }
      },
      (error) => {
        if (loadingState) {
          loadingState(false);
        }
        dispatch(
          failure(
            COURSES_REQUEST.PUBLISH_COURSE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function MarkCourseAsStarted(CourseId, auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.MARK_COURSE_AS_STARTED_REQUEST));
    markCourseAsStarted(CourseId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.MARK_COURSE_AS_STARTED_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.MARK_COURSE_AS_STARTED_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.MARK_COURSE_AS_STARTED_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function UpdateVideoTimeStamp(data, auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.UPDATE_VIDEO_TIME_STAMP_REQUEST));
    updateVideoTimeStamp(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.UPDATE_VIDEO_TIME_STAMP_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.UPDATE_VIDEO_TIME_STAMP_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.UPDATE_VIDEO_TIME_STAMP_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CertificateReceived(CourseId, auth) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.CERTIFICATE_RECEIVED_REQUEST));
    certificateReceived(CourseId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COURSES_REQUEST.CERTIFICATE_RECEIVED_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.CERTIFICATE_RECEIVED_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.CERTIFICATE_RECEIVED_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function PurchaseCourse(data, auth, AfterBuyCoaching, notification) {
  return (dispatch) => {
    dispatch(request(COURSES_REQUEST.PURCHASE_COURSE_REQUEST));
    purchaseCourse(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(COURSES_REQUEST.PURCHASE_COURSE_SUCCESS, data));
          notification(response.data.message, "success");
          AfterBuyCoaching();
        } else {
          dispatch(
            failure(
              COURSES_REQUEST.PURCHASE_COURSE_FAILURE,
              response.data.message
            )
          );
          notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            COURSES_REQUEST.PURCHASE_COURSE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
