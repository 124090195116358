import { request, success, failure, COURSE_WISH_LIST_REQUEST } from "./utilities";
import { create,  getWishlistCourses, remove } from "../api";

export function Create(courseId, auth, Notification) {
    return (dispatch) => {
        dispatch(request(COURSE_WISH_LIST_REQUEST.CREATE_REQUEST));
        create(courseId, auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(COURSE_WISH_LIST_REQUEST.CREATE_SUCCESS, response.data.data)
                    );

                    Notification(response.data.message, "success");
                } else {
                    dispatch(
                        failure(
                            COURSE_WISH_LIST_REQUEST.CREATE_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        COURSE_WISH_LIST_REQUEST.CREATE_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function Remove(courseId, auth, NotificationRemove, moveToNext) {
    return (dispatch) => {
        dispatch(request(COURSE_WISH_LIST_REQUEST.REMOVE_REQUEST));
        remove(courseId, auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(COURSE_WISH_LIST_REQUEST.REMOVE_SUCCESS, response.data.data)
                    );
                    if (moveToNext) {
                        moveToNext()
                    }

                    NotificationRemove(response.data.message, "success");
                } else {
                    dispatch(
                        failure(
                            COURSE_WISH_LIST_REQUEST.REMOVE_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        COURSE_WISH_LIST_REQUEST.REMOVE_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetWishlistCourses(auth) {
    return (dispatch) => {
        dispatch(request(COURSE_WISH_LIST_REQUEST.GET_WISH_LIST_COURSES_REQUEST));
        getWishlistCourses(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(COURSE_WISH_LIST_REQUEST.GET_WISH_LIST_COURSES_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            COURSE_WISH_LIST_REQUEST.GET_WISH_LIST_COURSES_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        COURSE_WISH_LIST_REQUEST.GET_WISH_LIST_COURSES_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}