import React from "react";
import { FaLock } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { PATH } from "../../config";

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <div>
        <div className="d-flex justify-content-between mx-3 privacy-policy-styling-parent align-items-center">
          <div className="edutech-logo">
            <LoginLogo />
          </div>
          <Link to={PATH.LOGIN}>
            <span className="pr-5 d-flex">
              {" "}
              <FaLock className="mb-1 mr-2" /> <h5>Login</h5>
            </span>
          </Link>
        </div>
        <hr />
        <div className="container">
          <div className="termsAndConditions-body py-5">
            <h2>Privacy Policy for Candle Tech</h2>
            <p>
              At Candle Tech, accessible from https://www.candletech.com, one of
              our main priorities is the privacy of our visitors. This Privacy
              Policy document contains types of information that is collected
              and recorded by Candle Tech and how we use it.
            </p>
            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in Candle Tech. This policy is not
              applicable to any information collected offline or via channels
              other than this website. Our Privacy Policy was created with the
              help of the Free Privacy Policy Generator.
            </p>
            <h2>Consent</h2>
            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
            <h2>Information we collect</h2>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>
            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>
            <h2>How we use your information</h2>
            <p>
              We use the information we collect in various ways, including to:
            </p>
            <ul>
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>
                Develop new products, services, features, and functionality
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
              <li>Log Files</li>
            </ul>
            <p>
              Candle Tech follows a standard procedure of using log files. These
              files log visitors when they visit websites. All hosting companies
              do this and a part of hosting services' analytics. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </p>
            <h2>Cookies and Web Beacons</h2>
            <p>
              Like any other website, Candle Tech uses 'cookies'. These cookies
              are used to store information including visitors' preferences, and
              the pages on the website that the visitor accessed or visited. The
              information is used to optimize the users' experience by
              customizing our web page content based on visitors' browser type
              and/or other information.
            </p>
            <h2>Advertising Partners Privacy Policies</h2>
            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of Candle Tech.
            </p>
            <p>
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on Candle Tech,
              which are sent directly to users' browser. They automatically
              receive your IP address when this occurs. These technologies are
              used to measure the effectiveness of their advertising campaigns
              and/or to personalize the advertising content that you see on
              websites that you visit.
            </p>
            <p>
              Note that Candle Tech has no access to or control over these
              cookies that are used by third-party advertisers.
            </p>
            <h2>Third Party Privacy Policies</h2>
            <p>
              Candle Tech's Privacy Policy does not apply to other advertisers
              or websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options.
            </p>
            <p>
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers'
              respective websites.
            </p>
            <p>CCPA Privacy Rights (Do Not Sell My Personal Information)</p>
            <p>
              Under the CCPA, among other rights, California consumers have the
              right to:
            </p>
            <ul>
              <li>
                Request that a business that collects a consumer's personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
              </li>
              <li>
                Request that a business delete any personal data about the
                consumer that a business has collected.
              </li>
              <li>
                Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data.
              </li>
              <li>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export function LoginLogo() {
  return (
    <Link to={PATH.SIGNUP}>
      <img
        src={IMAGES.EDUTECH_LOGO_SMALL}
        className="img-fluid"
        alt="edutech-logo"
      />
    </Link>
  );
}
