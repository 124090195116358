import { LOOKUP_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  lookUpLoading: false,
  lookUpSuccess: false,
  lookUpFailure: false,
  lookUpError: null,
  lookUp: [],

  uploadAzureLoading: false,
  uploadAzureSuccess: false,
  uploadAzureFailure: false,
  uploadAzureError: null,
  uploadAzure: [],

  uploadImageLoading: false,
  uploadImageSuccess: false,
  uploadImageFailure: false,
  uploadImageError: null,
  uploadImage: [],
};

export const LookUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOOKUP_REQUEST.LOOKUP_REQUEST:
      return {
        ...state,
        lookUpLoading: true,
        lookUpSuccess: false,
        lookUpFailure: false,
        lookUpError: null,
        lookUp: [],
      };
    case LOOKUP_REQUEST.LOOKUP_SUCCESS:
      return {
        ...state,
        lookUpLoading: false,
        lookUpSuccess: true,
        lookUpFailure: false,
        lookUpError: null,
        lookUp: action.payload,
      };
    case LOOKUP_REQUEST.LOOKUP_FAILURE:
      return {
        ...state,
        lookUpLoading: false,
        lookUpSuccess: false,
        lookUpFailure: true,
        lookUpError: action.payload,
      };
    case LOOKUP_REQUEST.UPLOAD_TO_AZURE_REQUEST:
      return {
        ...state,
        uploadAzureLoading: true,
        uploadAzureSuccess: false,
        uploadAzureFailure: false,
        uploadAzureError: null,
        uploadAzure: [],
      };
    case LOOKUP_REQUEST.UPLOAD_TO_AZURE_SUCCESS:
      return {
        ...state,
        uploadAzureLoading: false,
        uploadAzureSuccess: true,
        uploadAzureFailure: false,
        uploadAzureError: null,
        uploadAzure: action.payload,
      };
    case LOOKUP_REQUEST.UPLOAD_TO_AZURE_FAILURE:
      return {
        ...state,
        uploadAzureLoading: false,
        uploadAzureSuccess: false,
        uploadAzureFailure: true,
        uploadAzureError: action.payload,
      };
    case LOOKUP_REQUEST.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploadImageLoading: true,
        uploadImageSuccess: false,
        uploadImageFailure: false,
        uploadImageError: null,
        uploadImage: [],
      };
    case LOOKUP_REQUEST.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageLoading: false,
        uploadImageSuccess: true,
        uploadImageFailure: false,
        uploadImageError: null,
        uploadImage: action.payload,
      };
    case LOOKUP_REQUEST.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        uploadImageLoading: false,
        uploadImageSuccess: false,
        uploadImageFailure: true,
        uploadImageError: action.payload,
      };
    default:
      return state;
  }
};
