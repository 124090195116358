import { MENU_PERMISSION_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  createPermissionLoading: false,
  createPermissionSuccess: false,
  createPermissionFailure: false,
  createPermissionError: null,
  createPermission: [],

  getMenuPermissionByIdLoading: false,
  getMenuPermissionByIdSuccess: false,
  getMenuPermissionByIdFailure: false,
  getMenuPermissionByIdError: null,
  getMenuPermissionById: [],
};

export const createPermissionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MENU_PERMISSION_REQUEST.MENU_PERMISSION_REQUEST:
      return {
        ...state,
        createPermissionLoading: true,
        createPermissionSuccess: false,
        createPermissionFailure: false,
        createPermissionError: null,
      };
    case MENU_PERMISSION_REQUEST.MENU_PERMISSION_SUCCESS:
      return {
        ...state,
        createPermissionLoading: false,
        createPermissionSuccess: true,
        createPermissionFailure: false,
        createPermissionError: null,
        createPermission: action.payload,
      };
    case MENU_PERMISSION_REQUEST.MENU_PERMISSION_FAILURE:
      return {
        ...state,
        createPermissionLoading: false,
        createPermissionSuccess: false,
        createPermissionFailure: true,
        createPermissionError: action.payload,
      };

    case MENU_PERMISSION_REQUEST.GET_PERMISSIONBY_ID__REQUEST:
      return {
        ...state,
        getMenuPermissionByIdLoading: true,
        getMenuPermissionByIdSuccess: false,
        getMenuPermissionByIdFailure: false,
        getMenuPermissionByIdError: null,
      };
    case MENU_PERMISSION_REQUEST.GET_PERMISSIONBY_ID_SUCCESS:
      return {
        ...state,
        getMenuPermissionByIdLoading: false,
        getMenuPermissionByIdSuccess: true,
        getMenuPermissionByIdFailure: false,
        getMenuPermissionByIdError: null,
        getMenuPermissionById: action.payload,
      };
    case MENU_PERMISSION_REQUEST.GET_PERMISSIONBY_ID_FAILURE:
      return {
        ...state,
        getMenuPermissionByIdLoading: false,
        getMenuPermissionByIdSuccess: false,
        getMenuPermissionByIdFailure: true,
        getMenuPermissionByIdError: action.payload,
      };

    default:
      return state;
  }
};
