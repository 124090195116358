import React, { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import AddSummary from "./AddSummary";
import AddQuiz from "./AddQuiz";
import AddVideo from "./AddVideo";
import { GenericSearchField } from "../../../../../assets";
import { PATH } from "../../../../../config";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory, useLocation } from "react-router";
import AddTranscript from "./AddTranscript";
import AddResources from "./AddResources";
export default function AddLectures(props) {
  let history = useHistory();
  const location = useLocation()
  const CoursesData = location?.state?.courses
  const [selectedView, setSelectedView] = useState(0);
  console.log("heloo this is history consoole", CoursesData)
  function goBack() {
    history.push({
      pathname: PATH.TEACHERCURRICULUMLIST,
      // state: { fromPreviewPage: location.state.fromPreviewPage },
    });
  }
  const TabChanges = (value) => {
    document.getElementById(value).click();
    // document.getElementById("myform").submit();
  }
  function setValue(value) {
    if (selectedView > value) {
      setSelectedView(value)
    }
  }
  return (
    <div className="edutech-teacher-curriculum-parent-tab">
      {/* <div className="d-flex align-items-center justify-content-between">
        <BsArrowLeft size={35} className="cursor" onClick={() => goBack()} />
        <div>
          <GenericSearchField placeholder="Search" />
        </div>
      </div> */}
      <Tab.Container id="left-tabs-example" defaultActiveKey="AddVideo">
        <Row className="edutech-curriculum-gradient-color mx-0 mt-5">
          <Col sm={2} md={2} className="edutech-curriculum-col p-0">
            <div className='flex-column nav nav-pills cursor'>
              <div className={`text-center edutech-curriculum-font  p-4 nav-link ${selectedView === 0 ? "active" : "tab_non_active"}`} onClick={() => { setValue(0) }}>
                <h4 className='m-0'>Add Video</h4>
              </div>
              <div className={`text-center edutech-curriculum-font  p-4 nav-link ${selectedView === 1 ? "active" : "tab_non_active"}`} onClick={() => { setValue(1) }}>
                <h4 className='m-0'> Add Summary</h4>
              </div>
              <div className={`text-center edutech-curriculum-font  p-4 nav-link ${selectedView === 2 ? "active" : "tab_non_active"}`} onClick={() => { setValue(2) }}>
                <h4 className='m-0'>Add Quiz</h4>
              </div>
              <div className={`text-center edutech-curriculum-font  p-4 nav-link ${selectedView === 3 ? "active" : "tab_non_active"}`} onClick={() => { setValue(3) }}>
                <h4 className='m-0'>Add Transcript</h4>
              </div>
              <div className={`text-center edutech-curriculum-font  p-4 nav-link ${selectedView === 4 ? "active" : "tab_non_active"}`} onClick={() => { setValue(4) }}>
                <h4 className='m-0'>Add Resources</h4>
              </div>
            </div>
          </Col>
          <Col sm={10} md={10} className="pl-5  ">
            {
              selectedView === 0
              &&
              <AddVideo
                setSelectedView={setSelectedView}
                CoursesData={CoursesData}
              />
            }
            {
              selectedView === 1
              &&
              <AddSummary
                setSelectedView={setSelectedView}
                CoursesData={CoursesData}
              />
            }
            {
              selectedView === 2
              &&
              <AddQuiz
                setSelectedView={setSelectedView}
                CoursesData={CoursesData}
              />
            }
            {
              selectedView === 3
              &&
              <AddTranscript
                setSelectedView={setSelectedView}
                CoursesData={CoursesData}
              />
            }
            {
              selectedView === 4
              &&
              <AddResources
                setSelectedView={setSelectedView}
                CoursesData={CoursesData}
              />
            }
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
