import React, { useEffect, useState } from "react";
import { BsCoin } from "react-icons/bs";
import { Card, Button, Form, Row, Col, Modal, Dropdown } from "react-bootstrap";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GetPurchasedCoaching } from "../../../redux/actions/coachingAction";
import Cards from "react-credit-cards";
import { GetCredits } from "../../../redux/actions/accountAction";
import { PATH } from "../../../config";
import { FieldError, Loader, getCouponDiscountedAmount } from "../../../assets";
import {
  GetIndustry,
  GetManualPricingRange,
  GetSelectedPricing,
  IsEmailExist,
  PriceCheckWithinRange,
  PricingPlanByFilter,
  Purchasecredit,
  RegisterStudent,
} from "../../../redux/actions";
import { toast } from "react-toastify";
export default function PurchaseCredits() {
  const { register, handleSubmit, errors, reset } = useForm();
  const [buttonState, setButtonState] = useState(false);
  const [pricestate, setpricestate] = useState(false);
  const [bonusState, setBonusState] = useState(false);
  const [errorField, seterrorField] = useState();
  function onSubmit(data) {}
  const [otherPlan, setOtherPlan] = useState(false);
  const [otherPlanField, setOtherPlanField] = useState(false);
  const [bonusValue, setBonusValue] = useState(
    getCouponDiscountedAmount(buttonState)
  );
  const dispatch = useDispatch();
  let auth = useAuth();
  useEffect(() => {
    dispatch(GetManualPricingRange(auth));
  }, [dispatch]);
  function OtherPlanSubmit(data) {
    dispatch(PriceCheckWithinRange(otherPlan, auth, Notificiation));
  }
  let getPriceListing = useSelector((state) => state.pricingPlan);
  let pricingPlan = getPriceListing && getPriceListing.getSelectedPricing;
  let pricingPlanListing = getPriceListing?.getManualPriceRange;
  useEffect(() => {
    dispatch(GetSelectedPricing(auth));
  }, [dispatch]);
  function Notificiation(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  return (
    <>
      <div className="container subscription_plan mt-4 signup_page_colour_change">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 col-md-12">
              <div
                className={` p-3 card-custom w-100 ml-0 admin-course-request`}
              >
                <div className="card-header text-center">
                  <h1 className="font-weight-700 mb-0">Pricing Plan</h1>
                </div>
              </div>
              {/* <Card>
              <Card.Body className="text-center proceed_free_btn d-flex align-items-center justify-content-between">
                <h4 className="text-dark">You are entitled to a free trial of 7 Days</h4>
              </Card.Body>
            </Card> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-12">
              <Card className="mt-0">
                <Card.Body>
                  <div className="pricing_btn pricing-plan-height-limit">
                    {pricingPlan &&
                      pricingPlan.map((interest, index) => {
                        return (
                          <>
                            <ButtonSection2
                              setButtonState={setButtonState}
                              buttonState={buttonState}
                              setBonusState={setBonusState}
                              bonusState={bonusState}
                              pricestate={pricestate}
                              setpricestate={setpricestate}
                              setBonusValue={setBonusValue}
                              interest={interest}
                              index={index}
                            />
                          </>
                        );
                      })}
                  </div>
                  <Form.Group className="pt-3">
                    <Button
                      type="button"
                      onClick={() => {
                        setOtherPlanField(!otherPlanField);
                      }}
                      className={`cursor btn-select pricing-plan-other-selection ${
                        otherPlanField === true ? "active" : ""
                      }`}
                    >
                      {"Other Plan"}
                    </Button>
                  </Form.Group>
                  {otherPlanField === true ? (
                    <>
                      <p className="text-dark font-400 my-4 p-3">
                        The amount must be between {pricingPlanListing.minRange}{" "}
                        to {pricingPlanListing.maxRange} and multiple of 100.
                      </p>
                      <Form.Group>
                        <Form.Control
                          className="font-weight-bold edutech-coaching-name-text"
                          name="otherPlan"
                          type="number"
                          value={otherPlan}
                          onChange={(e) => {
                            setOtherPlan(e.target.value);
                          }}
                          placeholder="Type Amount"
                          style={{
                            borderColor:
                              errors && errors.otherPlan ? "#a80000" : "",
                          }}
                          ref={register({ required: true })}
                        />
                        {errors.otherPlan && (
                          <FieldError message={"This Field is Required"} />
                        )}
                      </Form.Group>
                      <div className="text-center">
                        <Button
                          className="payment-primary "
                          onClick={() => {
                            OtherPlanSubmit();
                          }}
                        >
                          {" "}
                          Save
                        </Button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <Card>
                <Card.Body className="proceed_btn">
                  <Form.Group as={Row} className="mb-0">
                    <Form.Label column md="5">
                      Your Credits
                    </Form.Label>
                    <Col md="12">
                      <Form.Control
                        name="lastName"
                        value={
                          Math.trunc(bonusValue?.total)
                            ? Math.trunc(bonusValue?.total)
                            : otherPlan
                            ? otherPlan
                            : "Please Select Your amount"
                        }
                        // type="number"
                        disabled
                        ref={register()}
                      />
                    </Col>
                    <Col md="5" className="mt-3 mx-auto">
                      <PaymentModal
                        bonusValue={bonusValue}
                        otherPlan={otherPlan}
                        buttonState={buttonState}
                        bonusState={bonusState}
                        pricestate={pricestate}
                        pricingPlan={pricingPlan}
                        register={register({ required: true })}
                        // stdData={stdData}
                      />
                    </Col>
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
function ButtonSection2(props) {
  let { index } = props;
  return (
    <>
      <Button
        type="button"
        key={index}
        onClick={() => {
          props.setBonusValue(getCouponDiscountedAmount(props.interest));
          props.setButtonState(props.interest.pricingPlanId);
          props.setpricestate(props.interest.price);
          props.setBonusState(props.interest.bonusPercentage);
        }}
        className={`cursor ${
          props.buttonState === props.interest.pricingPlanId ? "active" : ""
        }`}
      >
        {props.interest.price} ({props.interest.bonusPercentage}%)
      </Button>
    </>
  );
}
function PricingPaymentModal(props) {
  let dispatch1 = useDispatch();
  const { reset, errors } = useForm();
  // let stdData = props.studentData;
  const [errorField, seterrorField] = useState();
  const { register, handleSubmit } = useForm();
  let auth = useAuth();
  let history = useHistory();
  function onSubmit(data) {
    let finalData = {
      number: card.number,
      expiry: parseInt(card.expiry),
      cvc: card.cvc,
      price: parseInt(props.pricestate ? props.pricestate : props.otherPlan),
      percentage: parseInt(props.bonusState ? props.bonusState : 0),
    };
    dispatch1(Purchasecredit(finalData, auth, moveToNext, NotificationAlert));
  }
  function moveToNext() {
    props.onHide();
  }
  function NotificationAlert(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  const [card, setCard] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  });

  const handleInputFocus = (e) => {
    // setCard({ focus: e.target.name });
    // const { focus, value } = e.target;
    // setCard({
    //   ...card,
    //   [focus]: value,
    // });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCard({
      ...card,
      [name]: value,
    });
  };

  function validateFields(e) {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Payment Details
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div id="PaymentForm">
            <Cards
              cvc={card.cvc}
              expiry={card.expiry}
              focused={card.focus}
              name={card.name}
              number={card.number}
            />
            <Form.Group className="container mt-2">
              <div className="row">
                <div className="col-12">
                  <Form.Control
                    className="my-2  edutech-payment-inout-field"
                    name="number"
                    type="text"
                    maxLength="16"
                    // ref={register}
                    onInput={(e) => validateFields(e)}
                    autocomplete="off"
                    placeholder="Enter Valid number"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    style={{ borderColor: errors?.number ? "#a80000" : "" }}
                    ref={register({
                      required: true,
                    })}
                  />
                </div>
                <div className="col-12">
                  <Form.Control
                    className="my-2  edutech-payment-inout-field"
                    name="name"
                    type="text"
                    autocomplete="off"
                    ref={register}
                    placeholder="Enter Your Name"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </div>
                <div className="col-6">
                  <Form.Control
                    className="mt-2  edutech-payment-inout-field"
                    name="expiry"
                    type="text"
                    minLength="0"
                    maxLength="4"
                    // ref={register}
                    onInput={(e) => validateFields(e)}
                    autocomplete="off"
                    placeholder="mm/yy"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    style={{ borderColor: errors?.expiry ? "#a80000" : "" }}
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.expiry && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </div>
                <div className="col-6">
                  <Form.Control
                    className="mt-2  edutech-payment-inout-field"
                    name="cvc"
                    type="text"
                    maxLength="3"
                    ref={register}
                    onInput={(e) => validateFields(e)}
                    autocomplete="off"
                    placeholder="Enter CVC  number"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </div>
              </div>
            </Form.Group>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <Button className="payment-primary" type="submit">
            {/* onClick={props.onHide} */} Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
function PaymentModal(props) {
  const [modalShow, setModalShow] = React.useState(false);
  // let studentData = props.stdData
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Proceed
      </Button>

      <PricingPaymentModal
        {...props}
        show={modalShow}
        onHide={() => setModalShow(false)}
        // studentData={studentData}
      />
    </>
  );
}
