import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentPaymentDetails } from "../../../redux/actions";
import { ToastContainer } from "react-toastify";
import { BsArrowDown,  BsArrowUp } from "react-icons/bs";
import { dateTimeConvert, ErrorMessage, Loader } from "../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
export default function PaymentDetails() {
  let dispatch = useDispatch();
  let getListing = useSelector((state) => state.studentPayment);
  useEffect(() => {
    dispatch(GetStudentPaymentDetails());
    document.title = `Coaching || EduTech`;
  }, [dispatch]);
  let data = getListing && getListing.getStudentPaymentDetails;
  let columns = [
    {
      dataField: "name",
      text: "Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            {row.name ? row.name : "N/A"}
          </span>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            {row.email ? row.email : "N/A"}
          </span>
        );
      },
    },
    {
      dataField: "paymentDate",
      text: "Payment Date",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            {dateTimeConvert(row.paymentDate) ? dateTimeConvert(row.paymentDate) : "N/A"}
          </span>
        );
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            {row.amount ? row.amount : "N/A"}
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      <ToastContainer />
      <div className="teacher-curriculum-dashboard ">
        {/* <Link to={PATH.ADMIN_DASHBOARD}>
          <BsArrowLeft size={35} className="mr-2" />
        </Link> */}
        <div
          className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
        >
          <div className="card-header ">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className=" font-weight-700 mb-0">Payment Details</h3>
            </div>
          </div>
        </div>
      </div>
      <>
        {getListing &&
          getListing.getStudentPaymentDetailsLoading === true &&
          getListing.getStudentPaymentDetailsSuccess === false &&
          getListing.getStudentPaymentDetailsFailure === false && <Loader />}
        {getListing &&
          getListing.getStudentPaymentDetailsLoading === false &&
          getListing.getStudentPaymentDetailsSuccess === false &&
          getListing.getStudentPaymentDetailsFailure === true && (
            <ErrorMessage message={getListing.getStudentPaymentDetailsError} />
          )}
        {getListing &&
          getListing.getStudentPaymentDetailsLoading === false &&
          getListing.getStudentPaymentDetailsSuccess === true &&
          getListing.getStudentPaymentDetailsFailure === false && (
            <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
              <MyDataTable
                data={data.data}
                columns={columns}
                defaultSorted={defaultSorted}
              // handleDataChange={handleDataChange}
              />
            </div>
          )}
      </>
    </>
  );
}