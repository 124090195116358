import React from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Button, Form, Col, Modal, Row } from "react-bootstrap";
import { IMAGES, TeacherCourseTabs } from "../../../../../assets";
import PricingModals from "./PricingModals";
import { PATH } from "../../../../../config";
import { BsArrowLeft } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function TeacherPricingList() {
  const [previewCourseShow, setPreviewCourseShow] = React.useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [deletePricingList, setDeletePricingList] = useState(false);
  const [showPrizeModal, setprizeModal] = useState(0);
  const [Prizeindex, setPrizeIndex] = useState(0);
  function setModalValue(val) {
    setPrizeIndex(val);
    setprizeModal(1);
  }
  let history = useHistory();
  function goBack() {
    history.push({
      pathname: PATH.TEACHERCURRICULUMLIST,
      // state: { fromPreviewPage: false },
    });
  }

  const [updatePricingdetailModal, setPricingDetailModal] = React.useState(
    false
  );
  console.log(lgShow);
  var list = [
    {
      id: "3534687",
      planType: "Free",
      planName: "Muzamal Ali",
      price: "0.00 SGD",
      recurs: "No",
      checkoutUrl: "",
    },
    {
      id: "3534688",
      planType: "One-Time Purchase",
      planName: "Muzamal Ali",
      price: "234.00 SGD",
      recurs: "No",
      checkoutUrl: "",
    },
  ];
  function goNext() {
    history.push(PATH.TEACHERPRICINGLIST);
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <BsArrowLeft size={35} className="cursor" onClick={() => goBack()} />
        {/* <div className="teacher-curriculum-dashboard ">
          <button
            className="create_course w-100"
            type="button"
            onClick={() => setLgShow(true)}
          >
            Add Pricing Plan
          </button>
        </div> */}
      </div>
      <div className="teacher-curriculum-dashboard mx-md-5 my-5 mb-0 ">
        <div className="bg-white set-card-styling teacher-curriculum table-responsive px-4 py-2 mt-5">
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>PLAN TYPE</th>
                <th>PLAN NAME</th>
                <th>PRICE</th>
                <th>RECURS</th>
                <th>CHECKOUT URL</th>
              </tr>
            </thead>
            <tbody className="">
              {list &&
                list.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.id}</td>
                      <td>{data.planType}</td>
                      <td>{data.planName}</td>
                      <td>{data.price}</td>
                      <td>{data.recurs}</td>
                      <td>
                        {data.checkoutUrl === "" ? (
                          <>
                            <Link to="">Copy URL</Link>
                          </>
                        ) : (
                          <>{data.checkoutUrl}</>
                        )}
                      </td>
                      <td>
                        <MdOutlineModeEditOutline
                          className="mr-2 cursor"
                          size={22}
                          onClick={() => setPricingDetailModal(true)}
                        />
                        <RiDeleteBinLine
                          className="cursor"
                          size={22}
                          onClick={() => setDeletePricingList(true)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div className="row justify-content-end px-5 mt-4 pb-4">
            <div className="mx-2">Showing 1 - 1</div>
            <div className="mx-2">Of 1</div>
            <div className="">
              <FaAngleLeft className="mx-2 rounded-arrow cursor" />
              <FaAngleRight className="mr-2 rounded-arrow cursor" />
            </div>
          </div>
          <PreviewCourseModal
            show={previewCourseShow}
            onHide={() => setPreviewCourseShow(false)}
          />
        </div>
      </div>
      <div className="teacher-curriculum-dashboard d-flex align-items-center justify-content-end">
        <Link
          to={{
            pathname: PATH.TEACHERCOURSEINFORMATION,
          }}
        >
          <button
            className="create_course w-100 align-items-center d-flex"
            type="button"
          >
            Next <HiArrowRight className="ml-1" size={20} />
          </button>
        </Link>
      </div>
      <div className="d-flex align-items-center justify-content-center coursetabs_height_set">
        <div className="teachergroupbtn">
          <TeacherCourseTabs />
        </div>
      </div>
      {showPrizeModal === 1 ? (
        <PricingModals
          value={Prizeindex}
          prevModal={setprizeModal}
          setprizeModal={setprizeModal}
          setmainmodal={setLgShow}
        />
      ) : (
        <Modal
          size="lg"
          show={lgShow}
          aria-labelledby="example-modal-sizes-title-lg"
          className="teacherCourseModal X"
          centered
        >
          <Modal.Body>
            <IoCloseSharp
              className="modalclose"
              onClick={() => setLgShow(false)}
            />
            <Row className="d-flex justify-content-center">
              <Col md={6} className="pricing-div">
                <div
                  className={`mb-2 d-flex align-items-center divhover `}
                  onClick={() => goNext()}
                >
                  <div>
                    <img src={IMAGES.FREE} alt="" />
                  </div>
                  <div className="ml-4">
                    <h5>FREE</h5>
                    <p>Allow access to your content free of charge</p>
                  </div>
                </div>
                <div
                  className={`mb-2 d-flex align-items-center divhover `}
                  onClick={() => setModalValue(1)}
                >
                  <div>
                    <img src={IMAGES.FREE} alt="" />
                  </div>
                  <div className="ml-4">
                    <h5>PRICE</h5>
                    <p>Set up one-time payment</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
      <DeletePricingModal
        show={deletePricingList}
        onHide={() => setDeletePricingList(false)}
      />
      <UpdatePricingModal
        show={updatePricingdetailModal}
        onHide={() => setPricingDetailModal(false)}
      />
    </>
  );
}
function PreviewCourseModal(props) {
  const [courceNme, setCourceNme] = useState("Successful Habits");
  const [pricing, setPricing] = useState("One Time Payment");
  const [price, setPrice] = useState("790 GSD");
  const [status, setStatus] = useState("NO");
  const [condition3, setCondition3] = useState(true);
  const hideState = () => {
    setCondition3(!condition3);
  };
  const setprevent = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="p-5 teacher-curriculum-dashboard"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {condition3 ? (
          <Modal.Body className="px-4 pt-4 pb-0">
            <h4 className="text-center pt-3 pb-4">Preview of your course</h4>
            <div className="col-md-12 set-modal-footer-border pb-4">
              <Form onSubmit={setprevent}>
                <Form.Group>
                  <div className="row align-items-end">
                    <div className="col-2">
                      {" "}
                      <Form.Label>COURSE NAME</Form.Label>
                    </div>
                    <div className="col-10">
                      {" "}
                      <Form.Control
                        className="mt-1"
                        type="text"
                        value={courceNme}
                        onChange={(e) => setCourceNme(e.target.value)}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="row align-items-end">
                    <div className="col-2">
                      {" "}
                      <Form.Label>PRICING</Form.Label>
                    </div>
                    <div className="col-10">
                      {" "}
                      <Form.Control
                        className="mt-1"
                        name="password"
                        value={pricing}
                        onChange={(e) => setPricing(e.target.value)}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="row align-items-end">
                    <div className="col-2">
                      {" "}
                      <Form.Label>PRICE</Form.Label>
                    </div>
                    <div className="col-10">
                      {" "}
                      <Form.Control
                        className="mt-1"
                        name="password"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="row align-items-end">
                    <div className="col-2">
                      {" "}
                      <Form.Label>RECURRING</Form.Label>
                    </div>
                    <div className="col-10">
                      {" "}
                      <Form.Control
                        className="mt-1"
                        name="password"
                        type="text"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body className="pt-5 px-5 pb-0 teacher-curriculum-dashboard">
            <div className="col-md-12 set-modal-footer-border pb-4">
              <h4 className=" modal-text-color text-center ">
                Unpublishing a course will hide it in your Course Directory.
                Once unpublished, only students who are already enrolled, or
                those you directly send the course URL to, will be able to
                access the course.
              </h4>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer className="px-4 py-5 d-flex justify-content-center set-btn-styling ">
          <Button
            className="mr-4"
            onClick={() => {
              hideState();
            }}
          >
            Yes, publish course
          </Button>
          <Button onClick={props.onHide}>No, maybe later</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
function DeletePricingModal(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="p-5 teacher-curriculum-dashboard"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="pt-5 px-5 pb-0 teacher-curriculum-dashboard">
          <div className="col-md-12 set-modal-footer-border pb-4">
            <h3 className="text-center my-3">
              Are you sure you want to archive this pricing plan?
            </h3>
            <h5 className="text-center mt-3">
              Archiving this pricing plan will invalidate all of its outstanding
              coupons and deactivate all associated order bumps. It may also
              render checkout buttons on your sales page unusable.
            </h5>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-4 py-5 d-flex justify-content-center set-btn-styling ">
          <Button className="mr-4" onClick={props.onHide} data-dismiss="modal">
            Cancel
          </Button>
          <Button onClick={props.onHide}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function UpdatePricingModal(props) {
  const [name, setName] = useState("Free");
  const [subtitle, setSubtitle] = useState("The Wiz");

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="example-modal-sizes-title-sm"
      className="pricinglistModal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          <div className="d-flex justify-content-end">
            <h3 className="">Update Pricing Plan</h3>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <div className="row  justify-content-center ">
            <div className="col-12 ">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="name"
                  placeholder="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-12 ">
              <Form.Group>
                <Form.Label>Subtitle</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="subtitle"
                  placeholder="subtitle"
                  type="text"
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-12 ">
              <Form.Group className="ckeditor-modification">
                <Form.Label className="mb-2">Detailed Description</Form.Label>
                <CKEditor
                  className="mt-1"
                  editor={ClassicEditor}
                  data=""
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </Form.Group>
            </div>

            <div className="col-12 ">
              <p className="mb-1 mt-3">Plan Info</p>
              <p className="fs-13">
                You won't be able to edit this information. If you need to,
                create a new pricing plan and delete this one.
              </p>
            </div>
            <div className="col-12 ">
              <div className="card mt-2 mb-3">
                <div className="card-body">
                  <div className="">
                    <p className="mb-1 fw-600">Product</p>
                    <p className="fs-13">3333</p>
                  </div>
                  <div className="">
                    <p className="mb-1 fw-600">Price</p>
                    <p className="fs-13">0.000 SGD</p>
                  </div>
                  <div className="">
                    <p className="mb-1 fw-600">Is recurring? </p>
                    <p className="fs-13">No:</p>
                  </div>
                  <div className="">
                    <p className="mb-1 fw-600">Access to content expires?</p>
                    <p className="fs-13">No</p>
                  </div>
                  <div className="">
                    <p className="mb-1 fw-600">Check URL</p>
                    <p className="fs-13">
                      https://muzamal-ali-s-school.teachable.com/purchase?product_id=3534687
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer ClassName="edutech-footter-modal ">
        <Button variant=" edutech-admin-primary " onClick={props.onHide}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
