import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../../assets";
import { PATH } from "../../../../config";

export default function TeacherCreateCoaching() {
  var [showcardcolor, setcardcolor] = useState(0);
  function setbackground(val) {
    setcardcolor(val);
  }
  return (
    <div>
      <h2 className="font-weight-bold">Choose your category</h2>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 col-md-4 pt-2">
            <Card
              className={`cursor ${
                showcardcolor === 1
                  ? "edutech-coaching-card-color edutech-card-tittle-color"
                  : ""
              }  pt-3 mb-0 `}
              onClick={() => setbackground(1)}
            >
              <Card.Img
                variant="top"
                src={IMAGES.COACHGROUP}
                class=" mx-auto d-block  "
              />
              <Card.Body>
                <p
                  className={`${
                    showcardcolor === 1 ? " edutech-card-tittle-color" : ""
                  } edutech-card-course-text `}
                >
                  {" "}
                  One to one{" "}
                </p>
                <p className="px-3 mb-0 edutech-card-inner-coach-text text-center">
                  {" "}
                  Amet minim mollit non deserunt ullamco est sit...
                </p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-4 pt-2">
            <Card
              className={`cursor ${
                showcardcolor === 2
                  ? "edutech-coaching-card-color edutech-card-tittle-color"
                  : ""
              }  pt-3 mb-0 `}
              onClick={() => setbackground(2)}
            >
              <Card.Img
                variant="top"
                src={IMAGES.COACHGROUP}
                class=" mx-auto d-block  "
              />
              <Card.Body>
                <p
                  className={`${
                    showcardcolor === 2 ? " edutech-card-tittle-color" : ""
                  } edutech-card-course-text `}
                >
                  Group
                </p>
                <p className="px-3 mb-0 edutech-card-inner-coach-text text-center">
                  {" "}
                  Amet minim mollit non deserunt ullamco est sit...
                </p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-4 pt-2">
            <Card
              className={`cursor ${
                showcardcolor === 3
                  ? "edutech-coaching-card-color edutech-card-tittle-color"
                  : ""
              }  pt-3 mb-0 `}
              onClick={() => setbackground(3)}
            >
              <Card.Img
                variant="top"
                src={IMAGES.COACHCLASS}
                class=" mx-auto d-block  "
              />
              <Card.Body>
                <p
                  className={`${
                    showcardcolor === 3 ? " edutech-card-tittle-color" : ""
                  } edutech-card-course-text `}
                >
                  Class{" "}
                </p>
                <p className="px-3 mb-0 edutech-card-inner-coach-text text-center">
                  {" "}
                  Amet minim mollit non deserunt ullamco est sit...
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="mt-5 text-center">
          {showcardcolor === 1 ? (
            <Link to={PATH.TEACHERONETOONE}>
              <Button className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold float-none">
                Next
              </Button>
            </Link>
          ) : showcardcolor === 2 ? (
            <Link to={PATH.TEACHERGROUP}>
              <Button className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold float-none">
                Next
              </Button>
            </Link>
          ) : showcardcolor === 3 ? (
            <Link to={PATH.TEACHINGCLASS}>
              <Button className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold float-none">
                Next
              </Button>
            </Link>
          ) : (
            <Button className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold float-none">
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
