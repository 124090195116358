import React, { useEffect, useReducer, useState } from "react";
import { Card, Button, Form, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage, FieldError, Loader } from "../../../assets";
import { RiCloseCircleFill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import {
  CreateManualPriceRange,
  DeletePricingPlan,
  GetManualPricingRange,
  PricingPlan,
  PricingPlanByFilter,
  SelectPricingPlans,
} from "../../../redux/actions/pricingPlanAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
export default function AdminPricingPlan() {
  const [key, setKey] = useState("Student");
  return (
    <>
      <ToastContainer />
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="explore_tabs"
      >
        <Tab eventKey="Student" title="Student">
          <StudentPricingPlan />
        </Tab>
        <Tab eventKey="CourseProvider" title="Course Provider">
          <TeacherPricingPlan />
        </Tab>
      </Tabs>
    </>
  );
}
function StudentPricingPlan() {
  const { register, reset, errors, handleSubmit } = useForm();
  const [price, setPrice] = useState(null);
  const [bonusPercentage, setBonusPercentage] = useState(null);
  const dispatch = useDispatch();
  let auth = useAuth();
  function onSubmit(data) {
    let finalData = {
      price: parseInt(data.price),
      bonusPercentage: parseInt(data.bonusPercentage),
    };
    dispatch(
      PricingPlan(
        finalData,
        auth,
        Notificiation,
        moveToNext,
        AfterCreatePriceSuccess
      )
    );
  }
  let getPriceListing = useSelector((state) => state.pricingPlan);
  let pricingPlan = getPriceListing && getPriceListing.pricingPlanByFilter;
  useEffect(() => {
    dispatch(PricingPlanByFilter(auth));
  }, [dispatch]);
  const [pricingButtonSelect, setPricingButtonSelect] = useState([]);
  function PricingButtonSelected(id) {
    if (pricingButtonSelect.includes(id)) {
      for (let i = 0; i < pricingButtonSelect.length; i++) {
        if (pricingButtonSelect[i] === id) {
          pricingButtonSelect.splice(i, 1);
        }
      }
    } else if (pricingButtonSelect.length >= 3) {
      return toast.error("only 3 selected", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // return alert("only 3 selected");
    } else if (pricingButtonSelect.length < 3) {
      setPricingButtonSelect((prev) => [...prev, id]);
    }
  }
  // function onSubmit(data) {
  //   let finalData = {
  //     price: parseInt(data.price),
  //     bonusPercentage: parseInt(data.bonusPercentage),
  //   };
  //   dispatch(
  //     PricingPlan(
  //       finalData,
  //       auth,
  //       Notificiation,
  //       moveToNext,
  //       AfterCreatePriceSuccess
  //     )
  //   );
  // }
  function moveToNext(data) {
    dispatch(PricingPlanByFilter(auth));
  }
  function SelectByFilter(data) {
    let finalData2 = {
      pricingPlanId: pricingButtonSelect,
    };
    dispatch(
      SelectPricingPlans(
        finalData2,
        auth,
        Notificiation,
        AfterCreatePriceSuccess
      )
    );
  }
  const AfterCreatePriceSuccess = () => {
    setPrice();
    setBonusPercentage();
  };
  function Notificiation(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  return (
    <>
      <div className="container">
        <div
          className={`mb-4 card card-custom w-100 bg-dark admin-course-request`}
        >
          <div className="card-header ">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className=" font-weight-700 mb-0">Pricing Plan</h3>
            </div>
          </div>
        </div>
        <>
          {getPriceListing &&
            getPriceListing.pricingPlanByFilterLoading === true &&
            getPriceListing.pricingPlanByFilterSuccess === false &&
            getPriceListing.pricingPlanByFilterFailure === false && <Loader />}
          {getPriceListing &&
            getPriceListing.pricingPlanByFilterLoading === false &&
            getPriceListing.pricingPlanByFilterSuccess === false &&
            getPriceListing.pricingPlanByFilterFailure === true && (
              <ErrorMessage
                message={getPriceListing.pricingPlanByFilterError}
              />
            )}
          {getPriceListing &&
            getPriceListing.pricingPlanByFilterLoading === false &&
            getPriceListing.pricingPlanByFilterSuccess === true &&
            getPriceListing.pricingPlanByFilterFailure === false && (
              <div className="container subscription_plan">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Card>
                    <Card.Body>
                      <div className="row">
                        <div className="col-12 col-md-5">
                          <Form.Group className="mb-0">
                            <Form.Label>Price</Form.Label>
                            <Form.Control
                              className="font-weight-bold edutech-coaching-name-text"
                              name="price"
                              type="number"
                              value={price}
                              onChange={(e) => {
                                setPrice(e.target.value);
                              }}
                              style={{
                                borderColor:
                                  errors && errors.price ? "#a80000" : "",
                              }}
                              ref={register({ required: true })}
                            />
                            {errors.price && (
                              <FieldError message={"This Field is Required"} />
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-12 col-md-5">
                          <Form.Group className="mb-0">
                            <Form.Label>Bonus (%)</Form.Label>
                            <Form.Control
                              className="font-weight-bold edutech-coaching-name-text"
                              name="bonusPercentage"
                              type="number"
                              value={bonusPercentage}
                              onChange={(e) => {
                                setBonusPercentage(e.target.value);
                              }}
                              style={{
                                borderColor:
                                  errors && errors.bonusPercentage
                                    ? "#a80000"
                                    : "",
                              }}
                              ref={register({ required: true })}
                            />
                            {errors.bonusPercentage && (
                              <FieldError message={"This Field is Required"} />
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-12 col-md-2">
                          <Form.Group className="mb-0 save_btn text-center">
                            <Form.Label>Bonus (%)</Form.Label>
                            <Button type="submit">Save</Button>
                          </Form.Group>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <Card>
                        <Card.Body>
                          <div className="pricing_btn">
                            <div className="row">
                              {pricingPlan &&
                                pricingPlan.map((interest, index) => {
                                  return (
                                    <>
                                      <ButtonSection
                                        interest={interest}
                                        index={index}
                                        pricingButtonSelect={
                                          pricingButtonSelect
                                        }
                                        PricingButtonSelected={
                                          PricingButtonSelected
                                        }
                                      />
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="text-center">
                            <Button
                              type="button"
                              className="submit_btn"
                              onClick={() => {
                                SelectByFilter();
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Form>
              </div>
            )}
        </>
      </div>
      <PricingRangeForm />
    </>
  );
}
function PricingRangeForm(props) {
  const { register, errors, handleSubmit } = useForm();
  let getPriceListing = useSelector((state) => state.pricingPlan);
  let pricingPlanListing = getPriceListing?.getManualPriceRange;
  // const [minrange, setMinRange] = useState(
  //   pricingPlanListing?.minRange ? pricingPlanListing.minRange : null
  // );
  // const [maxrange, setMaxRange] = useState(
  //   pricingPlanListing.maxRange ? pricingPlanListing.maxRange : null
  // );
  const dispatch = useDispatch();
  let auth = useAuth();
  useEffect(() => {
    dispatch(GetManualPricingRange(auth));
  }, [dispatch]);
  function minMaxField(data) {
    // if (maxrange > minrange) {
    let finalData = {
      minRange: parseInt(data.minrange),
      maxRange: parseInt(data.maxrange),
    };
    dispatch(CreateManualPriceRange(finalData, auth, Notification, moveToNext));
    // } else {
    //   Notification2()
    // }
  }
  function moveToNext(data) {
    // dispatch(PricingPlanByFilter(auth))
  }
  function Notification(data) {
    toast.success(data, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  function Notification2() {
    toast.error("Max Value Should be greater than Min Value", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <div className="container subscription_plan">
      <div
        className={`mb-4 card card-custom w-100 bg-dark admin-course-request`}
      >
        <div className="card-header ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className=" font-weight-700 mb-0">Pricing Range</h3>
          </div>
        </div>
      </div>
      <Form onSubmit={handleSubmit(minMaxField)}>
        <Card>
          <Card.Body>
            <div className="row">
              <div className="col-12 col-md-5">
                <Form.Group className="mb-0">
                  <Form.Label>Min Price</Form.Label>
                  <Form.Control
                    className="font-weight-bold edutech-coaching-name-text"
                    name="minrange"
                    type="number"
                    defaultValue={pricingPlanListing?.minRange}
                    style={{
                      borderColor: errors && errors.minrange ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.minrange && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
              </div>
              <div className="col-12 col-md-5">
                <Form.Group className="mb-0">
                  <Form.Label>Max Price</Form.Label>
                  <Form.Control
                    className="font-weight-bold edutech-coaching-name-text"
                    name="maxrange"
                    type="number"
                    defaultValue={pricingPlanListing?.maxRange}
                    style={{
                      borderColor: errors && errors.maxrange ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.maxrange && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
              </div>
              <div className="col-12 col-md-2">
                <Form.Group className="mb-0 save_btn text-center">
                  <Form.Label>Range</Form.Label>
                  <Button type="submit">Save</Button>
                </Form.Group>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Form>
    </div>
  );
}
function TeacherPricingPlan() {
  const { register, handleSubmit } = useForm();
  function onSubmit(data) {
    let obj = {
      id: adminState.buttonList.length + 1,
      text: `${data.price}`,
      value: `${data.price}`,
    };
    let obj1 = {
      id: courseProviderState.buttonList.length + 1,
      text: `${data.bonus}`,
      value: `${data.bonus}`,
    };
    adminDispatch({ type: "ADD_BUTTON", payload: obj });
    courseProviderDispatch({ type: "ADD_BUTTON", payload: obj1 });
  }
  const [adminState, adminDispatch] = useReducer(
    adminBtnSetReducer,
    ADMIN_INITIAL_STATE
  );
  const [courseProviderState, courseProviderDispatch] = useReducer(
    adminBtnSetReducer,
    COURSE_PROVIDER_INITIAL_STATE
  );
  function submit() {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <div className="container">
      <div
        className={`mb-4 card card-custom w-100 bg-dark admin-course-request`}
      >
        <div className="card-header ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className=" font-weight-700 mb-0">Commission</h3>
          </div>
        </div>
      </div>
      <div className="container subscription_plan">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Card.Body>
              <div className="row">
                <div className="col-12 col-md-5">
                  <Form.Group className="mb-0">
                    <Form.Label>Admin (%)</Form.Label>
                    <Form.Control name="price" type="number" ref={register()} />
                  </Form.Group>
                </div>
                <div className="col-12 col-md-5">
                  <Form.Group className="mb-0">
                    <Form.Label>Course Provider (%)</Form.Label>
                    <Form.Control name="bonus" type="number" ref={register()} />
                  </Form.Group>
                </div>
                <div className="col-12 col-md-2">
                  <Form.Group className="mb-0 save_btn text-center">
                    <Form.Label>Bonus (%)</Form.Label>
                    <Button type="submit">Save</Button>
                  </Form.Group>
                </div>
              </div>
            </Card.Body>
          </Card>
          <div className="row">
            <div className="col-12 col-md-6">
              <Card>
                <Card.Body>
                  <div className="pricing_btn">
                    <div className="row">
                      {adminState &&
                        adminState.buttonList &&
                        adminState.buttonList.map((interest, index) => {
                          return (
                            <>
                              <ButtonSection1
                                interest={interest}
                                dispatch={adminDispatch}
                                index={index}
                              />
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="text-center">
                    <Button
                      type="button"
                      className="submit_btn"
                      onClick={() => submit()}
                    >
                      Submit
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-md-6">
              <Card>
                <Card.Body>
                  <div className="pricing_btn">
                    <div className="row">
                      {courseProviderState &&
                        courseProviderState.buttonList &&
                        courseProviderState.buttonList.map(
                          (interest, index) => {
                            return (
                              <>
                                <ButtonSection1
                                  interest={interest}
                                  dispatch={courseProviderDispatch}
                                  index={index}
                                />
                              </>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className="text-center">
                    <Button
                      type="button"
                      className="submit_btn"
                      onClick={() => submit()}
                    >
                      Submit
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
function ButtonSection1(props) {
  let { index } = props;
  const [buttonState, setButtonState] = useState(false);
  function listRemove(id) {
    props.dispatch({ type: "REMOVE_BUTTON", payload: id });
  }
  return (
    <>
      {props.interest.text ? (
        <div className="col-12 col-md-4">
          <Button
            type="button"
            key={index}
            onClick={() => setButtonState(!buttonState)}
            className={`cursor ${buttonState === true ? "active" : ""}`}
          >
            {props.interest.text ? props.interest.text : ""}%
            {buttonState === true ? (
              <RiCloseCircleFill
                onClick={(e) => listRemove(props.interest.id)}
              />
            ) : (
              ""
            )}
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
function ButtonSection(props) {
  const dispatch = useDispatch();
  const { reset } = useForm();
  let auth = useAuth();
  let { index } = props;
  const [buttonState, setButtonState] = useState(false);
  function listRemove(pricingPlanId) {
    // props.dispatch({ type: "REMOVE_BUTTON", payload: id });
    let finalData = {
      pricingPlanId: pricingPlanId,
    };
    dispatch(DeletePricingPlan(finalData, auth, moveToNext, Notificiation));
  }
  function moveToNext() {
    reset();
  }
  function Notificiation(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  useEffect(() => {
    setButtonState(
      props.pricingButtonSelect.includes(props.interest.pricingPlanId)
    );
  }, [props.pricingButtonSelect]);
  function buttonCheck() {
    if (buttonState) {
      setButtonState(false);
    }
  }
  return (
    <>
      <div className="col-12 col-md-3">
        <Button
          type="button"
          key={index}
          onClick={() => {
            props.PricingButtonSelected(props.interest.pricingPlanId);
            buttonCheck();
          }}
          className={`cursor ${
            buttonState === true || props.interest.isSelected === true
              ? "active"
              : ""
          }`}
        >
          {props.interest.price} ({props.interest.bonusPercentage}%)
          {buttonState === true ? (
            <RiCloseCircleFill
              onClick={(e) => listRemove(props.interest.pricingPlanId)}
            />
          ) : (
            ""
          )}
        </Button>
      </div>
    </>
  );
}
const ADMIN_INITIAL_STATE = {
  buttonList: [
    {
      id: 1,
      text: "5",
      value: "5",
    },
    {
      id: 2,
      text: "10",
      value: "10",
    },
  ],
};
const COURSE_PROVIDER_INITIAL_STATE = {
  buttonList: [
    {
      id: 1,
      text: "5",
      value: "5",
    },
    {
      id: 2,
      text: "10",
      value: "10",
    },
  ],
};

const adminBtnSetReducer = (intial_state, action) => {
  switch (action.type) {
    case "ADD_BUTTON":
      let button_list_for_add = intial_state.buttonList;
      if (button_list_for_add.includes(action.payload) === false) {
        button_list_for_add.push(action.payload);
      }
      return {
        ...intial_state,
        buttonList: button_list_for_add,
      };
    case "REMOVE_BUTTON":
      let button_list_for_remove = intial_state.buttonList;
      for (let i = 0; i < button_list_for_remove.length; i++) {
        if (button_list_for_remove[i].id === action.payload) {
          button_list_for_remove.splice(i, 1);
        }
      }
      return {
        ...intial_state,
        buttonList: button_list_for_remove,
      };
    default:
      return intial_state;
  }
};
