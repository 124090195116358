import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import { useHistory, useLocation } from "react-router-dom";
import { FieldError, Loader } from "../../../../../assets";
import { uploadImage } from "../../../../../redux/api";

export default function AddVideo(props) {

  let history = useHistory()
  const location = useLocation()
  const CoursesData = props?.CoursesData;
  const [lectureBanner, setLectureBanner] = useState(
    CoursesData && CoursesData.lectureBanner ? CoursesData.lectureBanner : ""
  );
  const [lectureVideo, setLectureVideo] = useState(
    CoursesData && CoursesData.lectureVideo ? CoursesData.lectureVideo : ""
  );

  const [showfileNameError, setShowFileNameError] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  // function onSubmit() {
  //   if (lectureVideo == "" || lectureVideo == undefined) {
  //     setShowFileNameError(true)
  //   }
  //   else {
  //     props.TabChanges("left-tabs-example-tab-AddSummary");

  //   }
  // }
  function onSubmit() {
    CoursesData.lectureBanner = lectureBanner
    CoursesData.lectureVideo = lectureVideo
    if (lectureVideo) {
      props.setSelectedView(1)
    }
    // moveToNext(data );
  }
  function moveToNext(finaldata) {
    // if (lectureVideo == "" || lectureVideo == undefined) {
    //   setShowFileNameError(true)
    // }
    // else {
    //   props.TabChanges("left-tabs-example-tab-AddSummary");
    //   finaldata= {finaldata}
    //   lectureVideo={lectureVideo}
    // }
    if (lectureVideo) {
      props.TabChanges("left-tabs-example-tab-AddSummary");
      history.push({
        finaldata: finaldata,
      });
    }
  }
  const onChangeVideoUploader = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    if (file) {
      setLectureVideo(!lectureVideo);
      uploadImage(formData).then(
        (response) => {
          console.log("respi", response);
          if (response.data.succeeded === true) {
            setLectureVideo(response.data.data);
          }
        },
        (error) => { }
      );
    }
  };
  return (
    <>
      <h3 className="font-weight-bold mb-3">File uploader</h3>
      <p className="font-weight-bold f-16 mb-5">
        Use the file uploader to upload video into your lecture.
      </p>

      <Form onSubmit={handleSubmit(onSubmit)} name="myform" id="myform" className="text-left">
        {lectureVideo === true || lectureVideo === false ? (
          <Loader />
        ) : (
          <>
            <ReactPlayer
              url={lectureVideo ? lectureVideo : ""}
              width="100%"
              height="100%"
              className={` ${lectureVideo !== null ? "video_show_set" : ""
                }`}
            />
            {/* {
                        setvideoFile(null)} */}
            <button type="button" className="upload_video_btn">
              <input
                type="file"
                name="video"
                placeholder="Uploading......"
                data-browse="UPLOAD"
                className="upload_video_input"
                accept="video/*"
                onChange={(e) => {
                  onChangeVideoUploader(e);
                }}
              />
              Upload file
            </button>
          </>
        )}
        {/* <div className="  mt-4">
          <VideoInput width={400} height={300} setShowFileNameError={setShowFileNameError} setLectureVideo={setLectureVideo} />
          {
            showfileNameError === true ?
              <FieldError message={"This Field is Required"} />
              : null
          }

        </div> */}
        <div className="d-flex align-items-center mt-5">
          <p className="calendar-fs mb-0 ml-2">Add Banner :</p>
          <div className="col-sm-8 col-xl-6">
            <input
              type="text"
              name="lectureBanner"
              className="form-control"
              id="zoom"
              placeholder="Add banner tittle"
              value={lectureBanner}
              onChange={(e) => {
                setLectureBanner(e.target.value);
              }}
              style={{
                borderColor: errors && errors.lectureBanner ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {errors.lectureBanner && (
              <FieldError message={"This Field is Required"} />
            )}
          </div>
        </div>
        <div className="mt-5">
          <Button className="edutech-curriculum-action-button edutech-pull-right my-2 font-weight-bold" type="submit">
            Next
          </Button>
        </div>
      </Form>
    </>);
}
function VideoInput(props) {
  const { height } = props;
  const inputRef = React.useRef();
  const [source, setSource] = React.useState();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);
    props.setLectureVideo(url);
  };
  const handleChoose = (event) => {
    inputRef.current.click();
  };
  return (
    <div className="VideoInput">
      <input
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        accept=".mov,.mp4"
      />
      {!source && (
        <button onClick={handleChoose} className="video-btn-style" type="button">
          <span> Click to Browse your video</span>
        </button>
      )}
      {source && (
        <video
          className="VideoInput_video "
          width="100%"
          height={height}
          controls
          src={source}
        />
      )}
      <div className="">{source || ""}</div>
    </div>
  );
}
