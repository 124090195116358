import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { IMAGES } from "../../assets/images";
import {  toast } from "react-toastify";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PATH } from "../../config";
import { BsCoin } from "react-icons/bs";
import { ImInfinite } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { GetCredits, PurchaseCourse } from "../../redux/actions";
export default function MyCoursePreview() {
  let history = useHistory();
  const location = useLocation();
  const RelatedCourses = location?.state?.relatedCourseData;
  const [addToCart, setAddToCart] = useState(true);
  function reloadPage() {
    window.location.reload();
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="font-weight-bold">
          <u>Course Preview</u>
        </h2>
      </div>
      <div className="row mt-5 edutech-sm-mt-5">
        <div className="col-12 col-md-8 mb-md-2">
          <Card className=" p-4">
            <div className="embed-responsive embed-responsive-16by9 video-height">
              <iframe
                title="dashboard-video"
                className="embed-responsive-item"
                src="//www.youtube.com/embed/zB4I68XVPzQ"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </div>
            <div className="text-capitilize b-500 px-3 py-2 pt-3 mt-4">
              <div className="d-flex justify-content-between">
                <h3 className="mb-3 font-weight-bold">Course Title</h3>
                <div>
                  <p className="firstpara text-left mt-0 pt-0">Course Price</p>
                  <span className="  mb-0  text-muted">Course Discount</span>
                </div>
              </div>

              <h5 className="mb-3">COURSE DESCRIPTION</h5>
              <p className="text-justify mb-0">Course Description</p>
            </div>
          </Card>
          <Card className="mt-3 px-4 pt-4 edutech-sm-mt-5">
            <h3 className="mb-4">Other Relevant Courses</h3>
            {history.location.pathname === PATH.EXPLORE_COURSE_PREVIEW && (
              <div className="row">
                <div className="col-12 col-md-4 ">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        alt="imgage"
                        src={IMAGES.VENTURE}
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 ">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        src={IMAGES.VENTURE}
                        alt="imgage"
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 ">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        src={IMAGES.VENTURE}
                        alt="imgage"
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {history.location.pathname === PATH.Dashboard_COURSE_PREVIEW && (
              <div className="row">
                <div className="col-12 col-md-4 mb-2">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        src={IMAGES.VENTURE}
                        alt="imgage"
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 mb-2">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        src={IMAGES.VENTURE}
                        alt="imgage"
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        src={IMAGES.VENTURE}
                        alt="imgage"
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {history.location.pathname === PATH.COURSE_PREVIEW && (
              <div className="row">
                <div className="col-12 col-md-4 mb-2">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        src={IMAGES.VENTURE}
                        alt="imgage"
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 mb-2">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        src={IMAGES.VENTURE}
                        alt="imgage"
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <div className="card" onClick={() => reloadPage()}>
                    {" "}
                    <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                      <img
                        className="img-fluid embed-responsive-item"
                        src={IMAGES.VENTURE}
                        alt="imgage"
                      />
                    </div>
                    <div className="pl-3 my-2">
                      <p className="firstpara text-left m-0">
                        {" "}
                        Venture Capital
                      </p>
                      <p className="text-muted text-left mb-0"> Company Name</p>
                      <p className="firstpara text-left m-0 p-0">
                        {" "}
                        $9.99{" "}
                        <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                          $24.99
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
        {addToCart === true ? (
          <PurchaseBeginCourseData setAddToCart={setAddToCart} />
        ) : (
          <PurchaseAddtoCart />
        )}
      </div>
    </>
  );
}
function PurchaseBeginCourseData(props) {
  let history = useHistory();
  let location = useLocation();
  const [beginCourse, setBeginCourse] = useState(
    location && location.state && location.state.id ? location.state.id : null
  );
  return (
    <div className="col-12 col-md-4 mb-md-2">
      <Card className="px-4 py-2 pt-3">
        <h3 className="f-20 font-weight-bold">About Course Provider</h3>
        <hr className="mt-0" />
        <div className="d-flex align-items-center">
          <span className="user-img mt-0">
            <img
              className="rounded-circle edutech-speaker-img"
              src={IMAGES.PROFILELOGO}
              width="31"
              alt="Ryan Taylor"
            />
          </span>
          <div>
            <h5 className="pl-4 font-weight-bold mb-0 py-2">Provider Name</h5>
          </div>
        </div>
        <div className="mt-4">
          <h5 className="font-weight-bold">Details :-</h5>
          <p className="edutech-speaker-text-detail mb-0">Description</p>
        </div>
      </Card>
      <Card className="px-4 py-2 pt-3">
        <h3 className="f-20">
          <b>More about the course</b>
        </h3>
        <hr className="mt-0" />
        <table className="table table-borderless ">
          <tr className="f-13">
            <td>
              <b>Duration:</b>
            </td>
            <td>19 minutes</td>
          </tr>
          <tr className="f-13">
            <td>
              <b>Number of Videos:</b>
            </td>
            <td>6 videos</td>
          </tr>
          <tr className="f-13">
            <td>
              <b>Resources:</b>
            </td>
            <td>Available</td>
          </tr>
          <tr className="f-13">
            <td>
              <b>Transcript:</b>
            </td>
            <td>English & Chinese</td>
          </tr>
        </table>
      </Card>
      <Card className="px-4 py-2 pt-3">
        <h3 className="f-20">
          <b>More actions</b>
        </h3>
        <hr className="mt-0" />
        <table className="table table-borderless table-responsive"></table>
        {history.location.pathname === PATH.EXPLORE_COURSE_PREVIEW && (
          <Button
            className="edutech-action-button my-2 border-0 w-100"
            onClick={() => props.setAddToCart(false)}
          >
            Begin course
          </Button>
        )}
        {history.location.pathname === PATH.COURSE_PREVIEW && (
          <>
            {beginCourse === 1 ? (
              <Link to={PATH.COURSES_BREAKTHROUGH}>
                <Button className="edutech-action-button my-2 border-0 w-100">
                  Begin Course
                </Button>
              </Link>
            ) : (
              <Button
                className="edutech-action-button my-2 border-0 w-100"
                onClick={() => props.setAddToCart(false)}
              >
                Begin Course
              </Button>
            )}
          </>
        )}
        {history.location.pathname === PATH.Dashboard_COURSE_PREVIEW && (
          <Button
            className="edutech-action-button my-2 border-0 w-100"
            onClick={() => props.setAddToCart(false)}
          >
            Begin Course
          </Button>
        )}
      </Card>
    </div>
  );
}
function PurchaseAddtoCart(props) {
  let dispatch = useDispatch();
  let auth = useAuth();
  let getCoachingCredits = useSelector((state) => state.account);
  useEffect(() => {
    dispatch(GetCredits(auth));
    document.title = `Credits || EduTech`;
  }, [dispatch]);
  let data = getCoachingCredits && getCoachingCredits.getCredits;
  function BuyNowCoaching() {
    let finalData = {
      courseId: props.CoursessssData.courseId,
      price: props.CoursessssData.price - props.CoursessssData.discountedPrice,
      // price: props.CoursessssData.price,
    };
    dispatch(PurchaseCourse(finalData, auth, AfterBuyCoaching, notification));
  }
  function AfterBuyCoaching() {
    return dispatch(GetCredits(auth));
  }
  function notification(data, condition) {
    if (condition === "success") {
      toast.success(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }
  return (
    <div className="col-12 col-sm-12 col-md-4 mb-md-2 add_to_cart">
      <Card>
        <div>
          <div className="add_cart_price_section">
            <h2 className="text-white d-flex justify-content-center align-items-center">
              Credit Left: {data.availableCredit} <BsCoin className="pl-2" />
            </h2>
          </div>
          <div className="course_include_section">
            <h3>This coaching includes:</h3>
            <p>
              <ImInfinite />
              Full lifetime access
            </p>
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            BuyNowCoaching();
          }}
          className="pricing_plan_btn mt-3"
        >
          Buy now
        </button>
      </Card>
    </div>
  );
}
