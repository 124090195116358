import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { useForm } from "react-hook-form";
import {
  FieldError,
  formatInMintAndSec,
  getCountryTimeZone,
  getTodayDateWithTimeFormat,
} from "../../../../../assets";
import { FIXEDDATE, RECURRENTDATE } from "../../../../../assets/LookUpdata";
const moment = require("moment-timezone");

export default function SelectDate(props) {
  const timeZone = getCountryTimeZone();
  const { Coaching, setCoaching } = props;
  const [selectedView, setSelectedView] = useState(0);
  const [dateType, setDateType] = useState(
    Coaching && Coaching.dateType ? Coaching.dateType : null
  );
  const [showinputfield, setinputfield] = useState(
    dateType && dateType === FIXEDDATE ? true : false
  );
  const [showinputfield1, setinputfield1] = useState(
    dateType && dateType === RECURRENTDATE ? true : false
  );
  const [showinputfield2, setinputfield2] = useState(
    showinputfield1 && showinputfield1 ? true : false
  );
  const { register, handleSubmit, errors } = useForm();
  const [fixedDate, setFixedDate] = useState(
    Coaching && Coaching.fixedDate ? Coaching.fixedDate : new Date()
  );

  const [startDate, SetStartDate] = useState(
    Coaching && Coaching.startRecurrentDate
      ? Coaching.startRecurrentDate
      : new Date()
  );
  console.log("s", startDate);

  const [endDate, SetEndDate] = useState(
    Coaching && Coaching.endRecurrentDate
      ? Coaching.endRecurrentDate
      : new Date()
  );
  console.log("C", endDate, startDate);

  var CreateEndSlotdate = moment(new Date((endDate + "").split("T")[0])).format(
    "YYYY-MM-DD"
  );
  var CreateStartSlotdate = moment(
    new Date((startDate + "").split("T")[0])
  ).format("YYYY-MM-DD");
  var CreateFixedSlotdate = moment(
    new Date((fixedDate + "").split("T")[0])
  ).format("YYYY-MM-DD");

  let fixedTimeWithZone = moment(
    (fixedDate + "").split("T")[1],
    "h:mm a"
  ).format("HH:mm:ssZ");

  let startTimeWithZone = moment(
    (startDate + "").split("T")[1],
    "h:mm a"
  ).format("HH:mm:ssZ");

  let endTimeWithZone = moment((endDate + "").split("T")[1], "h:mm a").format(
    "HH:mm:ssZ"
  );

  let finalFixedDateTime = moment(
    CreateFixedSlotdate + " " + fixedTimeWithZone
  );
  let finalStartDateTime = moment(
    CreateStartSlotdate + " " + startTimeWithZone
  );
  let finalEndDateTime = moment(CreateEndSlotdate + " " + endTimeWithZone);
  const COACHING_DAYS = [
    {
      Id: 100,
      type: "Daily",
    },
    {
      Id: 101,
      type: "Monthly",
    },
    {
      Id: 102,
      type: "Weekly",
    },
  ];

  function onSubmit() {
    if (!(dateType === undefined || dateType === null)) {
      if (dateType === RECURRENTDATE) {
        if (showinputfield2 !== false) {
          if (dateType === FIXEDDATE) {
            Coaching.dateType = dateType;
            Coaching.fixedDate = moment(
              new Date(finalFixedDateTime).toISOString()
            ).format("YYYY-MM-DDTHH:mm:ss");
            Coaching.isRecurrent = false;
            Coaching.coachingRecurrenceId = 103;
            Coaching.startRecurrentDate = null;
            Coaching.endRecurrentDate = null;
          } else {
            Coaching.dateType = dateType;
            Coaching.fixedDate = null;
            Coaching.isRecurrent = true;
            Coaching.coachingRecurrenceId = selectedView;
            Coaching.startRecurrentDate = moment(
              new Date(finalStartDateTime).toISOString()
            ).format("YYYY-MM-DDTHH:mm");
            Coaching.endRecurrentDate = moment(
              new Date(finalEndDateTime).toISOString()
            ).format("YYYY-MM-DDTHH:mm");
          }
          setCoaching(Coaching);
          props.changeView(4);
          props.handleNext(4);
        }
      } else {
        if (dateType === FIXEDDATE) {
          Coaching.dateType = dateType;
          Coaching.fixedDate = moment(
            new Date(finalFixedDateTime).toISOString()
          ).format("YYYY-MM-DDTHH:mm");
          Coaching.isRecurrent = false;
          Coaching.coachingRecurrenceId = 103;
          Coaching.startRecurrentDate = null;
          Coaching.endRecurrentDate = null;
        } else {
          Coaching.dateType = dateType;
          Coaching.fixedDate = null;
          Coaching.isRecurrent = true;
          Coaching.coachingRecurrenceId = selectedView;
          Coaching.startRecurrentDate = moment(
            new Date(finalStartDateTime).toISOString()
          ).format("YYYY-MM-DDTHH:mm");
          Coaching.endRecurrentDate = moment(
            new Date(finalEndDateTime).toISOString()
          ).format("YYYY-MM-DDTHH:mm");
        }
        setCoaching(Coaching);
        props.changeView(4);
        props.handleNext(4);
      }
    }
  }
  function goBack() {
    Coaching.dateType = 0;
    Coaching.fixedDate = null;
    Coaching.startRecurrentDate = null;
    Coaching.endRecurrentDate = null;
    props.changeView(2);
    props.handleBack(2);
  }
  console.log("selectDate", Coaching);
  return (
    <div>
      <h2
        className="d-flex align-items-center cursor font-weight-bold"
        onClick={() => goBack()}
      >
        <BsArrowLeft size={35} className="mr-2" /> Select a start date
      </h2>
      <hr className="edutech-hr-colors" />
      <p className="mt-4 font-weight-bold">What would you start date?</p>
      <Card className="p-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className=" d-flex">
            <input
              type="radio"
              id="fixeddate"
              name="fav_language"
              value="fixeddate"
              checked={showinputfield && showinputfield ? true : false}
              className="edutech-coaching-checkbox"
              onClick={() => {
                setinputfield(true);
                setinputfield1(false);
                setinputfield2(false);
                setDateType(FIXEDDATE);
              }}
            />{" "}
            <label for="fixeddate" className="ml-3 font-weight-bold cursor">
              {" "}
              On a fixed date
              <br />
              <label className="edutech-second-label">
                {" "}
                This is useful for recurring group classes and enrollment
                periods with firm start dates.
              </label>
            </label>
          </div>
          {showinputfield === true ? (
            <div className="col-sm-12 col-xl-10 d-flex">
              <div className="ml-4">
                <Form.Control
                  className="mt-1"
                  name="fixedDate"
                  type="datetime-local"
                  defaultValue={fixedDate}
                  // min="2022-03-29T00:00"
                  min={getTodayDateWithTimeFormat()}
                  onChange={(e) => {
                    setFixedDate(e.target.value);
                  }}
                  style={{
                    borderColor: errors && errors.fixedDate ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.fixedDate && (
                  <FieldError message={"This Field is Required"} />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className=" d-flex mt-3">
            {" "}
            <input
              type="radio"
              id="enrollmentdate"
              name="fav_language"
              value="enrollmentdate"
              checked={showinputfield1 && showinputfield1 ? true : false}
              className="edutech-coaching-checkbox"
              onClick={() => {
                setinputfield1(true);
                setinputfield(false);
                setinputfield2(false);
                setDateType(RECURRENTDATE);
              }}
            />{" "}
            <label
              for="enrollmentdate"
              className="ml-3 font-weight-bold cursor mb-0"
            >
              Recurrent Date
              <br />
              <label className="edutech-second-label">
                {" "}
                This is useful for 1:1 coaching where client enrollment is
                ongoing.
              </label>
            </label>
          </div>
          {showinputfield1 === true ? (
            <>
              <div className="col-sm-12 col-xl-10 d-flex "></div>
              <div className="col-sm-12 col-xl-10 d-flex">
                <div
                  className="d-flex align-items-center mr-3 ml-4 cursor"
                  onClick={() => setinputfield2(true)}
                >
                  <input
                    type="radio"
                    name="recurrent"
                    checked={showinputfield2 && showinputfield2 ? true : false}
                    className="edutech-coaching-checkbox"
                  />{" "}
                  <label className="ml-2 mb-0 font-weight-bold">Yes</label>
                </div>
                <div
                  className="d-flex align-items-center cursor"
                  onClick={() => setinputfield2(false)}
                >
                  <input
                    type="radio"
                    name="recurrent"
                    className="edutech-coaching-checkbox"
                  />{" "}
                  <label className="ml-2 mb-0 font-weight-bold ">No</label>
                </div>
              </div>
              {showinputfield2 === true ? (
                <>
                  <div className="col-sm-12 col-xl-10 d-flex mt-2">
                    <div className="ml-4">
                      <label className="mb-1 fw-600"> Start date</label>
                      <Form.Control
                        className="mt-1"
                        name="startRecurrentDate"
                        type="datetime-local"
                        defaultValue={startDate}
                        min={getTodayDateWithTimeFormat()}
                        onChange={(e) => {
                          SetStartDate(e.target.value);
                        }}
                        style={{
                          borderColor:
                            errors && errors.startRecurrentDate
                              ? "#a80000"
                              : "",
                        }}
                        ref={register({ required: true })}
                      />
                      {errors.startRecurrentDate && (
                        <FieldError message={"This Field is Required"} />
                      )}
                    </div>
                    <div className="ml-4">
                      <label className="mb-1 fw-600"> End date</label>
                      <Form.Control
                        className="mt-1"
                        name="endRecurrentDate"
                        type="datetime-local"
                        defaultValue={endDate}
                        min={getTodayDateWithTimeFormat()}
                        onChange={(e) => {
                          SetEndDate(e.target.value);
                        }}
                        style={{
                          borderColor:
                            errors && errors.endRecurrentDate ? "#a80000" : "",
                        }}
                        ref={register({ required: true })}
                      />
                      {errors.endRecurrentDate && (
                        <FieldError message={"This Field is Required"} />
                      )}
                    </div>
                  </div>
                  {COACHING_DAYS.map((item, index) => {
                    return (
                      <div className="d-flex day_wise_set">
                        <div
                          className={`left-modal-borders ${
                            selectedView === item.Id ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedView(item.Id);
                          }}
                        >
                          <p className="mb-0 number">{item.type}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          <br />
          <div className="mt-5">
            <Button
              type="submit"
              className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold"
            >
              Next
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}
