import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { FieldError } from "../../../assets";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import {
  CreatePermission,
  GetMenuPermissionById,
  ManageUsersAdmin,
} from "../../../redux/actions";
import { createPermission } from "../../../redux/api";

export default function RoleAndPermission(props) {
  const { register, handleSubmit, errors } = useForm();
  const [categoryText, setCategoryText] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [viewCourseCheck, setViewCourseCheck] = useState(false);
  const [coursesCheck, setCoursesCheck] = useState(false);
  const [coachingCheck, setCoachingCheck] = useState(false);
  const [addCoursesCheck, setAddCoursesCheck] = useState(false);
  const [viewCoachiingCheck, setViewCoachiingCheck] = useState(false);
  const [addCoachingCheck, setAddCoachingCheck] = useState(false);
  const [editCoachingCheck, setEditCoachingCheck] = useState(false);
  const [pricingPlanCheck, setPricingPlanCheck] = useState(false);
  const [paymentDetailCheck, setPaymentDetailCheck] = useState(false);
  const [manageUserCheck, setManageUserCheck] = useState(false);
  const [categoryRequestCheck, setCategoryRequestCheck] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(ManageUsersAdmin(4));
  }, [dispatch]);

  let user = useSelector((state) => state.admin);

  let data = user && user.manageUser;

  const coursesCheckData = {
    sideBarMenuId: 21,
    isViewPermission: viewCourseCheck,
    isAddPermission: addCoursesCheck,
  };

  const coachingCheckData = {
    sideBarMenuId: 22,
    isViewPermission: viewCoachiingCheck,
    isAddPermission: addCoachingCheck,
    isEditPermission: editCoachingCheck,
  };

  const pricingPlanCheckData = {
    sideBarMenuId: 23,
  };

  const paymentDetailCheckData = {
    sideBarMenuId: 24,
  };

  const manageUserCheckData = {
    sideBarMenuId: 25,
  };

  const categoryRequestCheckData = {
    sideBarMenuId: 26,
  };

  let auth = useAuth();

  function onSubmit(data) {
    let permissionArray = [];
    if (coursesCheck === true) {
      permissionArray.push(coursesCheckData);
    }
    if (coachingCheck === true) {
      permissionArray.push(coachingCheckData);
    }
    if (pricingPlanCheck === true) {
      permissionArray.push(pricingPlanCheckData);
    }
    if (paymentDetailCheck === true) {
      permissionArray.push(paymentDetailCheckData);
    }
    if (manageUserCheck === true) {
      permissionArray.push(manageUserCheckData);
    }
    if (categoryRequestCheck === true) {
      permissionArray.push(categoryRequestCheckData);
    }
    let finaldata = {
      userId: categoryId,
      permissionDTO: permissionArray,
    };
    dispatch(CreatePermission(finaldata, auth, Notification, moveToNext));
  }

  function moveToNext() {
    dispatch(GetMenuPermissionById(categoryId, auth));
  }

  function getUserFucntion(id) {
    dispatch(GetMenuPermissionById(id, auth));
  }

  function Notification(data, condition) {
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }

  let rolePermission = useSelector((state) => state.permission);

  useEffect(() => {
    debugger;
    rolePermission?.getMenuPermissionById?.data?.map((item, i) => {
      if (item?.sideBarMenuId === 21) {
        setCoursesCheck(item?.sideBarMenuId === 21 || false);
        setAddCoursesCheck(item?.isAddPermission || false);
        setViewCourseCheck(item?.isViewPermission || false);
      }
      if (item?.sideBarMenuId === 22) {
        setCoachingCheck(item?.sideBarMenuId === 22 || false);
        setAddCoachingCheck(item?.isAddPermission || false);
        setViewCoachiingCheck(item?.isViewPermission || false);
        setEditCoachingCheck(item?.isEditPermission || false);
      }
      if (item?.sideBarMenuId === 23) {
        setPricingPlanCheck(item?.sideBarMenuId === 23 || false);
      }
      if (item?.sideBarMenuId === 24) {
        setPaymentDetailCheck(item?.sideBarMenuId === 24 || false);
      }
      if (item?.sideBarMenuId === 25) {
        setManageUserCheck(item?.sideBarMenuId === 25 || false);
      }
      if (item?.sideBarMenuId === 26) {
        setCategoryRequestCheck(item?.sideBarMenuId === 26 || false);
      }
    });
  }, [rolePermission?.getMenuPermissionById?.data]);

  return (
    <>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <Form.Group className="dropdown_bg_grey parent-role-permission height_adjust_dropdown role-parent w-25">
              <Form.Label className="fw-700 f-20">Role</Form.Label>
              <Dropdown alignRight>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="text-left"
                  value={categoryText}
                  ref={register({ required: true })}
                  onChange={(e) => {
                    setCategoryText(e.target.value);
                  }}
                  style={{
                    borderColor: errors && errors.categoryText ? "#a80000" : "",
                  }}
                >
                  {categoryText ? categoryText : "Select "}
                  <IoMdArrowDropdown />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow search_field">
                  {data.map((item, index) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          setCategoryText(item.fullName);
                          setCategoryId(item.userId);
                          getUserFucntion(item.userId);
                        }}
                        key={index}
                      >
                        {item.fullName}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              {errors.categoryText && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
          </div>
        </div>
        {categoryId > 0 ? (
          <>
            <div className=" mb-4">
              <input
                type="checkbox"
                name="courses"
                ref={register({})}
                id="0"
                value="courses"
                onChange={(e) => setCoursesCheck(e.target.checked)}
                checked={coursesCheck}
              />
              <span className="fw-700 f-20"> Courses </span>
            </div>
            <div className="row mb-3 mx-3">
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    name="viewCourse"
                    ref={register({})}
                    disabled={coursesCheck ? false : true}
                    id="1"
                    value="viewCourse"
                    onChange={(e) => setViewCourseCheck(e.target.checked)}
                    checked={viewCourseCheck}
                  />
                  <span className="pl-2"> View Courses </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    checked={addCoursesCheck}
                    name="addCoursesCheck"
                    ref={register({})}
                    disabled={coursesCheck ? false : true}
                    id="1"
                    value="addCoursesCheck"
                    onChange={(e) => setAddCoursesCheck(e.target.checked)}
                  />
                  <span className="pl-2"> Add Courses </span>
                </div>
              </div>
            </div>
            <div className=" mb-4">
              <input
                type="checkbox"
                name="coaching"
                ref={register({})}
                id="11"
                value="coaching"
                onChange={(e) => setCoachingCheck(e.target.checked)}
                checked={coachingCheck}
              />
              <span className="fw-700 f-20"> Coaching </span>
            </div>
            <div className="row mb-3 mx-3">
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    checked={viewCoachiingCheck}
                    name="viewCoachiingCheck"
                    disabled={coachingCheck ? false : true}
                    ref={register({})}
                    id="1"
                    value="viewCoachiingCheck"
                    onChange={(e) => setViewCoachiingCheck(e.target.checked)}
                  />
                  <span className="pl-2"> View Coaching </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    checked={addCoachingCheck}
                    name="addCoachingCheck"
                    disabled={coachingCheck ? false : true}
                    ref={register({})}
                    id="1"
                    value="addCoachingCheck"
                    onChange={(e) => setAddCoachingCheck(e.target.checked)}
                  />
                  <span className="pl-2"> Add Coaching </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    checked={editCoachingCheck}
                    disabled={coachingCheck ? false : true}
                    name="editCoachingCheck"
                    ref={register({})}
                    id="1"
                    value="editCoachingCheck"
                    onChange={(e) => setEditCoachingCheck(e.target.checked)}
                  />
                  <span className="pl-2"> Edit Coaching </span>
                </div>
              </div>
            </div>
            {/* <div className=" mb-4">
              <input
                type="checkbox"
                name="others"
                ref={register({})}
                id="10"
                value="others"
                onChange={(e) => setOthersCheck(e.target.checked)}
                checked={othersCheck}
              />
              <span className="fw-700 f-20"> Others </span>
            </div className="fw-700 f-20"> */}
            <p className="fw-700 f-20">Others</p>
            <div className="row mb-3 mx-3">
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    checked={pricingPlanCheck}
                    name="pricingPlanCheck"
                    ref={register({})}
                    id="1"
                    value="pricingPlanCheck"
                    onChange={(e) => setPricingPlanCheck(e.target.checked)}
                  />
                  <span className="pl-2"> Pricing Plan </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    checked={paymentDetailCheck}
                    name="paymentDetailCheck"
                    ref={register({})}
                    id="1"
                    value="paymentDetailCheck"
                    onChange={(e) => setPaymentDetailCheck(e.target.checked)}
                  />
                  <span className="pl-2"> Payment detail </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    checked={manageUserCheck}
                    name="manageUserCheck"
                    ref={register({})}
                    id="1"
                    value="manageUserCheck"
                    onChange={(e) => setManageUserCheck(e.target.checked)}
                  />
                  <span className="pl-2"> Manage User </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="">
                  <input
                    type="checkbox"
                    checked={categoryRequestCheck}
                    name="categoryRequestCheck"
                    ref={register({})}
                    id="1"
                    value="categoryRequestCheck"
                    onChange={(e) => setCategoryRequestCheck(e.target.checked)}
                  />
                  <span className="pl-2"> Category Request </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <Button
                className="btn-admin-create-faq"
                variant="primary"
                type="submit"
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <p className="text-center text-danger fw-500 f-20 mt-5">
            Please select the role
          </p>
        )}
      </Form>
    </>
  );
}
