import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../../../../config";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FaEdit, FaEye } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { ErrorMessage, Loader } from "../../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCoachingList,
  PublishCoaching,
} from "../../../../../redux/actions/coachingAction";
import { useAuth } from "../../../../../Navigation/Auth/ProvideAuth";
import MyDataTable from "../../../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
export default function CoachingList() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let getListing = useSelector((state) => state.coaching);
  useEffect(() => {
    dispatch(GetCoachingList(auth));
    document.title = `Coaching || EduTech`;
  }, [dispatch]);
  let data = getListing && getListing.getCoaching;
  let columns = [
    {
      dataField: "coachingId",
      text: "Coaching ID",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "companyTitle",
      text: "Company Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return (
          <span>
            {row.status !== "UNPUBLISHED" ? (
              <span className="publish-btn-color">
                {row.status !== "UNPUBLISHED" ? "PUBLISHED" : ""}{" "}
              </span>
            ) : (
              <span className="unpublish-btn-color">
                {" "}
                {row.status !== "UNPUBLISHED" ? "" : "UNPUBLISHED"}
              </span>
            )}
          </span>
        );
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return <span>{row.price === 0 ? "FREE" : row.price}</span>;
      },
    },
    {
      dataField: "more",
      text: "Actions",
      classes: "align-middle table-right-hover-class edutech",
      formatter: (cell, row) => {
        return (
          <>
            <DropDownData row={row} />
          </>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      <ToastContainer />
      {auth?.edu_tech_user?.permissionDTO?.[1]?.isAddPermission === false ? (
        ""
      ) : (
        <>
          <div className="teacher-curriculum-dashboard ">
            <div className="d-flex align-items-center justify-content-end ">
              <div className="">
                <Link to={PATH.TEACHERGROUP}>
                  <button className="create_course w-100" type="button">
                    Create New Coaching
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <hr />
        </>
      )}

      <>
        {getListing &&
          getListing.getCoachingLoading === true &&
          getListing.getCoachingSuccess === false &&
          getListing.getCoachingFailure === false && <Loader />}
        {getListing &&
          getListing.getCoachingLoading === false &&
          getListing.getCoachingSuccess === false &&
          getListing.getCoachingFailure === true && (
            <ErrorMessage message={getListing.getCoachingError} />
          )}
        {getListing &&
          getListing.getCoachingLoading === false &&
          getListing.getCoachingSuccess === true &&
          getListing.getCoachingFailure === false && (
            <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
              <MyDataTable
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                // handleDataChange={handleDataChange}
              />
            </div>
          )}
      </>
    </>
  );
}
function DropDownData(props) {
  let history = useHistory();
  let dispatch = useDispatch();
  let auth = useAuth();
  const [loading, setLoading] = useState(false);
  function publishCoaching() {
    let finalData = {
      coachingId: props.row.coachingId,
    };
    dispatch(
      PublishCoaching(
        finalData,
        auth,
        loadingState,
        AfterStatusChange,
        Notification
      )
    );
  }
  function unPublishCoaching() {
    let finalData = {
      coachingId: props.row.coachingId,
    };
    dispatch(
      PublishCoaching(
        finalData,
        auth,
        loadingState,
        AfterStatusChange,
        Notification
      )
    );
  }
  function AfterStatusChange() {
    dispatch(GetCoachingList(auth));
  }
  function loadingState(value) {
    setLoading(value);
  }
  function Notification(data) {
    toast.success(data, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  function editCoachingList() {
    history.push({
      pathname: PATH.TEACHERUPDATECOACHING,
      state: {
        id: props.row.coachingId,
      },
    });
  }
  console.log("test data", loading);
  return (
    <>
      {/* {loading === true ?
        <LoaderTable />
        : */}
      <>
        {auth.edu_tech_user.roleId === 1 || auth.edu_tech_user.roleId === 4 ? (
          <Dropdown className="vertical_dropdown_icon">
            <Dropdown.Toggle className="status-options">
              <BiDotsVerticalRounded />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <>
                {auth.edu_tech_user.roleId === 4 &&
                auth?.edu_tech_user?.permissionDTO?.[1]?.isEditPermission ===
                  false ? (
                  ""
                ) : (
                  <>
                    <Dropdown.Item onClick={() => editCoachingList()}>
                      Update
                    </Dropdown.Item>
                  </>
                )}

                {props.row.status !== "UNPUBLISHED" ? (
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      unPublishCoaching();
                    }}
                  >
                    UnPublish
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      publishCoaching();
                    }}
                  >
                    Publish
                  </Dropdown.Item>
                )}
              </>
              {/* <Dropdown.Item
              href="#"
              onClick={() => deleteCoachingList(props.row.coachingId)}
            >
              Delete
            </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <FaEye onClick={() => editCoachingList()} className="cursor" />
        )}
      </>
      {/* } */}
    </>
  );
}
