import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { FieldError } from "../../../../../assets";
import { useHistory, useLocation } from "react-router-dom";
export default function AddSummary(props) {
  const { register, handleSubmit, errors } = useForm();
  let history = useHistory()
  const CoursesData = props?.CoursesData;
  console.log("CoursesData", CoursesData)
  const [lectureSummary, setLectureSummary] = useState(
    CoursesData && CoursesData.lectureSummary ? CoursesData.lectureSummary : ""
  );
  // function onSubmit() {
  //   props.TabChanges("left-tabs-example-tab-AddQuiz" )
  // }
  const location = useLocation()


  function onSubmit() {
    CoursesData.lectureSummary = lectureSummary
    props.setSelectedView(2)
  }
  function moveToNext(finaldata) {
    props.setSelectedView(2)
    history.push({
      finaldata: finaldata,
    });
  }
  // console.log("sadasdsadqwedwq121", props?.CoursesData)
  return (
    <>
      <h3 className="font-weight-bold mb-3">Add Summary</h3>
      <p className="font-weight-bold f-16 mb-5">
        Use the rich text editor to cleanly format text in your lecture.
      </p>
      <Form onSubmit={handleSubmit(onSubmit)} name="myform" id="myform" className="text-left">
        <CKEditor
          className="edutech-ckeditor"
          editor={ClassicEditor}
          data={lectureSummary}
          name="lectureSummary"
          onReady={(lectureSummary) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", lectureSummary);
          }}
          onChange={(event, lectureSummary) => {
            setLectureSummary(lectureSummary.getData());
            // console.log({ event, editor, data });
          }}
          onBlur={(event, lectureSummary) => {
            console.log("Blur.", lectureSummary);
          }}
          onFocus={(event, lectureSummary) => {
            console.log("Focus.", lectureSummary);
          }}
        />
        <input
          hidden
          type="text"
          name="lectureSummary"
          value={lectureSummary}
          ref={register({ required: true })}
        />
        {errors.lectureSummary && (
          <div className="mt-2">
            <FieldError message={"This Field is Required"} />
          </div>
        )}
        <div className="mt-5">
          <Button className="edutech-curriculum-action-button edutech-pull-right my-2 font-weight-bold" type="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
