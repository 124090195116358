import { INTEREST_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  interestLoading: false,
  interestSuccess: false,
  interestFailure: false,
  interestError: null,
  interest: [],

  publishInterestLoading: false,
  publishInterestSuccess: false,
  publishInterestFailure: false,
  publishInterestError: null,
  publishInterest: [],

  getAllApprovedInterestLoading: false,
  getAllApprovedInterestSuccess: false,
  getAllApprovedInterestFailure: false,
  getAllApprovedInterestError: null,
  getAllApprovedInterest: [],

  getAllUnapprovedInterestLoading: false,
  getAllUnapprovedInterestSuccess: false,
  getAllUnapprovedInterestFailure: false,
  getAllUnapprovedInterestError: null,
  getAllUnapprovedInterest: [],

  getSelectedInterestsByStudentsLoading: false,
  getSelectedInterestsByStudentsSuccess: false,
  getSelectedInterestsByStudentsFailure: false,
  getSelectedInterestsByStudentsError: null,
  getSelectedInterestsByStudents: [],

  createinterestLoading: false,
  createinterestSuccess: false,
  createinterestFailure: false,
  createinterestError: null,
  createinterest: [],

  updateSelectedInterestsLoading: false,
  updateSelectedInterestsSuccess: false,
  updateSelectedInterestsFailure: false,
  updateSelectedInterestsError: null,
  updateSelectedInterests: [],

  deleteInterestLoading: false,
  deleteInterestSuccess: false,
  deleteInterestFailure: false,
  deleteInterestError: null,
  deleteInterest: [],


};

export const InterestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INTEREST_REQUEST.INTEREST_REQUEST:
      return {
        ...state,
        interestLoading: true,
        interestSuccess: false,
        interestFailure: false,
        interestError: null,
      };
    case INTEREST_REQUEST.INTEREST_SUCCESS:
      return {
        ...state,
        interestLoading: false,
        interestSuccess: true,
        interestFailure: false,
        interestError: null,
        interest: action.payload,
      };
    case INTEREST_REQUEST.INTEREST_FAILURE:
      return {
        ...state,
        interestLoading: false,
        interestSuccess: false,
        interestFailure: true,
        interestError: action.payload,
      };

    case INTEREST_REQUEST.PUBLISH_INTEREST_REQUEST:
      return {
        ...state,
        publishInterestLoading: true,
        publishInterestSuccess: false,
        publishInterestFailure: false,
        publishInterestError: null,
      };
    case INTEREST_REQUEST.PUBLISH_INTEREST_SUCCESS:
      return {
        ...state,
        publishInterestLoading: false,
        publishInterestSuccess: true,
        publishInterestFailure: false,
        publishInterestError: null,
        publishInterest: action.payload,
      };
    case INTEREST_REQUEST.PUBLISH_INTEREST_FAILURE:
      return {
        ...state,
        publishInterestLoading: false,
        publishInterestSuccess: false,
        publishInterestFailure: true,
        publishInterestError: action.payload,
      };

    case INTEREST_REQUEST.CREATE_INTEREST_REQUEST:
      return {
        ...state,
        createinterestLoading: true,
        createinterestSuccess: false,
        createinterestFailure: false,
        createinterestError: null,
      };
    case INTEREST_REQUEST.CREATE_INTEREST_SUCCESS:
      return {
        ...state,
        createinterestLoading: false,
        createinterestSuccess: true,
        createinterestFailure: false,
        createinterestError: null,
        createinterest: action.payload,
      };
    case INTEREST_REQUEST.CREATE_INTEREST_FAILURE:
      return {
        ...state,
        createinterestLoading: false,
        createinterestSuccess: false,
        createinterestFailure: true,
        createinterestError: action.payload,
      };

    case INTEREST_REQUEST.UPDATE_SELECTED_INTEREST_REQUEST:
      return {
        ...state,
        updateSelectedInterestsLoading: true,
        updateSelectedInterestsSuccess: false,
        updateSelectedInterestsFailure: false,
        updateSelectedInterestsError: null,
      };
    case INTEREST_REQUEST.UPDATE_SELECTED_INTEREST_SUCCESS:
      return {
        ...state,
        updateSelectedInterestsLoading: false,
        updateSelectedInterestsSuccess: true,
        updateSelectedInterestsFailure: false,
        updateSelectedInterestsError: null,
        updateSelectedInterests: action.payload,
      };
    case INTEREST_REQUEST.UPDATE_SELECTED_INTEREST_FAILURE:
      return {
        ...state,
        updateSelectedInterestsLoading: false,
        updateSelectedInterestsSuccess: false,
        updateSelectedInterestsFailure: true,
        updateSelectedInterestsError: action.payload,
      };

    case INTEREST_REQUEST.DELETE_INTEREST_REQUEST:
      return {
        ...state,
        deleteInterestLoading: true,
        deleteInterestSuccess: false,
        deleteInterestFailure: false,
        deleteInterestError: null,
      };
    case INTEREST_REQUEST.DELETE_INTEREST_SUCCESS:
      let previousPricingPlanList = state.interest
      let newPricingPlanList = previousPricingPlanList.filter(item => item.interestId !== action.payload.interestId)
      return {
        ...state,
        deleteInterestLoading: false,
        deleteInterestSuccess: true,
        deleteInterestFailure: false,
        deleteInterestError: null,
        deleteInterest: action.payload,
        interest: newPricingPlanList
      };
    case INTEREST_REQUEST.DELETE_INTEREST_FAILURE:
      return {
        ...state,
        deleteInterestLoading: false,
        deleteInterestSuccess: false,
        deleteInterestFailure: true,
        deleteInterestError: action.payload,
      };

    case INTEREST_REQUEST.GET_ALL_APPROVED_INTEREST_REQUEST:
      return {
        ...state,
        getAllApprovedInterestLoading: true,
        getAllApprovedInterestSuccess: false,
        getAllApprovedInterestFailure: false,
        getAllApprovedInterestError: null,
      };
    case INTEREST_REQUEST.GET_ALL_APPROVED_INTEREST_SUCCESS:
      return {
        ...state,
        getAllApprovedInterestLoading: false,
        getAllApprovedInterestSuccess: true,
        getAllApprovedInterestFailure: false,
        getAllApprovedInterestError: null,
        getAllApprovedInterest: action.payload,
      };
    case INTEREST_REQUEST.GET_ALL_APPROVED_INTEREST_FAILURE:
      return {
        ...state,
        getAllApprovedInterestLoading: false,
        getAllApprovedInterestSuccess: false,
        getAllApprovedInterestFailure: true,
        getAllApprovedInterestError: action.payload,
      };

    case INTEREST_REQUEST.GET_SELECTED_INTERESTS_BY_STUDENT_REQUEST:
      return {
        ...state,
        getSelectedInterestsByStudentsLoading: true,
        getSelectedInterestsByStudentsSuccess: false,
        getSelectedInterestsByStudentsFailure: false,
        getSelectedInterestsByStudentsError: null,
      };
    case INTEREST_REQUEST.GET_SELECTED_INTERESTS_BY_STUDENT_SUCCESS:
      return {
        ...state,
        getSelectedInterestsByStudentsLoading: false,
        getSelectedInterestsByStudentsSuccess: true,
        getSelectedInterestsByStudentsFailure: false,
        getSelectedInterestsByStudentsError: null,
        getSelectedInterestsByStudents: action.payload,
      };
    case INTEREST_REQUEST.GET_SELECTED_INTERESTS_BY_STUDENT_FAILURE:
      return {
        ...state,
        getSelectedInterestsByStudentsLoading: false,
        getSelectedInterestsByStudentsSuccess: false,
        getSelectedInterestsByStudentsFailure: true,
        getSelectedInterestsByStudentsError: action.payload,
      };

    case INTEREST_REQUEST.GET_ALL_UNAPPROVED_INTEREST_REQUEST:
      return {
        ...state,
        getAllUnapprovedInterestLoading: true,
        getAllUnapprovedInterestSuccess: false,
        getAllUnapprovedInterestFailure: false,
        getAllUnapprovedInterestError: null,
      };
    case INTEREST_REQUEST.GET_ALL_UNAPPROVED_INTEREST_SUCCESS:
      return {
        ...state,
        getAllUnapprovedInterestLoading: false,
        getAllUnapprovedInterestSuccess: true,
        getAllUnapprovedInterestFailure: false,
        getAllUnapprovedInterestError: null,
        getAllUnapprovedInterest: action.payload,
      };
    case INTEREST_REQUEST.GET_ALL_UNAPPROVED_INTEREST_FAILURE:
      return {
        ...state,
        getAllUnapprovedInterestLoading: false,
        getAllUnapprovedInterestSuccess: false,
        getAllUnapprovedInterestFailure: true,
        getAllUnapprovedInterestError: action.payload,
      };

    default:
      return state;
  }
};
