import { PRICING_PLAN_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  pricingPlanLoading: false,
  pricingPlanSuccess: false,
  pricingPlanFailure: false,
  pricingPlanError: null,
  pricingPlan: [],

  selectPricingPlansLoading: false,
  selectPricingPlansSuccess: false,
  selectPricingPlansFailure: false,
  selectPricingPlansError: null,
  selectPricingPlans: [],

  pricingPlanByFilterLoading: false,
  pricingPlanByFilterSuccess: false,
  pricingPlanByFilterFailure: false,
  pricingPlanByFilterError: null,
  pricingPlanByFilter: [],

  getSelectedPricingLoading: false,
  getSelectedPricingSuccess: false,
  getSelectedPricingFailure: false,
  getSelectedPricingError: null,
  getSelectedPricing: [],

  deletePricingPlanLoading: false,
  deletePricingPlanSuccess: false,
  deletePricingPlanFailure: false,
  deletePricingPlanError: null,
  deletePricingPlan: [],

  createManualPriceRangeLoading: false,
  createManualPriceRangeSuccess: false,
  createManualPriceRangeFailure: false,
  createManualPriceRangeError: null,
  createManualPriceRangePlan: [],

  getManualPriceRangeLoading: false,
  getManualPriceRangeSuccess: false,
  getManualPriceRangeFailure: false,
  getManualPriceRangeError: null,
  getManualPriceRange: [],

  priceCheckWithinRangeLoading: false,
  priceCheckWithinRangeSuccess: false,
  priceCheckWithinRangeFailure: false,
  priceCheckWithinRangeError: null,
  priceCheckWithinRange: [],


};
export const pricingPlanReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRICING_PLAN_REQUEST.PRICING_PLAN_REQUEST:
      return {
        ...state,
        pricingPlanLoading: true,
        pricingPlanSuccess: false,
        pricingPlanFailure: false,
        pricingPlanError: null,
      };
    case PRICING_PLAN_REQUEST.PRICING_PLAN_SUCCESS:
      return {
        ...state,
        pricingPlanLoading: false,
        pricingPlanSuccess: true,
        pricingPlanFailure: false,
        pricingPlanError: null,
        pricingPlan: action.payload,
      };
    case PRICING_PLAN_REQUEST.PRICING_PLAN_FAILURE:
      return {
        ...state,
        pricingPlanLoading: false,
        pricingPlanSuccess: false,
        pricingPlanFailure: true,
        pricingPlanError: action.payload,
      };

    case PRICING_PLAN_REQUEST.SELECT_PRICING_PLAN_REQUEST:
      return {
        ...state,
        selectPricingPlansLoading: true,
        selectPricingPlansSuccess: false,
        selectPricingPlansFailure: false,
        selectPricingPlansError: null,
      };
    case PRICING_PLAN_REQUEST.SELECT_PRICING_PLAN_SUCCESS:
      return {
        ...state,
        selectPricingPlansLoading: false,
        selectPricingPlansSuccess: true,
        selectPricingPlansFailure: false,
        selectPricingPlansError: null,
        selectPricingPlans: action.payload,
      };
    case PRICING_PLAN_REQUEST.SELECT_PRICING_PLAN_FAILURE:
      return {
        ...state,
        selectPricingPlansLoading: false,
        selectPricingPlansSuccess: false,
        selectPricingPlansFailure: true,
        selectPricingPlansError: action.payload,
      };

    case PRICING_PLAN_REQUEST.PRICING_PLAN_BY_FILTER_REQUEST:
      return {
        ...state,
        pricingPlanByFilterLoading: true,
        pricingPlanByFilterSuccess: false,
        pricingPlanByFilterFailure: false,
        pricingPlanByFilterError: null,
      };
    case PRICING_PLAN_REQUEST.PRICING_PLAN_BY_FILTER_SUCCESS:
      return {
        ...state,
        pricingPlanByFilterLoading: false,
        pricingPlanByFilterSuccess: true,
        pricingPlanByFilterFailure: false,
        pricingPlanByFilterError: null,
        pricingPlanByFilter: action.payload,
      };
    case PRICING_PLAN_REQUEST.PRICING_PLAN_BY_FILTER_FAILURE:
      return {
        ...state,
        pricingPlanByFilterLoading: false,
        pricingPlanByFilterSuccess: false,
        pricingPlanByFilterFailure: true,
        pricingPlanByFilterError: action.payload,
      };

    case PRICING_PLAN_REQUEST.DELETE_PRICING_PLAN_REQUEST:
      return {
        ...state,
        deletePricingPlanLoading: true,
        deletePricingPlanSuccess: false,
        deletePricingPlanFailure: false,
        deletePricingPlanError: null,
      };
    case PRICING_PLAN_REQUEST.DELETE_PRICING_PLAN_SUCCESS:
      let previousPricingPlanList = state.pricingPlanByFilter
      let newPricingPlanList = previousPricingPlanList.filter(item => item.pricingPlanId !== action.payload.pricingPlanId)
      return {
        ...state,
        deletePricingPlanLoading: false,
        deletePricingPlanSuccess: true,
        deletePricingPlanFailure: false,
        deletePricingPlanError: null,
        deletePricingPlan: action.payload,
        pricingPlanByFilter: newPricingPlanList
      };
    case PRICING_PLAN_REQUEST.DELETE_PRICING_PLAN_FAILURE:
      return {
        ...state,
        deletePricingPlanLoading: false,
        deletePricingPlanSuccess: false,
        deletePricingPlanFailure: true,
        deletePricingPlanError: action.payload,
      };

    case PRICING_PLAN_REQUEST.CREATE_MANUAL_PRICING_RANGE_REQUEST:
      return {
        ...state,
        createManualPriceRangeLoading: true,
        createManualPriceRangeSuccess: false,
        createManualPriceRangeFailure: false,
        createManualPriceRangeError: null,
      };
    case PRICING_PLAN_REQUEST.CREATE_MANUAL_PRICING_RANGE_SUCCESS:
      return {
        ...state,
        createManualPriceRangeLoading: false,
        createManualPriceRangeSuccess: true,
        createManualPriceRangeFailure: false,
        createManualPriceRangeError: null,
        createManualPriceRange: action.payload,
      };
    case PRICING_PLAN_REQUEST.CREATE_MANUAL_PRICING_RANGE_FAILURE:
      return {
        ...state,
        createManualPriceRangeLoading: false,
        createManualPriceRangeSuccess: false,
        createManualPriceRangeFailure: true,
        createManualPriceRangeError: action.payload,
      };

    case PRICING_PLAN_REQUEST.GET_MANUAL_PRICING_RANGE_REQUEST:
      return {
        ...state,
        getManualPriceRangeLoading: true,
        getManualPriceRangeSuccess: false,
        getManualPriceRangeFailure: false,
        getManualPriceRangeError: null,
      };
    case PRICING_PLAN_REQUEST.GET_MANUAL_PRICING_RANGE_SUCCESS:
      return {
        ...state,
        getManualPriceRangeLoading: false,
        getManualPriceRangeSuccess: true,
        getManualPriceRangeFailure: false,
        getManualPriceRangeError: null,
        getManualPriceRange: action.payload,
      };
    case PRICING_PLAN_REQUEST.GET_MANUAL_PRICING_RANGE_FAILURE:
      return {
        ...state,
        getManualPriceRangeLoading: false,
        getManualPriceRangeSuccess: false,
        getManualPriceRangeFailure: true,
        getManualPriceRangeError: action.payload,
      };

    case PRICING_PLAN_REQUEST.GET_SELECTED_PRICING_REQUEST:
      return {
        ...state,
        getSelectedPricingLoading: true,
        getSelectedPricingSuccess: false,
        getSelectedPricingFailure: false,
        getSelectedPricingError: null,
      };
    case PRICING_PLAN_REQUEST.GET_SELECTED_PRICING_SUCCESS:
      return {
        ...state,
        getSelectedPricingLoading: false,
        getSelectedPricingSuccess: true,
        getSelectedPricingFailure: false,
        getSelectedPricingError: null,
        getSelectedPricing: action.payload,
      };
    case PRICING_PLAN_REQUEST.GET_SELECTED_PRICING_FAILURE:
      return {
        ...state,
        getSelectedPricingLoading: false,
        getSelectedPricingSuccess: false,
        getSelectedPricingFailure: true,
        getSelectedPricingError: action.payload,
      };

    case PRICING_PLAN_REQUEST.PRICE_CHECK_WITH_RANGE_REQUEST:
      return {
        ...state,
        priceCheckWithinRangeLoading: true,
        priceCheckWithinRangeSuccess: false,
        priceCheckWithinRangeailure: false,
        priceCheckWithinRangeError: null,
      };
    case PRICING_PLAN_REQUEST.PRICE_CHECK_WITH_RANGE_SUCCESS:
      return {
        ...state,
        priceCheckWithinRangeLoading: false,
        priceCheckWithinRangeSuccess: true,
        priceCheckWithinRangeFailure: false,
        priceCheckWithinRangeError: null,
        priceCheckWithinRange: action.payload,
      };
    case PRICING_PLAN_REQUEST.PRICE_CHECK_WITH_RANGE_FAILURE:
      return {
        ...state,
        priceCheckWithinRangeLoading: false,
        priceCheckWithinRangeSuccess: false,
        priceCheckWithinRangeFailure: true,
        priceCheckWithinRangeError: action.payload,
      };

    default:
      return state;
  }
};
