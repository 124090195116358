import { request, success, failure, NOTIFICATION_REQUEST } from "./utilities";
import { GetNotificationByUserId, MarkAsRead } from "../api";

export function MarkAsReadData(notificationId, moveToNext) {
    return (dispatch) => {
        dispatch(request(NOTIFICATION_REQUEST.MARK_AS_READ_REQUEST));
        MarkAsRead(notificationId).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(NOTIFICATION_REQUEST.MARK_AS_READ_SUCCESS, response.data.data)
                    );
                    if (moveToNext) {
                        moveToNext()
                    }
                } else {
                    dispatch(
                        failure(
                            NOTIFICATION_REQUEST.MARK_AS_READ_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        NOTIFICATION_REQUEST.MARK_AS_READ_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}
export function GetNotificationByUserIdData(auth) {
    return (dispatch) => {
        dispatch(request(NOTIFICATION_REQUEST.GET_NOTIFICATION_BY_USER_ID_REQUEST));
        GetNotificationByUserId(auth).then(
            (response) => {
                if (response.data.succeeded === true) {
                    dispatch(
                        success(NOTIFICATION_REQUEST.GET_NOTIFICATION_BY_USER_ID_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(
                            NOTIFICATION_REQUEST.GET_NOTIFICATION_BY_USER_ID_FAILURE,
                            response.data.message
                        )
                    );
                }
            },
            (error) => {
                dispatch(
                    failure(
                        NOTIFICATION_REQUEST.GET_NOTIFICATION_BY_USER_ID_FAILURE,
                        error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                    )
                );
            }
        );
    };
}