import React, { useEffect, useState } from "react";
import { BsArrowDown, BsArrowLeft, BsArrowUp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { PATH } from "../../../config";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetTotalCoachingSales, GetTotalCoursesSales } from "../../../redux/actions";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { dateTimeConvert, ErrorMessage, Loader } from "../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
export default function TotalSales() {
  const [key, setKey] = useState(3);
  let dispatch = useDispatch();
  let auth = useAuth()
  useEffect(() => {
    dispatch(GetTotalCoachingSales(auth));
    dispatch(GetTotalCoursesSales(auth));
  }, []);
  return (
    <>
      <Link to={PATH.ADMIN_DASHBOARD}>
        <BsArrowLeft size={35} className="mr-2" />
      </Link>
      <div
        className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
      >
        <div className="card-header ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className=" font-weight-700 mb-0">Total Sales</h3>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        // onSelect={(k) => Call(k)}
        className="explore_tabs"
      >
        <Tab eventKey={3} title="Courses">
          <TotalCourses />
        </Tab>
        <Tab eventKey={2} title="Coaching">
          <TotalCoaching />
        </Tab>
      </Tabs>
    </>
  );
}
function TotalCourses() {
  let totalCoursesSale = useSelector((state) => state.dashboardTiles);
  let data = totalCoursesSale && totalCoursesSale.getTotalCoursesSales;
  let columns = [
    {
      text: 'ID',
      dataField: 'id',
      formatter: (cell, row, index) => {
        return (
          <>
            {index + 1}
          </>
        )
      }
    },
    {
      dataField: "courseTitle",
      text: "Course Title",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            <>
              {row.courseTitle ? row.courseTitle : "N/A"}
            </>
          </span>
        );
      },
    },
    // {
    //   dataField: "coachingName",
    //   text: "Coaching Name",
    //   formatter: (cell, row) => {
    //     return (
    //       <span>
    //         <>
    //           {row.coachingName ? row.coachingName : "N/A"}
    //         </>
    //       </span>
    //     );
    //   },
    // },
    {
      dataField: "category",
      text: "Category",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            <>
              {row.category ? row.category : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            <>
              {dateTimeConvert(row.date) ? dateTimeConvert(row.date) : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "enrollments",
      text: "Enrollment",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            <>
              {row.enrollments ? row.enrollments : "N/A"}
            </>
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {totalCoursesSale &&
        totalCoursesSale.getTotalCoursesSalesLoading === true &&
        totalCoursesSale.getTotalCoursesSalesSuccess === false &&
        totalCoursesSale.getTotalCoursesSalesFailure === false && <Loader />}
      {totalCoursesSale &&
        totalCoursesSale.getTotalCoursesSalesLoading === false &&
        totalCoursesSale.getTotalCoursesSalesSuccess === false &&
        totalCoursesSale.getTotalCoursesSalesFailure === true && (
          <ErrorMessage message={totalCoursesSale.getTotalCoursesSalesError} />
        )}
      {totalCoursesSale &&
        totalCoursesSale.getTotalCoursesSalesLoading === false &&
        totalCoursesSale.getTotalCoursesSalesSuccess === true &&
        totalCoursesSale.getTotalCoursesSalesFailure === false && (
          <div className="admin-side-table-styling card p-4">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>

  )
}
function TotalCoaching() {
  let totalCoachingSale = useSelector((state) => state.dashboardTiles);
  let data = totalCoachingSale && totalCoachingSale.getTotalCoachingSales;
  let columns = [
    {
      text: 'ID',
      dataField: 'id',
      formatter: (cell, row, index) => {
        return (
          <>
            {index + 1}
          </>
        )
      }
    },
    // {
    //   dataField: "coachingId",
    //   text: "CoachingID",
    //   formatter: (cell, row) => {
    //     return (
    //       <span>
    //         <>
    //           {row.coachingId ? row.coachingId : "N/A"}
    //         </>
    //       </span>
    //     );
    //   },
    // },
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            <>
              {row.coachingName ? row.coachingName : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "category",
      text: "Category",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            <>
              {row.category ? row.category : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            <>
              {dateTimeConvert(row.date) ? dateTimeConvert(row.date) : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "enrollments",
      text: "Enrollment",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            <>
              {row.enrollments ? row.enrollments : "N/A"}
            </>
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {totalCoachingSale &&
        totalCoachingSale.getTotalCoachingSalesLoading === true &&
        totalCoachingSale.getTotalCoachingSalesSuccess === false &&
        totalCoachingSale.getTotalCoachingSalesFailure === false && <Loader />}
      {totalCoachingSale &&
        totalCoachingSale.getTotalCoachingSalesLoading === false &&
        totalCoachingSale.getTotalCoachingSalesSuccess === false &&
        totalCoachingSale.getTotalCoachingSalesFailure === true && (
          <ErrorMessage message={totalCoachingSale.getTotalCoachingSalesError} />
        )}
      {totalCoachingSale &&
        totalCoachingSale.getTotalCoachingSalesLoading === false &&
        totalCoachingSale.getTotalCoachingSalesSuccess === true &&
        totalCoachingSale.getTotalCoachingSalesFailure === false && (
          <div className="admin-side-table-styling card p-4">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>

  )
}

