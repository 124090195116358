import {
  request,
  success,
  failure,
  STUDENT_PAYMENT_REQUEST,
} from "./utilities";
import { getStudentPaymentDetails } from "../api";

export function GetStudentPaymentDetails() {
  return (dispatch) => {
    dispatch(
      request(STUDENT_PAYMENT_REQUEST.GET_STUDENT_PAYMENT_DETAIL_REQUEST)
    );
    getStudentPaymentDetails().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              STUDENT_PAYMENT_REQUEST.GET_STUDENT_PAYMENT_DETAIL_SUCCESS,
              response.data
            )
          );
        } else {
          dispatch(
            failure(
              STUDENT_PAYMENT_REQUEST.GET_STUDENT_PAYMENT_DETAIL_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            STUDENT_PAYMENT_REQUEST.GET_STUDENT_PAYMENT_DETAIL_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
