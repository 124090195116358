import { request, success, failure, INTEREST_REQUEST } from "./utilities";
import { createInterest, deleteInterest, getAllApprovedInterest, getAllUnapprovedInterest, interest, publishInterest, getSelectedInterestsByStudents, updateSelectedInterests } from "../api";
export function Interest() {
  return (dispatch) => {
    dispatch(request(INTEREST_REQUEST.INTEREST_REQUEST));
    interest().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(INTEREST_REQUEST.INTEREST_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              INTEREST_REQUEST.INTEREST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            INTEREST_REQUEST.INTEREST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetAllApprovedInterest() {
  return (dispatch) => {
    dispatch(request(INTEREST_REQUEST.GET_ALL_APPROVED_INTEREST_REQUEST));
    getAllApprovedInterest().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(INTEREST_REQUEST.GET_ALL_APPROVED_INTEREST_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              INTEREST_REQUEST.GET_ALL_APPROVED_INTEREST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            INTEREST_REQUEST.GET_ALL_APPROVED_INTEREST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetAllUnapprovedInterest(auth) {
  return (dispatch) => {
    dispatch(request(INTEREST_REQUEST.GET_ALL_UNAPPROVED_INTEREST_REQUEST));
    getAllUnapprovedInterest(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(INTEREST_REQUEST.GET_ALL_UNAPPROVED_INTEREST_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              INTEREST_REQUEST.GET_ALL_UNAPPROVED_INTEREST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            INTEREST_REQUEST.GET_ALL_UNAPPROVED_INTEREST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetSelectedInterestsByStudents(auth) {
  return (dispatch) => {
    dispatch(request(INTEREST_REQUEST.GET_SELECTED_INTERESTS_BY_STUDENT_REQUEST));
    getSelectedInterestsByStudents(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(INTEREST_REQUEST.GET_SELECTED_INTERESTS_BY_STUDENT_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              INTEREST_REQUEST.GET_SELECTED_INTERESTS_BY_STUDENT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            INTEREST_REQUEST.GET_SELECTED_INTERESTS_BY_STUDENT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CreateInterest(data, auth, moveToNext, Notificiation) {
  return (dispatch) => {
    dispatch(request(INTEREST_REQUEST.CREATE_INTEREST_REQUEST));
    createInterest(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(INTEREST_REQUEST.CREATE_INTEREST_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext();
          }
          Notificiation(response.data.message, "success");
        } else {
          dispatch(
            failure(
              INTEREST_REQUEST.CREATE_INTEREST_FAILURE,
              response.data.message
            )
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            INTEREST_REQUEST.CREATE_INTEREST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function UpdateSelectedInterests(data, auth, Notificiation) {
  return (dispatch) => {
    dispatch(request(INTEREST_REQUEST.UPDATE_SELECTED_INTEREST_REQUEST));
    updateSelectedInterests(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(INTEREST_REQUEST.UPDATE_SELECTED_INTEREST_SUCCESS, response.data.data)
          );
          Notificiation(response.data.message, "success");
        } else {
          dispatch(
            failure(
              INTEREST_REQUEST.UPDATE_SELECTED_INTEREST_FAILURE,
              response.data.message
            )
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            INTEREST_REQUEST.UPDATE_SELECTED_INTEREST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function PublishInterest(data, auth, AfterStatusChange, Notification) {
  return (dispatch) => {
    dispatch(request(INTEREST_REQUEST.PUBLISH_INTEREST_REQUEST));
    publishInterest(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(INTEREST_REQUEST.PUBLISH_INTEREST_SUCCESS, response.data.data)
          );
          if (AfterStatusChange) {
            AfterStatusChange();
          }
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              INTEREST_REQUEST.PUBLISH_INTEREST_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            INTEREST_REQUEST.PUBLISH_INTEREST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function DeleteInterest(data, auth, moveToNext, Notificiation) {
  return (dispatch) => {
    dispatch(request(INTEREST_REQUEST.DELETE_INTEREST_REQUEST));
    deleteInterest(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              INTEREST_REQUEST.DELETE_INTEREST_SUCCESS,
              data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          Notificiation(response.data.message, "success");
        } else {
          dispatch(
            failure(
              INTEREST_REQUEST.DELETE_INTEREST_FAILURE,
              response.data.message
            )
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            INTEREST_REQUEST.DELETE_INTEREST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
