import { NOTIFICATION_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {


    MarkAsReadLoading: false,
    MarkAsReadSuccess: false,
    MarkAsReadFailure: false,
    MarkAsReadError: null,
    MarkAsRead: [],

    GetNotificationByUserIdLoading: false,
    GetNotificationByUserIdSuccess: false,
    GetNotificationByUserIdFailure: false,
    GetNotificationByUserIdError: null,
    GetNotificationByUserId: [],

};

export const notificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NOTIFICATION_REQUEST.MARK_AS_READ_REQUEST:
            return {
                ...state,
                MarkAsReadLoading: true,
                MarkAsReadSuccess: false,
                MarkAsReadFailure: false,
                MarkAsReadError: null,
            };
        case NOTIFICATION_REQUEST.MARK_AS_READ_SUCCESS:
            return {
                ...state,
                MarkAsReadLoading: false,
                MarkAsReadSuccess: true,
                MarkAsReadFailure: false,
                MarkAsReadError: null,
                createfaqs: action.payload,
            };
        case NOTIFICATION_REQUEST.MARK_AS_READ_FAILURE:
            return {
                ...state,
                MarkAsReadLoading: false,
                MarkAsReadSuccess: false,
                MarkAsReadFailure: true,
                MarkAsReadError: action.payload,
            };

        case NOTIFICATION_REQUEST.GET_NOTIFICATION_BY_USER_ID_REQUEST:
            return {
                ...state,
                GetNotificationByUserIdLoading: true,
                GetNotificationByUserIdSuccess: false,
                GetNotificationByUserIdFailure: false,
                GetNotificationByUserIdError: null,
            };
        case NOTIFICATION_REQUEST.GET_NOTIFICATION_BY_USER_ID_SUCCESS:
            return {
                ...state,
                GetNotificationByUserIdLoading: false,
                GetNotificationByUserIdSuccess: true,
                GetNotificationByUserIdFailure: false,
                GetNotificationByUserIdError: null,
                GetNotificationByUserId: action.payload,
            };
        case NOTIFICATION_REQUEST.GET_NOTIFICATION_BY_USER_ID_FAILURE:
            return {
                ...state,
                GetNotificationByUserIdLoading: false,
                GetNotificationByUserIdSuccess: false,
                GetNotificationByUserIdFailure: true,
                GetNotificationByUserIdError: action.payload,
            };

        default:
            return state;
    }
};
