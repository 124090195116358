import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { convertBase64, IMAGES } from "../../../../assets";
import { FaQuestionCircle } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import { PATH } from "../../../../config";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetById } from "../../../../redux/actions/coursesAction";
import { useAuth } from "../../../../Navigation/Auth/ProvideAuth";
export default function TeacherCourseInformationPreview() {
  let location = useLocation();
  let auth = useAuth();
  let history = useHistory();
  let dispatch = useDispatch();
  console.log("askhkjdhas", history);
  let GetCourseValue = useSelector((state) => state?.courses);
  // const StudentPurchasedCourses = location?.state?.purchasedCourseDataId
  const StudentPurchasedCourses = location?.state?.id;
  useEffect(() => {
    document.title = `Course Information || EduTech`;
    dispatch(GetById(StudentPurchasedCourses, auth));
  }, []);
  let data = GetCourseValue && GetCourseValue.getById;
  console.log("asdasdasdsa", data);
  const [titleModal, setTitleModal] = useState();
  const [modalDescription, setModalDescription] = useState();
  const [ModalYesBtn, setModalYesBtn] = useState();
  const [ModalNoBtn, setModalNoBtn] = useState();
  console.log(
    "test data ",
    setModalNoBtn,
    setModalYesBtn,
    setModalDescription,
    setTitleModal
  );
  const [updateDetail, setUpdateDetail] = React.useState(false);
  const [updatePricingdetailModal, setPricingDetailModal] =
    React.useState(false);
  // let history = useHistory();
  // function goNext() {
  //   history.push({
  //     pathname: PATH.TEACHERCURRICULUMPUBLISH,
  //   });
  // }

  const [UnPublishCourse, setUnPublishCourse] = React.useState(false);
  const [profilePicture, setProfilePicture] = useState(null);

  const onChangeProfilePicture = (e) => {
    let file = e.target.files[0];
    if (file) {
      convertBase64(file).then((getValue) => {
        setProfilePicture([getValue]);
      });
    }
  };
  return (
    <>
      <div className="dashboard-teacher">
        <div className="px-2">
          <p className="publish-success-text px-3 py-3">
            <BsCheckCircleFill size={22} className="mr-2" /> Great! your 80%
            process has been completed please preview what you have done so far
            to publish it successfully!
          </p>
        </div>
        <div className=" row px-2 pt-4 mt-4">
          <div className="col-md-3 col-lg-3 col-sm-12 py-4">
            <h4>Details</h4>
            <p>Change information about your course.</p>
          </div>
          <div className="col-md-9 col-lg-9 col-sm-12  ">
            <div className="card p-5  ">
              <div className=" ">
                <table className="table my-2">
                  <tbody>
                    <tr className="tr-top-border">
                      <th>Course title</th>
                      <td className="text-fade">{data?.courseTitle} </td>
                    </tr>
                    <tr className="tr-top-border">
                      <th>Course subtitle (optional)</th>
                      <td className="text-fade">{data?.courseSubtitle} </td>
                    </tr>
                    <tr className="tr-top-border">
                      <th>Company name</th>
                      <td className="text-fade">{data?.companyName} </td>
                    </tr>
                    <tr className="tr-bottom-border">
                      <th>Categories</th>
                      <td className="text-fade">{data?.category}</td>
                    </tr>
                  </tbody>
                </table>
                {/* <div className="row justify-content-end mt-4 pt-3 mb-1 pr-3">
                  <div className="w-25">
                    <button
                      type="button"
                      className="btn-save w-100"
                      onClick={() => setUpdateDetail(true)}
                    >
                      Update
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className=" row px-2 pt-4 mt-4">
          <div className="col-md-3 col-lg-3 col-sm-12 py-4">
            <h4>Curriculum</h4>
            <p>Change curriculum about your course.</p>
          </div>
          <div className="col-md-9 col-lg-9 col-sm-12 ">
            <div className="card p-5 timeline-curriculum">
              {data?.sections &&
                data?.sections?.map((value, index) => {
                  console.log("ashdgkhsagdq", value);
                  return (
                    <div
                      className="timeline timeline-single-column timeline-single-full-column"
                      key={index}
                    >
                      <span className="timeline-label">
                        <span className="badge px-15 badge-info-light badge-lg">
                          {value?.sectionName}
                        </span>
                      </span>
                      <div className="timeline-item">
                        <div className="timeline-point timeline-point-blank"></div>
                        <div className="timeline-event">
                          {value?.lectures?.map((lecturesValues, lectureid) => {
                            console.log("ashdgkhsagdq", lecturesValues);
                            return (
                              <div className="timeline-heading" key={lectureid}>
                                <h4 className="timeline-title">
                                  {lecturesValues?.lectureName}
                                </h4>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    // {/* <div className="row justify-content-end mt-4 pt-3 mb-1 pr-3">
                    //   <Link
                    //     to={{
                    //       pathname: PATH.TEACHERCURRICULUMLIST,
                    //     }}
                    //     className="w-25"
                    //   >
                    //     <button type="button" className="btn-save w-100">
                    //       Update
                    //     </button>
                    //   </Link>
                    // </div> */}
                  );
                })}
            </div>
          </div>
        </div>
        <>
          <hr />
          <div className=" row px-2 pt-4 mt-4">
            <div className="col-md-3 col-lg-3 col-sm-12 py-4">
              <h4>Pricing Details</h4>
              <p>Change pricing plan of your course.</p>
            </div>
            <div className="col-md-9 col-lg-9 col-sm-12  ">
              <div className="card p-5  ">
                <div className=" ">
                  <table className="table my-2">
                    <tbody>
                      <tr className="tr-top-border">
                        <th>Course Name</th>
                        <td className="text-fade">{data?.courseTitle} </td>
                      </tr>
                      <tr className="tr-top-border">
                        <th> Course Subtitle</th>
                        <td className="text-fade">{data?.courseSubtitle} </td>
                      </tr>
                      <tr>
                        <th>Price</th>
                        <td className="text-fade">
                          {data?.price ? data?.price : "Free"}
                        </td>
                      </tr>
                      <tr className="tr-bottom-border">
                        <th>Discounted Price</th>
                        <td className="text-fade">{data?.discountedPrice}</td>
                      </tr>
                    </tbody>
                  </table>

                  {/* <div className="row justify-content-end mt-4 pt-3 mb-1 pr-3">
                      <div className="w-25">
                        <button
                          type="button"
                          className="btn-save w-100"
                          onClick={() => setPricingDetailModal(true)}
                        >
                          Update
                        </button>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
        <hr />
        <div className=" row d-flex justify-content-between px-2 pt-4 mt-4">
          <div className="col-md-3 col-lg-3 col-sm-12 py-4">
            <h4>Branding</h4>
            {/* <h4>{data?.courseTitle}</h4> */}
            {/* <p className="pb-1 text-muted">
              Brand your course by setting a custom course image.
              <button type="button" className="upload_thumbnail_btn mt-3">
                <Form.File
                  custom
                  bsCustomPrefix="form-file-input"
                  name="files"
                  id="upload-file"
                  className="upload_thumbnail_input"
                  data-browse="UPLOAD"
                  accept="image/*"
                  onChange={(e) => {
                    onChangeProfilePicture(e);
                  }}
                />
                Upload file
              </button>
            </p> */}
          </div>
          <div className="col-md-9 col-lg-9 col-sm-12  ">
            <div className="card p-4 bg-white set-card-radius ">
              <div className="d-flex justify-content-between px-2 mb-2 pb-4 pt-4 mt-2 align-items-center">
                <div className="col-md-3 col-lg-3 col-sm-12 ">
                  <img
                    src={
                      data?.coursePreviewPictureURL
                        ? data?.coursePreviewPictureURL
                        : "No Image FOund"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-9 col-lg-9 col-sm-12 d-flex justify-content-between align-items-center">
                  <div className="">
                    <p className="mb-0">{data?.courseTitle}</p>
                    <h4 className="pt-1 mb-0 pb-1 diversity-font-size">
                      {data?.courseSubtitle}
                    </h4>
                    {/* <span className="pt-1">BY: Pat Wadors</span> */}
                  </div>
                  <div className="branding-design-price">
                    {data?.price === 0 ? (
                      <span>
                        {" "}
                        {data?.price - data?.discountedPrice
                          ? data?.price - data?.discountedPrice
                          : "Free"}{" "}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        $
                        {data?.price - data?.discountedPrice
                          ? data?.price - data?.discountedPrice
                          : "Free"}{" "}
                      </span>
                    )}

                    {data?.price === 0 ? (
                      ""
                    ) : (
                      <p className="mb-0">${data?.discountedPrice}</p>
                    )}
                    {/* <span>{data?.price ? data?.price : "Free"}</span> */}
                    {/* <p className="mb-0">$19.99</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="text-center">
          <Link to={PATH.TEACHERCURRICULUMPUBLISH}>
            <button className="btn-duplicate border-0">Done</button>
          </Link>
          {/* <Link to={PATH.TEACHERCURRICULUMPUBLISH}>
            <button className="btn-duplicate border-0 mx-3">
              Save As Draft
            </button>
          </Link> */}
        </div>
        <hr />
      </div>
      <UnpublishCourseModal
        title={titleModal}
        description={modalDescription}
        btnYes={ModalYesBtn}
        btnNo={ModalNoBtn}
        show={UnPublishCourse}
        onHide={() => setUnPublishCourse(false)}
      />
      <UpdateDetailModal
        show={updateDetail}
        onHide={() => setUpdateDetail(false)}
      />
      <UpdatePricingModal
        show={updatePricingdetailModal}
        onHide={() => setPricingDetailModal(false)}
      />
    </>
  );
}
function UnpublishCourseModal(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="p-5 teacher-curriculum-dashboard"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="pt-5 px-5 pb-0 teacher-curriculum-dashboard">
          <div className="col-md-12 set-modal-footer-border pb-4">
            <h3 className="text-center my-3">{props.title}</h3>
            <h4 className=" modal-text-color text-center ">
              {props.description}
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-4 py-5 d-flex justify-content-center set-btn-styling ">
          <Button className="mr-4" onClick={props.onHide} data-dismiss="modal">
            {props.btnYes}
          </Button>
          <Button onClick={props.onHide}>{props.btnNo}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
function UpdateDetailModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="admin-manage-user"
    >
      <Modal.Title id="contained-modal-title-vcenter">
        Update Details
      </Modal.Title>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Course title</Form.Label>
                <Form.Control
                  name="fname"
                  type="text"
                  placeholder="Course title"
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Course subtitle (optional)</Form.Label>
                <Form.Control
                  name="lname"
                  type="text"
                  placeholder="Course subtitle"
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Company name</Form.Label>
                <Form.Control
                  name="compname"
                  type="text"
                  placeholder="Company name"
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Categories</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  placeholder="Categories"
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer ClassName="edutech-footter-modal ">
        <Button variant=" edutech-admin-primary " onClick={props.onHide}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function UpdatePricingModal(props) {
  const [name, setName] = useState("Free");
  const [subtitle, setSubtitle] = useState("The Wiz");
  const [price, setPrice] = useState("2");
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="admin-manage-user"
    >
      <Modal.Title id="contained-modal-title-vcenter">
        Update Pricing Details
      </Modal.Title>
      <Modal.Body>
        <Form>
          <div className="row pricingmodal">
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="name"
                  placeholder="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>
                  Subtitle
                  <FaQuestionCircle className="ml-2" />
                </Form.Label>
                <Form.Control
                  className="mt-1"
                  name="subtitle"
                  placeholder="subtitle"
                  type="text"
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>
                  Price
                  <FaQuestionCircle className="ml-2" />
                </Form.Label>
                <Form.Control
                  className="mt-1"
                  name="price"
                  type="number"
                  placeholder="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="ckeditor-modification">
                <Form.Label className="mb-2">Detailed Description</Form.Label>
                <CKEditor
                  className="mt-1"
                  editor={ClassicEditor}
                  data=""
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer ClassName="edutech-footter-modal ">
        <Button variant=" edutech-admin-primary " onClick={props.onHide}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
