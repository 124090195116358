import { COACHING_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  updateCoachingLoading: false,
  updateCoachingSuccess: false,
  updateCoachingFailure: false,
  updateCoachingError: null,
  updateCoaching: [],

  updateAdminCoachingLoading: false,
  updateAdminCoachingSuccess: false,
  updateAdminCoachingFailure: false,
  updateAdminCoachingError: null,
  updateAdminCoaching: [],

  getCoachingLoading: false,
  getCoachingSuccess: false,
  getCoachingFailure: false,
  getCoachingError: null,
  getCoaching: [],

  markCoachingAsCompletedLoading: false,
  markCoachingAsCompletedSuccess: false,
  markCoachingAsCompletedFailure: false,
  markCoachingAsCompletedError: null,
  markCoachingAsCompleted: [],

  getPurchasedCoachingLoading: false,
  getPurchasedCoachingSuccess: false,
  getPurchasedCoachingFailure: false,
  getPurchasedCoachingError: null,
  getPurchasedCoaching: [],

  deleteCoachingLoading: false,
  deleteCoachingSuccess: false,
  deleteCoachingFailure: false,
  deleteCoachingError: null,
  deleteCoaching: [],

  publishCoachingLoading: false,
  publishCoachingSuccess: false,
  publishCoachingFailure: false,
  publishCoachingError: null,
  publishCoaching: [],

  purchaseCoachingLoading: false,
  purchaseCoachingSuccess: false,
  purchaseCoachingFailure: false,
  purchaseCoachingError: null,
  purchaseCoaching: [],

  getUpcomingCoachingLoading: false,
  getUpcomingCoachingSuccess: false,
  getUpcomingCoachingFailure: false,
  getUpcomingCoachingError: null,
  getUpcomingCoaching: [],

  getCompletedCoachingLoading: false,
  getCompletedCoachingSuccess: false,
  getCompletedCoachingFailure: false,
  getCompletedCoachingError: null,
  getCompletedCoaching: [],

  getTodaysCoachingLoading: false,
  getTodaysCoachingSuccess: false,
  getTodaysCoachingFailure: false,
  getTodaysCoachingError: null,
  getTodaysCoaching: [],

  getTopCoachingLoading: false,
  getTopCoachingSuccess: false,
  getTopCoachingFailure: false,
  getTopCoachingError: null,
  getTopCoaching: [],

  modifyCoachingLoading: false,
  modifyCoachingSuccess: false,
  modifyCoachingFailure: false,
  modifyCoachingError: null,
  modifyCoaching: [],

  getTodaysCoachingLPLoading: false,
  getTodaysCoachingLPSuccess: false,
  getTodaysCoachingLPFailure: false,
  getTodaysCoachingLPError: null,
  getTodaysCoachingLP: [],

  getUpcomingCoachingLPLoading: false,
  getUpcomingCoachingLPSuccess: false,
  getUpcomingCoachingLPFailure: false,
  getUpcomingCoachingLPError: null,
  getUpcomingCoachingLP: [],
};

export const CoachingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COACHING_REQUEST.ADD_UPDATE_COACHING_REQUEST:
      return {
        ...state,
        updateCoachingLoading: true,
        updateCoachingSuccess: false,
        updateCoachingFailure: false,
        updateCoachingError: null,
        updateCoaching: [],
      };
    case COACHING_REQUEST.ADD_UPDATE_COACHING_SUCCESS:
      return {
        ...state,
        updateCoachingLoading: false,
        updateCoachingSuccess: true,
        updateCoachingFailure: false,
        updateCoachingError: null,
        updateCoaching: action.payload,
      };
    case COACHING_REQUEST.ADD_UPDATE_COACHING_FAILURE:
      return {
        ...state,
        updateCoachingLoading: false,
        updateCoachingSuccess: false,
        updateCoachingFailure: true,
        updateCoachingError: action.payload,
      };

    case COACHING_REQUEST.GET_UPCOMING_COACHING_REQUEST:
      return {
        ...state,
        getUpcomingCoachingLoading: true,
        getUpcomingCoachingSuccess: false,
        getUpcomingCoachingFailure: false,
        getUpcomingCoachingError: null,
        getUpcomingCoaching: [],
      };
    case COACHING_REQUEST.GET_UPCOMING_COACHING_SUCCESS:
      return {
        ...state,
        getUpcomingCoachingLoading: false,
        getUpcomingCoachingSuccess: true,
        getUpcomingCoachingFailure: false,
        getUpcomingCoachingError: null,
        getUpcomingCoaching: action.payload,
      };
    case COACHING_REQUEST.GET_UPCOMING_COACHING_FAILURE:
      return {
        ...state,
        getUpcomingCoachingLoading: false,
        getUpcomingCoachingSuccess: false,
        getUpcomingCoachingFailure: true,
        getUpcomingCoachingError: action.payload,
      };

    case COACHING_REQUEST.MARK_COACHING_COMPELTE_REQUEST:
      return {
        ...state,
        markCoachingAsCompletedLoading: true,
        markCoachingAsCompletedSuccess: false,
        markCoachingAsCompletedFailure: false,
        markCoachingAsCompletedError: null,
        markCoachingAsCompleted: [],
      };
    case COACHING_REQUEST.MARK_COACHING_COMPELTE_SUCCESS:
      return {
        ...state,
        markCoachingAsCompletedLoading: false,
        markCoachingAsCompletedSuccess: true,
        markCoachingAsCompletedFailure: false,
        markCoachingAsCompletedError: null,
        markCoachingAsCompleted: action.payload,
      };
    case COACHING_REQUEST.MARK_COACHING_COMPELTE_FAILURE:
      return {
        ...state,
        markCoachingAsCompletedLoading: false,
        markCoachingAsCompletedSuccess: false,
        markCoachingAsCompletedFailure: true,
        markCoachingAsCompletedError: action.payload,
      };

    case COACHING_REQUEST.GET_TODAYS_COACHING_REQUEST:
      return {
        ...state,
        getTodaysCoachingLoading: true,
        getTodaysCoachingSuccess: false,
        getTodaysCoachingFailure: false,
        getTodaysCoachingError: null,
        getTodaysCoaching: [],
      };
    case COACHING_REQUEST.GET_TODAYS_COACHING_SUCCESS:
      return {
        ...state,
        getTodaysCoachingLoading: false,
        getTodaysCoachingSuccess: true,
        getTodaysCoachingFailure: false,
        getTodaysCoachingError: null,
        getTodaysCoaching: action.payload,
      };
    case COACHING_REQUEST.GET_TODAYS_COACHING_FAILURE:
      return {
        ...state,
        getTodaysCoachingLoading: false,
        getTodaysCoachingSuccess: false,
        getTodaysCoachingFailure: true,
        getTodaysCoachingError: action.payload,
      };

    case COACHING_REQUEST.GET_TOP_COACHING_REQUEST:
      return {
        ...state,
        getTopCoachingLoading: true,
        getTopCoachingSuccess: false,
        getTopCoachingFailure: false,
        getTopCoachingError: null,
        getTopCoaching: [],
      };
    case COACHING_REQUEST.GET_TOP_COACHING_SUCCESS:
      return {
        ...state,
        getTopCoachingLoading: false,
        getTopCoachingSuccess: true,
        getTopCoachingFailure: false,
        getTopCoachingError: null,
        getTopCoaching: action.payload,
      };
    case COACHING_REQUEST.GET_TOP_COACHING_FAILURE:
      return {
        ...state,
        getTopCoachingLoading: false,
        getTopCoachingSuccess: false,
        getTopCoachingFailure: true,
        getTopCoachingError: action.payload,
      };

    case COACHING_REQUEST.UPDATE_ADMIN_COACHING_REQUEST:
      return {
        ...state,
        updateAdminCoachingLoading: true,
        updateAdminCoachingSuccess: false,
        updateAdminCoachingFailure: false,
        updateAdminCoachingError: null,
        updateAdminCoaching: [],
      };
    case COACHING_REQUEST.UPDATE_ADMIN_COACHING_SUCCESS:
      return {
        ...state,
        updateAdminCoachingLoading: false,
        updateAdminCoachingSuccess: true,
        updateAdminCoachingFailure: false,
        updateAdminCoachingError: null,
        updateAdminCoaching: action.payload,
      };
    case COACHING_REQUEST.UPDATE_ADMIN_COACHING_FAILURE:
      return {
        ...state,
        updateAdminCoachingLoading: false,
        updateAdminCoachingSuccess: false,
        updateAdminCoachingFailure: true,
        updateAdminCoachingError: action.payload,
      };

    case COACHING_REQUEST.GET_COACHING_LIST_REQUEST:
      return {
        ...state,
        getCoachingLoading: true,
        getCoachingSuccess: false,
        getCoachingFailure: false,
        getCoachingError: null,
        getCoaching: [],
      };
    case COACHING_REQUEST.GET_COACHING_LIST_SUCCESS:
      return {
        ...state,
        getCoachingLoading: false,
        getCoachingSuccess: true,
        getCoachingFailure: false,
        getCoachingError: null,
        getCoaching: action.payload,
      };
    case COACHING_REQUEST.GET_COACHING_LIST_FAILURE:
      return {
        ...state,
        getCoachingLoading: false,
        getCoachingSuccess: false,
        getCoachingFailure: true,
        getCoachingError: action.payload,
      };

    case COACHING_REQUEST.GET_PURCHASED_COACHING_REQUEST:
      return {
        ...state,
        getPurchasedCoachingLoading: true,
        getPurchasedCoachingSuccess: false,
        getPurchasedCoachingFailure: false,
        getPurchasedCoachingError: null,
        getPurchasedCoaching: [],
      };
    case COACHING_REQUEST.GET_PURCHASED_COACHING_SUCCESS:
      return {
        ...state,
        getPurchasedCoachingLoading: false,
        getPurchasedCoachingSuccess: true,
        getPurchasedCoachingFailure: false,
        getPurchasedCoachingError: null,
        getPurchasedCoaching: action.payload,
      };
    case COACHING_REQUEST.GET_PURCHASED_COACHING_FAILURE:
      return {
        ...state,
        getPurchasedCoachingLoading: false,
        getPurchasedCoachingSuccess: false,
        getPurchasedCoachingFailure: true,
        getPurchasedCoachingError: action.payload,
      };

    case COACHING_REQUEST.GET_COMPLETED_COACHING_REQUEST:
      return {
        ...state,
        getCompletedCoachingLoading: true,
        getCompletedCoachingSuccess: false,
        getCompletedCoachingFailure: false,
        getCompletedCoachingError: null,
        getCompletedCoaching: [],
      };
    case COACHING_REQUEST.GET_COMPLETED_COACHING_SUCCESS:
      return {
        ...state,
        getCompletedCoachingLoading: false,
        getCompletedCoachingSuccess: true,
        getCompletedCoachingFailure: false,
        getCompletedCoachingError: null,
        getCompletedCoaching: action.payload,
      };
    case COACHING_REQUEST.GET_COMPLETED_COACHING_FAILURE:
      return {
        ...state,
        getCompletedCoachingLoading: false,
        getCompletedCoachingSuccess: false,
        getCompletedCoachingFailure: true,
        getCompletedCoachingError: action.payload,
      };

    case COACHING_REQUEST.DELETE_COACHING_REQUEST:
      return {
        ...state,
        deleteCoachingLoading: true,
        deleteCoachingSuccess: false,
        deleteCoachingFailure: false,
        deleteCoachingError: null,
        deleteCoaching: [],
      };
    case COACHING_REQUEST.DELETE_COACHING_SUCCESS:
      let allList = state.getCoaching;
      for (let i = 0; i < allList.length; i++) {
        if (allList[i].coachingId === action.payload) {
          allList.splice(i, 1);
        }
      }
      return {
        ...state,
        deleteCoachingLoading: false,
        deleteCoachingSuccess: true,
        deleteCoachingFailure: false,
        deleteCoachingError: null,
        getCoaching: allList,
      };
    case COACHING_REQUEST.DELETE_COACHING_FAILURE:
      return {
        ...state,
        deleteCoachingLoading: false,
        deleteCoachingSuccess: false,
        deleteCoachingFailure: true,
        deleteCoachingError: action.payload,
      };

    case COACHING_REQUEST.PUBLISH_COACHING_REQUEST:
      return {
        ...state,
        publishCoachingLoading: true,
        publishCoachingSuccess: false,
        publishCoachingFailure: false,
        publishCoachingError: null,
        publishCoaching: [],
      };
    case COACHING_REQUEST.PUBLISH_COACHING_SUCCESS:
      const coachingData = state.getCoaching;
      for (let i = 0; i < coachingData.length; i++) {
        if (coachingData[i].coachingId === action.payload.coachingId) {
          coachingData[i].status = action.payload.status;
        }
      }
      return {
        ...state,
        publishCoachingLoading: false,
        publishCoachingSuccess: true,
        publishCoachingFailure: false,
        publishCoachingError: null,
        publishCoaching: action.payload,
        getCoaching: coachingData,
      };
    case COACHING_REQUEST.PUBLISH_COACHING_FAILURE:
      return {
        ...state,
        publishCoachingLoading: false,
        publishCoachingSuccess: false,
        publishCoachingFailure: true,
        publishCoachingError: action.payload,
      };

    case COACHING_REQUEST.PURCHASE_COACHING_REQUEST:
      return {
        ...state,
        purchaseCoachingLoading: true,
        purchaseCoachingSuccess: false,
        purchaseCoachingFailure: false,
        purchaseCoachingError: null,
        purchaseCoaching: [],
      };
    case COACHING_REQUEST.PURCHASE_COACHING_SUCCESS:
      return {
        ...state,
        purchaseCoachingLoading: false,
        purchaseCoachingSuccess: true,
        purchaseCoachingFailure: false,
        purchaseCoachingError: null,
        purchaseCoaching: action.payload,
      };
    case COACHING_REQUEST.PURCHASE_COACHING_FAILURE:
      return {
        ...state,
        purchaseCoachingLoading: false,
        purchaseCoachingSuccess: false,
        purchaseCoachingFailure: true,
        purchaseCoachingError: action.payload,
      };

    case COACHING_REQUEST.GET_TODAYS_COACHING_LP_REQUEST:
      return {
        ...state,
        getTodaysCoachingLPLoading: true,
        getTodaysCoachingLPSuccess: false,
        getTodaysCoachingLPFailure: false,
        getTodaysCoachingLPError: null,
        getTodaysCoachingLP: [],
      };
    case COACHING_REQUEST.GET_TODAYS_COACHING_LP_SUCCESS:
      return {
        ...state,
        getTodaysCoachingLPLoading: false,
        getTodaysCoachingLPSuccess: true,
        getTodaysCoachingLPFailure: false,
        getTodaysCoachingLPError: null,
        getTodaysCoachingLP: action.payload,
      };
    case COACHING_REQUEST.GET_TODAYS_COACHING_LP_FAILURE:
      return {
        ...state,
        getTodaysCoachingLPLoading: false,
        getTodaysCoachingLPSuccess: false,
        getTodaysCoachingLPFailure: true,
        getTodaysCoachingLPError: action.payload,
      };

    case COACHING_REQUEST.GET_UPCOMING_COACHING_LP_REQUEST:
      return {
        ...state,
        getUpcomingCoachingLPLoading: true,
        getUpcomingCoachingLPSuccess: false,
        getUpcomingCoachingLPFailure: false,
        getUpcomingCoachingLPError: null,
        getUpcomingCoachingLP: [],
      };
    case COACHING_REQUEST.GET_UPCOMING_COACHING_LP_SUCCESS:
      return {
        ...state,
        getUpcomingCoachingLPLoading: false,
        getUpcomingCoachingLPSuccess: true,
        getUpcomingCoachingLPFailure: false,
        getUpcomingCoachingLPError: null,
        getUpcomingCoachingLP: action.payload,
      };
    case COACHING_REQUEST.GET_UPCOMING_COACHING_LP_FAILURE:
      return {
        ...state,
        getUpcomingCoachingLPLoading: false,
        getUpcomingCoachingLPSuccess: false,
        getUpcomingCoachingLPFailure: true,
        getUpcomingCoachingLPError: action.payload,
      };

    case COACHING_REQUEST.UPDATE_COACHING_REQUEST:
      return {
        ...state,
        modifyCoachingLoading: true,
        modifyCoachingSuccess: false,
        modifyCoachingFailure: false,
        modifyCoachingError: null,
        modifyCoaching: [],
      };
    case COACHING_REQUEST.UPDATE_COACHING_SUCCESS:
      return {
        ...state,
        modifyCoachingLoading: false,
        modifyCoachingSuccess: true,
        modifyCoachingFailure: false,
        modifyCoachingError: null,
        modifyCoaching: action.payload,
      };
    case COACHING_REQUEST.UPDATE_COACHING_FAILURE:
      return {
        ...state,
        modifyCoachingLoading: false,
        modifyCoachingSuccess: false,
        modifyCoachingFailure: true,
        modifyCoachingError: action.payload,
      };
    default:
      return state;
  }
};
