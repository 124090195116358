import React from "react";
import { useCookies } from "react-cookie";
import { BiLogInCircle } from "react-icons/bi";
export function SignOut() {
  const Swal = require("sweetalert2");
  const [cookies, remove] = useCookies(["edu_tech_user"]);
  function logout() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",

      showCancelButton: true,
      confirmButtonColor: "#00386e",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        remove("edu_tech_user", { path: "/" });
        // dispatch(success(ACCOUNTS_REQUEST.GET_LINKEDIN_TOKEN_KEY_SUCCESS), null)
        window.localStorage.removeItem("goalTime");
      }
    });
  }
  return (
    <div
      className="swal-card-width"
      onClick={() => {
        logout();
      }}
    >
      <div className="logout-text">
        {"Log Out"}
        <BiLogInCircle size={19} />
      </div>
    </div>
  );
}
