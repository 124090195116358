import { FEED_BACK_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  createFeedbackLoading: false,
  createFeedbackSuccess: false,
  createFeedbackFailure: false,
  createFeedbackError: null,
  createFeedback: [],

  createCoachingFeedbackLoading: false,
  createCoachingFeedbackSuccess: false,
  createCoachingFeedbackFailure: false,
  createCoachingFeedbackError: null,
  createCoachingFeedback: [],
};

export const feedBackReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FEED_BACK_REQUEST.FEED_BACK_REQUEST:
      return {
        ...state,
        createFeedbackLoading: true,
        createFeedbackSuccess: false,
        createFeedbackFailure: false,
        createFeedbackError: null,
      };
    case FEED_BACK_REQUEST.FEED_BACK_SUCCESS:
      return {
        ...state,
        createFeedbackLoading: false,
        createFeedbackSuccess: true,
        createFeedbackFailure: false,
        createFeedbackError: null,
        createFeedback: action.payload,
      };
    case FEED_BACK_REQUEST.FEED_BACK_FAILURE:
      return {
        ...state,
        createFeedbackLoading: false,
        createFeedbackSuccess: false,
        createFeedbackFailure: true,
        createFeedbackError: action.payload,
      };

    case FEED_BACK_REQUEST.COACHING_FFED_BACK_REQUEST:
      return {
        ...state,
        createCoachingFeedbackLoading: true,
        createCoachingFeedbackSuccess: false,
        createCoachingFeedbackFailure: false,
        createCoachingFeedbackError: null,
      };
    case FEED_BACK_REQUEST.COACHING_FFED_BACK_SUCCESS:
      return {
        ...state,
        createCoachingFeedbackLoading: false,
        createCoachingFeedbackSuccess: true,
        createCoachingFeedbackFailure: false,
        createCoachingFeedbackError: null,
        createCoachingFeedback: action.payload,
      };
    case FEED_BACK_REQUEST.COACHING_FFED_BACK_FAILURE:
      return {
        ...state,
        createCoachingFeedbackLoading: false,
        createCoachingFeedbackSuccess: false,
        createCoachingFeedbackFailure: true,
        createCoachingFeedbackError: action.payload,
      };

    default:
      return state;
  }
};
