import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Loader } from "../../../assets";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { GetFAQByProvider } from "../../../redux/actions";

export default function TeacherFaq() {
  const dispatch = useDispatch();
  let auth = useAuth();
  useEffect(() => {
    document.title = `FAQ's || EduTech`;
    dispatch(GetFAQByProvider());
  }, []);
  let providerFaqs = useSelector((state) => state.faq);
  let getProviderFaqsList = providerFaqs?.getFAQByProvider;
  const bookings = [
    {
      id: 0,
      question: "Are your books correlated to state and national standards?",
      answer:
        "Yes, all of our books are correlated to state and national standards. This web site’s correlation page will help you easily match our products with your state standards, and choose excellent selections for national standards. Otherwise, feel free to call us at 1-877-980-4450 and we’ll assist you in meeting your needs.",
    },
    {
      id: 1,
      question: "Are your books on Accelerated Reader®?",
      answer:
        "Accelerated Reader quizzes are available for many of our books. The AR Logo will appear where quizzes are available. For more about AR, please click here.",
    },
    {
      id: 2,
      question: "Do you have “green” products?",
      answer:
        "Many of Cavendish Square’s products are produced with recycled pulp content, allowing our company to pursue its green goals while adding additional value for your eco-conscious purchases.",
    },
    {
      id: 3,
      question: "Do you provide book processing?",
      answer:
        "Cavendish Square gladly provides library processing services. Please call 1-877-980-4450 or email Customer Service to learn more about processing and available and customizable options for your bookshelf needs.",
    },
    {
      id: 4,
      question:
        "Where can I find information about your authors or how can I contact them?",
      answer:
        "Cavendish Square commissions authors who are subject-matter experts and professional writers. If you’d like to learn more about an author’s background or if you wish to contact one of our authors, please click here to submit your contact information along with the author’s name.",
    },
    {
      id: 5,
      question: "Are your books available in other formats, such as eBooks?",
      answer:
        "Cavendish Square titles are also available as eBooks. Also available are over 50 database titles. Click here for more information on our eBooks and digital databases. For a complete list of available digital resources, please call Customer Service at 1-877-980-4450 between 9AM and 5 PM EST, Monday through Friday.",
    },
  ];
  const [faqOpen, setFaqOpen] = useState(null);
  return (
    <>
      <div className="edutech-content mb-3 mt-2">
        <h1 className="edutech-faqs-heading">FAQ's</h1>
        {/* <h3>OUR BOOKS & MATERIALS</h3> */}
        <>
          {providerFaqs &&
            providerFaqs.getFAQByProviderLoading === true &&
            providerFaqs.getFAQByProviderSuccess === false &&
            providerFaqs.getFAQByProviderFailure === false &&
            <Loader />}
          {providerFaqs &&
            providerFaqs.getFAQByProviderLoading === false &&
            providerFaqs.getFAQByProviderSuccess === false &&
            providerFaqs.getFAQByProviderFailure === true && (
              <ErrorMessage message={providerFaqs.getFAQByProviderError} />
            )}
          {providerFaqs &&
            providerFaqs.getFAQByProviderLoading === false &&
            providerFaqs.getFAQByProviderSuccess === true &&
            providerFaqs.getFAQByProviderFailure === false && (
              <div>
                {getProviderFaqsList.map((book, index) => {
                  return (
                    <div key={index} className="faq_section">
                      <input
                        type="checkbox"
                        id={index}
                        name="q"
                        className="edutech-questions"
                      />
                      <div
                        onClick={() => setFaqOpen(index)}
                        className={`edutech-plus ${faqOpen === index ? "active" : ""
                          }`}
                      >
                        +
                      </div>
                      <label
                        for={index}
                        onClick={() => setFaqOpen(index)}
                        className={`edutech-question ${faqOpen === index ? "active" : ""
                          }`}
                      >
                        {book.question}
                      </label>
                      <div className="edutech-answers">
                        <p>{book.answer} </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
        </>

      </div>
    </>
  );
}
