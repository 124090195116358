import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { AdminDashboardTopCoursesGraph } from "../../../redux/actions/dashboardTilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import {  dataByTopCourses } from "../../../assets";
export default function TopCourses() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let GetTilesCount = useSelector((state) => state.dashboardTiles);
  let CoursesData = GetTilesCount && GetTilesCount.adminDashboardTopCoursesGraph;
  useEffect(() => {
    // Update the document title using the browser API
    dispatch(AdminDashboardTopCoursesGraph(auth));
    document.title = `Dashboard || EduTech`;
  }, []);
  let pieoption = {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Top Courses'
    },
    subtitle: {
      text: ''
    },
    accessibility: {
      announceNewData: {
        enabled: true
      },
      point: {
        valueSuffix: ''
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.y}'
        }
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },
    series: [
      {
        name: "Courses",
        colorByPoint: true,
        data: dataByTopCourses(GetTilesCount.adminDashboardTopCoursesGraph)
      }
    ]
  }
  return (
    <div>
      {
        GetTilesCount
        &&
        GetTilesCount.adminDashboardTopCoursesGraphSuccess === true
        &&
        <>
          <PieChart highcharts={Highcharts} options={pieoption} />
        </>
      }

    </div>
  );
}
