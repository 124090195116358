import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { RiArrowUpDownFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeConvert } from "../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { GetByFilterConatctUs } from "../../../redux/actions/contactUsAction";
export default function ContactUsAdmin() {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetByFilterConatctUs());
  }, []);
  let getContactUsData = useSelector((state) => state.contactUs);
  let data = getContactUsData && getContactUsData.getByFilterConatctUs;
  let columns = [
    {
      dataField: "studentName",
      text: "Full Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "role",
      text: "Role",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        return (
          <span>
            <ViewDescription row={row} />
          </span>
        );
      },
    },
    {
      dataField: "contactType",
      text: "Contact Type",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        return (
          <span>
            {row.contactType === "Inquiry" && (
              <span className="unpublish-btn-color">{"Inquiry"} </span>
            )}
            {row.contactType === "Feedback" && (
              <span className=" publish-btn-color">{"Feedback"}</span>
            )}
            {row.contactType === "Comaplaint" && (
              <span className="complain-btn-color">{"Comaplaint"} </span>
            )}
            {/* ) : (
            <span className="unpublish-btn-color">
              {" "}
              {row.status !== "UNPUBLISHED" ? "" : "UNPUBLISHED"}
            </span>
            )} */}
          </span>
        );
      },
    },
    {
      dataField: "createdDate",
      text: "Date",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        return (
          <span>
            <>{dateTimeConvert(row.createdDate) ? dateTimeConvert(row.createdDate) : "N/A"}</>
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      <div className="teacher-curriculum-dashboard ">
        <div
          className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
        >
          <div className="card-header ">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className=" font-weight-700 mb-0">Contact Us</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
        <MyDataTable
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
        />
      </div>
    </>
  );
}
function ViewDescription(props) {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState(props.row.description);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span className="view-style" onClick={handleShow}>View</span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Description:</Form.Label>
            <Form.Control
              className="mt-1"
              name="description"
              as="textarea"
              maxlength="300"
              rows={3}
              placeholder="Enter Your Description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              disabled
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
