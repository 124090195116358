import React, { useEffect } from "react";
import { BsBell } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { IMAGES } from "../../assets/images";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  GetNotificationByUserIdData,
  MarkAsReadData,
} from "../../redux/actions";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
export function Notification() {
  let auth = useAuth();
  let Token = auth?.edu_tech_user?.token;
  let dispatch = useDispatch();
  useEffect(() => {
    {
      dispatch(GetNotificationByUserIdData(auth));
      const interval = setInterval(() => {
        dispatch(GetNotificationByUserIdData(auth));
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [dispatch]);
  let NotificationByIdData = useSelector((state) => state.notification);
  let NotificationReducerData =
    NotificationByIdData && NotificationByIdData.GetNotificationByUserId;
  var IsReadCount = NotificationReducerData.filter(
    (list) => list.isRead === false
  ).length;
  function NotificationMarkAsRead(notification) {
    //
    dispatch(MarkAsReadData(notification, moveToNext));
  }
  function moveToNext() {
    dispatch(GetNotificationByUserIdData(auth));
  }
  return (
    <Dropdown className="main-drop-down-notification notificationList notify">
      <Dropdown.Toggle
        className="custom-grey-button dropdown-toggle nav-link dropdown_bell"
        id="dropdown-custom-components"
      >
        <p className="no-margin">
          <BsBell
            data-tip
            data-for="notification-icon"
            className="header-icon-tool"
          />
          <span className="IsReadCountAlignment ">{IsReadCount}</span>
          {/* <GoPrimitiveDot size={18} className="GoPrimitiveDot " /> */}
        </p>
      </Dropdown.Toggle>
      <Dropdown.Menu className="notification-list">
        {NotificationReducerData.length === 0 ? (
          <p className="text-center  pt-4">No Notifications Yet</p>
        ) : (
          <>
            {NotificationReducerData.map((notification, index) => {
              return (
                <Dropdown.Item
                  href="javascript:void(0)"
                  className="notification-message"
                >
                  <div className="media">
                    <span className="avatar avatar-sm mr-2">
                      <img
                        className="avatar-img rounded-circle"
                        alt="User"
                        src={IMAGES.AVATAR}
                      />
                    </span>
                    <div
                      className="media-body d-flex justify-content-between "
                      onClick={() => {
                        NotificationMarkAsRead(notification.notificationId);
                      }}
                      key={index}
                    >
                      <p className="noti-details">
                        {/* <span className="noti-title">Dr. Ruby Perrin</span>{" "} */}
                        <span className="noti-title">
                          {notification.message}
                        </span>{" "}
                        {/* Schedule{" "} */}
                        {/* <span className="noti-title">her appointment</span> */}
                      </p>
                      {notification.isRead === false ? (
                        <p className="noti-time">
                          {/* <GoPrimitiveDot
                            size={18}
                            className="GoPrimitiveDot "
                          /> */}
                          {/* <span className="notification-time">4 mins ago</span> */}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Dropdown.Item>
              );
            })}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
