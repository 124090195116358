import React, { useState } from "react";
import { Button, Col, Modal, Row, Card } from "react-bootstrap";
import { IMAGES, TeacherCourseTabs } from "../../../../../assets";
import PricingModals from "./PricingModals";
import { PATH } from "../../../../../config";
import { BsArrowLeft } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { CreateCoursePricing } from "../../../../../redux/actions/coursesAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../../Navigation/Auth/ProvideAuth";
export default function PricingPlan() {
  const [lgShow, setLgShow] = useState(false);
  const [showPrizeModal, setprizeModal] = useState(0);
  const [Prizeindex, setPrizeIndex] = useState(0);
  function setModalValue(val) {
    setPrizeIndex(val);
    setprizeModal(1);
  }
  let CourseValue = useSelector((state) => state.courses);
  console.log("askjhdkjahsd", CourseValue)
  let auth = useAuth()
  let dispatch = useDispatch();
  let history = useHistory();
  function goBack() {
    history.push({
      pathname: PATH.TEACHERCURRICULUMLIST,
      // state: { fromPreviewPage: false },
    });
  }
  function goNext(data) {
    let finaldata = {
      "courseId": parseInt(CourseValue.createCourses),
      "isDiscountAvailable": false,
      "pricePlanId": 150,
      "price": 0,
      "discountedPrice": 0
    }
    console.log("asdhasdkjhwq", finaldata)
    dispatch(CreateCoursePricing(finaldata, auth, moveToNext));
  }
  function moveToNext() {
    history.push({
      pathname: PATH.TEACHERCOURSEINFORMATION,
      state: {
        purchasedCourseDataId: CourseValue.createCourses,
      },
    });
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        {/* <BsArrowLeft size={35} className="cursor" onClick={() => goBack()} /> */}
      </div>
      <Card className="bg-color:white mx-md-5 my-5 mb-0 p-md-5 create-pricing text-center">
        <Card.Body>
          <h1 className="mb-3">Create a Pricing Plan</h1>
          <p className="f-16">
            Add a pricing plan to your course so your students can compensate
            you.
          </p>
          <Button onClick={() => setLgShow(true)} type="button">
            Add Pricing Plan
          </Button>
        </Card.Body>
      </Card>
      <div className="teacher-curriculum-dashboard d-flex align-items-center justify-content-end">
        <Link
          to={{
            pathname: PATH.TEACHERCOURSEINFORMATION,
          }}
        >
          <button
            className="create_course w-100 align-items-center d-flex"
            type="button"
          >
            Next <HiArrowRight className="ml-1" size={20} />
          </button>
        </Link>
      </div>
      <div className="d-flex align-items-center justify-content-center coursetabs_height_set">
        <div className="teachergroupbtn">
          <TeacherCourseTabs />
        </div>
      </div>
      {showPrizeModal === 1 ? (
        <PricingModals
          value={Prizeindex}
          prevModal={setprizeModal}
          setprizeModal={setprizeModal}
          setmainmodal={setLgShow}
        />
      ) : (
        <Modal
          size="lg"
          show={lgShow}
          aria-labelledby="example-modal-sizes-title-lg"
          className="teacherCourseModal X"
          centered
        >
          <Modal.Body>
            <IoCloseSharp
              className="modalclose"
              onClick={() => setLgShow(false)}
            />
            <Row className="d-flex justify-content-center">
              <Col md={6} className="pricing-div">
                <div
                  className={`mb-2 d-flex align-items-center divhover `}
                  onClick={() => goNext()}
                >
                  <div>
                    <img src={IMAGES.FREE} alt="" />
                  </div>
                  <div className="ml-4">
                    <h5>FREE</h5>
                    <p>Allow access to your content free of charge</p>
                  </div>
                </div>
                <div
                  className={`mb-2 d-flex align-items-center divhover `}
                  onClick={() => setModalValue(1)}
                >
                  <div>
                    <img src={IMAGES.FREE} alt="" />
                  </div>
                  <div className="ml-4">
                    <h5>PRICE</h5>
                    <p>Set up one-time payment</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
