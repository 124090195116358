import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Dropdown,
  Accordion,
  Tab,
  Tabs,
  Modal,
  Form,
} from "react-bootstrap";
import { AiOutlineDownload, AiOutlinePlusCircle } from "react-icons/ai";
import { BiCaretDown, BiDownload } from "react-icons/bi";
import { BsFilesAlt } from "react-icons/bs";
import { FieldError, IMAGES, Loader, Loader1 } from "../../../assets";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { IoCloseSharp } from "react-icons/io5";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  CertificateConversion,
  CertificateReceived,
  CreateNotes,
  GetAllNotesByLecture,
  GetById,
  GetCourseCertification,
  GetVideoTimeStamp,
  MarkCourseAsStarted,
  UpdateProviderProfile,
  UpdateVideoTimeStamp,
} from "../../../redux/actions";
import { useForm } from "react-hook-form";
import { checkQuizAnswer } from "../../../redux/api";
import { COURSES_REQUEST, success } from "../../../redux/actions/utilities";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";

export default function DashboardBreakthrough() {
  const location = useLocation();
  const TopCourses = location?.state?.Courses;
  const StudentPurchasedCourses = location?.state?.purchasedCourseDataId;
  const InProgressCourses = location?.state?.myprogressCourseDataList;
  const CurrentProgressCourses = location?.state?.currentprogressCourseDataList;
  return (
    <>
      {TopCourses === "PURCHASECOURSEBREAKTHROUGH" ? (
        <DashboardBreakthroughData
          StudentPurchasedCourses={StudentPurchasedCourses}
        />
      ) : TopCourses === "CURRENTPROGRESSBREAKTHROUGH" ? (
        <CurrentProgressDashboardBreakthrough
          CurrentProgressCourses={CurrentProgressCourses}
        />
      ) : (
        <MyProgressDashboardBreakthrough
          InProgressCourses={InProgressCourses}
        />
      )}
    </>
  );
}

function DashboardBreakthroughData(props) {
  let GetCourseValue = useSelector((state) => state.courses);
  let CoursesData = GetCourseValue && GetCourseValue.getById;
  const [activeStep, setActiveStep] = React.useState(0);
  const [lectureactiveStep, setLectureActiveStep] = React.useState(
    CoursesData?.sections?.[0]?.sectionId +
      CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
  );
  const [lectureName, setLectureName] = React.useState("");
  const [lectureID, setLectureID] = React.useState(
    CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
  );
  const [lectureNotesData, setLectureNotes] = React.useState(
    CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
  );
  const [currentLectureIndex, setCurrentLectureIndex] = React.useState(0);
  const [showfunc, setfunc] = React.useState(0);
  const [showQuiz, setShowQuiz] = React.useState(false);
  const [condition, setCondition] = useState(0);
  const [showCertificate, setCertificateSection] = useState(false);
  const [certificateURL, setCertificateURL] = useState("");
  const location = useLocation();
  let auth = useAuth();
  let dispatch = useDispatch();
  useEffect(() => {
    document.title = `Course Information || EduTech`;
    dispatch(GetById(props.StudentPurchasedCourses, auth));
    dispatch(GetAllNotesByLecture(lectureNotesData, auth));
  }, [dispatch]);
  // useEffect((step) => {
  //   dispatch(GetAllNotesByLecture(step, auth));
  // }, [dispatch]);
  function LectureNotes(step) {
    dispatch(GetAllNotesByLecture(step, auth));
  }
  let studentName =
    auth.edu_tech_user.firstName + " " + auth.edu_tech_user.lastName;
  let NotesValue = useSelector((state) => state.lectureNotes);
  let LectureNotesCourses = NotesValue && NotesValue.getAllNotesByLecture;
  if (CoursesData?.sections) {
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setfunc((prevActiveStep) => prevActiveStep + 1);
    if (showfunc === 0) {
      setCondition(0);
    }
    if (showfunc === 1) {
      setCondition(1);
    }
    if (showfunc === 2) {
      setCondition(2);
    }
    if (showfunc === 3) {
      setCondition(3);
    }
    if (showfunc === 4) {
      setCondition(4);
    }
    if (showfunc === 5) {
      setCertificateSection(true);
    }
    // if (showfunc == 4) {
    //   setCondition(0);
    // }
  };
  const handleReset = () => {
    setActiveStep(0);
    if (condition === false) {
      setCondition(true);
    } else {
      setCondition(false);
    }
    setActiveStep(0);
    setfunc(0);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setfunc((prevActiveStep) => prevActiveStep - 1);
  };
  const ANSWAER_CHOOSE = [
    {
      lookupId: 1,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 1,
    },
    {
      lookupId: 2,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 2,
    },
    {
      lookupId: 3,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 3,
    },
    {
      lookupId: 4,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 4,
    },
  ];
  const ANSWAER_CHOOSE1 = [
    {
      lookupId: 5,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 5,
    },
    {
      lookupId: 6,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 6,
    },
    {
      lookupId: 7,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 7,
    },
    {
      lookupId: 8,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 8,
    },
  ];
  const [button, setButton] = useState(0);
  const [button1, setButton1] = useState(0);
  let urls = [
    ["https://www.youtube.com/embed/8C46CbYocXI"],
    ["https://www.youtube.com/embed/E9lAeMz1DaM"],
    ["https://www.youtube.com/embed/8C46CbYocXI"],
    ["https://www.youtube.com/embed/nlMuHtV82q8"],
    ["https://www.youtube.com/embed/E9lAeMz1DaM"],
  ];
  const newFunc = (mapId, list, step) => {
    let newMapId = 0;
    let actualLectureIndex = 0;
    if (showfunc !== mapId) {
      newMapId = mapId;
      actualLectureIndex = 0;
    } else {
      newMapId = mapId;
      actualLectureIndex = currentLectureIndex + 1;
    }
    setfunc(newMapId);
    setActiveStep(list.lectures[actualLectureIndex.toString()].lectureVideo);
    setLectureName(step.lectureName);
    setLectureID(step.lectureId);
    setLectureNotes(step.lectureId);
    LectureNotes(step.lectureId);
    setCurrentLectureIndex(actualLectureIndex);
    setLectureActiveStep(
      list.sectionId + list.lectures[actualLectureIndex].lectureId
    );
  };
  return (
    <>
      <div className="row dashboard-page business-breakthrough-dashboard">
        <div className="col-sm-12 col-lg-12 col-md-12 p-4 bg-white mb-4 pb-2 div-rounded-30  d-flex">
          <>
            <div className="col-md-4">
              {CoursesData &&
                CoursesData.sections &&
                CoursesData.sections.map((list, mapId) => {
                  return (
                    <>
                      <Accordion
                        defaultActiveKey={mapId.toString()}
                        className="bg-white"
                        key={mapId}
                      >
                        <div className="mb-0 bg-white ">
                          <Card.Header className="pl-1 pt-1 bg-white">
                            <div className="row justify-content-between bg-white">
                              <div className="col-10 bg-white">
                                <Accordion.Toggle
                                  className="p-0 bg-white"
                                  as={Button}
                                  Introduction
                                  to
                                  Business
                                  Breakthrough
                                  variant="link"
                                  eventKey={mapId.toString()}
                                >
                                  <h3 className="text-dark m-0 p-0 font-size-22 accordin-title font-weight-700 text-left">
                                    {/* Business Breakthrough */}
                                    {/* {list.lectures[mapId].lectureName} */}
                                    {list.sectionName}
                                  </h3>
                                </Accordion.Toggle>
                              </div>
                              <div className="col-2  text-right">
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={mapId.toString()}
                                >
                                  <BiCaretDown className="text-dark set-icon-font-size" />
                                </Accordion.Toggle>
                              </div>
                            </div>
                            {/* <p cla86ssName=" mb-0 pt-0 font-size-12 text-dark text-left font-weight-600">
                              6 videos | Total: 19 minutes
                            </p> */}
                          </Card.Header>
                          <Accordion.Collapse eventKey={mapId.toString()}>
                            <Box sx={{ maxWidth: 400 }}>
                              <Stepper
                                orientation="vertical"
                                activeStep={lectureactiveStep}
                              >
                                {list.lectures.map((step, lectureID) => {
                                  return (
                                    <Step
                                      type="button"
                                      key={list.sectionId + step.lectureId}
                                      index={list.sectionId + step.lectureId}
                                      // index={ lectureID}
                                    >
                                      <StepLabel
                                        icon={
                                          <span className="stepper-active">
                                            {lectureID + 1}
                                          </span>
                                        }
                                        key={list.sectionId + step.lectureId}
                                        // key={mapId + "_" + lectureID}
                                        id={`uniqueId_${
                                          mapId + "_" + lectureID
                                        }`}
                                        onClick={() =>
                                          newFunc(mapId, list, step)
                                        }
                                        optional={
                                          mapId === 0 && (
                                            <Typography
                                              variant="caption"
                                              className="cursor"
                                              // onClick={() => setfunc(mapId) || setActiveStep(list.lectures[(mapId).toString()].lectureVideo) || setLectureName(list.sectionName)}
                                            >
                                              {/* {mapId} minutes */}
                                            </Typography>
                                          )
                                        }
                                      >
                                        {step.lectureName}
                                      </StepLabel>
                                      <StepContent>
                                        {/* {mapId === step.length - 1 ? (
                                            <Box sx={{ mb: 2 }}>
                                              <div>
                                                <Button
                                                  className="text-white"
                                                  variant="contained"
                                                  onClick={handleNext}
                                                  sx={{ mt: 1, mr: 1 }}
                                                >
                                                  Finish
                                                </Button>
                                              </div>
                                            </Box>
                                          ) : (
                                            <Button
                                              disabled={lectureID === 0}
                                              onClick={handleBack}
                                              sx={{ mt: 1, mr: 1 }}
                                            >
                                              Back
                                            </Button>
                                          )} */}
                                        <div
                                          className="row mt-2"
                                          sx={{ mb: 2, mt: 2 }}
                                        >
                                          <div className="col-12">
                                            <div className="float-right text-right">
                                              <div>
                                                {showfunc === mapId && (
                                                  <small></small>
                                                )}
                                                {/* {showfunc === 1 && <small></small>} */}
                                                {/* {showfunc === 2 && (
                                                    <small className="invisible">" "</small>
                                                  )} */}
                                              </div>
                                              <button
                                                className="btn btn-primary"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 3 }}
                                              >
                                                {mapId === step.length - 1
                                                  ? "Finish"
                                                  : "Next"}
                                              </button>
                                            </div>
                                            <div className="float-left">
                                              <div>
                                                {showfunc === 0 && (
                                                  <small className="invisible">
                                                    " "
                                                  </small>
                                                )}
                                                {showfunc === 1 && (
                                                  <small></small>
                                                )}
                                                {showfunc === 2 && (
                                                  <small></small>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </StepContent>
                                    </Step>
                                  );
                                })}
                              </Stepper>
                              {activeStep > activeStep.length && (
                                <Paper square elevation={1} sx={{ p: 3 }}>
                                  <Typography>
                                    All steps completed - you&apos;re finished
                                  </Typography>
                                  <Button
                                    onClick={handleReset}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    Reset
                                  </Button>
                                </Paper>
                              )}
                            </Box>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                    </>
                  );
                })}
            </div>
            <div className="col-md-8">
              <>
                {showQuiz === false ? (
                  <VideoSection
                    // mapId={mapId}
                    setShowQuiz={setShowQuiz}
                    setCurrentLectureIndex={setCurrentLectureIndex}
                    showfunc={showfunc}
                    newFunc={newFunc}
                    handleNext={handleNext}
                    currentLectureIndex={currentLectureIndex}
                    urls={urls[showfunc] ? urls[showfunc] : ""}
                    condition={condition}
                    firstIndexVideo={
                      CoursesData?.sections?.[0]?.lectures?.[0]?.lectureVideo
                    }
                    firstIndexLectureName={
                      CoursesData?.sections?.[0]?.lectures?.[0]?.lectureName
                    }
                    lectureName={lectureName}
                    activeStep={activeStep}
                    lectureactiveStep={lectureactiveStep}
                    setActiveStep={setActiveStep}
                    setLectureActiveStep={setLectureActiveStep}
                    CoursesData={CoursesData}
                    setLectureID={setLectureID}
                    lectureID={lectureID}
                    lectureNotesData={lectureNotesData}
                    LectureNotesCourses={LectureNotesCourses}
                  />
                ) : showCertificate === false ? (
                  <QuizSection
                    setCertificateSection={setCertificateSection}
                    ANSWAER_CHOOSE={ANSWAER_CHOOSE}
                    setShowQuiz={setShowQuiz}
                    setCurrentLectureIndex={setCurrentLectureIndex}
                    newFunc={newFunc}
                    setButton={setButton}
                    certificateURL={certificateURL}
                    setCertificateURL={setCertificateURL}
                    showfunc={showfunc}
                    button={button}
                    CoursesData={CoursesData}
                    ANSWAER_CHOOSE1={ANSWAER_CHOOSE1}
                    setButton1={setButton1}
                    button1={button1}
                  />
                ) : (
                  <ShowCerificate
                    studentName={studentName}
                    CoursesData={CoursesData}
                    certificateURL={certificateURL}
                  />
                )}
              </>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

function QuizSection(props) {
  const StudentTopCourses = props?.CoursesData?.sections;
  const [isSelected, setIsSelected] = useState("");
  let dispatch = useDispatch();
  let auth = useAuth();
  function CheckQuizAnswerFunction(questionOption) {
    // dispatch(CheckQuizAnswer(questionOption, auth));
    checkQuizAnswer(questionOption, auth)
      .then((response) => {
        setIsSelected({
          id: questionOption,
          flag: response.data,
        });
      })
      .catch((error) => {});
  }
  const QuizAnswerSelection = (questionOption) => {
    props.setButton(questionOption);
    CheckQuizAnswerFunction(questionOption);
    setIsSelected(questionOption);
  };
  function downloadApiCall() {
    dispatch(CertificateReceived(props.CoursesData.courseId, auth));
  }

  function generateCertificateUrl() {
    let finalData = {
      courseId: props?.CoursesData?.courseId,
    };
    dispatch(CertificateConversion(finalData, auth, moveToNext));
  }
  let GetCourseValue = useSelector((state) => state.courses);
  console.log("certificateLog", GetCourseValue);
  function moveToNext(url) {
    props?.setCertificateURL(url);
    if (url) {
      props?.setCertificateSection(true);
    }
  }

  // let CoursesData = GetCourseValue && GetCourseValue.getById;
  return (
    <>
      <div className="col-sm-12 col-lg-12 col-md-12 section-quiz">
        <Card className="py-4">
          <div className="d-flex align-items-center justify-content-between px-4">
            <div>
              <h4
                className="mb-0 font-weight-800 cursor float-right"
                onClick={() => props?.setCertificateSection(true)}
              >
                Skip Quiz
              </h4>
            </div>
          </div>
          <hr />
          {/* copy here */}
          {StudentTopCourses?.map((section, sectionId) => {
            return (
              <>
                <p className="ml-4 font-weight-700">
                  {section.sectionName} Quizes
                </p>
                {section?.lectures?.map((lecture, lectureId) => (
                  <>
                    <div className=" mt-3 pl-1 px-4" key={lectureId}>
                      <div className="font-weight-700 ">
                        {lecture.lectureName}
                      </div>
                      <br />
                      {lecture?.quiz?.map((quiz, quizId) => (
                        <>
                          {quiz?.quizQuestions?.map(
                            (quizQuestion, quizQuestionId) => (
                              <>
                                <p>{quizQuestion?.questionDescription}</p>
                                {quizQuestion?.quizOptions?.map(
                                  (questionOption, questionOptionId) => (
                                    <p
                                      key={questionOptionId}
                                      onClick={() => {
                                        QuizAnswerSelection(
                                          questionOption?.quizOptionId
                                        );
                                      }}
                                      className={`cursor set-p-tag-backround pl-4 ml-5 ${
                                        props.button ===
                                        questionOption?.quizOptionId
                                          ? questionOption?.quizOptionId ===
                                              isSelected?.id &&
                                            isSelected.flag === false
                                            ? "active-wrong"
                                            : "active-success"
                                          : ""
                                      }`}
                                    >
                                      {questionOption?.optionDescription}
                                    </p>
                                  )
                                )}
                              </>
                            )
                          )}
                        </>
                      ))}
                      {/* <div className="px-4 ">
                            <p className="font-weight-700">Q.{lectureId + 1}</p>
                          </div> */}
                    </div>
                  </>
                ))}
              </>
            );
          })}

          {/* ----- */}
          {GetCourseValue?.certificateConversionLoading === true ? (
            <Loader />
          ) : (
            <>
              <div className="col-md-2 mx-auto">
                <button
                  className="btn btn-primary submit-quiz text-end float-right "
                  onClick={() => generateCertificateUrl()}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Card>
      </div>
    </>
  );
}

function ShowAddModal(props) {
  let firstindexlectureID = props?.lectureID
    ? props?.lectureID
    : props?.CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId;
  let dispatch = useDispatch();
  let auth = useAuth();
  const { register, handleSubmit, errors } = useForm();
  const [notesTittle, setNotesTitle] = useState("");
  const [notesDescription, setNotesDescription] = useState("");
  function onSubmit() {
    props.onHide();
    let finaldata = {
      lectureId: firstindexlectureID,
      noteTitle: notesTittle,
      noteDescription: notesDescription,
    };
    dispatch(CreateNotes(finaldata, auth, moveToNext));
  }
  function moveToNext(step) {
    setNotesTitle(null);
    setNotesDescription(null);
    dispatch(GetAllNotesByLecture(firstindexlectureID, auth));
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="Notesheader">
        <div className="d-flex justify-content-center align-items-center modalheader ">
          <p>Add Notes</p>
          <IoCloseSharp className="modalclose" onClick={props.onHide} />
        </div>
        <hr />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              className="mt-1"
              name="notesTittle"
              type="text"
              placeholder="Add Notes Tittle"
              value={notesTittle}
              onChange={(e) => {
                setNotesTitle(e.target.value);
              }}
              ref={register({ required: true })}
              style={{
                borderColor: errors && errors.notesTittle ? "#a80000" : "",
              }}
            />
            {errors.notesTittle && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              className="mt-1"
              name="notesDescription"
              type="text"
              placeholder="Add Notes Description"
              value={notesDescription}
              onChange={(e) => {
                setNotesDescription(e.target.value);
              }}
              ref={register({ required: true })}
              style={{
                borderColor: errors && errors.notesDescription ? "#a80000" : "",
              }}
            />
            {errors.notesDescription && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <div className="d-flex justify-content-center ">
            <button type="submit" className="next-course-btn btn-save w-25">
              Submit
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function PurchaseVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Notes Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.lectureNotes.noteTitle}</h4>
        <p>{props.lectureNotes.noteDescription}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ShowCerificate(props) {
  let auth = useAuth();
  let dispatch = useDispatch();
  useEffect(() => {
    document.title = `Course Information || EduTech`;
    dispatch(GetCourseCertification(props.CoursesData.courseId, auth));
  }, []);
  let GetCourseCertificate = useSelector((state) => state.courses);
  let CourseCertificateData =
    GetCourseCertificate && GetCourseCertificate.getCourseCertification;

  function viewImage(url) {
    var url2;
    var fileExtension = url.substr(url.lastIndexOf(".") + 1);
    if (
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg"
    ) {
      var image = new Image();
      image.src = url;
      ////$("#imgTag").attr("src", url);
      //$(".imageViewrBody").html("")
      //$(".imageViewrBody").html(image.outerHTML)
      //$("#imageViewer").modal("show");
      var w = window.open("");
      w.document.write(image.outerHTML);
      w.stop();
    } else if (fileExtension === "pdf") {
      url2 = "https://docs.google.com/viewer?url=" + url;
      window.open(url2, "_blank");
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      url2 = "https://docs.google.com/viewer?url=" + url;
      window.open(url2, "Image Viewer", "_blank");
    } else if (fileExtension === "ppt") {
      url2 = "https://docs.google.com/viewer?url=" + url;
      window.open(url2, "Image Viewer", "_blank");
    } else if (fileExtension === "xlsx" || fileExtension === "xls") {
      url2 = "https://docs.google.com/viewer?url=" + url;
      window.open(url2, "Image Viewer", "_blank");
    } else if (fileExtension === "txt") {
      url2 = "https://docs.google.com/viewer?url=" + url;
      window.open(url2, "Image Viewer", "_blank");
    }

    //window.open(url);
  }
  const asdqwewqqsd = () => {
    var element = document.createElement("a");
    var file = new Blob(
      [
        "https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg",
      ],
      { type: "image/*" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "image.jpg";
    element.click();
  };
  const [pic, setPic] = useState(
    CourseCertificateData?.[0]?.courseCertificateURL
  );
  useEffect(() => {
    setPic(CourseCertificateData?.[0]?.courseCertificateURL);
  }, [CourseCertificateData]);
  const download = (e) => {
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png", "image.pdf", "image.jpg"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {});
  };
  function downloadApiCall() {
    dispatch(CertificateReceived(props.CoursesData.courseId, auth));
  }

  const printToPdf = () => {
    html2canvas(document.getElementById("print_to_pdf")).then((canvas) => {
      var data = canvas.toDataURL();
      // var pdfExportSetting = {
      //   pageOrientation: "portrait",
      //   pageSize: {
      //     width: 850,
      //     height: "auto",
      //   },
      //   // pageMargins: [40, 60, 40, 60],
      //   content: [
      //     {
      //       image: data,
      //       width: 750,
      //     },
      //   ],
      // };
      var pdfExportSetting = {
        // pageOrientation: "portrait",
        compress: false,
        pageSize: {
          width: 920,
          height: 650,
        },
        // pageMargins: [40, 60, 40, 60],
        content: [
          // {
          //   image: data,
          //   width: 480,
          //   height: 500,
          // },
          {
            image: data,
            width: 840,
          },
        ],
      };
      pdfMake.createPdf(pdfExportSetting).download("Certificate.pdf");
    });
  };
  return (
    <div className="col-sm-12 col-lg-12 col-md-12 Certication text-center">
      <Card className="">
        <Card.Body>
          <p className="fw-bold text-center firstheading">
            Congratulations, {props.studentName ? props.studentName : "N/A"}!
          </p>
          <p className="text-center text-muted para">
            You have Completed Course Successfully!
          </p>

          {/* <div id="print_to_pdf">
            <button className="btn btn-primary submit-quiz mt-2"
              onClick={() => {
                    downloadApiCall();
                    printToPdf();
                  }}>
              Download Certificate
            </button>
          </div> */}
          <Card className="certicate-card mt-5 text-left">
            <Card.Body className="overflow-auto">
              <div className="background-image-certificate " id="print_to_pdf">
                <div className="row p-5">
                  <div className="col-md-8 col-6">
                    <p className="cert-text-size mb-0">CERTIFICATE</p>
                    <p className="appreciate-text">OF APPRECIATION</p>
                  </div>
                  <div className="col-md-4 col-6 pt-4 parent-class">
                    <span className="f-20 query ml-5">November 12th 2022</span>
                  </div>
                </div>
                <div className="row pl-5">
                  <div className="col-md-12 col-12 ">
                    <p className="f-20 ">This award is proudly given to:</p>
                    <p className="name-style text-uppercase">
                      {props.studentName ? props.studentName : "N/A"}
                    </p>
                    <p className="f-20 text-uppercase">
                      Completed :{" "}
                      {props?.CoursesData?.courseTitle
                        ? props.CoursesData.courseTitle
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row p-5">
                  <div className="col-md-8">
                    <p className="fw-600 mb-4 authorize-text">Authorized by:</p>
                    <p className="mb-0">____________</p>
                    <p className="ml-3 Principal-text">The Principal</p>
                    <span className="signature-style">
                      <img
                        src={IMAGES.SIGNCERT}
                        alt="signature"
                        className="img-fluid "
                      />
                    </span>
                  </div>
                  <div className="col-md-4 ">
                    <img
                      src={IMAGES.EDUTECH_LOGO_SMALL}
                      className="img-fluid candletech-logo"
                      alt="edutech-logo"
                    />
                  </div>
                </div>
              </div>
              <div className=" mt-4 d-flex justify-content-center">
                <a href={props?.certificateURL} download>
                  <button className="notes-download-btn">
                    <BiDownload className="download-btn-style" />
                    {"Download"}
                  </button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
}

function VideoSection(props) {
  let time = 0;
  let auth = useAuth();
  let firstindexlectureID = props?.lectureID
    ? props?.lectureID
    : props?.CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId;
  let dispatch = useDispatch();
  const [showNextButton, setShowNextButton] = useState(false);
  const [modalShowForNotification, setModalShowForNotification] =
    React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [notesId, setNotesId] = React.useState("");
  const clickOnNext = () => {
    let selector =
      "uniqueId_" + props.showfunc + "_" + (props.currentLectureIndex + 1);
    let findObj = document.getElementById(selector);
    if (findObj !== null) {
      findObj.click();
    } else {
      props.setCurrentLectureIndex(0);
      selector = "uniqueId_" + (props.showfunc + 1) + "_" + 0;
      findObj = document.getElementById(selector);
      if (findObj !== null) {
        findObj.click();
      } else {
        props.setShowQuiz(true);
      }
    }
  };
  const [videoUrl, setVideoUrl] = useState(props.activeStep);
  const [played, setPlayed] = useState(0);
  let GetCourseValue = useSelector((state) => state.courses);
  // useEffect(() => {

  //   return onPause

  //   // onPause();
  //   // dispatch(UpdateVideoTimeStamp(props.CoursesData.courseId, props.lectureID, auth));

  // }, []);
  function onStart() {
    if (props.activeStep === 0) {
      dispatch(MarkCourseAsStarted(props.CoursesData.courseId, auth));
    }
  }
  function onPause() {
    // if (props.activeStep === 0) {
    let finalData = {
      courseId: props.CoursesData.courseId,
      lectureId: firstindexlectureID,
      videoTimeStamp: Math.floor(played),
    };
    dispatch(UpdateVideoTimeStamp(finalData, auth));
  }

  const onProgress = (progress) => {
    setPlayed(progress.playedSeconds);
    time = progress.playedSeconds;
  };
  let ref = useRef();

  // }
  useEffect(() => {
    setVideoUrl(props.activeStep);
  }, [props.activeStep]);
  return (
    <>
      <div className="col-sm-12 col-lg-12 col-md-12 pt-3">
        <h2 className="mb-3 font-weight-700">
          {props.lectureName ? props.lectureName : props.firstIndexLectureName}
        </h2>
        <Card className=" p-4">
          <div className="embed-responsive embed-responsive-16by9 video-height">
            {showNextButton === false ? (
              <>
                <ReactPlayer
                  ref={ref}
                  url={videoUrl !== 0 ? videoUrl : props.firstIndexVideo}
                  playing
                  controls
                  onPause={onPause}
                  onProgress={onProgress}
                  onStart={onStart}
                  onEnded={() => clickOnNext()}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="row pt-4 pl-2 align-items-center">
            <div className="col-8 d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="mr-3 dropdown-note set-backrouund-light d-flex align-items-center"
                >
                  <BsFilesAlt className=" icon-font-size-30 text-color-green" />
                  <span className="text-color-green ml-2 "> Add Note</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.LectureNotesCourses.map((list, index) => {
                    return (
                      <Dropdown.Item
                        href="#"
                        key={index}
                        // onClick={() => setModalShowForNotification(true)}
                        onClick={() => {
                          setModalShowForNotification(true);
                          setNotesId(list);
                        }}
                      >
                        {list.noteTitle}
                      </Dropdown.Item>
                    );
                  })}
                  <PurchaseVerticallyCenteredModal
                    lectureNotes={notesId}
                    show={modalShowForNotification}
                    onHide={() => setModalShowForNotification(false)}
                  />
                  <Dropdown.Item
                    href="#"
                    className="NoteSize"
                    onClick={() => setModalShow(true)}
                  >
                    Add <AiOutlinePlusCircle className="icon" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div className="col-4 text-right edutech">
              <IoIosThumbsUp className="icon-font-size-30 thumbup mr-4 cursor" />
              <IoIosThumbsDown className=" icon-font-size-30 thumbdown cursor" />
            </div> */}
          </div>
        </Card>
        <div className="tabs-modify">
          <Tabs
            className="nav-justified"
            style={{
              background: "#f7f7f7",
              borderBottom: "3px solid #f0ecec",
              borderColor: "none",
            }}
          >
            <Tab className="nav-item" eventKey={3} title=" SUMMARY">
              <div className="row">
                <div className="col-md-12 col-12 col-lg-12 col-sm-12 ">
                  <Card className=" p-4">
                    <div className="text-capitilize b-500 ">
                      {/* <h3 className="font-weight-00"> SUMMARY</h3> */}
                      {props?.CoursesData?.sections?.map(
                        (section, sectionId) => {
                          return (
                            <>
                              {section?.lectures?.map((Lecture, lectureId) => (
                                <>
                                  <h4 key={lectureId} className="text-dark">
                                    {Lecture?.lectureName}
                                  </h4>
                                  <p>{Lecture?.lectureSummary}</p>
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab className="nav-item " eventKey={1} title="RESOURCES">
              <div className="row">
                <div className="col-md-12 col-12 col-lg-12 col-sm-12 ">
                  <Card className=" p-4">
                    <div className="text-capitilize b-500 ">
                      {/* <h3 className="font-weight-700">LESSON RESOURCES</h3> */}
                      {props?.CoursesData?.sections?.map(
                        (section, sectionId) => {
                          return (
                            <>
                              {section?.lectures?.map((Lecture, lectureId) => (
                                <>
                                  <h4 key={lectureId} className="text-dark">
                                    {Lecture?.lectureName}
                                  </h4>
                                  <p>
                                    <span className="text-color-green  ">
                                      Save Resourse
                                    </span>{" "}
                                    {
                                      <a
                                        href={Lecture?.lectureResource}
                                        download
                                        // onClick={(e) => download(e)}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <AiOutlineDownload className=" icon-font-size-30 text-color-green  margin-left-20" />
                                      </a>
                                    }
                                  </p>
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                      {/* <div className="d-flex align-items-center py-2">
                        <span className="text-color-green  ">
                          {" "}
                          Download slides{" "}
                        </span>
                        <AiOutlineDownload className=" icon-font-size-30 text-color-green ml-5 " />
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="text-color-green  ">
                          Download transcript
                        </span>{" "}
                        <AiOutlineDownload className=" icon-font-size-30 text-color-green  margin-left-20" />
                      </div> */}
                    </div>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab className="nav-item " eventKey={6} title="TRANSCRIPT">
              <div className="col-md-12 col-12 col-lg-12 col-sm-12 transcript-dropdown">
                <Card className="d-flex p-4">
                  {/* <h3 className="font-weight-700">TRANSCRIPT</h3> */}
                  {props?.CoursesData?.sections?.map((section, sectionId) => {
                    return (
                      <>
                        {section?.lectures?.map((Lecture, id) => (
                          <>
                            <h4 key={id} className="text-dark">
                              {Lecture?.lectureName}
                            </h4>
                            <p>{Lecture?.lectureTranscript}</p>
                            <ShowAddModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              CourseDataLectureID={props.CoursesData}
                              lectureID={props.lectureNotesData}
                            />
                          </>
                        ))}
                      </>
                    );
                  })}
                </Card>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      {/* <ShowAddModal show={modalShow} onHide={() => setModalShow(false)} CourseDataLectureID={props.CoursesData} lectureID={props.lectureID} /> */}
    </>
  );
}

function MyProgressDashboardBreakthrough(props) {
  let GetCourseValue = useSelector((state) => state.courses);
  let CoursesData = GetCourseValue && GetCourseValue.getById;
  let MyProgressCourseData =
    GetCourseValue && GetCourseValue?.getVideoTimeStamp;
  let continueCourseStep =
    (MyProgressCourseData &&
      MyProgressCourseData.sectionId + MyProgressCourseData.lectureId) ||
    0;
  const [activeStep, setActiveStep] = React.useState(0);
  // const [lectureactiveStep, setLectureActiveStep] = React.useState(CoursesData?.sections?.[0]?.sectionId + CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId);
  const [lectureactiveStep, setLectureActiveStep] =
    React.useState(continueCourseStep);
  const [lectureName, setLectureName] = React.useState("");
  const [lectureID, setLectureID] = React.useState("");
  const [lectureNotesData, setLectureNotes] = React.useState(
    CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
  );
  const [currentLectureIndex, setCurrentLectureIndex] = React.useState(0);
  const [showfunc, setfunc] = React.useState(0);
  const [showQuiz, setShowQuiz] = React.useState(false);
  const [condition, setCondition] = useState(0);
  const [showCertificate, setCertificateSection] = useState(false);
  const [certificateURL, setCertificateURL] = useState("");

  let auth = useAuth();
  let dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(success(COURSES_REQUEST.GET_VIDEO_TIME_STAMP_SUCCESS, null));
    };
  }, []);
  useEffect(() => {
    if (MyProgressCourseData && CoursesData) {
      let findMapIdOfSection = CoursesData.sections.findIndex(
        (item) => item.sectionId === MyProgressCourseData.sectionId
      );
      let findSectionData = CoursesData.sections[findMapIdOfSection];

      let lectureDataFromSection = findSectionData.lectures.filter(
        (item) => item.lectureId === MyProgressCourseData.lectureId
      )[0];

      newFunc2(findMapIdOfSection, findSectionData, lectureDataFromSection);
    }
  }, [MyProgressCourseData, CoursesData]);

  useEffect(() => {
    document.title = `Course Information || EduTech`;
    dispatch(GetById(props?.InProgressCourses?.courseId, auth));
    dispatch(GetAllNotesByLecture(lectureNotesData, auth));
    dispatch(GetVideoTimeStamp(props?.InProgressCourses?.courseId, auth));
  }, [dispatch]);
  // useEffect((step) => {
  //   dispatch(GetAllNotesByLecture(step, auth));
  // }, [dispatch]);
  function LectureNotes(step) {
    dispatch(GetAllNotesByLecture(step, auth));
  }
  let studentName =
    auth.edu_tech_user.firstName + " " + auth.edu_tech_user.lastName;
  let NotesValue = useSelector((state) => state.lectureNotes);
  let GetCourseCertificate = useSelector((state) => state.courses);

  let LectureNotesCourses = NotesValue && NotesValue.getAllNotesByLecture;
  if (CoursesData?.sections) {
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setfunc((prevActiveStep) => prevActiveStep + 1);
    if (showfunc === 0) {
      setCondition(0);
    }
    if (showfunc === 1) {
      setCondition(1);
    }
    if (showfunc === 2) {
      setCondition(2);
    }
    if (showfunc === 3) {
      setCondition(3);
    }
    if (showfunc === 4) {
      setCondition(4);
    }
    if (showfunc === 5) {
      setCertificateSection(true);
    }
    // if (showfunc == 4) {
    //   setCondition(0);
    // }
  };
  const handleReset = () => {
    setActiveStep(0);
    if (condition === false) {
      setCondition(true);
    } else {
      setCondition(false);
    }
    setActiveStep(0);
    setfunc(0);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setfunc((prevActiveStep) => prevActiveStep - 1);
  };
  const ANSWAER_CHOOSE = [
    {
      lookupId: 1,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 1,
    },
    {
      lookupId: 2,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 2,
    },
    {
      lookupId: 3,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 3,
    },
    {
      lookupId: 4,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 4,
    },
  ];
  const ANSWAER_CHOOSE1 = [
    {
      lookupId: 5,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 5,
    },
    {
      lookupId: 6,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 6,
    },
    {
      lookupId: 7,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 7,
    },
    {
      lookupId: 8,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 8,
    },
  ];
  const [button, setButton] = useState(0);
  const [button1, setButton1] = useState(0);
  let urls = [
    ["https://www.youtube.com/embed/8C46CbYocXI"],
    ["https://www.youtube.com/embed/E9lAeMz1DaM"],
    ["https://www.youtube.com/embed/8C46CbYocXI"],
    ["https://www.youtube.com/embed/nlMuHtV82q8"],
    ["https://www.youtube.com/embed/E9lAeMz1DaM"],
  ];
  const newFunc2 = (mapId, list, step) => {
    let newMapId = 0;
    let actualLectureIndex = 0;
    if (showfunc !== mapId) {
      newMapId = mapId;
      actualLectureIndex = 0;
    } else {
      newMapId = mapId;
      actualLectureIndex = currentLectureIndex + 1;
    }
    setfunc(newMapId);
    // setActiveStep(list?.lectures?.[actualLectureIndex]?.lectureVideo)
    setLectureName(step.lectureName);
    setLectureID(step.lectureId);
    setLectureNotes(step.lectureId);
    LectureNotes(step.lectureId);
    setCurrentLectureIndex(actualLectureIndex);
    setLectureActiveStep(
      list?.sectionId + list?.lectures?.[actualLectureIndex]?.lectureId
    );
  };
  const newFunc = (mapId, list, step) => {
    let newMapId = 0;
    let actualLectureIndex = 0;
    if (showfunc !== mapId) {
      newMapId = mapId;
      actualLectureIndex = 0;
    } else {
      newMapId = mapId;
      actualLectureIndex = currentLectureIndex + 1;
    }
    setfunc(newMapId);
    setActiveStep(list?.lectures?.[actualLectureIndex]?.lectureVideo);
    setLectureName(step.lectureName);
    setLectureID(step.lectureId);
    setLectureNotes(step.lectureId);
    LectureNotes(step.lectureId);
    setCurrentLectureIndex(actualLectureIndex);
    setLectureActiveStep(
      list?.sectionId + list?.lectures?.[actualLectureIndex]?.lectureId
    );
  };

  return (
    <>
      <div className="row dashboard-page business-breakthrough-dashboard">
        <div className="col-sm-12 col-lg-12 col-md-12 p-4 bg-white mb-4 pb-2 div-rounded-30  d-flex">
          <>
            <div className="col-md-4">
              {CoursesData !== null &&
                CoursesData?.sections?.map((list, mapId) => {
                  return (
                    <>
                      <Accordion
                        defaultActiveKey={mapId.toString()}
                        className="bg-white"
                        key={mapId}
                      >
                        <div className="mb-0 bg-white ">
                          <Card.Header className="pl-1 pt-1 bg-white">
                            <div className="row justify-content-between bg-white">
                              <div className="col-10 bg-white">
                                <Accordion.Toggle
                                  className="p-0 bg-white"
                                  as={Button}
                                  Introduction
                                  to
                                  Business
                                  Breakthrough
                                  variant="link"
                                  eventKey={mapId.toString()}
                                >
                                  <h3 className="text-dark m-0 p-0 font-size-22 accordin-title font-weight-700 text-left">
                                    {list.sectionName}
                                  </h3>
                                </Accordion.Toggle>
                              </div>
                              <div className="col-2  text-right">
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={mapId?.toString()}
                                >
                                  <BiCaretDown className="text-dark set-icon-font-size" />
                                </Accordion.Toggle>
                              </div>
                            </div>
                          </Card.Header>
                          <Accordion.Collapse eventKey={mapId.toString()}>
                            <Box sx={{ maxWidth: 400 }}>
                              {/* <Stepper orientation="vertical" activeStep={MyProgressCourseData.lectureId}> */}
                              <Stepper
                                orientation="vertical"
                                activeStep={lectureactiveStep}
                              >
                                {/* <Stepper orientation="vertical" activeStep={}> */}
                                {list &&
                                  list?.lectures &&
                                  list?.lectures?.length > 0 &&
                                  list?.lectures?.map((step, lectureID) => {
                                    return (
                                      <Step
                                        type="button"
                                        key={list?.sectionId + step?.lectureId}
                                        index={
                                          list?.sectionId + step?.lectureId
                                        }
                                      >
                                        <StepLabel
                                          // icon={<span className="stepper-active">{lectureID + 1 !== null && 1}</span>}
                                          icon={
                                            <span className="stepper-active">
                                              {lectureID + 1}
                                            </span>
                                          }
                                          key={
                                            list?.sectionId + step?.lectureId
                                          }
                                          id={`uniqueId_${
                                            mapId + "_" + lectureID
                                          }`}
                                          onClick={() =>
                                            newFunc(mapId, list, step)
                                          }
                                          optional={
                                            mapId === 0 && (
                                              <Typography
                                                variant="caption"
                                                className="cursor"
                                              ></Typography>
                                            )
                                          }
                                        >
                                          {step.lectureName}
                                        </StepLabel>
                                        <StepContent>
                                          <div
                                            className="row mt-2"
                                            sx={{ mb: 2, mt: 2 }}
                                          >
                                            <div className="col-12">
                                              <div className="float-right text-right">
                                                <div>
                                                  {showfunc === mapId && (
                                                    <small></small>
                                                  )}
                                                </div>
                                                <button
                                                  className="btn btn-primary"
                                                  onClick={handleNext}
                                                  sx={{ mt: 1, mr: 3 }}
                                                >
                                                  {mapId === step.length - 1
                                                    ? "Finish"
                                                    : "Next"}
                                                </button>
                                              </div>
                                              <div className="float-left">
                                                <div>
                                                  {showfunc === 0 && (
                                                    <small className="invisible">
                                                      " "
                                                    </small>
                                                  )}
                                                  {showfunc === 1 && (
                                                    <small></small>
                                                  )}
                                                  {showfunc === 2 && (
                                                    <small></small>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </StepContent>
                                      </Step>
                                    );
                                  })}
                              </Stepper>
                              {activeStep > activeStep.length && (
                                <Paper square elevation={1} sx={{ p: 3 }}>
                                  <Typography>
                                    All steps completed - you&apos;re finished
                                  </Typography>
                                  <Button
                                    onClick={handleReset}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    Reset
                                  </Button>
                                </Paper>
                              )}
                            </Box>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                    </>
                  );
                })}
            </div>
            <div className="col-md-8">
              <>
                {showQuiz === false ? (
                  <MyProgressVideoSection
                    // mapId={mapId}
                    setShowQuiz={setShowQuiz}
                    setCurrentLectureIndex={setCurrentLectureIndex}
                    showfunc={showfunc}
                    newFunc={newFunc}
                    handleNext={handleNext}
                    currentLectureIndex={currentLectureIndex}
                    urls={urls[showfunc] ? urls[showfunc] : ""}
                    condition={condition}
                    firstIndexVideo={
                      CoursesData?.sections?.[0]?.lectures?.[0]?.lectureVideo
                    }
                    firstIndexLectureName={
                      CoursesData?.sections?.[0]?.lectures?.[0]?.lectureName
                    }
                    lectureName={lectureName}
                    activeStep={activeStep}
                    lectureactiveStep={lectureactiveStep}
                    setActiveStep={setActiveStep}
                    setLectureActiveStep={setLectureActiveStep}
                    CoursesData={CoursesData}
                    MyProgressCourseData={MyProgressCourseData}
                    setLectureID={setLectureID}
                    lectureID={lectureID}
                    lectureNotesData={lectureNotesData}
                    LectureNotesCourses={LectureNotesCourses}
                  />
                ) : showCertificate === false ? (
                  <MyProgressQuizSection
                    setCertificateSection={setCertificateSection}
                    ANSWAER_CHOOSE={ANSWAER_CHOOSE}
                    setShowQuiz={setShowQuiz}
                    setCurrentLectureIndex={setCurrentLectureIndex}
                    newFunc={newFunc}
                    setButton={setButton}
                    certificateURL={certificateURL}
                    setCertificateURL={setCertificateURL}
                    showfunc={showfunc}
                    button={button}
                    CoursesData={CoursesData}
                    ANSWAER_CHOOSE1={ANSWAER_CHOOSE1}
                    setButton1={setButton1}
                    button1={button1}
                  />
                ) : (
                  <MyProgressShowCerificate
                    studentName={studentName}
                    CoursesData={CoursesData}
                    certificateURL={certificateURL}
                  />
                )}
              </>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

function MyProgressQuizSection(props) {
  const StudentTopCourses = props?.CoursesData?.sections;
  const [isSelected, setIsSelected] = useState("");
  let auth = useAuth();
  let dispatch = useDispatch();
  function CheckQuizAnswerFunction(questionOption) {
    // dispatch(CheckQuizAnswer(questionOption, auth));
    checkQuizAnswer(questionOption, auth)
      .then((response) => {
        setIsSelected({
          id: questionOption,
          flag: response.data,
        });
      })
      .catch((error) => {});
  }
  const QuizAnswerSelection = (questionOption) => {
    props.setButton(questionOption);
    CheckQuizAnswerFunction(questionOption);
    setIsSelected(questionOption);
  };
  function downloadApiCall() {
    dispatch(CertificateReceived(props.CoursesData.courseId, auth));
  }

  function generateCertificateUrl() {
    let finalData = {
      courseId: props?.CoursesData?.courseId,
    };
    dispatch(CertificateConversion(finalData, auth, moveToNext));
  }
  let GetCourseValue = useSelector((state) => state.courses);
  console.log("certificateLog", GetCourseValue);
  function moveToNext(url) {
    props?.setCertificateURL(url);
    if (url) {
      props?.setCertificateSection(true);
    }
  }

  // let CoursesData = GetCourseValue && GetCourseValue.getById;
  return (
    <>
      <div className="col-sm-12 col-lg-12 col-md-12 section-quiz">
        <Card className="py-4">
          <div className="d-flex align-items-center justify-content-between px-4">
            <div>
              <h4
                className="mb-0 font-weight-800 cursor float-right"
                onClick={() => {
                  downloadApiCall();
                  props?.setCertificateSection(true);
                }}
              >
                Skip Quiz
              </h4>
            </div>
          </div>
          <hr />
          {/* copy here */}
          {StudentTopCourses?.map((section, sectionId) => {
            return (
              <>
                <p className="ml-4 font-weight-700">
                  {section.sectionName} Quizes
                </p>
                {section?.lectures?.map((lecture, lectureId) => (
                  <>
                    <div className=" mt-3 pl-1 px-4" key={lectureId}>
                      <div className="font-weight-700 ">
                        {lecture.lectureName}
                      </div>
                      <br />
                      {lecture?.quiz?.map((quiz, quizId) => (
                        <>
                          {quiz?.quizQuestions?.map(
                            (quizQuestion, quizQuestionId) => (
                              <>
                                <p>{quizQuestion?.questionDescription}</p>
                                {quizQuestion?.quizOptions?.map(
                                  (questionOption, questionOptionId) => (
                                    <p
                                      key={questionOptionId}
                                      onClick={() => {
                                        QuizAnswerSelection(
                                          questionOption?.quizOptionId
                                        );
                                      }}
                                      className={`cursor set-p-tag-backround pl-4 ml-5 ${
                                        props.button ===
                                        questionOption?.quizOptionId
                                          ? questionOption?.quizOptionId ===
                                              isSelected?.id &&
                                            isSelected.flag === false
                                            ? "active-wrong"
                                            : "active-success"
                                          : ""
                                      }`}
                                    >
                                      {questionOption?.optionDescription}
                                    </p>
                                  )
                                )}
                              </>
                            )
                          )}
                        </>
                      ))}
                      {/* <div className="px-4 ">
                            <p className="font-weight-700">Q.{lectureId + 1}</p>
                          </div> */}
                    </div>
                  </>
                ))}
              </>
            );
          })}

          {/* ----- */}
          {GetCourseValue?.certificateConversionLoading === true ? (
            <Loader />
          ) : (
            <>
              <div className="col-md-2 mx-auto">
                <button
                  className="btn btn-primary submit-quiz text-end float-right "
                  onClick={() => generateCertificateUrl()}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Card>
      </div>
    </>
  );
}

function MyProgressShowAddModal(props) {
  let firstindexlectureID = props?.CoursesData?.sections?.[0]?.lectures?.[0]
    ?.lectureId
    ? props?.CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
    : props.lectureID;
  let dispatch = useDispatch();
  let auth = useAuth();
  const { register, handleSubmit, errors } = useForm();
  const [notesTittle, setNotesTitle] = useState("");
  const [notesDescription, setNotesDescription] = useState("");
  function onSubmit() {
    props.onHide();
    let finaldata = {
      lectureId: firstindexlectureID,
      noteTitle: notesTittle,
      noteDescription: notesDescription,
    };
    dispatch(CreateNotes(finaldata, auth, moveToNext));
  }
  function moveToNext(step) {
    setNotesTitle(null);
    setNotesDescription(null);
    dispatch(GetAllNotesByLecture(firstindexlectureID, auth));
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="Notesheader">
        <div className="d-flex justify-content-center align-items-center modalheader ">
          <p>Add Notes</p>
          <IoCloseSharp className="modalclose" onClick={props.onHide} />
        </div>
        <hr />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              className="mt-1"
              name="notesTittle"
              type="text"
              placeholder="Add Notes Tittle"
              value={notesTittle}
              onChange={(e) => {
                setNotesTitle(e.target.value);
              }}
              ref={register({ required: true })}
              style={{
                borderColor: errors && errors.notesTittle ? "#a80000" : "",
              }}
            />
            {errors.notesTittle && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              className="mt-1"
              name="notesDescription"
              type="text"
              placeholder="Add Notes Description"
              value={notesDescription}
              onChange={(e) => {
                setNotesDescription(e.target.value);
              }}
              ref={register({ required: true })}
              style={{
                borderColor: errors && errors.notesDescription ? "#a80000" : "",
              }}
            />
            {errors.notesDescription && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <div className="d-flex justify-content-center ">
            <button type="submit" className="next-course-btn btn-save w-25">
              Submit
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Notes Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.lectureNotes.noteTitle}</h4>
        <p>{props.lectureNotes.noteDescription}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyProgressShowCerificate(props) {
  let auth = useAuth();
  let dispatch = useDispatch();
  useEffect(() => {
    document.title = `Course Information || EduTech`;
    dispatch(GetCourseCertification(props.CoursesData.courseId, auth));
  }, []);
  let GetCourseCertificate = useSelector((state) => state.courses);
  let CourseCertificateData =
    GetCourseCertificate && GetCourseCertificate.getCourseCertification;
  console.log("ahkjshad", props?.certificateURL);
  const [pic, setPic] = useState(
    CourseCertificateData?.[0]?.courseCertificateURL
  );
  useEffect(() => {
    setPic(CourseCertificateData?.[0]?.courseCertificateURL);
  }, [CourseCertificateData]);

  function downloadApiCall() {
    dispatch(CertificateReceived(props.CoursesData.courseId, auth));
  }

  const printToPdf = () => {
    html2canvas(document.getElementById("print_to_pdf")).then((canvas) => {
      var data = canvas.toDataURL();
      var pdfExportSetting = {
        // pageOrientation: "portrait",
        compress: false,
        pageSize: {
          width: 920,
          height: 650,
        },
        // pageMargins: [40, 60, 40, 60],
        content: [
          // {
          //   image: data,
          //   width: 480,
          //   height: 500,
          // },
          {
            image: data,
            width: 840,
          },
        ],
      };
      pdfMake.createPdf(pdfExportSetting).download("Certificate.pdf");
    });
  };
  return (
    <div className="col-sm-12 col-lg-12 col-md-12 Certication text-center">
      <Card className="">
        <Card.Body>
          <p className="fw-bold text-center firstheading">
            Congratulations, {props.studentName ? props.studentName : "N/A"}!
          </p>
          <p className="text-center text-muted para">
            You have Completed Course Successfully!
          </p>

          {/* <div id="print_to_pdf">
          <button className="btn btn-primary submit-quiz mt-2"
            onClick={() => {
                    downloadApiCall();
                    printToPdf();
                  }}>
            Download Certificate
          </button>
        </div> */}
          <Card className="certicate-card mt-5 text-left">
            <Card.Body className="overflow-auto">
              <div className="background-image-certificate" id="print_to_pdf">
                <div className="row p-5">
                  <div className="col-md-8 col-6">
                    <p className="cert-text-size mb-0">CERTIFICATE</p>
                    <p className="appreciate-text">OF APPRECIATION</p>
                  </div>
                  <div className="col-md-4 col-6 pt-4 parent-class">
                    <span className="f-20 query ml-5">November 12th 2022</span>
                  </div>
                </div>
                <div className="row pl-5">
                  <div className="col-md-12 col-12 ">
                    <p className="f-20 ">This award is proudly given to:</p>
                    <p className="name-style text-uppercase">
                      {props.studentName ? props.studentName : "N/A"}
                    </p>
                    <p className="f-20 text-uppercase">
                      Completed :{" "}
                      {props?.CoursesData?.courseTitle
                        ? props.CoursesData.courseTitle
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row p-5">
                  <div className="col-md-8">
                    <p className="fw-600 mb-4 authorize-text">Authorized by:</p>
                    <p className="mb-0">____________</p>
                    <p className="ml-3 Principal-text">The Principal</p>
                    <span className="signature-style">
                      <img
                        src={IMAGES.SIGNCERT}
                        alt="signature"
                        className="img-fluid "
                      />
                    </span>
                  </div>
                  <div className="col-md-4 ">
                    <img
                      src={IMAGES.EDUTECH_LOGO_SMALL}
                      className="img-fluid candletech-logo "
                      alt="edutech-logo"
                    />
                  </div>
                </div>
              </div>
              <div className=" mt-4 d-flex justify-content-center">
                <a href={props?.certificateURL} download>
                  <button className="notes-download-btn">
                    <BiDownload className="download-btn-style" />
                    {"Download"}
                  </button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
}

function MyProgressVideoSection(props) {
  let auth = useAuth();
  let firstindexlectureID = props?.CoursesData?.sections?.[0]?.lectures?.[0]
    ?.lectureId
    ? props?.CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
    : props.lectureID;
  let dispatch = useDispatch();
  const [showNextButton, setShowNextButton] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [notesId, setNotesId] = React.useState("");
  const [modalShowForNotification, setModalShowForNotification] =
    React.useState(false);
  const [currentUrlIndex, setCurrentUrlIndex] = useState("");
  const clickOnNext = () => {
    let selector =
      "uniqueId_" + props.showfunc + "_" + (props.currentLectureIndex + 1);
    let findObj = document.getElementById(selector);
    if (findObj !== null) {
      findObj.click();
    } else {
      props.setCurrentLectureIndex(0);
      selector = "uniqueId_" + (props.showfunc + 1) + "_" + 0;
      findObj = document.getElementById(selector);
      if (findObj !== null) {
        findObj.click();
      } else {
        props.setShowQuiz(true);
      }
    }
  };
  const handleClose = () => setModalShowForNotification(false);
  const handleShow = () => setModalShowForNotification(true);
  const [videoUrl, setVideoUrl] = useState(props.activeStep);
  const [played, setPlayed] = useState(0);
  const valueRef = useRef();
  const [isReady, setIsReady] = React.useState(false);
  const onReady = React.useCallback(() => {
    if (!isReady && props?.MyProgressCourseData) {
      const timeToStart = props?.MyProgressCourseData?.videoTimeStamp;
      valueRef.current.seekTo(timeToStart, "seconds");
      setIsReady(true);
    }
  }, [isReady, props?.MyProgressCourseData]);
  // }
  let time = 0;
  useEffect(() => {
    setVideoUrl(props?.activeStep);
  }, [props?.activeStep]);

  function onPause() {
    // if (props.activeStep === 0) {
    let finalData = {
      courseId: props?.CoursesData?.courseId,
      lectureId: firstindexlectureID,
      videoTimeStamp: Math.floor(played),
    };
    dispatch(UpdateVideoTimeStamp(finalData, auth));
  }
  function onStart() {
    if (props?.activeStep === 0) {
      dispatch(MarkCourseAsStarted(props?.CoursesData?.courseId, auth));
    }
  }
  const onProgress = (progress) => {
    setPlayed(progress.playedSeconds);
    time = progress.playedSeconds;
  };
  return (
    <>
      <div className="col-sm-12 col-lg-12 col-md-12 pt-3">
        <h2 className="mb-3 font-weight-700">
          {props.lectureName ? props.lectureName : props.firstIndexLectureName}
        </h2>
        <Card className=" p-4">
          <div className="embed-responsive embed-responsive-16by9 video-height">
            {showNextButton === false ? (
              <>
                <ReactPlayer
                  // ref={ref}
                  url={videoUrl !== 0 ? videoUrl : props.firstIndexVideo}
                  playing
                  ref={valueRef}
                  controls
                  onReady={onReady}
                  onPause={onPause}
                  onStart={onStart}
                  onProgress={onProgress}
                  // onPause={onPause}
                  // onProgress={(progress) => {
                  //   setPlayed(progress.playedSeconds);
                  // }}
                  // onStart={onStart}
                  onEnded={() => clickOnNext()}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="row pt-4 pl-2 align-items-center">
            <div className="col-8 d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="mr-3 dropdown-note set-backrouund-light d-flex align-items-center"
                >
                  <BsFilesAlt className=" icon-font-size-30 text-color-green" />
                  <span className="text-color-green ml-2 "> Add Note</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.LectureNotesCourses.map((list, index) => {
                    return (
                      <Dropdown.Item
                        href="#"
                        key={index}
                        // onClick={() => setModalShowForNotification(true)}
                        // onClick={() => setModalShowForNotification(true)}
                        onClick={() => {
                          setModalShowForNotification(true);
                          setNotesId(list);
                        }}
                      >
                        {list?.noteTitle}
                        {/* hello world */}
                      </Dropdown.Item>
                    );
                  })}
                  <MyVerticallyCenteredModal
                    lectureNotes={notesId}
                    show={modalShowForNotification}
                    onHide={() => setModalShowForNotification(false)}
                  />
                  <Dropdown.Item
                    href="#"
                    className="NoteSize"
                    onClick={() => setModalShow(true)}
                  >
                    Add <AiOutlinePlusCircle className="icon" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div className="col-4 text-right edutech">
              <IoIosThumbsUp className="icon-font-size-30 thumbup mr-4 cursor" />
              <IoIosThumbsDown className=" icon-font-size-30 thumbdown cursor" />
            </div> */}
          </div>
        </Card>

        <div className="tabs-modify">
          <Tabs
            className="nav-justified"
            style={{
              background: "#f7f7f7",
              borderBottom: "3px solid #f0ecec",
              borderColor: "none",
            }}
          >
            <Tab className="nav-item" eventKey={3} title=" SUMMARY">
              <div className="row">
                <div className="col-md-12 col-12 col-lg-12 col-sm-12 ">
                  <Card className=" p-4">
                    <div className="text-capitilize b-500 ">
                      {/* <h3 className="font-weight-00"> SUMMARY</h3> */}
                      {props?.CoursesData?.sections?.map(
                        (section, sectionId) => {
                          return (
                            <>
                              {section?.lectures?.map((Lecture, lectureId) => (
                                <>
                                  <h4 key={lectureId} className="text-dark">
                                    {Lecture?.lectureName}
                                  </h4>
                                  <p>{Lecture?.lectureSummary}</p>
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab className="nav-item " eventKey={1} title="RESOURCES">
              <div className="row">
                <div className="col-md-12 col-12 col-lg-12 col-sm-12 ">
                  <Card className=" p-4">
                    <div className="text-capitilize b-500 ">
                      {/* <h3 className="font-weight-700">LESSON RESOURCES</h3> */}
                      {props?.CoursesData?.sections?.map(
                        (section, sectionId) => {
                          return (
                            <>
                              {section?.lectures?.map((Lecture, lectureId) => (
                                <>
                                  <h4 key={lectureId} className="text-dark">
                                    {Lecture?.lectureName}
                                  </h4>
                                  <p>
                                    <span className="text-color-green  ">
                                      Save Resourse
                                    </span>{" "}
                                    {
                                      <a
                                        href={Lecture?.lectureResource}
                                        download
                                        // onClick={(e) => download(e)}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <AiOutlineDownload className=" icon-font-size-30 text-color-green  margin-left-20" />
                                      </a>
                                    }
                                  </p>
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                      {/* <div className="d-flex align-items-center py-2">
                      <span className="text-color-green  ">
                        {" "}
                        Download slides{" "}
                      </span>
                      <AiOutlineDownload className=" icon-font-size-30 text-color-green ml-5 " />
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-color-green  ">
                        Download transcript
                      </span>{" "}
                      <AiOutlineDownload className=" icon-font-size-30 text-color-green  margin-left-20" />
                    </div> */}
                    </div>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab className="nav-item " eventKey={6} title="TRANSCRIPT">
              <div className="col-md-12 col-12 col-lg-12 col-sm-12 transcript-dropdown">
                <Card className="d-flex p-4">
                  {/* <h3 className="font-weight-700">TRANSCRIPT</h3> */}
                  {props?.CoursesData?.sections?.map((section, sectionId) => {
                    return (
                      <>
                        {section?.lectures?.map((Lecture, id) => (
                          <>
                            <h4 key={id} className="text-dark">
                              {Lecture?.lectureName}
                            </h4>
                            <p>{Lecture?.lectureTranscript}</p>
                            <MyProgressShowAddModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              CourseDataLectureID={props.CoursesData}
                              lectureID={props.lectureNotesData}
                            />
                          </>
                        ))}
                      </>
                    );
                  })}
                </Card>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      {/* <ShowAddModal show={modalShow} onHide={() => setModalShow(false)} CourseDataLectureID={props.CoursesData} lectureID={props.lectureID} /> */}
    </>
  );
}

function CurrentProgressDashboardBreakthrough(props) {
  let GetCourseValue = useSelector((state) => state.courses);
  let CoursesData = GetCourseValue && GetCourseValue.getById;
  let MyProgressCourseData = GetCourseValue && GetCourseValue.getVideoTimeStamp;
  const [activeStep, setActiveStep] = React.useState(0);
  // const [lectureactiveStep, setLectureActiveStep] = React.useState(MyProgressCourseData?.sectionId + MyProgressCourseData?.lectureId);
  const [lectureactiveStep, setLectureActiveStep] = React.useState(
    MyProgressCourseData?.sectionId + MyProgressCourseData?.lectureId || 0
  );
  const [lectureName, setLectureName] = React.useState("");
  const [lectureID, setLectureID] = React.useState("");
  const [lectureNotesData, setLectureNotes] = React.useState(
    CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
  );
  const [currentLectureIndex, setCurrentLectureIndex] = React.useState(0);
  const [showfunc, setfunc] = React.useState(0);
  const [showQuiz, setShowQuiz] = React.useState(false);
  const [condition, setCondition] = useState(0);
  const [showCertificate, setCertificateSection] = useState(false);
  const [certificateURL, setCertificateURL] = useState("");
  let auth = useAuth();
  let dispatch = useDispatch();
  useEffect(() => {
    if (MyProgressCourseData && CoursesData) {
      let findMapIdOfSection = CoursesData.sections.findIndex(
        (item) => item.sectionId === MyProgressCourseData.sectionId
      );
      let findSectionData = CoursesData.sections[findMapIdOfSection];
      let lectureDataFromSection = findSectionData.lectures.filter(
        (item) => item.lectureId === MyProgressCourseData.lectureId
      )[0];
      continueNewFunc(
        findMapIdOfSection,
        findSectionData,
        lectureDataFromSection
      );
    }
  }, [MyProgressCourseData, CoursesData]);
  useEffect(() => {
    return () => {
      dispatch(success(COURSES_REQUEST.GET_VIDEO_TIME_STAMP_SUCCESS), null);
    };
  }, []);
  useEffect(() => {
    document.title = `Course Information || EduTech`;
    // dispatch(GetById(props.StudentPurchasedCourses, auth));
    dispatch(GetById(props.CurrentProgressCourses.courseId, auth));
    dispatch(GetAllNotesByLecture(lectureNotesData, auth));
    dispatch(GetVideoTimeStamp(props.CurrentProgressCourses.courseId, auth));
  }, [dispatch]);
  // useEffect((step) => {
  //   dispatch(GetAllNotesByLecture(step, auth));
  // }, [dispatch]);
  function LectureNotes(step) {
    dispatch(GetAllNotesByLecture(step, auth));
  }
  let studentName =
    auth.edu_tech_user.firstName + " " + auth.edu_tech_user.lastName;
  let NotesValue = useSelector((state) => state.lectureNotes);
  let LectureNotesCourses = NotesValue && NotesValue.getAllNotesByLecture;
  if (CoursesData?.sections) {
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setfunc((prevActiveStep) => prevActiveStep + 1);
    if (showfunc === 0) {
      setCondition(0);
    }
    if (showfunc === 1) {
      setCondition(1);
    }
    if (showfunc === 2) {
      setCondition(2);
    }
    if (showfunc === 3) {
      setCondition(3);
    }
    if (showfunc === 4) {
      setCondition(4);
    }
    if (showfunc === 5) {
      setCertificateSection(true);
    }
    // if (showfunc == 4) {
    //   setCondition(0);
    // }
  };
  const handleReset = () => {
    setActiveStep(0);
    if (condition === false) {
      setCondition(true);
    } else {
      setCondition(false);
    }
    setActiveStep(0);
    setfunc(0);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setfunc((prevActiveStep) => prevActiveStep - 1);
  };
  const ANSWAER_CHOOSE = [
    {
      lookupId: 1,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 1,
    },
    {
      lookupId: 2,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 2,
    },
    {
      lookupId: 3,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 3,
    },
    {
      lookupId: 4,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 4,
    },
  ];
  const ANSWAER_CHOOSE1 = [
    {
      lookupId: 5,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 5,
    },
    {
      lookupId: 6,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 6,
    },
    {
      lookupId: 7,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 7,
    },
    {
      lookupId: 8,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 8,
    },
  ];
  const [button, setButton] = useState(0);
  const [button1, setButton1] = useState(0);
  let urls = [
    ["https://www.youtube.com/embed/8C46CbYocXI"],
    ["https://www.youtube.com/embed/E9lAeMz1DaM"],
    ["https://www.youtube.com/embed/8C46CbYocXI"],
    ["https://www.youtube.com/embed/nlMuHtV82q8"],
    ["https://www.youtube.com/embed/E9lAeMz1DaM"],
  ];
  const continueNewFunc = (mapId, list, step) => {
    let newMapId = 0;
    let actualLectureIndex = 0;
    if (showfunc !== mapId) {
      newMapId = mapId;
      actualLectureIndex = 0;
    } else {
      newMapId = mapId;
      actualLectureIndex = currentLectureIndex + 1;
    }
    setfunc(newMapId);
    // setActiveStep(list?.lectures?.[actualLectureIndex]?.lectureVideo)
    setLectureName(step?.lectureName);
    setLectureID(step?.lectureId);
    setLectureNotes(step?.lectureId);
    LectureNotes(step?.lectureId);
    setCurrentLectureIndex(actualLectureIndex);
    setLectureActiveStep(
      list?.sectionId + list?.lectures?.[actualLectureIndex]?.lectureId
    );
  };
  const newFunc = (mapId, list, step) => {
    let newMapId = 0;
    let actualLectureIndex = 0;
    if (showfunc !== mapId) {
      newMapId = mapId;
      actualLectureIndex = 0;
    } else {
      newMapId = mapId;
      actualLectureIndex = currentLectureIndex + 1;
    }
    setfunc(newMapId);
    setActiveStep(list?.lectures?.[actualLectureIndex]?.lectureVideo);
    setLectureName(step?.lectureName);
    setLectureID(step?.lectureId);
    setLectureNotes(step?.lectureId);
    LectureNotes(step?.lectureId);
    setCurrentLectureIndex(actualLectureIndex);
    setLectureActiveStep(
      list?.sectionId + list?.lectures?.[actualLectureIndex]?.lectureId
    );
  };
  return (
    <>
      <div className="row dashboard-page business-breakthrough-dashboard">
        <div className="col-sm-12 col-lg-12 col-md-12 p-4 bg-white mb-4 pb-2 div-rounded-30  d-flex">
          <>
            <div className="col-md-4">
              {CoursesData &&
                CoursesData.sections &&
                CoursesData.sections.map((list, mapId) => {
                  return (
                    <>
                      <Accordion
                        defaultActiveKey={mapId.toString()}
                        className="bg-white"
                        key={mapId}
                      >
                        <div className="mb-0 bg-white ">
                          <Card.Header className="pl-1 pt-1 bg-white">
                            <div className="row justify-content-between bg-white">
                              <div className="col-10 bg-white">
                                <Accordion.Toggle
                                  className="p-0 bg-white"
                                  as={Button}
                                  Introduction
                                  to
                                  Business
                                  Breakthrough
                                  variant="link"
                                  eventKey={mapId.toString()}
                                >
                                  <h3 className="text-dark m-0 p-0 font-size-22 accordin-title font-weight-700 text-left">
                                    {list.sectionName}
                                  </h3>
                                </Accordion.Toggle>
                              </div>
                              <div className="col-2  text-right">
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={mapId.toString()}
                                >
                                  <BiCaretDown className="text-dark set-icon-font-size" />
                                </Accordion.Toggle>
                              </div>
                            </div>
                          </Card.Header>
                          <Accordion.Collapse eventKey={mapId.toString()}>
                            <Box sx={{ maxWidth: 400 }}>
                              {/* <Stepper orientation="vertical" activeStep={MyProgressCourseData.lectureId}> */}
                              <Stepper
                                orientation="vertical"
                                activeStep={lectureactiveStep}
                              >
                                {/* <Stepper orientation="vertical" activeStep={}> */}
                                {list &&
                                  list?.lectures &&
                                  list?.lectures?.length > 0 &&
                                  list?.lectures?.map((step, lectureID) => {
                                    return (
                                      <Step
                                        type="button"
                                        key={list?.sectionId + step?.lectureId}
                                        index={
                                          list?.sectionId + step?.lectureId
                                        }
                                      >
                                        <StepLabel
                                          icon={
                                            <span className="stepper-active">
                                              {lectureID + 1}
                                            </span>
                                          }
                                          key={
                                            list?.sectionId + step?.lectureId
                                          }
                                          id={`uniqueId_${
                                            mapId + "_" + lectureID
                                          }`}
                                          onClick={() =>
                                            newFunc(mapId, list, step)
                                          }
                                          optional={
                                            mapId === 0 && (
                                              <Typography
                                                variant="caption"
                                                className="cursor"
                                              ></Typography>
                                            )
                                          }
                                        >
                                          {step?.lectureName}
                                        </StepLabel>
                                        <StepContent>
                                          <div
                                            className="row mt-2"
                                            sx={{ mb: 2, mt: 2 }}
                                          >
                                            <div className="col-12">
                                              <div className="float-right text-right">
                                                <div>
                                                  {showfunc === mapId && (
                                                    <small></small>
                                                  )}
                                                </div>
                                                <button
                                                  className="btn btn-primary"
                                                  onClick={handleNext}
                                                  sx={{ mt: 1, mr: 3 }}
                                                >
                                                  {mapId === step.length - 1
                                                    ? "Finish"
                                                    : "Next"}
                                                </button>
                                              </div>
                                              <div className="float-left">
                                                <div>
                                                  {showfunc === 0 && (
                                                    <small className="invisible">
                                                      " "
                                                    </small>
                                                  )}
                                                  {showfunc === 1 && (
                                                    <small></small>
                                                  )}
                                                  {showfunc === 2 && (
                                                    <small></small>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </StepContent>
                                      </Step>
                                    );
                                  })}
                              </Stepper>
                              {activeStep > activeStep.length && (
                                <Paper square elevation={1} sx={{ p: 3 }}>
                                  <Typography>
                                    All steps completed - you&apos;re finished
                                  </Typography>
                                  <Button
                                    onClick={handleReset}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    Reset
                                  </Button>
                                </Paper>
                              )}
                            </Box>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                    </>
                  );
                })}
            </div>
            <div className="col-md-8">
              <>
                {showQuiz === false ? (
                  <CurrentProgressVideoSection
                    // mapId={mapId}
                    setShowQuiz={setShowQuiz}
                    setCurrentLectureIndex={setCurrentLectureIndex}
                    showfunc={showfunc}
                    newFunc={newFunc}
                    handleNext={handleNext}
                    currentLectureIndex={currentLectureIndex}
                    urls={urls[showfunc] ? urls[showfunc] : ""}
                    condition={condition}
                    firstIndexVideo={
                      CoursesData?.sections?.[0]?.lectures?.[0]?.lectureVideo
                    }
                    firstIndexLectureName={
                      CoursesData?.sections?.[0]?.lectures?.[0]?.lectureName
                    }
                    lectureName={lectureName}
                    activeStep={activeStep}
                    lectureactiveStep={lectureactiveStep}
                    setActiveStep={setActiveStep}
                    setLectureActiveStep={setLectureActiveStep}
                    CoursesData={CoursesData}
                    MyProgressCourseData={MyProgressCourseData}
                    setLectureID={setLectureID}
                    lectureID={lectureID}
                    lectureNotesData={lectureNotesData}
                    LectureNotesCourses={LectureNotesCourses}
                  />
                ) : showCertificate === false ? (
                  <CurrentProgressQuizSection
                    setCertificateSection={setCertificateSection}
                    ANSWAER_CHOOSE={ANSWAER_CHOOSE}
                    setShowQuiz={setShowQuiz}
                    setCurrentLectureIndex={setCurrentLectureIndex}
                    newFunc={newFunc}
                    setButton={setButton}
                    certificateURL={certificateURL}
                    setCertificateURL={setCertificateURL}
                    showfunc={showfunc}
                    button={button}
                    CoursesData={CoursesData}
                    ANSWAER_CHOOSE1={ANSWAER_CHOOSE1}
                    setButton1={setButton1}
                    button1={button1}
                  />
                ) : (
                  <CurrentProgressShowCerificate
                    studentName={studentName}
                    CoursesData={CoursesData}
                    certificateURL={certificateURL}
                  />
                )}
              </>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

function CurrentProgressQuizSection(props) {
  let GetCourseValue = useSelector((state) => state.courses);
  const StudentTopCourses = props?.CoursesData?.sections;
  const [isSelected, setIsSelected] = useState("");
  let auth = useAuth();
  let dispatch = useDispatch();
  function CheckQuizAnswerFunction(questionOption) {
    // dispatch(CheckQuizAnswer(questionOption, auth));
    checkQuizAnswer(questionOption, auth)
      .then((response) => {
        setIsSelected({
          id: questionOption,
          flag: response.data,
        });
      })
      .catch((error) => {});
  }
  const QuizAnswerSelection = (questionOption) => {
    props.setButton(questionOption);
    CheckQuizAnswerFunction(questionOption);
    setIsSelected(questionOption);
  };

  function downloadApiCall() {
    dispatch(CertificateReceived(props.CoursesData.courseId, auth));
  }

  function generateCertificateUrl() {
    let finalData = {
      courseId: props?.CoursesData?.courseId,
    };
    dispatch(CertificateConversion(finalData, auth, moveToNext));
  }
  function moveToNext(url) {
    props?.setCertificateURL(url);
    if (url) {
      props?.setCertificateSection(true);
    }
  }

  // let CoursesData = GetCourseValue && GetCourseValue.getById;
  return (
    <>
      <div className="col-sm-12 col-lg-12 col-md-12 section-quiz">
        <Card className="py-4">
          <div className="d-flex align-items-center justify-content-between px-4">
            <div>
              <h4
                className="mb-0 font-weight-800 cursor float-right"
                onClick={() => props?.setCertificateSection(true)}
              >
                Skip Quiz
              </h4>
            </div>
          </div>
          <hr />
          {/* copy here */}
          {StudentTopCourses?.map((section, sectionId) => {
            return (
              <>
                <p className="ml-4 font-weight-700">
                  {section.sectionName} Quizes
                </p>
                {section?.lectures?.map((lecture, lectureId) => (
                  <>
                    <div className=" mt-3 pl-1 px-4" key={lectureId}>
                      <div className="font-weight-700 ">
                        {lecture.lectureName}
                      </div>
                      <br />
                      {lecture?.quiz?.map((quiz, quizId) => (
                        <>
                          {quiz?.quizQuestions?.map(
                            (quizQuestion, quizQuestionId) => (
                              <>
                                <p>{quizQuestion?.questionDescription}</p>
                                {quizQuestion?.quizOptions?.map(
                                  (questionOption, questionOptionId) => (
                                    <p
                                      key={questionOptionId}
                                      onClick={() => {
                                        QuizAnswerSelection(
                                          questionOption?.quizOptionId
                                        );
                                      }}
                                      className={`cursor set-p-tag-backround pl-4 ml-5 ${
                                        props.button ===
                                        questionOption?.quizOptionId
                                          ? questionOption?.quizOptionId ===
                                              isSelected?.id &&
                                            isSelected.flag === false
                                            ? "active-wrong"
                                            : "active-success"
                                          : ""
                                      }`}
                                    >
                                      {questionOption?.optionDescription}
                                    </p>
                                  )
                                )}
                              </>
                            )
                          )}
                        </>
                      ))}
                      {/* <div className="px-4 ">
                            <p className="font-weight-700">Q.{lectureId + 1}</p>
                          </div> */}
                    </div>
                  </>
                ))}
              </>
            );
          })}

          {/* ----- */}
          {GetCourseValue?.certificateConversionLoading === true ? (
            <Loader />
          ) : (
            <>
              <div className="col-md-2 mx-auto">
                <button
                  className="btn btn-primary submit-quiz text-end float-right "
                  onClick={() => generateCertificateUrl()}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Card>
      </div>
    </>
  );
}

function CurrentProgressShowAddModal(props) {
  let firstindexlectureID = props?.CoursesData?.sections?.[0]?.lectures?.[0]
    ?.lectureId
    ? props?.CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
    : props.lectureID;
  let dispatch = useDispatch();
  let auth = useAuth();
  const { register, handleSubmit, errors } = useForm();
  const [notesTittle, setNotesTitle] = useState("");
  const [notesDescription, setNotesDescription] = useState("");
  function onSubmit() {
    props.onHide();
    let finaldata = {
      lectureId: firstindexlectureID,
      noteTitle: notesTittle,
      noteDescription: notesDescription,
    };
    dispatch(CreateNotes(finaldata, auth, moveToNext));
  }
  function moveToNext(step) {
    setNotesTitle(null);
    setNotesDescription(null);
    dispatch(GetAllNotesByLecture(firstindexlectureID, auth));
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="Notesheader">
        <div className="d-flex justify-content-center align-items-center modalheader ">
          <p>Add Notes</p>
          <IoCloseSharp className="modalclose" onClick={props.onHide} />
        </div>
        <hr />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              className="mt-1"
              name="notesTittle"
              type="text"
              placeholder="Add Notes Tittle"
              value={notesTittle}
              onChange={(e) => {
                setNotesTitle(e.target.value);
              }}
              ref={register({ required: true })}
              style={{
                borderColor: errors && errors.notesTittle ? "#a80000" : "",
              }}
            />
            {errors.notesTittle && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              className="mt-1"
              name="notesDescription"
              type="text"
              placeholder="Add Notes Description"
              value={notesDescription}
              onChange={(e) => {
                setNotesDescription(e.target.value);
              }}
              ref={register({ required: true })}
              style={{
                borderColor: errors && errors.notesDescription ? "#a80000" : "",
              }}
            />
            {errors.notesDescription && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <div className="d-flex justify-content-center ">
            <button type="submit" className="next-course-btn btn-save w-25">
              Submit
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function CurrentProgresMyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Notes Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.lectureNotes.noteTitle}</h4>
        <p>{props.lectureNotes.noteDescription}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function CurrentProgressShowCerificate(props) {
  console.log("helloos", props);
  let auth = useAuth();
  let dispatch = useDispatch();
  useEffect(() => {
    document.title = `Course Information || EduTech`;
    dispatch(GetCourseCertification(props.CoursesData.courseId, auth));
  }, []);
  let GetCourseCertificate = useSelector((state) => state.courses);
  let CourseCertificateData =
    GetCourseCertificate && GetCourseCertificate.getCourseCertification;

  const [pic, setPic] = useState(
    CourseCertificateData?.[0]?.courseCertificateURL
  );
  useEffect(() => {
    setPic(CourseCertificateData?.[0]?.courseCertificateURL);
  }, [CourseCertificateData]);

  function downloadApiCall() {
    dispatch(CertificateReceived(props.CoursesData.courseId, auth));
  }

  const printToPdf = () => {
    html2canvas(document.getElementById("print_to_pdf")).then((canvas) => {
      var data = canvas.toDataURL();
      var pdfExportSetting = {
        // pageOrientation: "portrait",
        compress: false,
        pageSize: {
          width: 920,
          height: 650,
        },
        // pageMargins: [40, 60, 40, 60],
        content: [
          // {
          //   image: data,
          //   width: 480,
          //   height: 500,
          // },
          {
            image: data,
            width: 840,
          },
        ],
      };
      pdfMake.createPdf(pdfExportSetting).download("Certificate.pdf");
    });
  };
  return (
    <div className="col-sm-12 col-lg-12 col-md-12 Certication text-center">
      <Card className="">
        <Card.Body>
          <p className="fw-bold text-center firstheading">
            Congratulations, {props.studentName ? props.studentName : "N/A"}!
          </p>
          <p className="text-center text-muted para">
            You have Completed Course Successfully!
          </p>

          {/* <div id="print_to_pdf">
          <button className="btn btn-primary submit-quiz mt-2"
            onClick={() => {
                    downloadApiCall();
                    printToPdf();
                  }}>
            Download Certificate
          </button>
        </div> */}
          <Card className="certicate-card mt-5 text-left">
            <Card.Body className="overflow-auto">
              {/* {CourseCertificateData &&
                CourseCertificateData.map((list, mapId) => {
                  debugger
                  return (
                    <>
                      <div className="row d-flex  align-items-center text-center">
                        <div className="set-view-small-screen mr-4 col-md-12 mb-3">
                          <img src={IMAGES.IPO2} className="img-fluid" alt="" />
                          <img
                            src={list.courseCertificateURL}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mx-auto">
                          <a
                            href={pic}
                            download
                            onClick={() => downloadApiCall()}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              className="download-btn mb-2 bg-default-light mt-3 ml-4"
                              variant="danger"
                              onClick={() => {
                                downloadApiCall();
                              }}
                            >
                              Open In New Tab
                            </Button>
                          </a>
                        </div>
                      </div>
                    </>
                  );
                })} */}
              <div className="background-image-certificate" id="print_to_pdf">
                <div className="row p-5">
                  <div className="col-md-8 col-6">
                    <p className="cert-text-size mb-0">CERTIFICATE</p>
                    <p className="appreciate-text">OF APPRECIATION</p>
                  </div>
                  <div className="col-md-4 col-6 pt-4 parent-class">
                    <span className="f-20 query ml-5">November 12th 2022</span>
                  </div>
                </div>
                <div className="row pl-5">
                  <div className="col-md-12 col-12 ">
                    <p className="f-20 ">This award is proudly given to:</p>
                    <p className="name-style text-uppercase">
                      {props.studentName ? props.studentName : "N/A"}
                    </p>
                    <p className="f-20 text-uppercase">
                      Completed :{" "}
                      {props?.CoursesData?.courseTitle
                        ? props.CoursesData.courseTitle
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row p-5">
                  <div className="col-md-8">
                    <p className="fw-600 mb-4 authorize-text">Authorized by:</p>
                    <p className="mb-0">____________</p>
                    <p className="ml-3 Principal-text">The Principal</p>
                    <span className="signature-style">
                      <img
                        src={IMAGES.SIGNCERT}
                        alt="signature"
                        className="img-fluid "
                      />
                    </span>
                  </div>
                  <div className="col-md-4 ">
                    <img
                      src={IMAGES.EDUTECH_LOGO_SMALL}
                      className="img-fluid candletech-logo"
                      alt="edutech-logo"
                    />
                  </div>
                </div>
              </div>
              <div className=" mt-4 d-flex justify-content-center">
                <a href={props?.certificateURL} download>
                  <button className="notes-download-btn">
                    <BiDownload className="download-btn-style" />
                    {"Download"}
                  </button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
}

function CurrentProgressVideoSection(props) {
  let auth = useAuth();
  let firstindexlectureID = props?.CoursesData?.sections?.[0]?.lectures?.[0]
    ?.lectureId
    ? props?.CoursesData?.sections?.[0]?.lectures?.[0]?.lectureId
    : props.lectureID;
  let dispatch = useDispatch();
  const [showNextButton, setShowNextButton] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [notesId, setNotesId] = React.useState("");
  const [modalShowForNotification, setModalShowForNotification] =
    React.useState(false);
  const [currentUrlIndex, setCurrentUrlIndex] = useState("");
  const clickOnNext = () => {
    let selector =
      "uniqueId_" + props.showfunc + "_" + (props.currentLectureIndex + 1);
    let findObj = document.getElementById(selector);
    if (findObj !== null) {
      findObj.click();
    } else {
      props.setCurrentLectureIndex(0);
      selector = "uniqueId_" + (props.showfunc + 1) + "_" + 0;
      findObj = document.getElementById(selector);
      if (findObj !== null) {
        findObj.click();
      } else {
        props.setShowQuiz(true);
      }
    }
  };
  const [videoUrl, setVideoUrl] = useState(props.activeStep);
  const [played, setPlayed] = useState(0);
  const valueRef = useRef();
  const [isReady, setIsReady] = React.useState(false);
  const onReady = React.useCallback(() => {
    if (!isReady && props?.MyProgressCourseData) {
      const timeToStart = props?.MyProgressCourseData?.videoTimeStamp;
      valueRef.current.seekTo(timeToStart, "seconds");
      setIsReady(true);
    }
  }, [isReady, props?.MyProgressCourseData]);
  // }
  let time = 0;
  useEffect(() => {
    setVideoUrl(props?.activeStep);
  }, [props?.activeStep]);
  function onPause() {
    // if (props.activeStep === 0) {
    let finalData = {
      courseId: props?.CoursesData?.courseId,
      lectureId: firstindexlectureID,
      videoTimeStamp: Math.floor(played),
    };
    dispatch(UpdateVideoTimeStamp(finalData, auth));
  }
  function onStart() {
    if (props?.activeStep === 0) {
      dispatch(MarkCourseAsStarted(props?.CoursesData?.courseId, auth));
    }
  }
  const onProgress = (progress) => {
    setPlayed(progress.playedSeconds);
    time = progress.playedSeconds;
  };
  return (
    <>
      <div className="col-sm-12 col-lg-12 col-md-12 pt-3">
        <h2 className="mb-3 font-weight-700">
          {props.lectureName ? props.lectureName : props.firstIndexLectureName}
        </h2>
        <Card className=" p-4">
          <div className="embed-responsive embed-responsive-16by9 video-height">
            {showNextButton === false ? (
              <>
                <ReactPlayer
                  url={videoUrl !== 0 ? videoUrl : props.firstIndexVideo}
                  playing
                  ref={valueRef}
                  controls
                  onReady={onReady}
                  onPause={onPause}
                  onStart={onStart}
                  onProgress={onProgress}
                  // onPause={onPause}
                  // onProgress={(progress) => {
                  //   setPlayed(progress.playedSeconds);
                  // }}
                  // onStart={onStart}
                  onEnded={() => clickOnNext()}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="row pt-4 pl-2 align-items-center">
            <div className="col-8 d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="mr-3 dropdown-note set-backrouund-light d-flex align-items-center"
                >
                  <BsFilesAlt className=" icon-font-size-30 text-color-green" />
                  <span className="text-color-green ml-2 "> Add Note</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.LectureNotesCourses.map((list, index) => {
                    return (
                      <Dropdown.Item
                        href="#"
                        key={index}
                        // onClick={() => setModalShowForNotification(true)}
                        onClick={() => {
                          setModalShowForNotification(true);
                          setNotesId(list);
                        }}
                      >
                        {list.noteTitle}
                      </Dropdown.Item>
                    );
                  })}
                  <CurrentProgresMyVerticallyCenteredModal
                    lectureNotes={notesId}
                    show={modalShowForNotification}
                    onHide={() => setModalShowForNotification(false)}
                  />
                  <Dropdown.Item
                    href="#"
                    className="NoteSize"
                    onClick={() => setModalShow(true)}
                  >
                    Add <AiOutlinePlusCircle className="icon" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div className="col-4 text-right edutech">
              <IoIosThumbsUp className="icon-font-size-30 thumbup mr-4 cursor" />
              <IoIosThumbsDown className=" icon-font-size-30 thumbdown cursor" />
            </div> */}
          </div>
        </Card>
        <div className="tabs-modify">
          <Tabs
            className="nav-justified"
            style={{
              background: "#f7f7f7",
              borderBottom: "3px solid #f0ecec",
              borderColor: "none",
            }}
          >
            <Tab className="nav-item" eventKey={3} title=" SUMMARY">
              <div className="row">
                <div className="col-md-12 col-12 col-lg-12 col-sm-12 ">
                  <Card className=" p-4">
                    <div className="text-capitilize b-500 ">
                      {/* <h3 className="font-weight-00"> SUMMARY</h3> */}
                      {props?.CoursesData?.sections?.map(
                        (section, sectionId) => {
                          return (
                            <>
                              {section?.lectures?.map((Lecture, lectureId) => (
                                <>
                                  <h4 key={lectureId} className="text-dark">
                                    {Lecture?.lectureName}
                                  </h4>
                                  <p>{Lecture?.lectureSummary}</p>
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab className="nav-item " eventKey={1} title="RESOURCES">
              <div className="row">
                <div className="col-md-12 col-12 col-lg-12 col-sm-12 ">
                  <Card className=" p-4">
                    <div className="text-capitilize b-500 ">
                      {/* <h3 className="font-weight-700">LESSON RESOURCES</h3> */}
                      {props?.CoursesData?.sections?.map(
                        (section, sectionId) => {
                          return (
                            <>
                              {section?.lectures?.map((Lecture, lectureId) => (
                                <>
                                  <h4 key={lectureId} className="text-dark">
                                    {Lecture?.lectureName}
                                  </h4>
                                  <p>
                                    <span className="text-color-green  ">
                                      Save Resourse
                                    </span>{" "}
                                    {
                                      <a
                                        href={Lecture?.lectureResource}
                                        download
                                        // onClick={(e) => download(e)}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <AiOutlineDownload className=" icon-font-size-30 text-color-green  margin-left-20" />
                                      </a>
                                    }
                                  </p>
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                      {/* <div className="d-flex align-items-center py-2">
                      <span className="text-color-green  ">
                        {" "}
                        Download slides{" "}
                      </span>
                      <AiOutlineDownload className=" icon-font-size-30 text-color-green ml-5 " />
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-color-green  ">
                        Download transcript
                      </span>{" "}
                      <AiOutlineDownload className=" icon-font-size-30 text-color-green  margin-left-20" />
                    </div> */}
                    </div>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab className="nav-item " eventKey={6} title="TRANSCRIPT">
              <div className="col-md-12 col-12 col-lg-12 col-sm-12 transcript-dropdown">
                <Card className="d-flex p-4">
                  {/* <h3 className="font-weight-700">TRANSCRIPT</h3> */}
                  {props?.CoursesData?.sections?.map((section, sectionId) => {
                    return (
                      <>
                        {section?.lectures?.map((Lecture, id) => (
                          <>
                            <h4 key={id} className="text-dark">
                              {Lecture?.lectureName}
                            </h4>
                            <p>{Lecture?.lectureTranscript}</p>
                            <CurrentProgressShowAddModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              CourseDataLectureID={props.CoursesData}
                              lectureID={props.lectureNotesData}
                            />
                          </>
                        ))}
                      </>
                    );
                  })}
                </Card>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      {/* <ShowAddModal show={modalShow} onHide={() => setModalShow(false)} CourseDataLectureID={props.CoursesData} lectureID={props.lectureID} /> */}
    </>
  );
}
