import React from "react";
import { useState, useRef } from "react";
import { convertBase64, IMAGES } from "../../../../assets";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { Form } from "react-bootstrap";

export default function TeacherCoachingInformation() {
  const [isShown, setIsShown] = useState("Upload Image");
  const [iconDelete, setIconDelete] = useState(null);
  console.log("test data", iconDelete)
  const fileInputRef = useRef();
  const [icon, setIcon] = useState(FaPlusCircle);
  const [preview, setPreview] = useState(IMAGES.AVATAR);
  function uploadFile(e) {
    let file = e.target.files[0];
    if (file) {
      convertBase64(file).then((getValue) => {
        setPreview(getValue);
        setIsShown("Replace Image");
        setIcon(null);
        setIconDelete(FaTrashAlt);
      });
    }
  }
  //  function goNext() {
  //  history.push({
  //     pathname: PATH.TEACHERNEWCOURSE,
  //   });
  // }
  return (
    <>
      <div className="teacher-Coaching-information">
        <div className=" row p-2">
          <div className="col-md-4 col-lg-3 mt-2">
            <h3>Basic</h3>
            <p>Edit your coaching service details and add a thumbnail.</p>
          </div>
          <div className="col-md-8 col-lg-9">
            <div className="card  cursor">
              <div className="card-body mt-3 mb-3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <p className="fw-600 mb-1">Name</p>
                  <Form.Control type="email" placeholder="" />
                </Form.Group>
                <h6 className="pt-2">Thumbnail</h6>
                <div className="d-flex pl-0">
                  <div className="col-7 p-0">
                    <div
                      className="set-border-div"
                      onMouseLeave={() => {
                        setIsShown("");
                        setIcon(null);
                      }}
                      onMouseEnter={() => {
                        setIsShown("Replace Image");
                        setIcon(FaPlusCircle);
                      }}
                    >
                      <img className=" gravatar" src={preview} alt="" />
                      <div>
                        <button
                          className="upload-image-ck-editor"
                          onClick={(event) => {
                            event.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          {icon} {isShown}
                        </button>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          accept="image/*"
                          onChange={(event) => {
                            uploadFile(event);
                          }}
                        />
                        {/* <h5 className="delete-btn-styling" onClick={setDefault}>{iconDelete}</h5>  */}
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <p className="mb-1 ml-4 fw-700">Image guide</p>
                    <ul className="mt-0">
                      <li>Aspect ratio: 16:9</li>
                      <li>Recommended size: 1024x576</li>
                      <li>Use the upload area to double check your image.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" row p-2">
          <div className="col-md-4 col-lg-3  mt-2">
            <h3>Author</h3>
            <p>Choose an author for your coaching service.</p>
          </div>
          <div className="col-md-8 col-lg-9">
            <div className="card  cursor">
              <div className="card-body mt-3 mb-3">
                <p className="fw-600 mb-1" for="cars">
                  Author
                </p>
                <select
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <p className="mt-1 text-muted">
                  Don’t see the user you’re looking for? You may need to make
                  them an Author.
                </p>
                <h4 className="mt-5">Author details</h4>
                <p className="text-muted para-line-height">
                  This bio is connected to an Owner. To edit an owner’s bio,
                  please go to the school Bios page
                </p>
                <h5 className="img-mtp">Image</h5>
                <h5 className="img-mtp">Bio</h5>
              </div>
            </div>
          </div>
        </div>
        <div className=" row p-2">
          <div className="col-md-4 col-lg-3  mt-2">
            <h3>Advanced Settings</h3>
          </div>
          <div className="col-md-8 col-lg-9">
            <div className="card  cursor">
              <div className="card-body mt-3 mb-3">
                <h5>We have native support for Calendly.</h5>
                <p>
                  Adding an API key will allow you to view your appointments
                  from within Teachable.
                </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <p className="text-muted mb-1">Calendly API KEY (optional)</p>
                  <Form.Control type="email" placeholder="" />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className=" row p-2">
          <div className="col-md-4 col-lg-3  mt-2">
            <h3>Delete</h3>
            <p>
              Permanently delete all coaching content, including student
              enrollment records.
            </p>
          </div>
          <div className="col-md-8 col-lg-9">
            <div className="card  cursor">
              <div className="card-body mt-3 mb-3">
                <button className="btn-unpublish">Delete Coaching</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
