import axios from "axios";
import { APP_SETTINGS } from "../../config";
export function getAdminDashboardTiles(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getAdminDashboardTiles, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function getTotalCoachingSales(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getTotalCoachingSales, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function getTotalCoursesSales(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getTotalCoursesSales, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function getProviderDashboardTiles(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getProviderDashboardTiles, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function getCertificationsAwardedList(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getCertificationsAwardedList, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function getCourseSalesList(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getCourseSalesList, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function getCoachingSalesList(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getCoachingSalesList, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function getCourseStudentsList(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getCourseStudentsList, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function providerDashboardStatusOfCourses(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.providerDashboardStatusOfCourses, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function adminDashboardTopCoursesGraph(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.adminDashboardTopCoursesGraph, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function adminDashboardTopCategoriesGraph(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.adminDashboardTopCategoriesGraph, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function providerDashboardTotalEarnings(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.providerDashboardTotalEarnings, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function getCoachingStudentsList(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.getCoachingStudentsList, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
export function adminDashboardRevenue(auth) {
    return axios.get(APP_SETTINGS.API_PATH.DASHBOARD.adminDashboardRevenue, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
  export function getQuotation(QuoteId, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.DASHBOARD.getQuotation}?QuoteId=${QuoteId}`, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
    );
  }