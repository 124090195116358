import React, { useState, useRef, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { FiCheckCircle } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoArrowBackSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Cards from "react-credit-cards";
import { FieldError, Loader, getCouponDiscountedAmount } from "../../assets";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Card, Button, Form, Row, Col, Modal, Dropdown } from "react-bootstrap";
import { CREDENTIALS } from "../../assets/LookUpdata";
import { ToastContainer, toast } from "react-toastify";

import { PATH } from "../../config";
import {
  GetIndustry,
  GetManualPricingRange,
  GetSelectedPricing,
  IsEmailExist,
  PriceCheckWithinRange,
  PricingPlanByFilter,
  RegisterStudent,
} from "../../redux/actions";
import {
  GetAllApprovedInterest,
  Interest,
} from "../../redux/actions/interestAction";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";

export default function StudentStep(props) {
  const [stdstep, setstdstep] = useState(
    props.StudentProfilePage ? props.StudentProfilePage : 0
  );
  return (
    <div>
      <ToastContainer />
      {stdstep === 0 && <Step setstdstep={setstdstep} {...props} />}
      {stdstep === 1 && (
        <Step1
          setstdstep={setstdstep}
          changeView={props.changeView}
          {...props}
        />
      )}
      {stdstep === 2 && (
        <Step2
          setstdstep={setstdstep}
          changeView={props.changeView}
          {...props}
        />
      )}
      {stdstep === 3 && <Step3 setstdstep={setstdstep} {...props} />}
    </div>
  );
}

function Step(props) {
  let dispatch = useDispatch();
  const { register, handleSubmit, errors, watch } = useForm();
  let stdData = props.studentForm;
  let emailExist = useSelector((state) => state.account);
  const [email, setEmail] = useState(
    stdData && stdData.email ? stdData.email : ""
  );
  const [fname, setFname] = useState(
    stdData && stdData.firstName ? stdData.firstName : ""
  );
  const [lname, setLname] = useState(
    stdData && stdData.lastName ? stdData.lastName : ""
  );
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [passwordShown1, setPasswordShown1] = useState(false);
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
  };
  const password = useRef({});
  password.current = watch("password", "");
  function onSubmit(data) {
    stdData.firstName = data.firstName;
    stdData.lastName = data.lastName;
    stdData.email = data.email;
    stdData.password = data.password;
    stdData.confirmPassword = data.password;
    dispatch(IsEmailExist(stdData.email, moveToNext, notification));
    props.setStudentform({ ...props.studentForm }, stdData);
  }
  function moveToNext() {
    props.setstdstep(1);
  }
  function notification(data, condition) {
    if (condition === "success") {
      moveToNext();
    } else {
      toast.error(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }
  function goBack() {
    stdData.firstName = "";
    stdData.lastName = "";
    stdData.email = "";
    stdData.password = "";
    stdData.confirmPassword = "";

    props.changeView(0);
  }
  return (
    <div className="step-section">
      <div className="card">
        <div className="card-body">
          <IoArrowBackSharp
            className="edutech-back-arrow mb-3"
            onClick={() => goBack()}
          />
          <h5 className="card-title">
            Just a few details and we are good to go!
          </h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                className="mt-1"
                name="firstName"
                type="text"
                autocomplete="off"
                value={fname}
                onChange={(e) => {
                  setFname(e.target.value);
                }}
                placeholder="Enter First Name"
                style={{
                  borderColor: errors && errors.firstName ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {fname !== "" ? <FiCheckCircle className="check-icon" /> : ""}
              {errors.firstName && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                className="mt-1"
                name="lastName"
                type="text"
                autocomplete="off"
                value={lname}
                onChange={(e) => {
                  setLname(e.target.value);
                }}
                placeholder="Enter Last Name"
                style={{
                  borderColor: errors && errors.lastName ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {lname !== "" ? <FiCheckCircle className="check-icon" /> : ""}
              {errors.lastName && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="mt-1"
                name="email"
                type="email"
                autocomplete="off"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter Email"
                style={{ borderColor: errors && errors.email ? "#a80000" : "" }}
                ref={register({
                  required: true,
                  pattern:
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
                })}
              />
              {email !== "" ? <FiCheckCircle className="check-icon" /> : ""}
              {errors.email && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                className="mt-1"
                name="password"
                type={passwordShown ? "text" : "password"}
                placeholder="Enter Password"
                style={{
                  borderColor: errors && errors.password ? "#a80000" : "",
                }}
                ref={register({
                  required: true,
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#^%*?&.()-_=+])[A-Za-z\d@$!%#^*?&.()-_=+]{8,}$/,
                })}
              />
              {passwordShown === false ? (
                <AiFillEyeInvisible
                  className="show_pswd"
                  onClick={togglePasswordVisiblity}
                />
              ) : (
                <AiFillEye
                  className="show_pswd"
                  onClick={togglePasswordVisiblity}
                />
              )}
              {errors && errors.password && (
                <FieldError
                  message={
                    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                  }
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                className="mt-1"
                name="confirmPassword"
                type={passwordShown1 ? "text" : "password"}
                autocomplete="off"
                placeholder="Enter Confirm Password"
                style={{
                  borderColor:
                    errors && errors.confirmPassword ? "#a80000" : "",
                }}
                ref={register({
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
              />
              {passwordShown1 === false ? (
                <AiFillEyeInvisible
                  className="show_pswd"
                  onClick={togglePasswordVisiblity1}
                />
              ) : (
                <AiFillEye
                  className="show_pswd"
                  onClick={togglePasswordVisiblity1}
                />
              )}
              {errors && errors.confirmPassword && (
                <FieldError message={errors.confirmPassword.message} />
              )}
            </Form.Group>
            <Form.Group>
              {emailExist && emailExist.emailExistLoading === false && (
                <Button type="submit" className="next_button">
                  {"Next"}
                </Button>
              )}
              {emailExist && emailExist.emailExistLoading === true && (
                <Loader />
              )}
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}
function Step1(props) {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetIndustry("IndustryType"));
  }, [dispatch]);
  let interestValue = useSelector((state) => state.country);
  let stdData = props.studentForm;
  const { register, handleSubmit, errors } = useForm();
  let signUp = useSelector((state) => state.account);
  let IndustryTypevalue = null;

  const [job, setJob] = useState(
    stdData && stdData.student && stdData.student.jobTitle
      ? stdData.student.jobTitle
      : ""
  );
  const [IndustryType, setIndustryType] = useState(IndustryTypevalue);
  const [experience, setExperience] = useState(
    stdData && stdData.student && stdData.student.experience
      ? stdData.student.experience
      : ""
  );
  const [IndustryTypeError, setIndustryTypeError] = useState(null);
  const [searchList, setSearchList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const updateSearch = (search) => {
    setSearchValue(search);
    let filterSearch = interestValue.getIndustry;
    filterSearch = interestValue.getIndustry.filter((list) => {
      return list.value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    setSearchList(filterSearch);
  };
  useEffect(() => {
    setSearchList(interestValue.getIndustry);
  }, [interestValue]);
  function onSubmit(data) {
    if (IndustryType == null) {
      setIndustryTypeError(true);
    } else {
      stdData.student.experience = parseInt(experience);
      stdData.student.jobTitle = data.job;
      interestValue?.getIndustry.map((item, i) => {
        if (item.text === IndustryType) {
          stdData.student.lookUpId = item.lookUpId;
        }
      });
      props.setStudentform({ ...props.studentForm }, stdData);
      props.setstdstep(2);
    }
  }
  function setIndustryValuee(val) {
    setIndustryType(val);
    setIndustryTypeError(false);
  }
  function goBack() {
    stdData.student.industryId = 0;
    stdData.student.jobTitle = "";
    stdData.student.experience = 0;
    if (stdData.loginTypeId !== CREDENTIALS) {
      props.changeView(0);
    } else {
      props.setstdstep(0);
    }
  }
  return (
    <div className="step-section">
      <div className="card">
        <div className="card-body">
          <IoArrowBackSharp
            className="edutech-back-arrow  mb-3"
            onClick={() => goBack()}
          />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="dropdown_bg_grey industry-type-parent-class">
              <Form.Label>What Industry are you in?</Form.Label>
              <Dropdown alignRight>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="text-left "
                >
                  {IndustryType ? IndustryType : "Select Industry"}
                  <IoMdArrowDropdown />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow">
                  <div className="search_field">
                    <Form.Control
                      name="searchLocation"
                      type="text"
                      autocomplete="off"
                      value={searchValue}
                      onChange={(e) => {
                        updateSearch(e.target.value);
                      }}
                      ref={register()}
                    />
                  </div>
                  {/* {interestValue.getIndustry &&
                    interestValue.getIndustry.map((item, index) => { */}
                  {searchList.length === 0 ? (
                    <p className="searchfilter-text-style">No Record Found</p>
                  ) : (
                    <>
                      {searchList &&
                        searchList.map((item, index) => {
                          return (
                            <Dropdown.Item
                              onClick={() => setIndustryValuee(item.text)}
                              key={index}
                            >
                              {item.text}
                            </Dropdown.Item>
                          );
                        })}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {IndustryTypeError && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>What is your job title</Form.Label>
              <Form.Control
                className="mt-1"
                name="job"
                type="text"
                autocomplete="off"
                value={job}
                onChange={(e) => {
                  setJob(e.target.value);
                }}
                style={{ borderColor: errors && errors.job ? "#a80000" : "" }}
                ref={register({ required: true })}
              />
              {job !== "" ? <FiCheckCircle className="check-icon" /> : ""}
              {errors.job && <FieldError message={"This Field is Required"} />}
            </Form.Group>
            <Form.Group>
              <Form.Label>How many years of experience do you have?</Form.Label>
              <Form.Control
                className="mt-1"
                name="experience"
                type="number"
                autocomplete="off"
                value={experience}
                onChange={(e) => {
                  setExperience(e.target.value);
                }}
                style={{
                  borderColor: errors && errors.experience ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {experience !== "" ? (
                <FiCheckCircle className="check-icon" />
              ) : (
                ""
              )}
              {errors.experience && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              {signUp && signUp.registerStudentLoading === false && (
                <Button type="submit" className="next_button">
                  {"Next"}
                </Button>
              )}
              {signUp && signUp.registerStudentLoading === true && <Loader />}
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}
function Step2(props) {
  const [state, onPageDispatch] = useReducer(btnSetReducer, INITIAL_STATE);
  let stdData = props.studentForm;
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllApprovedInterest());
  }, [dispatch]);
  let interestValue = useSelector((state) => state.interest);
  const { handleSubmit } = useForm();
  let signUp = useSelector((state) => state.account);
  const [errorField, seterrorField] = useState();
  function onSubmit(data) {
    // if (stdData.studentInterest.length > 0) {
    //   dispatch1(RegisterStudent(stdData, moveToNext, notification));
    // } else {
    //   seterrorField(true);
    // }
    props.setStudentform({ ...props.studentForm }, stdData);
    props.setstdstep(3);
  }
  // function moveToNext() {
  //   setTimeout(
  //     function () {
  //       history.push(PATH.LOGIN);
  //     }.bind(this),
  //     4600
  //   );
  // }
  function setInterestValue(val) {
    seterrorField(false);
    let findIndex = -1;

    if (stdData.studentInterest.length === 0) {
      stdData.studentInterest.push(val);
      onPageDispatch({ type: "ADD_BUTTON", payload: val });
    } else {
      findIndex = stdData.studentInterest.indexOf(val);
      if (findIndex === -1) {
        stdData.studentInterest.push(val);
        onPageDispatch({ type: "ADD_BUTTON", payload: val });
      } else {
        stdData.studentInterest.splice(findIndex, 1);
        onPageDispatch({ type: "REMOVE_BUTTON", payload: val });
      }
    }
  }
  function goBack() {
    stdData.studentInterest = [];
    props.setstdstep(1);
  }
  return (
    <div className="step-section signup_page_colour_change">
      <div className="card">
        <div className="card-body">
          <IoArrowBackSharp
            className="edutech-back-arrow mb-3"
            onClick={() => goBack()}
          />
          <Form onSubmit={handleSubmit(onSubmit)}>
            {errorField && errorField === true ? (
              <FieldError message={"Select any of field"} />
            ) : (
              ""
            )}
            <Form.Group>
              <Form.Label className="font-weight-bold font-20">{`Choose your area of interests`}</Form.Label>
              <div className="interest-btn text-center">
                {interestValue.getAllApprovedInterest &&
                  interestValue.getAllApprovedInterest.map((item, i) => {
                    return (
                      <>
                        <ButtonSection
                          key={i}
                          item={item}
                          index={i}
                          setInterestValue={setInterestValue}
                          stdData={stdData}
                          interestVal={interestValue}
                          state={state}
                        />
                      </>
                    );
                  })}
              </div>
            </Form.Group>
            <Form.Group>
              {signUp && signUp.registerStudentLoading === false && (
                <Button type="submit" className="next_button">
                  {"Next"}
                </Button>
              )}
              {signUp && signUp.registerStudentLoading === true && <Loader />}
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}
function Step3(props) {
  function goBack() {
    props.setstdstep(2);
  }
  return (
    <>
      <IoArrowBackSharp
        className="edutech-back-arrow mt-3 text-white ml-4"
        onClick={() => goBack()}
      />
      <PricingPlanDetail
        studentForm={props.studentForm}
        setStudentform={props.setStudentform}
      />
    </>
  );
}

function ButtonSection({ ...props }) {
  let { index } = props;
  // useEffect(() => { }, [props.item.lookupId]);
  return (
    <Button
      type="button"
      key={index}
      onClick={() => props.setInterestValue(props.item.interestId)}
      className={`cursor ${
        props &&
        props.state &&
        props.state.buttonList &&
        props.state.buttonList.includes(props.item.interestId) === true
          ? "active"
          : ""
      }`}
    >
      {props.item.description}
    </Button>
  );
}
const INITIAL_STATE = {
  buttonList: [],
};

const btnSetReducer = (intial_state, action) => {
  switch (action.type) {
    case "ADD_BUTTON":
      let button_list_for_add = intial_state.buttonList;
      if (button_list_for_add.includes(action.payload) === false) {
        button_list_for_add.push(action.payload);
      }
      return {
        ...intial_state,
        buttonList: button_list_for_add,
      };
    case "REMOVE_BUTTON":
      let button_list_for_remove = intial_state.buttonList;
      for (let i = 0; i < button_list_for_remove.length; i++) {
        if (button_list_for_remove[i] === action.payload) {
          button_list_for_remove.splice(i, 1);
        }
      }
      return {
        ...intial_state,
        buttonList: button_list_for_remove,
      };
    default:
      return intial_state;
  }
};
function PricingPlanDetail(props) {
  const { register, handleSubmit, errors, reset } = useForm();
  const [buttonState, setButtonState] = useState(false);
  const [pricestate, setpricestate] = useState(false);
  const [bonusState, setBonusState] = useState(false);
  const [errorField, seterrorField] = useState();
  let stdData = props.studentForm;
  function onSubmit(data) {}
  const [otherPlan, setOtherPlan] = useState(false);
  const [otherPlanField, setOtherPlanField] = useState(false);
  const [bonusValue, setBonusValue] = useState(
    getCouponDiscountedAmount(buttonState)
  );
  const dispatch = useDispatch();
  let auth = useAuth();
  useEffect(() => {
    dispatch(GetManualPricingRange(auth));
  }, [dispatch]);
  function OtherPlanSubmit(data) {
    dispatch(PriceCheckWithinRange(otherPlan, auth, Notificiation));
  }
  let getPriceListing = useSelector((state) => state.pricingPlan);
  let pricingPlan = getPriceListing && getPriceListing.getSelectedPricing;
  let pricingPlanListing = getPriceListing?.getManualPriceRange;
  useEffect(() => {
    dispatch(GetSelectedPricing(auth));
  }, [dispatch]);
  function Notificiation(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  return (
    <>
      <div className="container subscription_plan mt-4 signup_page_colour_change">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 col-md-12">
              <div
                className={` p-3 card-custom w-100 ml-0 admin-course-request`}
              >
                <div className="card-header text-center">
                  <h1 className="font-weight-700 mb-0">Pricing Plan</h1>
                </div>
              </div>
              {/* <Card>
                <Card.Body className="text-center proceed_free_btn d-flex align-items-center justify-content-between">
                  <h4 className="text-dark">You are entitled to a free trial of 7 Days</h4>
                </Card.Body>
              </Card> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-12">
              <Card className="mt-0">
                <Card.Body>
                  <div className="pricing_btn pricing-plan-height-limit">
                    {pricingPlan &&
                      pricingPlan.map((interest, index) => {
                        return (
                          <>
                            <ButtonSection2
                              setButtonState={setButtonState}
                              buttonState={buttonState}
                              setBonusState={setBonusState}
                              bonusState={bonusState}
                              pricestate={pricestate}
                              setpricestate={setpricestate}
                              setBonusValue={setBonusValue}
                              interest={interest}
                              index={index}
                            />
                          </>
                        );
                      })}
                  </div>
                  <Form.Group className="pt-3">
                    <Button
                      type="button"
                      onClick={() => {
                        setOtherPlanField(!otherPlanField);
                      }}
                      className={`cursor btn-select pricing-plan-other-selection ${
                        otherPlanField === true ? "active" : ""
                      }`}
                    >
                      {"Other Plan"}
                    </Button>
                  </Form.Group>
                  {otherPlanField === true ? (
                    <>
                      <p className="text-dark font-400 my-4 p-3">
                        The amount must be between {pricingPlanListing.minRange}{" "}
                        to {pricingPlanListing.maxRange} and multiple of 100.
                      </p>
                      <Form.Group>
                        <Form.Control
                          className="font-weight-bold edutech-coaching-name-text"
                          name="otherPlan"
                          type="number"
                          value={otherPlan}
                          onChange={(e) => {
                            setOtherPlan(e.target.value);
                          }}
                          placeholder="Type Amount"
                          style={{
                            borderColor:
                              errors && errors.otherPlan ? "#a80000" : "",
                          }}
                          ref={register({ required: true })}
                        />
                        {errors.otherPlan && (
                          <FieldError message={"This Field is Required"} />
                        )}
                      </Form.Group>
                      <div className="text-center">
                        <Button
                          className="payment-primary "
                          onClick={() => {
                            OtherPlanSubmit();
                          }}
                        >
                          {" "}
                          Save
                        </Button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <Card>
                <Card.Body className="proceed_btn">
                  <Form.Group as={Row} className="mb-0">
                    <Form.Label column md="5">
                      Your Credits
                    </Form.Label>
                    <Col md="12">
                      <Form.Control
                        name="lastName"
                        value={
                          Math.trunc(bonusValue?.total)
                            ? Math.trunc(bonusValue?.total)
                            : otherPlan
                            ? otherPlan
                            : "Please Select Your amount"
                        }
                        // type="number"
                        disabled
                        ref={register()}
                      />
                    </Col>
                    <Col md="5" className="mt-3 mx-auto">
                      <PaymentModal
                        bonusValue={bonusValue}
                        otherPlan={otherPlan}
                        buttonState={buttonState}
                        bonusState={bonusState}
                        pricestate={pricestate}
                        pricingPlan={pricingPlan}
                        register={register({ required: true })}
                        stdData={stdData}
                      />
                    </Col>
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
function ButtonSection2(props) {
  let { index } = props;
  return (
    <>
      <Button
        type="button"
        key={index}
        onClick={() => {
          props.setBonusValue(getCouponDiscountedAmount(props.interest));
          props.setButtonState(props.interest.pricingPlanId);
          props.setpricestate(props.interest.price);
          props.setBonusState(props.interest.bonusPercentage);
        }}
        className={`cursor ${
          props.buttonState === props.interest.pricingPlanId ? "active" : ""
        }`}
      >
        {props.interest.price} ({props.interest.bonusPercentage}%)
      </Button>
    </>
  );
}
function PricingPaymentModal(props) {
  let dispatch1 = useDispatch();
  let stdData = props.studentData;
  const [errorField, seterrorField] = useState();
  const { register, handleSubmit } = useForm();
  let history = useHistory();
  function onSubmit(data) {
    if (props.pricestate !== false) {
      if (stdData.studentInterest.length > 0) {
        let finalData = {
          firstName: stdData.firstName,
          lastName: stdData.lastName,
          email: stdData.email,
          password: stdData.password,
          confirmPassword: stdData.confirmPassword,
          roleId: stdData.roleId,
          loginTypeId: stdData.loginTypeId,
          socialKeyId: stdData.socialKeyId,
          image: stdData.image,
          studentInterest: stdData.studentInterest,
          student: {
            industryId: stdData.student.lookUpId,
            jobTitle: stdData.student.jobTitle,
            experience: stdData.student.experience,
          },
          number: card.number,
          expiry: parseInt(card.expiry),
          cvc: card.cvc,
          price: parseInt(
            props.pricestate ? props.pricestate : props.otherPlan
          ),
          percentage: parseInt(props.bonusState ? props.bonusState : 0),
        };
        dispatch1(RegisterStudent(finalData, moveToNext, notification));
      } else {
        seterrorField(true);
      }
    } else {
      toast.error(
        "Your entered amount must be greater than 0, please enter again",
        {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }
  function moveToNext() {
    setTimeout(function () {
      history.push(PATH.LOGIN);
    }, 4600);
  }
  function notification(data, condition) {
    debugger;
    if (condition === "success") {
      toast.success(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const [card, setCard] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  });

  const handleInputFocus = (e) => {
    // setCard({ focus: e.target.name });
    // const { focus, value } = e.target;
    // setCard({
    //   ...card,
    //   [focus]: value,
    // });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCard({
      ...card,
      [name]: value,
    });
  };

  function validateFields(e) {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Payment Details
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div id="PaymentForm">
            <Cards
              cvc={card.cvc}
              expiry={card.expiry}
              focused={card.focus}
              name={card.name}
              number={card.number}
            />
            <Form>
              <Form.Group className="container mt-2">
                <div className="row">
                  <div className="col-12">
                    <Form.Control
                      className="my-2  edutech-payment-inout-field"
                      name="number"
                      type="text"
                      maxLength="16"
                      ref={register}
                      onInput={(e) => validateFields(e)}
                      autocomplete="off"
                      placeholder="Enter Valid number"
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                    />
                  </div>
                  <div className="col-12">
                    <Form.Control
                      className="my-2  edutech-payment-inout-field"
                      name="name"
                      type="text"
                      autocomplete="off"
                      ref={register}
                      placeholder="Enter Your Name"
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                    />
                  </div>
                  <div className="col-6">
                    <Form.Control
                      className="mt-2  edutech-payment-inout-field"
                      name="expiry"
                      type="text"
                      maxLength="4"
                      ref={register}
                      onInput={(e) => validateFields(e)}
                      autocomplete="off"
                      placeholder="Enter Expiry number"
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                    />
                  </div>
                  <div className="col-6">
                    <Form.Control
                      className="mt-2  edutech-payment-inout-field"
                      name="cvc"
                      type="text"
                      maxLength="3"
                      ref={register}
                      onInput={(e) => validateFields(e)}
                      autocomplete="off"
                      placeholder="Enter CVC  number"
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                    />
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <Button className="payment-primary" type="submit">
            {/* onClick={props.onHide} */} Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
function PaymentModal(props) {
  const [modalShow, setModalShow] = React.useState(false);
  let studentData = props.stdData;
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Proceed
      </Button>

      <PricingPaymentModal
        {...props}
        show={modalShow}
        onHide={() => setModalShow(false)}
        studentData={studentData}
      />
    </>
  );
}
