import React, { useEffect, useState } from "react";
import { ErrorMessage, Loader } from "../../../../../assets";
import { UpdateCoaching } from "../../../../../redux/actions/coachingAction";
import { useAuth } from "../../../../../Navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import ReviewandSave from "./ReviewAndSave";
import { useLocation } from "react-router-dom";

export default function TeacherUpdateCoaching() {
  let auth = useAuth();
  let update = useSelector((state) => state.coaching);
  let dispatch = useDispatch();
  let location = useLocation();
  console.log("aljshdlkjsada", update);
  const ID =
    location && location.state && location.state.id ? location.state.id : null;
  useEffect(() => {
    dispatch(UpdateCoaching(ID, auth));
  }, [dispatch, ID, auth]);

  const [Coaching, setCoaching] = useState({
    coachingId: 0,
    coachingName: null,
    companyName: null,
    // pricePlan: 0,
    price: 0,
    // dateType: 0,
    interestId: 0,
    isPaid: null,
    isRecurrent: null,
    fixedDate: null,
    startRecurrentDate: null,
    endRecurrentDate: null,
    description: null,
    zoomId: null,
    zoomLink: null,
    zoomPassCode: null,
    videoURL: null,
    coachingRecurrenceId: 103,
    // isPublished: null,
    // userId: 0,
    // roleId: 0,
    status: 0,
  });

  return (
    <>
      {update &&
        update.modifyCoachingLoading === true &&
        update.modifyCoachingSuccess === false &&
        update.modifyCoachingFailure === false && <Loader />}
      {update &&
        update.modifyCoachingLoading === false &&
        update.modifyCoachingSuccess === false &&
        update.modifyCoachingFailure === true && (
          <ErrorMessage message={update.modifyCoachingError} />
        )}
      {update &&
        update.modifyCoachingLoading === false &&
        update.modifyCoachingSuccess === true &&
        update.modifyCoachingFailure === false &&
        ((Coaching.coachingId = update.modifyCoaching.coachingId),
        (Coaching.coachingName = update.modifyCoaching.coachingName),
        (Coaching.companyTitle = update.modifyCoaching.companyTitle),
        // Coaching.pricePlan = update.modifyCoaching.pricePlan,
        (Coaching.price = update.modifyCoaching.price
          ? update.modifyCoaching.price
          : 0),
        // Coaching.dateType = update.modifyCoaching.dateType,
        (Coaching.fixedDate = update.modifyCoaching.fixedDate
          ? update.modifyCoaching.fixedDate
          : null),
        (Coaching.startRecurrentDate = update.modifyCoaching.startRecurrentDate
          ? update.modifyCoaching.startRecurrentDate
          : null),
        (Coaching.endRecurrentDate = update.modifyCoaching.endRecurrentDate
          ? update.modifyCoaching.endRecurrentDate
          : null),
        (Coaching.description = update.modifyCoaching.description),
        (Coaching.zoomId = update.modifyCoaching.zoomId),
        (Coaching.interestId = update.modifyCoaching.interestId),
        (Coaching.zoomLink = update.modifyCoaching.zoomLink),
        (Coaching.isRecurrent = update.modifyCoaching.isRecurrent),
        (Coaching.isPaid = update.modifyCoaching.isPaid),
        (Coaching.zoomPassCode = update.modifyCoaching.zoomPassCode),
        (Coaching.videoURL = update.modifyCoaching.videoURL),
        (Coaching.coachingRecurrenceId =
          update.modifyCoaching.coachingRecurrenceId),
        // Coaching.userId = update.modifyCoaching.userId,
        // Coaching.roleId = update.modifyCoaching.roleId,
        (Coaching.status = update.modifyCoaching.status),
        (
          <>
            <ReviewandSave coaching={Coaching} setCoaching={setCoaching} />
          </>
        ))}
    </>
  );
}
