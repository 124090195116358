import axios from "axios";
import { APP_SETTINGS } from "../../config";
export function interest() {
  return axios.get(`${APP_SETTINGS.API_PATH.INTEREST.interest}`);
}
export function getAllApprovedInterest() {
  return axios.get(`${APP_SETTINGS.API_PATH.INTEREST.getAllApprovedInterest}`);
}
export function getAllUnapprovedInterest(auth) {
  return axios.get(APP_SETTINGS.API_PATH.INTEREST.getAllUnapprovedInterest, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getSelectedInterestsByStudents(auth) {
  return axios.get(APP_SETTINGS.API_PATH.INTEREST.getSelectedInterestsByStudents, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function createInterest(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.INTEREST.createInterest, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function updateSelectedInterests(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.INTEREST.updateSelectedInterests, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function deleteInterest(data, auth) {
  return axios.post(`${APP_SETTINGS.API_PATH.INTEREST.deleteInterest}?interestId=${data.interestId}`, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  }
  );
}
export function publishInterest(data, auth) {
  return axios.post(`${APP_SETTINGS.API_PATH.INTEREST.publishInterest}?InterestId=${data.InterestId}`, { InterestId: data.InterestId }, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  }
  );
}