import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function getRSSFeedInvesting(auth) {
  return axios.get(APP_SETTINGS.API_PATH.RSS_FEED.getRSSFeedInvesting, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getRSSFeedFT(auth) {
  return axios.get(APP_SETTINGS.API_PATH.RSS_FEED.getRSSFeedFT, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getRSSFeedFortune(auth) {
  return axios.get(APP_SETTINGS.API_PATH.RSS_FEED.getRSSFeedFortune, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
