import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, GenericSearchField, IMAGES, Loader } from "../../assets";
import Slider from "react-slick";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { Card } from "react-bootstrap";
import { PATH } from "../../config";
import { useHistory } from "react-router-dom";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { AiOutlineCheck } from "react-icons/ai";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { GetAllApprovedInterest } from "../../redux/actions/interestAction";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRelatedCourses,
  GetTopCategoriesCourses,
  GetTopCourses,
} from "../../redux/actions/coursesAction";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { Create, Remove } from "../../redux/actions";
export default function ExploreDashboard(props) {
  function SetExplore(val) {
    props.setExplore(val);
  }
  var settings = {
    centerMode: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 5,
    dots: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let auth = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllApprovedInterest());
    dispatch(GetRelatedCourses(auth));
    dispatch(GetTopCourses());
  }, []);
  let interestValue = useSelector((state) => state.interest);
  let coursesValuesData = useSelector((state) => state.courses);
  let relatedCourseData =
    coursesValuesData && coursesValuesData.getRelatedCourses;
  let topCourseData = coursesValuesData && coursesValuesData.getTopCourses;
  const setCategories1 = useRef();
  const setCategories2 = useRef();
  function nextslide1() {
    setCategories1.current.slickNext();
  }
  function prevslide1() {
    setCategories1.current.slickPrev();
  }
  function nextslide2() {
    setCategories2.current.slickNext();
  }
  function prevslide2() {
    setCategories2.current.slickPrev();
  }
  return (
    <>
      <ToastContainer />
      <div className="Explore">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-sm-between category position-relative">
            <h2 className="mb-0 mt-1 ml-2 fw-800">Top Catergories</h2>
            <div className="mr-3">
              {/*<GenericSearchField placeholder="search" />*/}
            </div>
          </div>
          <div className="firstcard mt-3  position-relative">
            <div className=" icons right-icon prev-arrow-position ml-lg-n3 cursor">
              <BsArrowLeftShort onClick={prevslide1} />
            </div>
            <div className=" icons edutech-icon right-icon cursor">
              <BsArrowRightShort onClick={nextslide1} />
            </div>
            {interestValue &&
              interestValue.getAllApprovedInterestLoading === true &&
              interestValue.getAllApprovedInterestSuccess === false &&
              interestValue.getAllApprovedInterestFailure === false && (
                <Loader />
              )}
            {interestValue &&
              interestValue.getAllApprovedInterestLoading === false &&
              interestValue.getAllApprovedInterestSuccess === false &&
              interestValue.getAllApprovedInterestFailure === true && (
                <ErrorMessage
                  message={interestValue.getAllApprovedInterestError}
                />
              )}
            {interestValue &&
              interestValue.getAllApprovedInterestLoading === false &&
              interestValue.getAllApprovedInterestSuccess === true &&
              interestValue.getAllApprovedInterestFailure === false && (
                <Slider {...settings} ref={setCategories1}>
                  {interestValue.getAllApprovedInterest.map((list) => {
                    return (
                      <TopCategoryBody list={list} SetExplore={SetExplore} />
                    );
                  })}
                </Slider>
              )}
          </div>
        </div>
        <div className="Courses Coursebackground pt-4 pb-4 pl-2 position-relative mt-4">
          <div className="container-fluid">
            <h2 className="mb-0 mt-1 ml-2 fw-800">Top Courses</h2>
            <p className="topcourses mb-0 ml-2 mt-3">
              Top courses that people in your industry are taking
            </p>
            <div className="firstcard mt-3  position-relative">
              <div className=" icons right-icon prev-arrow-position ml-lg-n3 cursor">
                <BsArrowLeftShort onClick={prevslide2} />
              </div>
              <div className=" icons edutech-icon right-icon cursor">
                <BsArrowRightShort onClick={nextslide2} />
              </div>
              {topCourseData.length === 0 ? (
                <h4 className="text-center  pt-4">No Record Found</h4>
              ) : (
                <>
                  {coursesValuesData &&
                    coursesValuesData.getTopCoursesLoading === false &&
                    coursesValuesData.getTopCoursesSuccess === false &&
                    coursesValuesData.getTopCoursesFailure === true && (
                      <ErrorMessage
                        message={coursesValuesData.getTopCoursesError}
                      />
                    )}
                  {coursesValuesData &&
                    coursesValuesData.getTopCoursesLoading === false &&
                    coursesValuesData.getTopCoursesSuccess === true &&
                    coursesValuesData.getTopCoursesFailure === false && (
                      <Slider {...settings} ref={setCategories2}>
                        {topCourseData.map((list) => {
                          return <TopCoursesBody list={list} />;
                        })}
                      </Slider>
                    )}
                </>
              )}
            </div>
          </div>
          <br></br>
        </div>
        <div className=" related-courses relatedbackground pt-3 pb-4 pl-2 position-relative">
          <div className="container-fluid">
            <h2 className="mb-0 mt-1 ml-2 fw-800 "> Related Courses</h2>
            <p className="topcourses mb-0 ml-2 mt-3">
              Recommended courses based on your topic selection
            </p>
            <div className="firstcard mt-3  position-relative">
              {relatedCourseData.length === 0 ? (
                <h4 className="text-center  pt-4">No Record Found</h4>
              ) : (
                <>
                  <div className="row">
                    {coursesValuesData &&
                      coursesValuesData.getRelatedCoursesLoading === false &&
                      coursesValuesData.getRelatedCoursesSuccess === false &&
                      coursesValuesData.getRelatedCoursesFailure === true && (
                        <ErrorMessage
                          message={coursesValuesData.getRelatedCoursesError}
                        />
                      )}
                    {coursesValuesData &&
                      coursesValuesData.getRelatedCoursesLoading === false &&
                      coursesValuesData.getRelatedCoursesSuccess === true &&
                      coursesValuesData.getRelatedCoursesFailure === false && (
                        <>
                          {relatedCourseData.map((list) => {
                            return (
                              <RelatedCoursesBody
                                list={list}
                                SetExplore={SetExplore}
                              />
                            );
                          })}
                        </>
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function TopCategoryBody(props) {
  let dispatch = useDispatch();
  let history = useHistory();
  let auth = useAuth();
  function onSubmit() {
    dispatch(GetTopCategoriesCourses(props.list.interestId, moveToNext));
  }
  function moveToNext(data) {
    // props.SetExplore(false);
    history.push({
      pathname: PATH.EXPLORECATEGORY,
      data: props.list,
      Courses: "EXPLOREPAGE",
    });
  }
  return (
    <>
      <div className="slide-item mt-1" onClick={() => onSubmit()}>
        <Card className="mx-2 mb-0 cursor">
          <Card.Body className="p-3">
            <div className="Courseiframeheight">
              <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                <img
                  className="img-fluid embed-responsive-item"
                  // src={props.list.image}
                  src={IMAGES.CATEGORY1}
                />
                {/* <div className="wishlist_border">
                  {props.list.wishlist === true ? (
                    <WishListIcon
                      addWishList={addWishList}
                      setAddWishList={setAddWishList}
                    />
                  ) : (
                    <WishListIcon
                      addWishList={addWishList}
                      setAddWishList={setAddWishList}
                    />
                  )}
                </div> */}
              </div>
            </div>
            {/* <div className="explore-img-set wishlist_icon_set">
            <img
                  className="img-fluid embed-responsive-item"
                  src={props.list.image}
                />
              <img src={props.list.image} className="img-fluid" alt="list" />
              <div className="wishlist_border">
                {props.list.wishlist === true ? (
                  <WishListIcon
                    addWishList={addWishList}
                    setAddWishList={setAddWishList}
                  />
                ) : (
                  <WishListIcon
                    addWishList={addWishList}
                    setAddWishList={setAddWishList}
                  />
                )}
              </div>
            </div> */}
          </Card.Body>
        </Card>
        <div className="mt-3 mx-3 text-center">
          <p className="firstpara">{props.list.description}</p>
        </div>
      </div>
    </>
  );
}
function TopCoursesBody(props) {
  const [addWishList, setAddWishList] = useState(null);
  let history = useHistory();
  function moveToNext() {
    history.push({
      pathname: PATH.EXPLORE_COURSE_PREVIEW,
      state: {
        topCourseDataList: props.list,
        topCourses: "TOPCOURSESDATA",
      },
    });
  }
  return (
    <>
      <div
        className="slide-item position-relative mt-4 cursor"
        onClick={() => moveToNext()}
      >
        <Card className="mx-2 mb-0">
          <Card.Body className="p-0">
            <div className="Courseiframeheight">
              <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                <img
                  className="img-fluid embed-responsive-item"
                  src={props.list.coursePreviewPictureURL}
                />
                <div className="wishlist_border">
                  {props.list.wishlist === true ? (
                    <WishListIcon
                      addWishList={addWishList}
                      setAddWishList={setAddWishList}
                      CourseId={props.list.courseId}
                    />
                  ) : (
                    <WishListIcon
                      addWishList={addWishList}
                      setAddWishList={setAddWishList}
                      CourseId={props.list.courseId}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="pl-3 my-2">
              <p className="firstpara text-left m-0 longtextdottedone">
                {" "}
                {props.list.courseTitle}
              </p>
              <p className="text-muted text-left mb-0">
                {" "}
                {props.list.providerName}
              </p>
              <div className="d-flex justify-content-between align-item-center">
                {props.list.price === 0 ? (
                  <p className="firstpara text-left mt-0 pt-0">
                    {" "}
                    {props.list.price - props.list.discountedPrice
                      ? props.list.price - props.list.discountedPrice
                      : "Free"}{" "}
                  </p>
                ) : (
                  <p className="firstpara text-left mt-0 pt-0">
                    {" "}
                    $
                    {props.list.price - props.list.discountedPrice
                      ? props.list.price - props.list.discountedPrice
                      : "Free"}{" "}
                  </p>
                )}

                {props.list.price === 0 ? (
                  ""
                ) : (
                  <span className=" ml-3 mb-0 pricing-discount-line text-muted float-right pr-2">
                    ${props.list.discountedPrice}
                  </span>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
function RelatedCoursesBody(props) {
  const [addWishList, setAddWishList] = useState(null);
  let history = useHistory();
  function moveToNext() {
    history.push({
      pathname: PATH.EXPLORE_COURSE_PREVIEW,
      state: {
        relatedCourseData: props.list,
        Courses: "RELATEDCOURSES",
      },
    });
  }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title className="popover-headre" data-toggle="popover" as="h3">
        {props.list.courseTitle}
      </Popover.Title>
      <Popover.Content>
        <p>
          {props.list.courseDescription
            ? props.list.courseDescription
            : "No Description Yet"}
        </p>
        <div className="d-flex  ">
          <AiOutlineCheck size={16} className="mt-1" />
          <p className="ml-4 ">2.5 hours on demand</p>
        </div>
        <div className="d-flex  ">
          <AiOutlineCheck size={16} className="mt-1" />
          <p className="ml-4 ">6 articles</p>
        </div>
        <div className="d-flex  ">
          <AiOutlineCheck size={16} className="mt-1" />
          <p className="ml-4 ">11 downloadable resources</p>
        </div>
        <div className="d-flex  ">
          <AiOutlineCheck size={16} className="mt-1" />
          <p className="ml-4 ">Full lifetime access</p>
        </div>
        <div className="text-center pb-3">
          <button className="btn-add-to-cart" onClick={() => moveToNext()}>
            View
          </button>
        </div>
      </Popover.Content>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose
        overlay={popover}
      >
        <div className="col-md-3 mt-2 cursor">
          <Card className="mx-2 my-2">
            <Card.Body className="p-0">
              <div className="Courseiframeheight">
                <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                  <img
                    className="img-fluid embed-responsive-item"
                    src={props.list.coursePreviewPictureURL}
                  />
                  <div className="wishlist_border">
                    {props.list.wishlist === true ? (
                      <WishListIcon
                        addWishList={addWishList}
                        setAddWishList={setAddWishList}
                        CourseId={props.list.courseId}
                      />
                    ) : (
                      <WishListIcon
                        addWishList={addWishList}
                        setAddWishList={setAddWishList}
                        CourseId={props.list.courseId}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="pl-2 py-2">
                <p className="firstpara text-left mb-0 longtextdottedone">
                  {" "}
                  {props.list.courseTitle}
                </p>
                <p className="text-muted text-left mb-0">
                  {" "}
                  {props.list.providerName}
                </p>
                <div className="d-flex justify-content-between align-item-center">
                  {props.list.price === 0 ? (
                    <p className="firstpara text-left mt-0 pt-0">
                      {" "}
                      {props.list.price - props.list.discountedPrice
                        ? props.list.price - props.list.discountedPrice
                        : "Free"}{" "}
                    </p>
                  ) : (
                    <p className="firstpara text-left mt-0 pt-0">
                      {" "}
                      $
                      {props.list.price - props.list.discountedPrice
                        ? props.list.price - props.list.discountedPrice
                        : "Free"}{" "}
                    </p>
                  )}
                  {props.list.price === 0 ? (
                    ""
                  ) : (
                    <span className=" ml-3 mb-0 pricing-discount-line text-muted float-right pr-2">
                      ${props.list.discountedPrice}
                    </span>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </OverlayTrigger>
    </>
  );
}
function WishListIcon(props) {
  let auth = useAuth();
  let dispatch = useDispatch();
  function wishlist(e, val) {
    e.stopPropagation();
    props.setAddWishList(val);
    if (props.addWishList !== true) {
      dispatch(Create(props.CourseId, auth, Notification));
    } else {
      dispatch(Remove(props.CourseId, auth, NotificationRemove));
    }
  }
  function Notification(data) {
    toast.success("Added to wishlist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  function NotificationRemove(data) {
    toast.error("Removed from wishlist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      {props.addWishList === true ? (
        <RiHeartFill onClick={(e) => wishlist(e, false)} />
      ) : (
        <RiHeartLine onClick={(e) => wishlist(e, true)} />
      )}
    </>
  );
}
var list1 = [
  {
    id: 0,
    image: IMAGES.VENTURE,
    heading: "Trade Finance",
    wishlist: true,
  },
  {
    id: 1,
    image: IMAGES.DATASTRUCTURE,
    heading: "Crowd Funding",
    wishlist: true,
  },
  {
    id: 2,
    image: IMAGES.MARKETING,
    heading: "Venture Capital ",
    wishlist: true,
  },
  {
    id: 3,
    image: IMAGES.DATASTRUCTURE,
    heading: "Fund Management",
    wishlist: false,
  },
  {
    id: 4,
    image: IMAGES.VENTURE,
    heading: "Capital markets",
    wishlist: false,
  },
  {
    id: 5,
    image: IMAGES.MARKETING,
    heading: "Capital markets",
    wishlist: true,
  },
  {
    id: 6,
    image: IMAGES.VENTURE,
    heading: "Venture Capital",
    wishlist: false,
  },
];
var list2 = [
  {
    id: 0,
    image: IMAGES.VENTURE,
    wishlist: true,
  },
  {
    id: 1,
    image: IMAGES.MARKETING,
    wishlist: false,
  },
  {
    id: 2,
    image: IMAGES.DATASTRUCTURE,
    wishlist: false,
  },
  {
    id: 3,
    image: IMAGES.DEVELOPMENT,
    wishlist: false,
  },
  {
    id: 4,
    image: IMAGES.VENTURE,
    wishlist: false,
  },
  {
    id: 5,
    image: IMAGES.DATASTRUCTURE,
    wishlist: true,
  },
];
var list3 = [
  {
    id: 0,
    image: IMAGES.VENTURE,
    wishlist: true,
  },
  {
    id: 1,
    image: IMAGES.DEVELOPMENT,
    wishlist: false,
  },
  {
    id: 2,
    image: IMAGES.MARKETING,
    wishlist: false,
  },
  {
    id: 3,
    image: IMAGES.DATASTRUCTURE,
    wishlist: false,
  },
  {
    id: 4,
    image: IMAGES.VENTURE,
    wishlist: false,
  },
  {
    id: 5,
    image: IMAGES.MARKETING,
    wishlist: true,
  },
];
