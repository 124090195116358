import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { ToolkitProvider } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Dropdown, DropdownButton, Button, Modal, Form } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";

import paginationFactory from "react-bootstrap-table2-paginator";
const Swal = require("sweetalert2");
function Cancelled() {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#00386e",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Cancelled",
  });
}

function Published() {
  Swal.fire({
    title: "your course has been published",
    //   text: "You won't be able to revert this!",
    icon: "success",
    showCancelButton: false,
    confirmButtonColor: "#00386e",
    //   cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  });
}
function Unpublished() {
  Swal.fire({
    title: "your course has not been published",
    //   text: "You won't be able to revert this!",
    icon: "info",
    showCancelButton: false,
    confirmButtonColor: "#00386e",
    //   cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  });
}
function Pending() {
  Swal.fire({
    title: "your request is Pending",
    //   text: "You won't be able to revert this!",
    icon: "info",
    showCancelButton: false,
    confirmButtonColor: "#00386e",
    //   cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  });
}

class CoachingRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
    };
  }

  columns = [
    {
      dataField: "name",
      text: "	NAME",
    },

    {
      dataField: "status",
      text: "STATUS",
    },
    {
      dataField: "price",
      text: "PRICE",
    },
    {
      dataField: "student",
      text: "STUDENT",
    },
    {
      dataField: "date",
      text: "DATE",
    },
    {
      dataField: "more",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div
            className="float-right font-600 text-secondary d-flex "
            id="clinician-table-three-button-dropdown"
          >
            <div
              className="cursor manage-user"
              id="clinician-table-three-button-dropdown"
            >
              <DropdownButton
                id="clinician-table-three-button-dropdown"
                title={
                  <BsThreeDots
                    id="clinician-table-three-button-dropdown"
                    className="mr-2 cursor"
                  />
                }
                size="sm"
                alignRight
                bsPrefix="three-dot-icon"
                className="edutech-admin-three-dot-icon  clinician-drop-down"
              >
                {/* <Dropdown.Item as="button" className="add-org-field-dropdown"   >

                                    <div className="edutech-admin-dots-inner" >

                                        {"View"}
                                    </div>

                                </Dropdown.Item> */}

                <Dropdown.Item
                  as="button"
                  className="delete-field-dropdown"
                  onClick={() => {
                    Published();
                  }}
                >
                  <div className="edutech-admin-dots-inner">{"Published"}</div>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  className="delete-field-dropdown"
                  onClick={() => {
                    Unpublished();
                  }}
                >
                  <div className="edutech-admin-dots-inner">
                    {"Unpublished"}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  className="delete-field-dropdown"
                  onClick={() => {
                    Pending();
                  }}
                >
                  <div className="edutech-admin-dots-inner">{"Pending"}</div>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  className="delete-field-dropdown"
                  onClick={() => {
                    Cancelled();
                  }}
                >
                  <div className="edutech-admin-dots-inner">{"Cancelled"}</div>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        );
      },

      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];

  products = [
    {
      name: "Dummy Coach Name",
      status: "Publish",
      info: "addres",
      price: "€200",
      student: "12",
      date: "5/13/21",
    },
    {
      name: "orange",
      status: "Unpublish",
      info: "addres",
      price: "€400",
      student: "6",
      date: "5/13/21 - 8/2/21",
    },
  ];

  defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];

  handleDataChange = ({ dataSize }) => {
    this.setState({ rowCount: dataSize });
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  render() {
    return (
      <div>
        <div
          className={`mb-4 card card-custom w-100 bg-dark admin-course-request`}
        >
          <div className="card-header ">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className=" font-weight-700 mb-0">Coaching Requests</h3>
            </div>
          </div>
        </div>
        <div className="admin-side-table-styling card p-4">
          <ToolkitProvider
            bootstrap4
            keyField="id"
            data={this.products}
            columns={this.columns}
            columnToggle
            search
          >
            {(props) => (
              <div>
                <div className="text-left pt-3 pb-4 mt-0 d-flex justify-content-between"></div>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  {...props.baseProps}
                  filter={filterFactory()}
                  pagination={paginationFactory()}
                  defaultSorted={this.defaultSorted}
                  onDataSizeChange={this.handleDataChange}
                  noDataIndication="There is no record"
                  hover
                  condensed
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Modal
          show={this.state.isOpen}
          onHide={this.closeModal}
          className="admin-manage-user"
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Add Coaching Request
          </Modal.Title>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Form.Group>
                    <Form.Label>Tittle</Form.Label>
                    <Form.Control
                      name="coachname"
                      type="text"
                      placeholder="Enter Coach Name"
                    />
                  </Form.Group>
                </div>

                <div className="col-12 col-md-6">
                  <Form.Group>
                    <Form.Label>Add Category</Form.Label>
                    <Form.Control
                      name="category"
                      type="text"
                      placeholder="Enter Category"
                    />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer ClassName="edutech-footter-modal ">
            <Button variant=" edutech-admin-primary " onClick={this.closeModal}>
              Add Request
            </Button>
            {/* <Button variant=" edutech-admin-primary" onClick={this.closeModal} >Cancel</Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CoachingRequests;
