import { LOACTION_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  countriesLoading: false,
  countriesSuccess: false,
  countriesFailure: false,
  countriesError: null,
  countries: [],

  countryByNameLoading: false,
  countryByNameSuccess: false,
  countryByNameFailure: false,
  countryByNameError: null,
  countryByName: [],

  getIndustryLoading: false,
  getIndustrySuccess: false,
  getIndustryFailure: false,
  getIndustryError: null,
  getIndustry: [],
};

export const countryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOACTION_REQUEST.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        countriesLoading: true,
        countriesSuccess: false,
        countriesFailure: false,
        countriesError: null,
      };
    case LOACTION_REQUEST.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countriesLoading: false,
        countriesSuccess: true,
        countriesFailure: false,
        countriesError: null,
        countries: action.payload,
      };
    case LOACTION_REQUEST.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        countriesLoading: false,
        countriesSuccess: false,
        countriesFailure: true,
        countriesError: action.payload,
      };

    case LOACTION_REQUEST.INDUSTRY_BY_TYPE_REQUEST:
      return {
        ...state,
        getIndustryLoading: true,
        getIndustrySuccess: false,
        getIndustryFailure: false,
        getIndustryError: null,
      };
    case LOACTION_REQUEST.INDUSTRY_BY_TYPE_SUCCESS:
      return {
        ...state,
        getIndustryLoading: false,
        getIndustrySuccess: true,
        getIndustryFailure: false,
        getIndustryError: null,
        getIndustry: action.payload,
      };
    case LOACTION_REQUEST.INDUSTRY_BY_TYPE_FAILURE:
      return {
        ...state,
        getIndustryLoading: false,
        getIndustrySuccess: false,
        getIndustryFailure: true,
        getIndustryError: action.payload,
      };

    case LOACTION_REQUEST.COUNTRIES_BY_NAME_REQUEST:
      return {
        ...state,
        countryByNameLoading: true,
        countryByNameSuccess: false,
        countryByNameFailure: false,
        countryByNameError: null,
      };
    case LOACTION_REQUEST.COUNTRIES_BY_NAME_SUCCESS:
      return {
        ...state,
        countryByNameLoading: false,
        countryByNameSuccess: true,
        countryByNameFailure: false,
        countryByNameError: null,
        countryByName: action.payload,
      };
    case LOACTION_REQUEST.COUNTRIES_BY_NAME_FAILURE:
      return {
        ...state,
        countryByNameLoading: false,
        countryByNameSuccess: false,
        countryByNameFailure: true,
        countryByNameError: action.payload,
      };
    default:
      return state;
  }
};
