import axios from "axios";
import { APP_SETTINGS } from "../../config";

export function remove(courseId, auth) {
  return axios.post(`${APP_SETTINGS.API_PATH.COURSEWISHLIST.remove}?courseId=${courseId}`, { courseId: courseId }, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  }
  );
}
export function create(courseId, auth) {
  return axios.post(`${APP_SETTINGS.API_PATH.COURSEWISHLIST.create}?courseId=${courseId}`, { courseId: courseId }, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  }
  );
}
export function getWishlistCourses(auth) {
  return axios.get(APP_SETTINGS.API_PATH.COURSEWISHLIST.getWishlistCourses, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}