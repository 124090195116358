import React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
export function Delete() {
    const Swal = require("sweetalert2");
    const [cookies, remove] = useCookies(["edu_tech_user"]);
    function Delete() {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00386e",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete",
        })

    }
    return (
        <div
            onClick={() => {
                Delete();
            }}
        >
            <div className="logout-text d-flex text-center">
                <Link to="#">  <RiDeleteBinLine className="mr-3" size={25} />{"Delete"}</Link>

            </div>
        </div>
    );
}
