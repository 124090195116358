import axios from "axios";
import { APP_SETTINGS } from "../../config";
  export function pricingPlan(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.PRICING_PLAN.pricingPlan, data, {
      headers: {
        // Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
  export function pricingPlanByFilter(auth) {
    return axios.get(APP_SETTINGS.API_PATH.PRICING_PLAN.pricingPlanByFilter, {
      headers: {
        // Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
  export function getSelectedPricing(auth) {
    return axios.get(APP_SETTINGS.API_PATH.PRICING_PLAN.getSelectedPricing, {
      headers: {
        // Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
  export function deletePricingPlan(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.PRICING_PLAN.deletePricingPlan}?pricingPlanId=${data.pricingPlanId}`, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
    );
  }
  export function createManualPriceRange(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.PRICING_PLAN.createManualPriceRange, data, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
  export function selectPricingPlans(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.PRICING_PLAN.selectPricingPlans, data, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
  export function getManualPricingRange(auth) {
    return axios.get(APP_SETTINGS.API_PATH.PRICING_PLAN.getManualPricingRange, {
      headers: {
        // Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    });
  }
  export function priceCheckWithinRange(price, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.PRICING_PLAN.priceCheckWithinRange}?price=${price}`, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
    );
  }