import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { PATH } from "../../../../../config";
import { useHistory } from "react-router";
import { convertBase64, IMAGES } from "../../../../../assets";
import { useForm } from "react-hook-form";
import { CreateLecture } from "../../../../../redux/actions/coursesAction";
import { useAuth } from "../../../../../Navigation/Auth/ProvideAuth";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../../../../redux/api";
import { Document, Page, pdfjs } from 'react-pdf';

export default function AddResources(props) {
  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  let history = useHistory();
  const CoursesData = props?.CoursesData;
  console.log("asdsadsadqwe", CoursesData)
  let dispatch = useDispatch()
  let auth = useAuth()
  const { handleSubmit } = useForm();
  const [profilePicture, setProfilePicture] = useState(
    CoursesData && CoursesData.lectureResource ? CoursesData.lectureResource : ""
  );
  const [doc, setDoc] = useState(null)
  if (doc !== null) {
    console.log("asjdasd", doc)
  }
  function onSubmit(data) {
    CoursesData.lectureResource = profilePicture
    dispatch(CreateLecture(CoursesData, auth, moveToNext));
  }
  function moveToNext() {
    history.push({
      pathname: PATH.TEACHERCURRICULUMLIST,
    });
  }
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const onChangeProfilePicture = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    if (file) {
      setDoc(file)
      uploadImage(formData).then(
        (response) => {
          console.log("respi", response);
          if (response.data.succeeded === true) {
            setProfilePicture(response.data.data);
          }
        },
        (error) => { }
      );
    }
  };
  return (
    <>
      <p className="font-weight-bold f-16 mb-5">
        Use the file uploader to upload files into your
        lecture.
      </p>
      <Form onSubmit={handleSubmit(onSubmit)} name="myform" id="myform" className="text-left">
        <div className=" row d-flex justify-content-between px-2 pt-4 mt-4">
          <div className="col-md-3 col-lg-3 col-sm-12 py-4">
            <button type="button" className="upload_thumbnail_btn mt-3 resourse">
              <Form.File
                custom
                bsCustomPrefix="form-file-input"
                name="files"
                id="upload-file"
                className="upload_thumbnail_input"
                data-browse="UPLOAD"
                accept="application/pdf"
                // accept="image/*,.doc,.docx,application/msword,application/pdf"
                onChange={(e) => {
                  onChangeProfilePicture(e);
                }}
              />
              Upload file
            </button>
          </div>
          <div className="col-md-9 col-lg-9 col-sm-12  ">
            <div className="card p-4 bg-white set-card-radius ">
              <div className="d-flex justify-content-between px-2 mb-2 pb-4 pt-4 mt-2 align-items-center">
                <div className="col-md-12 col-lg-12 col-sm-12 ">
                  {/* <img src={profilePicture ? profilePicture : ""} className="img-fluid" alt="" /> */}
                  <Document file={doc ? doc : ""} onLoadSuccess={onDocumentLoadSuccess} height="70px" width="40%">
                    <Page pageNumber={pageNumber} />
                  </Document>

                  {/* <iframe title="docs" src={`https://docs.google.com/gview?url=${profilePicture ? profilePicture : ""}}&embedded=true`} height="70px" width="40%"></iframe> */}
                  {/* : */}
                  {/* "" */}
                  {/* } */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Button
            className="edutech-curriculum-action-button edutech-pull-right my-2 font-weight-bold"
            // onClick={goNext}
            type="submit"
          >
            Next
          </Button>
        </div>
      </Form>
      {/* </Link> */}
    </>
  );
}

