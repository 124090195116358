// import React from "react";
// import Highcharts from "highcharts/highstock";
// import PieChart from "highcharts-react-official";

// const options = {
//   chart: {
//     type: "pie",
//   },
//   title: {
//     text: "",
//   },
//   credits: {
//     enabled: false,
//   },
//   plotOptions: {
//     pie: {
//       allowPointSelect: true,
//       cursor: "pointer",
//       dataLabels: {
//         enabled: true,
//         format: "<b>{point.name}</b>: {point.percentage:.1f} %",
//         connectorColor: "silver",
//       },
//     },
//   },
//   series: [
//     {
//       name: "Top Category",
//       data: [
//         { name: "Trade Finance", y: 23, color: "#588bae" },
//         { name: "Venture Capital", y: 33, color: "#89cff0" },
//         { name: "Fund Management", y: 54, color: "#57a0d3" },
//         { name: "Crowd Funding", y: 45, color: "#73c2fb" },
//         { name: "Entrepreneurship", y: 64, color: "#008ecc" },
//         { name: "Trade Finance", y: 22, color: "#0e4d92" },
//       ],
//       showInLegend: true,
//     },
//   ],
// };

// const Topcategpory = () => {
//   return (
//     <div>
//       <PieChart highcharts={Highcharts} options={options} />
//     </div>
//   );
// };

// export default Topcategpory;
import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { AdminDashboardTopCoursesGraph } from "../../../redux/actions/dashboardTilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import {  dataByTopCategory} from "../../../assets";

export function Topcategpory() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let GetTilesCount = useSelector((state) => state.dashboardTiles);
  let CoursesData = GetTilesCount && GetTilesCount.adminDashboardTopCategoriesGraph;
  useEffect(() => {
    // Update the document title using the browser API
    dispatch(AdminDashboardTopCoursesGraph(auth));
    document.title = `Dashboard || EduTech`;
  }, []);
  let pieoption = {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Top Category'
    },
    subtitle: {
      text: ''
    },
    accessibility: {
      announceNewData: {
        enabled: true
      },
      point: {
        valueSuffix: ''
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.y}'
        }
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },
    series: [
      {
        name: "Category",
        colorByPoint: true,
        data: dataByTopCategory(GetTilesCount.adminDashboardTopCategoriesGraph)
      }
    ]
  }
  return (
    <div>
      {
        GetTilesCount
        &&
        GetTilesCount.adminDashboardTopCategoriesGraphSuccess === true
        &&
        <>
          <PieChart highcharts={Highcharts} options={pieoption} />
        </>
      }

    </div>
  )
}