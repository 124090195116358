import React, { useEffect, useState } from "react";
import { Modal, Button, Dropdown, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ErrorMessage, FieldError, Loader } from "../../../assets";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import {
  CreateFaqs,
  GetFAQByProvider,
  GetFAQByStudent,
  UpdateFaqs,
} from "../../../redux/actions";

export default function AdminFaq() {
  let auth = useAuth();
  return (
    <>
      <div className="edutech-content mb-3">
        <div className="d-flex justify-content-end align-items-center">
          {auth.edu_tech_user.roleId === 4 ? (
            ""
          ) : (
            <>
              <Example />
            </>
          )}
        </div>
        <h3>Student</h3>
        <StudentFaqs />
      </div>
      <div className="edutech-content mb-3">
        <h3>Course Provider</h3>
        <CourseProviderFaqs />
      </div>
    </>
  );
}
function Example() {
  const { register, handleSubmit, errors, reset } = useForm();
  let dispatch = useDispatch();
  let auth = useAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [display, setDisply] = useState(false);
  const [question, setQuestion] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [filterSelect, setFilterSelect] = useState(null);
  const selectOption = () => {
    setDisply(true);
  };
  const onSubmit = (data) => {
    let finalData = {
      question: question,
      answer: answer,
      roleId: filterSelect === "Student" ? 3 : 2,
    };
    dispatch(CreateFaqs(finalData, auth, AfterStatusChange, Notificiation));
  };
  function AfterStatusChange() {
    dispatch(GetFAQByStudent());
    dispatch(GetFAQByProvider());
    setDisply(false);
    setQuestion(null);
    setAnswer(null);
  }
  function Notificiation(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  return (
    <>
      <button className="btn-admin-create-faq  " onClick={handleShow}>
        CREATE FAQ
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Faq</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="dropdown_bg_grey">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="text-left "
                >
                  {filterSelect ? filterSelect : "Select Role"}
                  <IoMdArrowDropdown />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow">
                  <Dropdown.Item
                    onClick={() => {
                      setFilterSelect("Student");
                      selectOption();
                    }}
                  >
                    Student
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterSelect("Course Provider");
                      selectOption();
                    }}
                  >
                    Course Provider
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {display ? (
              <div>
                <Form.Group>
                  <Form.Label className="font-weight-bold">Question</Form.Label>
                  <Form.Control
                    className="font-weight-bold edutech-coaching-name-text"
                    name="question"
                    type="text"
                    placeholder="Enter Quetion"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    style={{
                      borderColor: errors && errors.question ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.question && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="font-weight-bold">Answer</Form.Label>
                  <Form.Control
                    className="font-weight-bold edutech-coaching-name-text"
                    name="answer"
                    // type="text"
                    as="textarea"
                    rows={2}
                    placeholder="Enter Answer"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    style={{
                      borderColor: errors && errors.answer ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.answer && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-admin-create-faq"
              variant="primary"
              onClick={() => {
                handleClose();
                onSubmit();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
function StudentFaqs() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetFAQByStudent());
  }, []);
  let auth = useAuth();
  let studentFaqs = useSelector((state) => state.faq);
  let getStudentFaqsList = studentFaqs?.getFAQByStudent;
  const [faqOpen, setFaqOpen] = useState(false);
  return (
    <>
      {studentFaqs &&
        studentFaqs.getFAQByStudentLoading === true &&
        studentFaqs.getFAQByStudentSuccess === false &&
        studentFaqs.getFAQByStudentFailure === false && <Loader />}
      {studentFaqs &&
        studentFaqs.getFAQByStudentLoading === false &&
        studentFaqs.getFAQByStudentSuccess === false &&
        studentFaqs.getFAQByStudentFailure === true && (
          <ErrorMessage message={studentFaqs.getFAQByStudentError} />
        )}
      {studentFaqs &&
        studentFaqs.getFAQByStudentLoading === false &&
        studentFaqs.getFAQByStudentSuccess === true &&
        studentFaqs.getFAQByStudentFailure === false && (
          <div>
            {getStudentFaqsList.map((book, index) => {
              return (
                <div key={index} className="faq_section">
                  <input
                    type="checkbox"
                    id={index}
                    name="q"
                    className="edutech-questions"
                  />
                  <div
                    onClick={() => setFaqOpen(index)}
                    className={`edutech-plus ${
                      faqOpen === index ? "active" : ""
                    }`}
                  >
                    +
                  </div>
                  <label
                    for={index}
                    onClick={() => setFaqOpen(index)}
                    className={`edutech-question ${
                      faqOpen === index ? "active" : ""
                    }`}
                  >
                    {book.question}
                  </label>
                  {auth.edu_tech_user.roleId === 4 ? (
                    ""
                  ) : (
                    <>
                      <div className="faq-edit-icon-style">
                        <StudentFaqModalBody index={index} book={book} />
                      </div>
                    </>
                  )}

                  <div className="edutech-answers">
                    <p>{book.answer} </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
    </>
  );
}
function CourseProviderFaqs() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetFAQByProvider());
  }, []);
  let auth = useAuth();
  let providerFaqs = useSelector((state) => state.faq);
  let getProviderFaqsList = providerFaqs?.getFAQByProvider;
  const [faqOpenProvider, setFaqOpenProvider] = useState(null);
  return (
    <>
      {getProviderFaqsList.map((provider, index) => {
        return (
          <div key={index} className="faq_section">
            <input
              type="checkbox"
              id={provider.faqId}
              name="provider"
              className="edutech-questions"
            />
            <div
              onClick={() => setFaqOpenProvider(faqOpenProvider)}
              className={`edutech-plus ${
                faqOpenProvider === true ? "active" : ""
              }`}
            >
              +
            </div>
            <label
              for={provider.faqId}
              onClick={() => setFaqOpenProvider(faqOpenProvider)}
              className={`edutech-question ${
                faqOpenProvider === true ? "active" : ""
              }`}
            >
              {provider.question}
            </label>
            {auth.edu_tech_user.roleId === 4 ? (
              ""
            ) : (
              <>
                <div className="faq-edit-icon-style">
                  <ProviderFaqModalBody index={index} provider={provider} />
                </div>
              </>
            )}

            <div className="edutech-answers">
              <p>{provider.answer} </p>
            </div>
          </div>
        );
      })}
    </>
  );
}
function StudentFaqModalBody(props) {
  const [show, setShow] = useState(false);
  let dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit, reset, errors } = useForm();
  const [questionEdit, setQuestionEdit] = useState("");
  const [answerEdit, setAnswerEdit] = useState("");
  let auth = useAuth();
  const onSubmit = (data) => {
    let finalData = {
      question: questionEdit,
      answer: answerEdit,
      roleId: 3,
      faqId: props.book.faqId,
    };
    dispatch(UpdateFaqs(finalData, auth, Notificiation, MoveToNext));
  };
  function MoveToNext() {
    dispatch(GetFAQByStudent());
    handleClose();
  }
  function Notificiation(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  useEffect(() => {
    setQuestionEdit(props.book.question ? props.book.question : null);
    setAnswerEdit(props.book.answer ? props.book.answer : null);
  }, [props.book]);
  return (
    <>
      <FaEdit onClick={handleShow} />
      <Modal
        {...props}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit Faq</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div>
              <Form.Group>
                <Form.Label className="font-weight-bold">Question</Form.Label>
                <Form.Control
                  className="font-weight-bold edutech-coaching-name-text"
                  name="questionEdit"
                  type="text"
                  placeholder="Enter Question"
                  value={questionEdit}
                  onChange={(e) => setQuestionEdit(e.target.value)}
                  style={{
                    borderColor: errors && errors.questionEdit ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.questionEdit && (
                  <FieldError message={"This Field is Required"} />
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold">Answer</Form.Label>
                <Form.Control
                  className="font-weight-bold edutech-faqs-name-text"
                  name="answerEdit"
                  // type="text"
                  as="textarea"
                  rows={4}
                  placeholder="Enter Answer"
                  value={answerEdit}
                  onChange={(e) => setAnswerEdit(e.target.value)}
                  style={{
                    borderColor: errors && errors.answerEdit ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.answerEdit && (
                  <FieldError message={"This Field is Required"} />
                )}
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-admin-create-faq"
              variant="primary"
              type="submit"
              // onClick={() => {
              //   handleClose();
              // }}
            >
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
function ProviderFaqModalBody(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let dispatch = useDispatch();
  let auth = useAuth();
  const { register, handleSubmit, reset, errors } = useForm();
  const [questionEdit, setQuestionEdit] = useState("");
  const [answerEdit, setAnswerEdit] = useState("");
  const onSubmit = (data) => {
    let finalData = {
      question: questionEdit,
      answer: answerEdit,
      roleId: 2,
      faqId: props.provider.faqId,
    };
    dispatch(UpdateFaqs(finalData, auth, Notificiation, MoveToNext));
  };
  function MoveToNext() {
    dispatch(GetFAQByProvider());
    handleClose();
  }
  function Notificiation(data, condition) {
    reset();
    condition === "error"
      ? toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  }
  useEffect(() => {
    setQuestionEdit(props.provider.question ? props.provider.question : null);
    setAnswerEdit(props.provider.answer ? props.provider.answer : null);
  }, [props.provider]);
  return (
    <>
      <FaEdit onClick={handleShow} />
      <Modal
        {...props}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit Faq</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div>
              <Form.Group>
                <Form.Label className="font-weight-bold">Question</Form.Label>
                <Form.Control
                  className="font-weight-bold edutech-coaching-name-text"
                  name="questionEdit"
                  type="text"
                  placeholder="Enter Question"
                  value={questionEdit}
                  onChange={(e) => setQuestionEdit(e.target.value)}
                  style={{
                    borderColor: errors && errors.questionEdit ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.questionEdit && (
                  <FieldError message={"This Field is Required"} />
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold">Answer</Form.Label>
                <Form.Control
                  className="font-weight-bold edutech-faqs-name-text"
                  name="answerEdit"
                  // type="text"
                  as="textarea"
                  rows={4}
                  placeholder="Enter Answer"
                  value={answerEdit}
                  onChange={(e) => setAnswerEdit(e.target.value)}
                  style={{
                    borderColor: errors && errors.answerEdit ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.answerEdit && (
                  <FieldError message={"This Field is Required"} />
                )}
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-admin-create-faq"
              variant="primary"
              type="submit"
              // onClick={() => {
              //   handleClose();
              // }}
            >
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
