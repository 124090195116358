import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton} from "react-bootstrap";
import { BsArrowDown, BsArrowUp, BsThreeDots } from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage, Loader } from "../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { GetAllApprovedInterest, Interest, PublishInterest } from "../../../redux/actions/interestAction";
import { RiArrowUpDownFill } from "react-icons/ri";

export default function RequestCategory() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let getListing = useSelector((state) => state.interest);
  useEffect(() => {
    dispatch(Interest(auth));
    document.title = `Coaching || EduTech`;
  }, [dispatch]);
  let data = getListing && getListing.interest;
  let columns = [
    {
      dataField: "studentName",
      text: "Student Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "description",
      text: "Category",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "isApproved",
      text: "Status",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            {row.isApproved === false ? (
              <span className="unpublish-btn-color">{row.isApproved !== "false" ? "Unapproved" : ""} </span>
            ) : (
              <span className="publish-btn-color">
                {" "}
                {row.isApproved !== "false" ? "Approved" : ""}
              </span>
            )}
          </span>
        );
      },
    },
    {
      dataField: "more",
      text: "Actions",
      classes: "align-middle table-right-hover-class edutech",
      formatter: (cell, row) => {
        return (
          <>
            <DropDownData row={row} />
          </>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      <ToastContainer />
      <div className="teacher-curriculum-dashboard ">
        <div className="d-flex align-items-center justify-content-end ">
        </div>
      </div>
      <hr />
      <>
        {getListing &&
          getListing.interestLoading === true &&
          getListing.interestSuccess === false &&
          getListing.interestFailure === false && <Loader />}
        {getListing &&
          getListing.interestLoading === false &&
          getListing.interestSuccess === false &&
          getListing.interestFailure === true && (
            <ErrorMessage message={getListing.interestError} />
          )}
        {getListing &&
          getListing.interestLoading === false &&
          getListing.interestSuccess === true &&
          getListing.interestFailure === false && (
            <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
              <MyDataTable
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
              // handleDataChange={handleDataChange}
              />
            </div>
          )}
      </>
    </>
  );
}
function DropDownData(props) {
  let dispatch = useDispatch();
  let auth = useAuth();
  const [loading, setLoading] = useState(false);
  function Accept() {
    let finalData = {
      InterestId: props.row.interestId,
    };
    dispatch(PublishInterest(finalData, auth, AfterStatusChange, Notification));
  }
  function Reject() {
    let finalData = {
      InterestId: props.row.interestId,
    };
    dispatch(PublishInterest(finalData, auth, AfterStatusChange, Notification));
  }
  function AfterStatusChange(){
    dispatch(Interest(auth));
    dispatch(GetAllApprovedInterest());
}
  // function AfterStatusChange() {
  //   dispatch(GetCoachingList(auth));
  // }
  function Notification(data) {
    toast.success(data, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      <div
        className="manage-user float-right font-600 text-secondary d-flex"
        id="clinician-table-three-button-dropdown"
      >
        <div className="cursor" id="clinician-table-three-button-dropdown">
          <DropdownButton
            id="clinician-table-three-button-dropdown"
            title={
              <BsThreeDots
                id="clinician-table-three-button-dropdown"
                className="mr-2 cursor"
              />
            }
            size="sm"
            alignRight
            bsPrefix="three-dot-icon"
            className="edutech-admin-three-dot-icon  clinician-drop-down"
          >
            <>
              {props.row.isApproved !== false ? (
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    Reject();
                  }}
                >
                  Unapprove
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    Accept();
                  }}
                >
                  Approve
                </Dropdown.Item>
              )}
            </>
            {/* <Dropdown.Item
                  as="button"
                  className="delete-field-dropdown"
                  onClick={() => {
                    Accept();
                  }}
                >
                  <div className="edutech-admin-dots-inner">
                    {"Accept"}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  className="delete-field-dropdown"
                  onClick={() => {
                    Reject();
                  }}
                >
                  <div className="edutech-admin-dots-inner">{"Reject"}</div>
                </Dropdown.Item> */}
          </DropdownButton>
        </div>
      </div>
    </>
  );
}