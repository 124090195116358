import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Dropdown } from "react-bootstrap";
import { FieldError } from "../../assets/genericComponents/ErrorMessage";
import { INDEPENDENT_PROVIDER, PATH, PROVIDING_SERVICES } from "../../config";
import { Link, useHistory } from "react-router-dom";
import { Loader, ErrorMessage, IMAGES } from "../../assets";
import { FiCheckCircle } from "react-icons/fi";
import { FaCamera } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import Select from "react-select";
import {
  getCountries,
  GetIndustry,
  GetUserProfile,
  GetUserStudentProfile,
  UpdateProviderProfile,
  UpdateStudentProfile,
} from "../../redux/actions";
import { uploadImage } from "../../redux/api";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { AREA_OF_INTEREST, INDUSTRY_TYPE } from "../../assets/LookUpdata";
import { GetAllApprovedInterest } from "../../redux/actions/interestAction";
export default function ProfileEdit() {
  useEffect(() => {
    document.title = "Profile || EduTech";
  }, []);
  let history = useHistory();
  const [selectFont, setSelectFont] = useState(null);
  return (
    <div className="profile-view">
      <div className="row">
        <div className="col-12 col-xl-12 col-lg-12">
          {history.location.pathname === PATH.STUDENT_PROFILEEDIT && (
            <StudentProfile />
          )}
          {history.location.pathname === PATH.TEACHER_PROFILEEDIT && (
            <TeacherProfile />
          )}
        </div>
        {/* <div className="col-12 col-xl-3 col-lg-3">
          <div className="card border-0">
            <div className="card-body mode-select">
              <Form.Group className="d-flex align-items-center justify-content-between">
                <Form.Label>Dark mode</Form.Label>
                <label className="switch">
                  <input type="checkbox" defaultChecked />
                  <span className="slider round" />
                </label>
              </Form.Group>
              <Form.Group className="dropdown_bg_white">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="form-label text-left bg-transparent pl-0"
                  >
                    {selectFont ? selectFont : "Select font"}
                    <IoMdArrowDropdown />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow">
                    <Dropdown.Item onClick={() => setSelectFont("16")}>
                      16
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectFont("18")}>
                      18
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectFont("20")}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectFont("22")}>
                      22
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
function StudentProfile() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let profile = useSelector((state) => state.userProfile);
  useEffect(() => {
    dispatch(GetUserStudentProfile(auth));
  }, [dispatch]);
  return (
    <>
      {profile &&
        profile.getUserStudentProfileLoading === true &&
        profile.getUserStudentProfileSuccess === false &&
        profile.getUserStudentProfileFailure === false && <Loader />}
      {profile &&
        profile.getUserStudentProfileLoading === false &&
        profile.getUserStudentProfileSuccess === false &&
        profile.getUserStudentProfileFailure === true && (
          <ErrorMessage message={profile.getUserStudentProfileError} />
        )}
      {profile &&
        profile.getUserStudentProfileLoading === false &&
        profile.getUserStudentProfileSuccess === true &&
        profile.getUserStudentProfileFailure === false && (
          <>
            {profile &&
              profile.getUserStudentProfile &&
              profile.getUserStudentProfile.map((data, index) => {
                return <StudentProfileBody data={data} index={index} />;
              })}
          </>
        )}
    </>
  );
}
function StudentProfileBody(props) {
  let dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    dispatch(GetAllApprovedInterest());
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetIndustry("IndustryType"));
  }, [dispatch]);
  const [interests, setInterests] = useState();
  let interestValue = useSelector((state) => state.country);
  let industryValue = useSelector((state) => state.interest);
  console.log("asssssslog", interests);
  let auth = useAuth();
  const [searchList, setSearchList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const updateSearch = (search) => {
    setSearchValue(search);
    let filterSearch = interestValue.getIndustry;
    filterSearch = interestValue.getIndustry.filter((list) => {
      return list.value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    setSearchList(filterSearch);
  };
  useEffect(() => {
    setSearchList(interestValue.getIndustry);
  }, [interestValue]);
  let emailData = auth.edu_tech_user.email;
  let profile = useSelector((state) => state.userProfile);
  const { register, handleSubmit, errors } = useForm();
  useEffect(() => {
    let getInterests =
      props &&
      props.data &&
      props.data.studentInterestDto &&
      props.data.studentInterestDto.map((item) => {
        return {
          interestId: item.interestId,
          order: null,
          status: null,
          description: item.interestName,
          type: "AreaOfInterest",
          value: item.interestName.replace(/ /g, ""),
        };
      });
    setInterests(getInterests);
  }, [dispatch]);
  const [IndustryTypeError, setIndustryTypeError] = useState(null);

  const [fname, setFName] = useState(
    props && props.data && props.data.firstName ? props.data.firstName : ""
  );
  const [lname, setLname] = useState(
    props && props.data && props.data.lastName ? props.data.lastName : ""
  );
  const [email, setEmail] = useState(emailData);
  const [about, setAbout] = useState(
    props && props.data && props.data.aboutUs ? props.data.aboutUs : ""
  );
  const [profilePicture, setProfilePicture] = useState(
    props && props.data && props.data.photo ? props.data.photo : ""
  );
  let IndustryTypevalue = null;
  searchList.map((item, i) => {
    if (props.data.industryWorkName === item.text) {
      IndustryTypevalue = item.text;
    }
  });
  const [IndustryType, setIndustryType] = useState(
    IndustryTypevalue ? IndustryTypevalue : props.data.industryWorkName
  );

  const handleInterestsChange = (value) => {
    setInterests(value);
  };
  const [job, setJob] = useState(
    props && props.data && props.data.jobTitle ? props.data.jobTitle : ""
  );
  const [experience, setExperience] = useState(
    props && props.data && props.data.experience ? props.data.experience : ""
  );
  function setIndustryValuee(val) {
    setIndustryType(val);
    setIndustryTypeError(false);
  }
  const onChangeProfilePicture = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file?.name);
    if (file) {
      setProfilePicture(true);
      uploadImage(formData).then(
        (response) => {
          if (response.data.succeeded === true) {
            setProfilePicture(response.data.data);
          }
        },
        (error) => {}
      );
    }
  };
  const StudentProfile = {
    firstName: "",
    lastName: "",
    email: "",
    photo: "",
    industryId: 0,
    jobTitle: "",
    aboutUs: "",
    experience: 0,
    studentInterest: [],
  };
  searchList.map((item, i) => {
    if (item.text === IndustryType) {
      StudentProfile.industryId = item.lookUpId;
    } else {
      StudentProfile.industryId = item.lookUpId;
    }
  });
  function onSubmit(data) {
    let filterArray = [];
    for (let i = 0; i < interests.length; i++) {
      filterArray.push(interests?.[i]?.interestId);
    }
    if (IndustryType == null) {
      setIndustryTypeError(true);
    } else {
      StudentProfile.firstName = data.fname;
      StudentProfile.lastName = data.lname;
      StudentProfile.email = emailData;
      StudentProfile.photo = profilePicture;
      StudentProfile.aboutUs = data.about;
      StudentProfile.jobTitle = data.job;
      StudentProfile.experience = parseInt(data.experience);
      StudentProfile.studentInterest = filterArray;
      searchList.map((item, i) => {
        if (item.text === IndustryType) {
          StudentProfile.industryId = item.lookUpId;
        }
      });
    }
    dispatch(UpdateStudentProfile(StudentProfile, auth, moveToNext));
  }
  function moveToNext() {
    history.push(PATH.STUDENT_PROFILESHOW);
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="card border-0">
        <div className="card-body">
          <div className="img-sec mb-5">
            <img
              alt=""
              className="img-fluid"
              src={profilePicture ? profilePicture : IMAGES.AVATAR}
            />
            <div className="add-pictures">
              <FaCamera size={35} />
            </div>
            <Form.File
              custom
              bsCustomPrefix="form-file-input"
              name="files"
              id="upload-file"
              data-browse="UPLOAD"
              accept="image/*"
              onChange={(e) => {
                onChangeProfilePicture(e);
              }}
            />
          </div>
          <p className="image-size-style">(Upto 1 Mb)</p>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              className="mt-1"
              name="fname"
              type="text"
              value={fname}
              onChange={(e) => {
                setFName(e.target.value);
              }}
              placeholder="Enter First Name"
              style={{
                borderColor: errors && errors.fname ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {fname !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.fname && <FieldError message={"This Field is Required"} />}
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              className="mt-1"
              name="lname"
              type="text"
              value={lname}
              onChange={(e) => {
                setLname(e.target.value);
              }}
              style={{
                borderColor: errors && errors.lname ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {lname !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.lname && <FieldError message={"This Field is Required"} />}
          </Form.Group>
          <Form.Group>
            <Form.Control
              className="mt-1"
              name="email"
              type="email"
              hidden
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{
                borderColor: errors && errors.email ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {errors.email && <FieldError message={"This Field is Required"} />}
          </Form.Group>
          <Form.Group>
            <Form.Label>Industry</Form.Label>
            <div className="dropdown_bg_grey industry-type-parent-class">
              <Dropdown alignRight>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="text-left "
                >
                  {IndustryType ? IndustryType : "Select Industry"}
                  <IoMdArrowDropdown />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow country-dropdown">
                  <div className="search_field">
                    <Form.Control
                      name="searchLocation"
                      type="text"
                      autocomplete="off"
                      value={searchValue}
                      onChange={(e) => {
                        updateSearch(e.target.value);
                      }}
                      ref={register()}
                    />
                  </div>
                  {searchList.length === 0 ? (
                    <p className="searchfilter-text-style">No Record Found</p>
                  ) : (
                    <>
                      {searchList &&
                        searchList.map((item, index) => {
                          return (
                            <Dropdown.Item
                              onClick={() => setIndustryValuee(item.text)}
                              key={index}
                            >
                              {item.text}
                            </Dropdown.Item>
                          );
                        })}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {IndustryTypeError && (
                <FieldError message={"This Field is Required"} />
              )}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Job title</Form.Label>
            <Form.Control
              className="mt-1"
              name="job"
              type="text"
              value={job}
              onChange={(e) => {
                setJob(e.target.value);
              }}
              style={{ borderColor: errors && errors.job ? "#a80000" : "" }}
              ref={register({ required: true })}
            />
            {job !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.job && <FieldError message={"This Field is Required"} />}
          </Form.Group>
          <Form.Group>
            <Form.Label>Experience</Form.Label>
            <Form.Control
              className="mt-1"
              name="experience"
              type="number"
              value={experience}
              onChange={(e) => {
                setExperience(e.target.value);
              }}
              style={{
                borderColor: errors && errors.experience ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {experience !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.experience && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Area of interests</Form.Label>
            <Select
              className="mt-1"
              isMulti
              name="studentInterest"
              options={industryValue.getAllApprovedInterest}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => parseInt(option.interestId)}
              onChange={(val) => handleInterestsChange(val)}
              value={interests}
            />
            {errors.studentInterest && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>About me</Form.Label>
            <Form.Control
              className="mt-1"
              name="about"
              as="textarea"
              maxlength="200"
              rows={4}
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
              style={{
                borderColor: errors && errors.about ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {about !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.about && <FieldError message={"This Field is Required"} />}
          </Form.Group>
        </div>
      </div>
      <div className="text-right mb-3">
        <Link to={PATH.STUDENT_PROFILESHOW}>
          <Button type="button" className="cancel_button mr-2">
            {"Cancel"}
          </Button>
        </Link>
        {profile && profile.updateStudentProfileLoading === false && (
          <Button type="submit" className="update_button">
            {"Update Profile"}
          </Button>
        )}
        {profile && profile.updateStudentProfileLoading === true && (
          <Button className="bg-transparent border-0">
            <Loader />
          </Button>
        )}
      </div>
    </Form>
  );
}
function TeacherProfile() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let profile = useSelector((state) => state.userProfile);
  useEffect(() => {
    dispatch(GetUserProfile(auth));
  }, [dispatch]);
  return (
    <>
      {profile &&
        profile.getUserProfileLoading === true &&
        profile.getUserProfileSuccess === false &&
        profile.getUserProfileFailure === false && <Loader />}
      {profile &&
        profile.getUserProfileLoading === false &&
        profile.getUserProfileSuccess === false &&
        profile.getUserProfileFailure === true && (
          <ErrorMessage message={profile.getUserProfileError} />
        )}
      {profile &&
        profile.getUserProfileLoading === false &&
        profile.getUserProfileSuccess === true &&
        profile.getUserProfileFailure === false && (
          <>
            {profile &&
              profile.getUserProfile &&
              profile.getUserProfile.map((data, index) => {
                return <TeacherProfileBody data={data} index={index} />;
              })}
          </>
        )}
    </>
  );
}
function TeacherProfileBody(props) {
  let dispatch = useDispatch();
  let history = useHistory();
  let auth = useAuth();
  let emailData = auth.edu_tech_user.email;
  let profile = useSelector((state) => state.userProfile);
  let country = useSelector((state) => state.country);
  const { register, handleSubmit, errors } = useForm();

  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [fname, setFName] = useState(
    props && props.data && props.data.firstName ? props.data.firstName : ""
  );
  const [lname, setLname] = useState(
    props && props.data && props.data.lastName ? props.data.lastName : ""
  );
  const [email, setEmail] = useState(emailData);
  const [about, setAbout] = useState(
    props && props.data && props.data.aboutUs ? props.data.aboutUs : ""
  );
  const [profilePicture, setProfilePicture] = useState(
    props && props.data && props.data.photo ? props.data.photo : ""
  );
  const [provider, setProvider] = useState(
    props &&
      props.data &&
      props.data.providerStatus &&
      props.data.providerStatus
      ? props.data.providerStatus
      : ""
  );
  const [providerId, setProviderId] = useState("");
  const [Locationselect, setLocationselect] = useState(
    props && props.data && props.data.locationName
      ? props.data.locationName
      : null
  );

  const [LocationselectId, setLocationselectId] = useState("");
  const [nicheMarket, setNicheMarket] = useState(
    props && props.data && props.data.region ? props.data.region : ""
  );
  const [nicheMarketId, setNicheMarketId] = useState("");
  const [experience, setExperience] = useState(
    props && props.data && props.data.experience ? props.data.experience : ""
  );
  const onChangeProfilePicture = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    if (file) {
      setProfilePicture(true);
      uploadImage(formData).then(
        (response) => {
          if (response.data.succeeded === true) {
            setProfilePicture(response.data.data);
          }
        },
        (error) => {}
      );
    }
  };
  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);
  const updateSearch = (search) => {
    setSearchValue(search);
    let filterSearch = country.countries;
    filterSearch = country.countries.filter((list) => {
      return list.value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    setSearchList(filterSearch);
  };
  useEffect(() => {
    setSearchList(country.countries);
  }, [country]);
  console.log("asdasdsadaqq", profile);
  function onSubmit(data) {
    debugger;
    // if (provider === "Yes") {
    //   data.isIndependentProvider = true;
    // } else {
    //   data.isIndependentProvider = false;
    // }
    country.countries.map((item, i) => {
      if (item.value === Locationselect) {
        data.locationId = item.countryId;
      }
    });
    data.locationId = LocationselectId
      ? LocationselectId
      : profile?.getUserProfile?.[0]?.locationId;
    data.providerId = auth.edu_tech_user.userId;
    data.regionId = nicheMarketId
      ? nicheMarketId
      : profile?.getUserProfile?.[0]?.regionId;
    data.providerStatusId = providerId
      ? providerId
      : profile?.getUserProfile?.[0]?.providerStatusId;
    data.experience = parseInt(data.experience);
    data.email = emailData;
    data.photo = profilePicture;
    dispatch(UpdateProviderProfile(data, auth, moveToNext));
  }
  function moveToNext() {
    history.push(PATH.TEACHER_PROFILESHOW);
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="card border-0">
        <div className="card-body">
          <div className="img-sec mb-5">
            <img
              alt=""
              className="img-fluid"
              src={profilePicture ? profilePicture : IMAGES.AVATAR}
            />{" "}
            <div className="add-pictures">
              <FaCamera size={35} />
            </div>
            <Form.File
              custom
              bsCustomPrefix="form-file-input"
              name="files"
              id="upload-file"
              data-browse="UPLOAD"
              accept="image/*"
              onChange={(e) => {
                onChangeProfilePicture(e);
              }}
            />
          </div>
          <p className="image-size-style">(Upto 1 Mb)</p>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              className="mt-1"
              name="firstName"
              type="text"
              value={fname}
              onChange={(e) => {
                setFName(e.target.value);
              }}
              placeholder="Enter First Name"
              style={{
                borderColor: errors && errors.firstName ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {fname !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.firstName && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              className="mt-1"
              name="lastName"
              type="text"
              value={lname}
              onChange={(e) => {
                setLname(e.target.value);
              }}
              style={{
                borderColor: errors && errors.lastName ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {lname !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.lastName && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Control
              className="mt-1"
              name="email"
              type="email"
              hidden
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{
                borderColor: errors && errors.email ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
          </Form.Group>
          <Form.Group className="dropdown_bg_grey">
            <Form.Label>Are you?</Form.Label>
            <Dropdown alignRight>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="text-left"
                name="locationId"
              >
                {provider ? provider : "Select"}
                <IoMdArrowDropdown />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow search_field">
                {INDEPENDENT_PROVIDER &&
                  INDEPENDENT_PROVIDER.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setProvider(item.text);
                          setProviderId(item.lookupId);
                        }}
                      >
                        {item.text}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group className="dropdown_bg_grey">
            <Form.Label>Region</Form.Label>
            <Dropdown alignRight>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="text-left "
                name="locationId"
              >
                {nicheMarket ? nicheMarket : "Select"}
                <IoMdArrowDropdown />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow search_field">
                {PROVIDING_SERVICES &&
                  PROVIDING_SERVICES.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setNicheMarket(item.text);
                          setNicheMarketId(item.lookupId);
                        }}
                      >
                        {item.text}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group>
            <Form.Label>Experience</Form.Label>
            <Form.Control
              className="mt-1"
              name="experience"
              type="number"
              value={experience}
              onChange={(e) => {
                setExperience(e.target.value);
              }}
              style={{
                borderColor: errors && errors.experience ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {experience !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.experience && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group className="dropdown_bg_grey height_adjust_dropdown">
            <Form.Label>Location</Form.Label>
            <Dropdown alignRight>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="text-left"
                name="locationId"
              >
                {Locationselect ? Locationselect : "Select"}
                <IoMdArrowDropdown />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow search_field">
                <div className="search_field">
                  <Form.Control
                    name="searchLocation"
                    type="text"
                    autocomplete="off"
                    value={searchValue}
                    onChange={(e) => {
                      updateSearch(e.target.value);
                    }}
                    ref={register()}
                  />
                </div>
                {searchList &&
                  searchList.map((country, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setLocationselect(country.value);
                          setLocationselectId(country.countryId);
                        }}
                      >
                        {country.value}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group>
            <Form.Label>About me</Form.Label>
            <Form.Control
              className="mt-1"
              maxlength="200"
              name="aboutUs"
              as="textarea"
              rows={4}
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
              style={{
                borderColor: errors && errors.aboutUs ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {about !== "" ? <FiCheckCircle className="check-icon" /> : ""}
            {errors.aboutUs && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
        </div>
      </div>
      <div className="text-right mb-3">
        <Link to={PATH.TEACHER_PROFILESHOW}>
          <Button type="button" className="cancel_button mr-2">
            {"Cancel"}
          </Button>
        </Link>
        {profile && profile.updateProviderProfileLoading === false && (
          <Button type="submit" className="update_button">
            {"Update Profile"}
          </Button>
        )}
        {profile && profile.updateProviderProfileLoading === true && (
          <Button className="bg-transparent border-0">
            <Loader />
          </Button>
        )}
      </div>
    </Form>
  );
}
